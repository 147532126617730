import React from 'react';
import { Link } from 'react-scroll';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import { Accordion, Card } from 'react-bootstrap';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

export default function TextMessageUseCaseNaviScrollSpy() {
  return (
    <div className='col-xs-12 col-lg-3 order-lg-2'>
      <Accordion defaultActiveKey='0' className='accordion-wrap'>
        <h2 className='faq-navi-header'>Text Message Navigation</h2>
        <Card>
          <Card.Header className='accordion-header'>
            <p className='active-link'>
              <Link
                to='intro-text'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Introduction to Text Messages
              </Link>
            </p>
            <Accordion.Toggle variant='link' eventKey='0' className='carret'>
              <ArrowDropDownIcon />
            </Accordion.Toggle>
          </Card.Header>

          <Accordion.Collapse eventKey='0'>
            <Card.Body>
              <ul className='tutorial-side-nav'>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='prerequisites'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Prerequisites Before Creating and sending a Text Message
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='navigation'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Navigate to Inbox
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link'>
                    <Link
                      to='understanding-sms'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Understanding The Impact of Text Message Marketing
                    </Link>
                  </p>
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header className='accordion-header'>
            <p className='active-link'>
              <Link
                to='text-create'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Creating and Sending a Text Message
              </Link>
            </p>
          </Card.Header>
        </Card>

        <Card>
          <Card.Header className='accordion-header'>
            <p className='active-link'>
              <Link
                to='best-sms-practices'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Best Practices for Text Message Marketing
              </Link>
            </p>
            <Accordion.Toggle variant='link' eventKey='1' className='carret'>
              <ArrowDropDownIcon />
            </Accordion.Toggle>
          </Card.Header>

          <Accordion.Collapse eventKey='1'>
            <Card.Body>
              <ul className='tutorial-side-nav'>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='gen-leads'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Generate Leads
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='permissions'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Get Acknowledgment From Your Customers
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link'>
                    <Link
                      to='short-sweet'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Keep It Short and Sweet
                    </Link>
                  </p>
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header className='accordion-header'>
            <p className='active-link'>
              <Link
                to='do-dont'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Do's and Don'ts
              </Link>
            </p>
          </Card.Header>
        </Card>

        {/* <Card>
          <Card.Header className='accordion-header'>
            <p className='active-link'>
              <Link
                to='video-demo'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Video Demonstration
              </Link>
            </p>
          </Card.Header>
        </Card> */}
      </Accordion>

      <Accordion className='position-fixed bottom-nav-wrap'>
        <Card>
          <Card.Header className='accordion-header'>
            <div className='header-wrap'>
              <p className='mobile-nav-header'>Jump To...</p>
              <Accordion.Toggle variant='link' eventKey='0' className='carret'>
                <ArrowDropUpIcon />
              </Accordion.Toggle>
            </div>
          </Card.Header>

          <Accordion.Collapse eventKey='0'>
            <Card.Body>
              <ul className='tutorial-side-nav'>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='task-text'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Introduction to Text Messages
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='prerequisites'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Prerequisites Before Creating and Sending a Text Message
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='navigation'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Navigate to Inbox
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='understanding-sms'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Understanding The Impact of Text Message Marketing
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='text-create'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Creating and Sending a Text Message
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='best-sms-practices'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Best Practices for Text Message Marketing
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='gen-leads'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Generate Leads
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='permissions'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Get Acknowledgment From Your Customers
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='short-sweet'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Keep It Short and Sweet
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link'>
                    <Link
                      to='do-dont'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Do's and Don'ts
                    </Link>
                  </p>
                </li>
                {/* <li>
                  <p className='active-sub-link'>
                    <Link
                      to='video-demo'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Video Demonstration
                    </Link>
                  </p>
                </li> */}
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}
