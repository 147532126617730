import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../generalCss/style.scss';
import './home.scss';
import { HashLink as Link } from 'react-router-hash-link';
import $ from 'jquery';
// import Zoom from 'react-reveal/Zoom';
// import Fade from 'react-reveal/Fade';
import HomeBannerImg from '../img/phone-example2.png';
import ContactHomeImg from '../img/contact-page.png';
import TaskHomeImg from '../img/tasks.png';
import CrossPlatformImg from '../img/cross-platform.png';
import TabView from '../img/tablet_view.png';
import language from '../utils/vast-languagepack';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import CampaignImgG from '../Faq/Campaigns/img/campaign_full_page2.png';
import MultiChannelInboxImgD from '../img/inbox_home_triple_channel.png';
import MobileDownload from '../img/on_mobile.png';
import additionalUsage from '../img/additional_usage.png';
import iOSDownloadButton from '../img/apple_store.jpg';
import androidDownloadButton from '../img/google_play_badge.png';
import AnalyzeResponsesImg from '../img/custom_form_analyze.png';
import CheckIcon from '@material-ui/icons/Check';

export default class Home extends Component {
  componentDidMount() {
    $.getScript('https://leads.vastreach.com/scripts/form_finder.js');
  }

  render() {
    return (
      <div>
        <div
          className='vast_reach_form'
          data-vast-form-id='aed59afd-0472-4a8e-87e5-1f848489fff2'
        ></div>
        <div
          className='container-fluid text-center home-intro banner-wrap'
          title={language.home.altTitles.homeBanner}
        >
          <div className='container center-content'>
            <div className='row'>
              <div className='col-xs-12 col-md-6'>
                <h1 style={{ fontWeight: '1000' }}>
                  {language.home.homeHeading}
                </h1>
                <h3>
                  <strong style={{ color: '#006fd9' }}>
                    {language.home.homeIntroOne}
                  </strong>{' '}
                  {language.home.homeIntroTwo} <br />
                  <strong style={{ color: '#006fd9' }}>
                    {language.home.homeIntroThree}
                  </strong>{' '}
                  {language.home.homeIntroFour}
                </h3>
                <div className='row header-buttons'>
                  <Link to='/form'>
                    <a className='nav-link' href='Form.html'>
                      <button className='dl-btn'>
                        {language.general.startNowButton}
                      </button>
                    </a>
                  </Link>
                  <Link to='/VastVideo'>
                    <a className='nav-link' href='Vast_video.html'>
                      <button>
                        {language.home.homeButtons.watchVideoButton}
                      </button>
                    </a>
                  </Link>
                </div>
              </div>
              <div className='col-xs-12 col-md-6'>
                <img
                  src={HomeBannerImg}
                  alt={language.home.altTitles.vastReach}
                  className='img-fluid'
                  style={{ maxWidth: '200px' }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='top-wrap'>
          <div className='container-fluid padding-full'>
            <div className='container wrap'>
              <div className='row'>
                <div className='col-xs-12 text-center'>
                  {/* <Zoom> */}
                  <h2 className='text-margin'>{language.home.vastHeading}</h2>
                  {/* </Zoom> */}
                  {/* <Zoom> */}
                  <p className='text-margin'>
                    {language.home.vastDescription}
                    <strong style={{ color: '#006fd9' }}>
                      {language.home.vastDescriptionTwo}
                    </strong>
                    {language.home.vastDescriptionThree}
                    <strong style={{ color: '#006fd9' }}>
                      {language.home.vastDescriptionFour}
                    </strong>
                    {language.home.vastDescriptionFive}
                    <strong style={{ color: '#006fd9' }}>
                      {language.home.vastDescriptionSix}
                    </strong>
                    {language.home.vastDescriptionSeven}
                    <strong style={{ color: '#006fd9' }}>
                      {language.home.vastDescriptionEight}
                    </strong>
                    {language.home.vastDescriptionNine}
                    <strong style={{ color: '#006fd9' }}>
                      {language.home.vastDescriptionTen}
                    </strong>
                    {language.home.vastDescriptionEleven}
                  </p>
                  {/* </Zoom> */}
                  {/* <Fade up> */}
                  <Link to='/features'>
                    <button className='button-spacer'>
                      {language.home.homeButtons.findOutMoreButton}
                    </button>
                  </Link>
                  {/* </Fade> */}
                </div>
              </div>
            </div>
          </div>

          <div className='container-fluid zero-padding'>
            <div className='bg-2'>
              <div className='row padding-full wrap'>
                {/* <Fade left> */}
                <div className='col-xs-12 text-center col-md-6 order-md-2'>
                  <img
                    src={MultiChannelInboxImgD}
                    alt={language.features.titleAlt.multiChanAlt}
                    className='img-fluid center-block'
                  />
                </div>
                {/* </Fade>
                <Fade right> */}
                <div className='col-xs-12 col-md-6 order-md-1 image-space'>
                  <h2 className='text-margin'>
                    <LabelImportantIcon
                      style={{
                        fontSize: '2.5rem',
                        color: '#006fd9',
                        margin: '-6px 5px 0 0',
                      }}
                    />
                    {language.home.vastHeadingCallsMessages}
                  </h2>
                  <p className='text-margin'>
                    {language.home.vastDescriptionCallsMessages}
                    <strong style={{ color: '#006fd9' }}>
                      {language.home.vastDescriptionCallsMessagesTwo}
                    </strong>
                    {language.home.vastDescriptionCallsMessagesThree}
                  </p>
                  <Link to='/CallsAndMessages'>
                    <button className='button-spacer'>
                      {language.home.homeButtons.findOutMoreButton}
                    </button>
                  </Link>
                </div>
                {/* </Fade> */}
              </div>
            </div>

            <div className='row padding-full wrap'>
              {/* <Fade right> */}
              <div className='col-xs-12 text-center col-md-6'>
                <img
                  src={CampaignImgG}
                  alt={language.features.titleAlt.campaignsAlt}
                  className='img-fluid center-block'
                />
              </div>
              {/* </Fade>
              <Fade left> */}
              <div className='col-xs-12 col-md-6 image-space'>
                <h2 className='text-margin'>
                  <LabelImportantIcon
                    style={{
                      fontSize: '2.5rem',
                      color: '#006fd9',
                      margin: '-6px 5px 0 0',
                    }}
                  />
                  {language.home.vastHeadingCampaign}
                </h2>
                <p className='text-margin'>
                  <strong style={{ color: '#006fd9' }}>
                    {language.home.vastDescriptionCampaigns}
                  </strong>
                  {language.home.vastDescriptionCampaignsTwo}
                  <strong style={{ color: '#006fd9' }}>
                    {language.home.vastDescriptionCampaignsThree}
                  </strong>
                  {language.home.vastDescriptionCampaignsFour}
                  <strong style={{ color: '#006fd9' }}>
                    {language.home.vastDescriptionCampaignsFive}
                  </strong>
                  {language.home.vastDescriptionCampaignsSix}
                  <strong style={{ color: '#006fd9' }}>
                    {language.home.vastDescriptionCampaignsSeven}
                  </strong>
                  {language.home.vastDescriptionCampaignsEight}
                </p>
                <Link to='/CampaignInfo'>
                  <button className='button-spacer'>
                    {language.home.homeButtons.findOutMoreButton}
                  </button>
                </Link>
              </div>
              {/* </Fade> */}
            </div>

            <div className='bg-2'>
              <div className='row padding-full wrap'>
                <div className='col-xs-12 text-center col-md-6 order-md-2'>
                  <img
                    src={ContactHomeImg}
                    alt={language.home.altTitles.contacts}
                    className='img-fluid center-block'
                  />
                </div>
                <div className='col-xs-12 col-md-6 order-md-1 image-space'>
                  <h2 className='text-margin'>
                    <LabelImportantIcon
                      style={{
                        fontSize: '2.5rem',
                        color: '#006fd9',
                        margin: '-6px 5px 0 0',
                      }}
                    />
                    {language.home.vastHeadingContacts}
                  </h2>
                  <p className='text-margin'>
                    {language.home.vastDescriptionContacts}
                  </p>
                  <Link to='/ContactSetUp'>
                    <button className='button-spacer'>
                      {language.home.homeButtons.findOutMoreButton}
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className='row padding-full wrap'>
              <div className='col-xs-12 text-center col-md-6 order-md-1'>
                <img
                  src={TaskHomeImg}
                  alt={language.home.altTitles.tasks}
                  className='img-fluid center-block'
                />
              </div>

              <div className='col-xs-12 col-md-6 order-md-2 image-space'>
                <h2 className='text-margin'>
                  <LabelImportantIcon
                    style={{
                      fontSize: '2.5rem',
                      color: '#006fd9',
                      margin: '-6px 5px 0 0',
                    }}
                  />
                  {language.home.vastHeadingAutomation}
                </h2>
                <p className='text-margin'>
                  {language.home.vastDescriptionAutomation}
                  <strong style={{ color: '#006fd9' }}>
                    {language.home.vastDescriptionAutomationTwo}
                  </strong>
                  {language.home.vastDescriptionAutomationThree}
                  <strong style={{ color: '#006fd9' }}>
                    {language.home.vastDescriptionAutomationFour}
                  </strong>
                  {language.home.vastDescriptionAutomationFive}
                </p>
                <Link to='/Automation'>
                  <button className='button-spacer'>
                    {language.home.homeButtons.findOutMoreButton}
                  </button>
                </Link>
              </div>
            </div>

            <div className='bg-2'>
              <div className='row padding-full wrap' id='analyze'>
                <div className='col-xs-12 text-center col-md-6 order-md-2'>
                  <img
                    src={AnalyzeResponsesImg}
                    alt={language.home.altTitles.analyzeFormResponses}
                    className='img-fluid center-block'
                  />
                </div>
                <div className='col-xs-12 col-md-6 order-md-1 image-space'>
                  <h2 className='text-margin'>
                    <LabelImportantIcon
                      style={{
                        fontSize: '2.5rem',
                        color: '#006fd9',
                        margin: '-6px 5px 0 0',
                      }}
                    />
                    {language.home.vastHeadingAnalyze}
                  </h2>
                  <p className='text-margin'>
                    {language.home.vastDescriptionAnalyze}
                  </p>
                </div>
              </div>
            </div>

            <div className='row padding-full wrap' id='cross-platform'>
              <div className='col-xs-12 text-center col-md-6'>
                <img
                  src={CrossPlatformImg}
                  alt={language.features.titleAlt.crossPlatformAlt}
                  className='img-fluid center-block'
                />
              </div>
              <div className='col-xs-12 col-md-6 image-space'>
                <h2 className='text-margin'>
                  <LabelImportantIcon
                    style={{
                      fontSize: '2.5rem',
                      color: '#006fd9',
                      margin: '-6px 5px 0 0',
                    }}
                  />
                  {language.home.vastHeadingCrossPlatform}
                </h2>
                <p className='text-margin'>
                  {language.home.vastDescriptionCrossPlatform}
                  <strong style={{ color: '#006fd9' }}>
                    {language.home.vastDescriptionCrossPlatformTwo}
                  </strong>
                  {language.home.vastDescriptionCrossPlatformThree}
                  <strong style={{ color: '#006fd9' }}>
                    {language.home.vastDescriptionCrossPlatformFour}
                  </strong>
                  {language.home.vastDescriptionCrossPlatformFive}
                  <strong style={{ color: '#006fd9' }}>
                    {language.home.vastDescriptionCrossPlatformSix}
                  </strong>
                  {language.home.vastDescriptionCrossPlatformSeven}
                </p>
              </div>
            </div>

            <div className='bg-2'>
              <div className='row padding-full wrap'>
                <div className='col-xs-12 text-center col-md-6  order-md-2'>
                  <ReactPlayer
                    muted
                    playing
                    loop
                    playsinline
                    width='100%'
                    height='100%'
                    url={[
                      `${process.env.PUBLIC_URL}/video/ai.mp4`,
                      `${process.env.PUBLIC_URL}/video/ai.webm`,
                      `${process.env.PUBLIC_URL}/video/ai.gif`,
                    ]}
                  />
                </div>

                <div className='col-xs-12 col-md-6 order-md-1 image-space'>
                  <h2 className='text-margin'>
                    <LabelImportantIcon
                      style={{
                        fontSize: '2.5rem',
                        color: '#006fd9',
                        margin: '-6px 5px 0 0',
                      }}
                    />
                    {language.home.vastHeadingAi}
                  </h2>
                  <p className='text-margin'>
                    {language.home.vastDescriptionAi}
                  </p>
                </div>
              </div>
            </div>

            <div className='row padding-full wrap' id='mobile-download'>
              <div className='col-xs-12 text-center col-md-6'>
                <img
                  src={MobileDownload}
                  alt={language.home.altTitles.vastMobileDownload}
                  className='img-fluid center-block'
                />
              </div>
              <div className='col-xs-12 col-md-6 image-space'>
                <h2 className='text-margin'>
                  <LabelImportantIcon
                    style={{
                      fontSize: '2.5rem',
                      color: '#006fd9',
                      margin: '-6px 5px 0 0',
                    }}
                  />
                  {language.home.vastMobileDownloadHeading}
                </h2>
                <p className='text-margin'>
                  <strong style={{ color: '#006fd9' }}>
                    {language.home.vastMobileDownloadDescription}
                  </strong>{' '}
                  {language.home.vastMobileDownloadDescription1}{' '}
                  <strong style={{ color: '#006fd9' }}>
                    {language.home.vastMobileDownloadDescription2}
                  </strong>{' '}
                  {language.home.vastMobileDownloadDescription3}{' '}
                  <strong style={{ color: '#006fd9' }}>
                    {language.home.vastMobileDownloadDescription4}
                  </strong>
                  {language.home.vastMobileDownloadDescription5}
                </p>
                <p style={{ textAlign: 'center' }}>
                  <a
                    href='https://apps.apple.com/us/app/vast-reach/id1534213563'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      src={iOSDownloadButton}
                      alt={language.home.altTitles.iOSDownloadButton}
                      className='img-fluid center-block'
                      style={{ padding: '5px' }}
                    />
                  </a>
                  <a
                    href='https://play.google.com/store/apps/details?id=com.reach_mobile'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      src={androidDownloadButton}
                      alt={language.home.altTitles.androidDownloadButton}
                      className='img-fluid center-block'
                      style={{ padding: '5px' }}
                    />
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className='bg-2'>
            <div className='row padding-full wrap'>
              <div className='col-xs-12 text-center col-md-6 order-md-2'>
                <img
                  src={additionalUsage}
                  alt={language.home.altTitles.additionalUsage}
                  className='img-fluid center-block'
                />
              </div>

              <div className='col-xs-12 col-md-6 order-md-1 image-space'>
                <h2 className='text-margin'>
                  <LabelImportantIcon
                    style={{
                      fontSize: '2.5rem',
                      color: '#006fd9',
                      margin: '-6px 5px 0 0',
                    }}
                  />
                  {language.home.additionalUsageHeading}
                </h2>
                <p className='text-margin'>
                  {language.home.vastDescriptionAdditionalUsage}
                </p>
              </div>
            </div>
          </div>

          <div className='row padding-full wrap'>
            <div className='col-xs-12 image-space'>
              <h2 className='text-margin'>
                <LabelImportantIcon
                  style={{
                    fontSize: '2.5rem',
                    color: '#006fd9',
                    margin: '-6px 5px 0 0',
                  }}
                />
                {language.home.subscriptionPlansHeading}
              </h2>
              <p className='text-margin'>
                {language.home.vastDescriptionSubscriptionPlans}
              </p>
              <div className='container-fluid plan-prime-wrapper'>
                <div className='col-xs-12 plan-wrapper'>
                  <h2 className='text-center'>
                    Vast Reach Base Plan
                    <br /> $79.00/mo
                  </h2>
                  <p className='text-center'>
                    Have a small team? Then this is the plan for you!
                  </p>
                  <h3 className='text-center'>Try it free for 14 days</h3>
                  <h3 className='text-center'>Plan Includes</h3>
                  <ul className='plan-feature-list'>
                    <li>
                      <p>
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        1 user
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        100, 000 emails
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        1, 000 text messages
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        500 minutes
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        2 phone numbers
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        45 gigabytes
                      </p>
                    </li>
                  </ul>
                </div>

                <div className='col-xs-12 plan-wrapper'>
                  <h2 className='text-center'>
                    Base Plan Plus
                    <br /> $400.00/mo
                  </h2>
                  <p className='text-center'>
                    This is for small teams that need a little more room on
                    limits!
                  </p>
                  <h3 className='text-center'>Try it free for 14 days</h3>
                  <h3 className='text-center'>Plan Includes</h3>
                  <ul className='plan-feature-list'>
                    <li>
                      <p>
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        3 users
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        250, 000 emails
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        2, 000 text messages
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        1, 000 minutes
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        10 phone numbers
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        100 gigabytes
                      </p>
                    </li>
                  </ul>
                </div>

                <div className='col-xs-12 plan-wrapper'>
                  <h2 className='text-center'>
                    Premium Plan
                    <br /> $600.00/mo
                  </h2>
                  <p className='text-center'>
                    The premium plan is geared towards mid-size teams!
                  </p>
                  <h3 className='text-center'>Try it free for 14 days</h3>
                  <h3 className='text-center'>Plan Includes</h3>
                  <ul className='plan-feature-list'>
                    <li>
                      <p>
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        10 users
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        250, 000 emails
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        2, 000 text messages
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        1, 000 minutes
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        10 phone numbers
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        100 gigabytes
                      </p>
                    </li>
                  </ul>
                </div>

                <div className='col-xs-12 plan-wrapper'>
                  <h2 className='text-center'>
                    Ultimate Plan
                    <br /> $5000.00/mo
                  </h2>
                  <p className='text-center'>
                    The Ultimate Plan is for large marketing and sales teams!
                  </p>
                  <h3 className='text-center'>Try it free for 14 days</h3>
                  <h3 className='text-center'>Plan Includes</h3>
                  <ul className='plan-feature-list'>
                    <li>
                      <p>
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        100 users
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        1, 000, 000 emails
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        5, 000 text messages
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        10, 000 minutes
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        100 phone numbers
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <CheckIcon
                          style={{
                            fontSize: '1.5rem',
                            color: '#15b940',
                            margin: '0 10px 0 0',
                          }}
                        />
                        1, 000 gigabytes
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className='container-fluid padding-full bg-2'>
            <div className='container wrap'>
              <div className='row'>
                <div className='col-xs-12'>
                  <div className='col-xs-12 text-center padding-full'>
                    <img
                      src={TabView}
                      alt={language.home.altTitles.appTabletView}
                      className='img-fluid center-block'
                    />
                  </div>

                  <p className='text-center'>
                    {language.home.tabDescription}{' '}
                    <strong style={{ color: '#006fd9' }}>
                      {language.home.tabDescriptionTwo}
                    </strong>{' '}
                    {language.home.tabDescriptionThree}{' '}
                    <strong style={{ color: '#006fd9' }}>
                      {language.home.tabDescriptionFour}
                    </strong>
                    {language.home.tabDescriptionFive}
                  </p>

                  <div className='text-center'>
                    <Link to='/form'>
                      <button className='button-spacer'>
                        {language.home.homeButtons.contactUsButton}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
