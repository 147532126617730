import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import ReCaptchaMobileNaviScrollSpy from './ReCaptchaMobileNaviScrollSpy.js';
import ReCaptchaImgA from '../ReCaptcha/img/recaptcha1.jpg';
import ReCaptchaMobileImgA from './img/recaptcha_mobile1.png';
import ReCaptchaMobileImgB from './img/recaptcha_mobile2.png';
import AccountSettingsMobileC from '../AccountSettingsMobile/img/mobile_nav_account.jpg';
import video from '../ReCaptcha/video/recaptcha_set_up.mp4';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function ReCaptchaMobile() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <ReCaptchaMobileNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/ReCaptcha'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/ReCaptchaMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>
                RECAPTCHA SET-UP ON MOBILE
              </h1>
              <p className='recaptcha-intro'>
                Google ReCaptcha helps protect <i>your</i> forms so you don't
                receive spam (Click{' '}
                <a href='https://developers.google.com/recaptcha/'>here</a> to
                learn more about Google ReCaptcha). This tutorial will teach{' '}
                <i>you</i> how to configure <i>your</i> Google ReCaptcha through
                the <strong>Account Settings & Preferences</strong> page.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Setting-Up ReCaptcha
              </h3>
              <p>
                Before setting-up <strong>ReCaptcha</strong>, <i>you</i> must
                first have an active account set up with <i>your</i>{' '}
                <strong>Payment Settings</strong>. This is mandatory and must be
                done before accessing <strong>Account Settings</strong> where{' '}
                <strong>ReCaptcha</strong> is located. Click the{' '}
                <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Button</strong> below to set up{' '}
                <strong>Payments</strong> before beginning this tutorial if{' '}
                <i>you</i> have not done so already.
              </p>
              <p>
                <Link to='/PaymentMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
              </p>
              <h3 className='navigation'>Navigate to Account Settings</h3>
              <p>
                <i>You</i> can begin by clicking on <strong>Account</strong>{' '}
                with the <strong>Gear Icon</strong> in the navigation (Mobile
                menu Icon at the top left).
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={AccountSettingsMobileC}
                  alt='account settings navigation'
                  className='img-fluid'
                  style={{ maxWidth: '375px', width: '100%' }}
                />
              </p>
              <p>
                Once on <strong>Account Settings & Preferences</strong> page{' '}
                <i>you</i> should create a new tab on <i>your</i> web browser.
                On the new tab visit{' '}
                <a href='https://www.google.com/'>www.Google.com</a> and either
                create a New <strong>Google</strong> account or sign-in to an
                existing <strong>Google</strong> account. After signing-in visit
                the following link:{' '}
                <a href='https://www.google.com/recaptcha/intro/v3.html'>
                  https://www.google.com/recaptcha/intro/v3.html
                </a>{' '}
                on the same tab. Once on the page clicking the button named{' '}
                <strong>Admin console</strong> at the top right and it will take{' '}
                <i>you</i> to a new page.
              </p>
              <p>
                <img
                  src={ReCaptchaImgA}
                  alt='Google ReCaptcha page'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              <h3 className='config-recaptcha'>
                Configuring ReCaptcha With Vast Reach
              </h3>
              <ol>
                <li>
                  <p>Fill out name under Label text input area.</p>
                </li>
                <li>
                  <p>
                    Under reCAPTCHA type select ReCAPTCHA v2 then{' '}
                    <strong>I am not a robot</strong> Checkbox.
                  </p>
                </li>
                <li>
                  <p>
                    Add <i>your</i> website link under Domains.
                  </p>
                </li>
                <li>
                  <p>
                    Under owners <i>your</i> email will be there by default.
                  </p>
                </li>
                <li>
                  <p>Accept the terms of service.</p>
                </li>
              </ol>
              <p>
                Finally, when done filing out the page, click the{' '}
                <strong>SUBMIT</strong> button at the bottom. <i>You</i> will
                now be taken to a new page that shows <strong>Site Key</strong>{' '}
                and <strong>Secret Key</strong>. Copy the{' '}
                <strong>Secret Key</strong> and switch to the{' '}
                <strong>Vast Reach</strong> app, under the ReCaptcha section in{' '}
                <strong>Account Settings & Preferences</strong> paste the{' '}
                <strong>Secret Key</strong> under the{' '}
                <strong>Secret Key</strong> label. Do the same by copying the{' '}
                <strong>Site Key</strong> into <i>your</i>{' '}
                <strong>Account Settings & Preferences</strong>. <i>You</i> can
                switch between <strong>Invisible</strong> and{' '}
                <strong>Checkbox ReCaptcha</strong> from here as well.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={ReCaptchaMobileImgA}
                  alt='Vast Reach mobile ReCaptcha settings'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>
              <h3 className='recaptcha-forms'>
                Share Vast Reach Forms With ReCaptcha Enabled
              </h3>
              <p>
                Vast Reach now will apply Google ReCaptcha to all of <i>your</i>{' '}
                forms when embed to <i>your</i> site(s). <i>You</i> can share
                and embed forms by clicking the <strong>Share</strong> step at
                the top stepper in a form previously created. Click{' '}
                <Link to='/SignUpFormsMobile'>here</Link> to learn more about
                forms.
              </p>
              <p>
                Clicking the <strong>Share</strong> icon allows <i>you</i> to
                embed this form to <i>your</i> website or share a direct link to
                this form. There are 2 fields, the top field is for embedding
                and the bottom is for linking. <i>You</i> can click the{' '}
                <strong>COPY</strong> button of the desired option and it will
                automatically be added to <i>your</i> clipboard to paste. In
                order to navigate directly to a form make sure that <i>you</i>{' '}
                add "
                <Link to='leads.vastreach.com'>
                  <strong>leads.vastreach.com</strong>
                </Link>
                " to <i>your</i> domains in recaptcha.
              </p>
              <p>
                <i>Note: </i>
                If <i>you</i> are using a BYO (Bring your own) ReCaptcha then
                remember to add "
                <Link to='leads.vastreach.com'>
                  <strong>leads.vastreach.com</strong>
                </Link>
                "{' '}
                <strong>
                  under the domains section of <i>your</i> BYO ReCaptcha set up.
                </strong>
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={ReCaptchaMobileImgB}
                  alt='Vast Reach mobile form share settings'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Remember forms can’t be shared without setting up ReCaptcha
                    (<i>you</i> will get a warning message when <i>you</i> visit
                    the share step of a form).
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Forget to use “
                    <Link to='leads.vastreach.com'>leads.vastreach.com</Link>”
                    under the domains section of your ReCaptcha set-up.
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <h3>Setting Up Your ReCaptcha With Vast Reach</h3>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
