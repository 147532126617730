import React, { Component } from 'react';

export default class meetwithwil extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <div className='container-fluid padding-full'>
          <div className='container-fluid' style={{paddingTop: 150}}>
            <div className='container wrap'>
              <div className='col-xs-12'>
                <h2 className='text-center'>
                  Schedule some time with Wil!
                </h2>
              </div>
            </div>
          </div>

          <div className='container-fluid padding-full '>
            <div className='col-xs-12 zero-padding wrap'>
              <iframe
                title='book'
                src='https://koalendar.com/e/meet-with-wil-casillas?embed=true'
                width='100%'
                height='800px'
                frameborder='0'
              ></iframe>
              <div className='col-xs-12 text-center'>
                <h3>
                  Don't See a time that works for you? Get in contact with Wil
                  Casillas{' '}
                </h3>
                <p>
                  <a href='mailto: wcasillas@vasttechnologies.com'>
                    wcasillas@vasttechnologies.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
