import React, { Component } from "react";
import "./form.scss";
import $ from "jquery";
import Background from "../img/embed-banner.jpg";

export default class Form extends Component {
  componentDidMount() {
    $.getScript('https://leads.vastreach.com/scripts/form_finder.js');
  }
  render() {
    return (
      <div>
        <div
          className='embed-banner padding-top'
          style={{ backgroundImage: `url(${Background})` }}
        ></div>

        <div className='container-fluid form-wrap'>
          <div className='container wrap'>
            <div
              className='vast_reach_form'
              data-vast-form-id='73f39634-e397-49f1-9858-0f424ea0f015'
            ></div>
          </div>
        </div>
      </div>
    );
  }
}
