import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import SignUpFormsMobileNaviScrollSpy from './SignUpFormsMobileNaviScrollSpy.js';
import SignUpFormsImgA from '../SignUpForms/img/create_forms_button.png';
import SignUpFormsImgB from './img/sign_up_forms_creation.png';
import SignUpFormsImgC from './img/sign_up_form_builder1.png';
import SignUpFormsImgF from './img/sign_up_form_responses1.png';
import SignUpFormsImgH from '../ReCaptchaMobile/img/recaptcha_mobile2.png';
import SignUpFormsImgI from './img/mobile_nav.jpg';
import SignUpFormsImgJ from './img/sign_up_form_builder2.png';
import SignUpFormsImgL from './img/forms_fields.png';
import SignUpFormsImgM from './img/forms_font.png';
import SignUpFormsImgN from './img/forms_background.png';
import SignUpFormsImgO from './img/forms_button.png';
import SignUpFormsImgP from './img/forms_mobile_logo.png';
import video from '../SignUpForms/video/create_sign_up_form.mov';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function SignUpFormsMobile() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <SignUpFormsMobileNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/SignUpForms'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/SignUpFormsMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading forms'>
                SIGN-UP FORMS ON MOBILE
              </h1>
              <p className='sign-up-intro'>
                This tutorial will guide <i>you</i> on how to create sign-up
                forms on a mobile device. The purpose of signup forms is to
                build an audience or to collect new <i>contacts</i>. By the end
                of this tutorial <i>you</i> will learn how to create a sign-up
                form, design it, customize it, learn how to embed/share the form
                and finally understand how to monitor the form analytics after
                it's live.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Creating a Sign-up Form
              </h3>
              <p>
                Before creating a <strong>Sign-up Form</strong>, <i>you</i> must
                first have a account set up with an active subscription. Setting
                up ReCaptcha for your forms to prevent spam is recommended.
                Click the <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Buttons</strong> below to set up anything <i>you're</i>{' '}
                missing before beginning this tutorial if <i>you</i> have not
                done so already.
              </p>
              <p>
                <Link to='/PaymentMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
                <Link to='/ReCaptchaMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    ReCaptcha
                  </button>
                </Link>
              </p>
              <p>
                <i>Note:</i> Although not required to create a{' '}
                <strong>Sign-up From</strong>, <i>you</i> can send these forms
                directly to a <strong>Group</strong>. However, <i>you</i> need
                create <i>contacts</i> to add to a group. Click{' '}
                <Link to='/ContactsMobile'>here</Link> to learn how to create a{' '}
                <i>contact</i> and click <Link to='/GroupsMobile'>here</Link> to
                learn how to create a group.
              </p>
              <h3 className='navigation'>Navigate to Sign-up Forms</h3>
              <p>
                <i>You</i> can create a sign-up form by clicking the{' '}
                <strong>Forms</strong> link on the navigation (logo at the top
                left).
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={SignUpFormsImgI}
                  alt='form navigation'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>

              <h2 className='create-form'>Create a New Form</h2>
              <p>
                To begin <i>you</i> can click the <strong>ADD FORM</strong>{' '}
                button at the top right of the form list to begin.
              </p>
              <p style={{ margin: '10px 0 10px 0' }}>
                <img
                  src={SignUpFormsImgA}
                  alt='create form button'
                  className='img-fluid'
                  style={{ maxWidth: '200px', width: '100%' }}
                />
              </p>
              <p>
                <i>You</i> will be taken to a step by step process for creating
                forms. On step one <i>you</i> can enter a{' '}
                <strong>Form Name</strong>, select a form type and optionally
                add a <i>contact</i> group automation to the form (
                <Link to='/GroupsMobile'> Click here </Link> to learn more about
                groups). In our case, for the purposes of the tutorial we will
                select <strong>SIGNUP FORM</strong> and click{' '}
                <strong>NEXT</strong>.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={SignUpFormsImgB}
                  alt='sign up forms creation page'
                  className='img-fluid'
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    border: 'solid 1px #000000',
                  }}
                />
              </p>
              <p>
                <i>Note:</i> If <i>you</i> click the <strong>CANCEL</strong>{' '}
                button at the top left, <i>you</i> will return to the form list.
              </p>
              <h2 className='build'>Build</h2>
              <p>
                <i>You</i> will now be taken to the form creation step with
                several tabs below.
              </p>
              <h3 className='form_section'>Building a Form</h3>
              <p>
                The first tab is a preview window and this will be the default
                tab seen. Here <i>you</i> will see <i>your</i> form in real time
                even during editing.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={SignUpFormsImgC}
                  alt='sign-up form builder screen 1'
                  className='img-fluid'
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    border: 'solid 1px #000000',
                  }}
                />
              </p>
              <h3 className='look_and_feel_section'>
                Design The Look and Feel of Your Forms
              </h3>
              <p>
                In the second tab when selected, at the top there are several
                more tabs. Each tab controls a style or text customization of
                the form.
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h4>Description</h4>
                  <p>
                    Description tab allows <i>you</i> to enter a title and
                    description for the form as well as the color of the
                    description font.
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    <img
                      src={SignUpFormsImgJ}
                      alt='sign-up form builder look and feel'
                      className='img-fluid'
                      style={{
                        maxWidth: '400px',
                        width: '100%',
                        border: 'solid 1px #000000',
                      }}
                    />
                  </p>
                </li>
                <li>
                  <h4>Fields </h4>
                  <p>
                    Select a desired field in this tab by turning that fields
                    switch on. This will add that option as a from input to{' '}
                    <i>your</i> forms seen in the preview (by default at least a
                    phone number or email is required). On the right side of
                    each switch there will be a <strong>Required</strong> check
                    box. Checking this box will make sure the <i>contact</i> is
                    required to fill out this field. Additionally, there will be
                    a <strong>*</strong> by each required field letting the{' '}
                    <i>contact</i> know this is a required field.
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    <img
                      src={SignUpFormsImgL}
                      alt='sign-up form builder fields tab'
                      className='img-fluid'
                      style={{
                        maxWidth: '400px',
                        width: '100%',
                        border: 'solid 1px #000000',
                      }}
                    />
                  </p>
                </li>
                <li>
                  <h4>Font</h4>
                  <p>
                    The Font tab allows <i>you</i> to select font type and the
                    color of the font for the entire form.
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    <img
                      src={SignUpFormsImgM}
                      alt='sign-up form builder font tab'
                      className='img-fluid'
                      style={{
                        maxWidth: '400px',
                        width: '100%',
                        border: 'solid 1px #000000',
                      }}
                    />
                  </p>
                </li>
                <li>
                  <h4>Background</h4>
                  <p>
                    The Background tab allows <i>you</i> to change the form
                    background color.
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    <img
                      src={SignUpFormsImgN}
                      alt='sign-up form builder background tab'
                      className='img-fluid'
                      style={{
                        maxWidth: '400px',
                        width: '100%',
                        border: 'solid 1px #000000',
                      }}
                    />
                  </p>
                </li>
                <li>
                  <h4>Button</h4>
                  <p>
                    The Button tab allows <i>you</i> to change the text on the{' '}
                    <strong>SUBMIT</strong> button as well as the font text
                    color and button background color.
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    <img
                      src={SignUpFormsImgO}
                      alt='sign-up form builder button tab'
                      className='img-fluid'
                      style={{
                        maxWidth: '400px',
                        width: '100%',
                        border: 'solid 1px #000000',
                      }}
                    />
                  </p>
                </li>
              </ul>
              <h3 className='logo_section'>Add Your Logo to Your Forms</h3>
              <p>
                In the third tab <i>you</i> can add <i>your</i> logo to{' '}
                <i>your</i> forms by using a direct link to the image or
                clicking the <strong>BROWSE</strong> button and upload it from{' '}
                <i>your</i> computer.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={SignUpFormsImgP}
                  alt='sign-up form builder logo tab'
                  className='img-fluid'
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    border: 'solid 1px #000000',
                  }}
                />
              </p>

              <h2 className='embed-share'>Share Your Forms</h2>
              <p>
                Once a form has been created <i>you</i> will have access to the
                share step in the stepper. <i>You</i> can embed this form to{' '}
                <i>your</i> website or share a direct link to this form. There
                are 2 sections, the top section is for embedding and the bottom
                is for linking. <i>You</i> can click the <strong>COPY</strong>{' '}
                button and it will automatically be added to the <i>your</i>{' '}
                clipboard to paste.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={SignUpFormsImgH}
                  alt='form embed and share tab'
                  className='img-fluid'
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    border: 'solid 1px #000000',
                  }}
                />
              </p>
              <p>
                <Link to='/ReCaptcha'>Click here</Link> to learn about how to
                set-up ReCaptcha.
              </p>
              <h2 className='responses'>
                Analyze Your Form Responses and Statistics
              </h2>
              <p>
                Once a form has been created <i>you</i> will also have access to
                the analyze step in the stepper. There are 2 aggregate tiles at
                the top, one green and one yellow. The Green tile shows{' '}
                <i>you</i> the total response rate of this form in percentage
                and the number of form responses as a number. The yellow tile
                will show the total number of <i>contacts</i> that visited this
                form (visits do not count as responses). Below that <i>you</i>{' '}
                can see all of the <i>contacts</i> that have or have not filled
                out this form.{' '}
                {/*In addition to that, a <i>user</i> can
                click on the individual <i>contact's</i> name to see their exact
                responses. A <i>user</i> can also click “View by Questions”
                button at the top left of the screen to see the most answered
                questions with charts and statistics. At the top right there is
                an ellipsis Icon (3 dot icon). Clicking this icon allows a{' '}
                <i>user</i> to export these questions as an excel file or print
                them.*/}{' '}
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={SignUpFormsImgF}
                  alt='sign-up form responses screen'
                  className='img-fluid'
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    border: 'solid 1px #000000',
                  }}
                />
              </p>

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Remember that at least a phone number <strong>OR</strong>{' '}
                    email is required for every <strong>Sign-up Form</strong> in
                    order to get in touch with the <i>contact</i>.
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Forget all <strong>Sign-up Forms</strong> require a{' '}
                    <strong>Form Name</strong>, <strong>Title</strong>,{' '}
                    <strong>Description</strong>, <strong>Contact Group</strong>{' '}
                    and a <strong>Phone Number</strong> or{' '}
                    <strong>Email</strong> to be created/updated.
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <h3>Creating a Sign-up Form</h3>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
