
import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default (props) => (
  <SvgIcon
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='14.5'
    font-size='2em'
  >
    <path
     d="M21,17V8H7V17H21M21,3A2,2 0 0,1 23,5V17A2,2 0 0,1 21,19H7C5.89,19 5,18.1 5,17V5A2,2 0 0,1 7,3H8V1H10V3H18V1H20V3H21M17.53,11.06L13.09,15.5L10.41,12.82L11.47,11.76L13.09,13.38L16.47,10L17.53,11.06M3,21H17V23H3C1.89,23 1,22.1 1,21V9H3V21Z"
    />
  </SvgIcon>
);

