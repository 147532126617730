import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./generalCss/style.scss";
import Navi from "./Navi";
import Footer from "./Footer";
import Home from "./Home";
import CallsAndMessages from './CallsAndMessages';
import CampaignInfo from './CampaignInfo';
import Automation from './Automation';
import ContactSetUp from './ContactSetUp';
import About from "./About";
import Features from "./Features";
import Faq from "./Faq";
import Pricing from "./Pricing";
import Form from "./Form";
import VastVideo from "./VastVideo";
import TaskGuide from "./Faq/Tasks";
import GettingStartedGuide from './Faq/GettingStarted';
import AccountSettingsGuide from './Faq/AccountSettings';
import Support from "./Support";
import CampaignGuide from './Faq/Campaigns';
import DripCampaignsGuide from './Faq/DripCampaigns';
import SignUpCampaignsGuide from './Faq/SignUpCampaigns';
import EventGoalsGuide from './Faq/EventGoals';
import PaymentGuide from './Faq/Payment';
import ContactsGuide from './Faq/Contacts';
import GroupsGuide from './Faq/Groups';
import ReCaptchaGuide from './Faq/ReCaptcha';
import FormListGuide from './Faq/FormList';
import SignUpFormsGuide from './Faq/SignUpForms';
import CustomFormsGuide from './Faq/CustomForms';
import UserPermissionGuide from './Faq/UserPermission';
import ManagePhoneNumbersGuide from './Faq/ManagePhoneNumbers';
import MultiChannelInboxGuide from './Faq/MultiChannelInbox';
import PhoneCallsGuide from './Faq/PhoneCalls';
import TextMessageUseCaseGuide from './Faq/TextMessageUseCase';
import CreateUserGuide from './Faq/CreateUser';
import DynamicAudience from './Faq/DynamicAudience';
import GettingStartedMobileGuide from './Faq/GettingStartedMobile';
import AccountSettingsMobileGuide from './Faq/AccountSettingsMobile';
import TaskGuideMobile from './Faq/TasksMobile';
import CampaignGuideMobile from './Faq/CampaignsMobile';
import DripCampaignsGuideMobile from './Faq/DripCampaignsMobile';
import SignUpCampaignsGuideMobile from './Faq/SignUpCampaignsMobile';
import EventGoalsGuideMobile from './Faq/EventGoalsMobile';
import PaymentGuideMobile from './Faq/PaymentMobile';
import ContactsGuideMobile from './Faq/ContactsMobile';
import GroupsGuideMobile from './Faq/GroupsMobile';
import ReCaptchaGuideMobile from './Faq/ReCaptchaMobile';
import FormListGuideMobile from './Faq/FormListMobile';
import SignUpFormsGuideMobile from './Faq/SignUpFormsMobile';
import CustomFormsGuideMobile from './Faq/CustomFormsMobile';
import UserPermissionGuideMobile from './Faq/UserPermissionMobile';
import ManagePhoneNumbersGuideMobile from './Faq/ManagePhoneNumbersMobile';
import MultiChannelInboxMobileGuide from './Faq/MultiChannelInboxMobile';
import DynamicAudienceMobile from './Faq/DynamicAudienceMobile';
import PhoneCallsMobileGuide from './Faq/PhoneCallsMobile';
import TextMessageUseCaseMobileGuide from './Faq/TextMessageUseCaseMobile';
import CreateUserGuideMobile from './Faq/CreateUserMobile';
import VerificationFail from './Verification/Fail';
import VerificationSuccess from './Verification/Success';
import ScrollTo from "./scrollTo";
import TermsOfService from "./TermsOfService";
import AcceptableUse from "./AcceptableUse";
import PrivacyPolicy from "./PrivacyPolicy";
import BrandingGuidelines from './BrandingGuidelines';
import CookiePolicy from './CookiePolicy';
import dealermeeting2021 from './dealermeeting2021';
import meetwithwil from './meetwithwil';
import schedule from './schedule';
import demo from './demo';
import NotFound from './NotFound';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default class App extends Component {
  render() {
    return (
      <Router>
        <ScrollTo />
        <div>
          <Navi />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/index' component={Home} />
            <Route path='/CallsAndMessages' component={CallsAndMessages} />
            <Route path='/CampaignInfo' component={CampaignInfo} />
            <Route path='/Automation' component={Automation} />
            <Route path='/ContactSetUp' component={ContactSetUp} />
            <Route path='/about' component={About} />
            <Route path='/features' component={Features} />
            <Route path='/faq' component={Faq} />
            <Route path='/pricing' component={Pricing} />
            <Route path='/support' component={Support} />
            <Route path='/form' component={Form} />
            <Route path='/VastVideo' component={VastVideo} />
            <Route path='/GettingStarted' component={GettingStartedGuide} />
            <Route path='/AccountSettings' component={AccountSettingsGuide} />
            <Route path='/Tasks' component={TaskGuide} />
            <Route path='/Campaigns' component={CampaignGuide} />
            <Route path='/DripCampaigns' component={DripCampaignsGuide} />
            <Route path='/SignUpCampaigns' component={SignUpCampaignsGuide} />
            <Route path='/EventGoals' component={EventGoalsGuide} />
            <Route path='/Payment' component={PaymentGuide} />
            <Route path='/Contacts' component={ContactsGuide} />
            <Route path='/Groups' component={GroupsGuide} />
            <Route path='/ReCaptcha' component={ReCaptchaGuide} />
            <Route path='/FormList' component={FormListGuide} />
            <Route path='/SignUpForms' component={SignUpFormsGuide} />
            <Route path='/CustomForms' component={CustomFormsGuide} />
            <Route path='/UserPermission' component={UserPermissionGuide} />
            <Route
              path='/ManagePhoneNumbers'
              component={ManagePhoneNumbersGuide}
            />
            <Route
              path='/MultiChannelInbox'
              component={MultiChannelInboxGuide}
            />
            <Route path='/PhoneCalls' component={PhoneCallsGuide} />
            <Route
              path='/TextMessageUseCase'
              component={TextMessageUseCaseGuide}
            />
            <Route path='/CreateUser' component={CreateUserGuide} />
            <Route path='/DynamicAudience' component={DynamicAudience} />
            <Route
              path='/GettingStartedMobile'
              component={GettingStartedMobileGuide}
            />
            <Route
              path='/AccountSettingsMobile'
              component={AccountSettingsMobileGuide}
            />
            <Route path='/TasksMobile' component={TaskGuideMobile} />
            <Route path='/CampaignsMobile' component={CampaignGuideMobile} />
            <Route
              path='/DripCampaignsMobile'
              component={DripCampaignsGuideMobile}
            />
            <Route
              path='/SignUpCampaignsMobile'
              component={SignUpCampaignsGuideMobile}
            />
            <Route path='/EventGoalsMobile' component={EventGoalsGuideMobile} />
            <Route path='/PaymentMobile' component={PaymentGuideMobile} />
            <Route path='/ContactsMobile' component={ContactsGuideMobile} />
            <Route path='/GroupsMobile' component={GroupsGuideMobile} />
            <Route path='/ReCaptchaMobile' component={ReCaptchaGuideMobile} />
            <Route path='/FormListMobile' component={FormListGuideMobile} />
            <Route
              path='/SignUpFormsMobile'
              component={SignUpFormsGuideMobile}
            />
            <Route
              path='/CustomFormsMobile'
              component={CustomFormsGuideMobile}
            />
            <Route
              path='/UserPermissionMobile'
              component={UserPermissionGuideMobile}
            />
            <Route
              path='/ManagePhoneNumbersMobile'
              component={ManagePhoneNumbersGuideMobile}
            />
            <Route
              path='/MultiChannelInboxMobile'
              component={MultiChannelInboxMobileGuide}
            />
            <Route path='/PhoneCallsMobile' component={PhoneCallsMobileGuide} />
            <Route
              path='/TextMessageUseCaseMobile'
              component={TextMessageUseCaseMobileGuide}
            />
            <Route path='/CreateUserMobile' component={CreateUserGuideMobile} />
            <Route
              path='/DynamicAudienceMobile'
              component={DynamicAudienceMobile}
            />
            <Route path='/Fail' component={VerificationFail} />
            <Route path='/Success' component={VerificationSuccess} />
            <Route path='/TermsOfService' component={TermsOfService} />
            <Route path='/PrivacyPolicy' component={PrivacyPolicy} />
            <Route path='/AcceptableUse' component={AcceptableUse} />
            <Route path='/BrandingGuidelines' component={BrandingGuidelines} />
            <Route path='/CookiePolicy' component={CookiePolicy} />
            <Route path='/dealermeeting2021' component={dealermeeting2021} />
            <Route path='/meetwithwil' component={meetwithwil} />
            <Route path='/schedule' component={schedule} />
            <Route path='/demo' component={demo} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </div>
      </Router>
    );
  }
}
