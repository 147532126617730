import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import Logo2 from '../../img/logo_black.png';
import language from '../../utils/vast-languagepack';

export default function Success() {
  return (
    <div className='pad-top'>
      <div className='padding-full'>
        <div className='col-xs-12 text-center wrap'>
          <img
            src={Logo2}
            alt={language.navi.titleAlt.logoAlt}
            className='logo img-fluid center-block'
          />
          <h1 className='text-center margin-top'>
            {language.verification.success.heading}
            <br />
            {language.verification.success.heading2}
          </h1>
          <p className='margin-top'>
            {language.verification.success.successMessage}
            <a href='https://console.vastreach.com'>
              {language.verification.success.successMessage2}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
