import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import DynamicAudienceNaviScrollSpy from './DynamicAudienceScrollSpy.js';
import DynamicAudienceImgA from './img/dynamic_stage_1.png';
import DynamicAudienceImgB from './img/dynamic_stage_2.png';
import DynamicAudienceImgC from './img/dynamic_stage_3.png';
import CampaignImgH from '../Campaigns/img/desktop_nav.jpg';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import video from './video/dynamic_audience_tutorial.mp4';

export default function Groups() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <DynamicAudienceNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/DynamicAudience'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/DynamicAudienceMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>
                CREATE A DYNAMIC AUDIENCE
              </h1>
              <p className='dynamic-audience-intro'>
                <strong>Dynamic Audiences</strong> help <i>you</i> organize{' '}
                <i>your</i> <i>contacts</i> for different marketing strategies
                and campaigns. If a <i>contact</i> needs to be added, removed or
                moved based on certain criteria or conditions <i>you</i> can do
                so creating dynamic audiences. This tutorial will teach{' '}
                <i>you</i> how to use dynamic audiences through a{' '}
                <strong>Campaign</strong>. By the end of this tutorial{' '}
                <i>you</i> will know how to manage <i>your</i> <i>contacts</i>{' '}
                based on <i>your</i> desired conditions.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Creating a Dynamic Audience
              </h3>
              <p>
                Before creating a <strong>Dynamic Audience</strong>, <i>you</i>{' '}
                must first have an active account set up with <i>your</i>{' '}
                <strong>Payment Settings</strong>. This is mandatory and must be
                done before accessing any part of <strong>Vast Reach</strong>.
                Next <i>you</i> will need to set up <i>your</i> <i>contact</i>{' '}
                information (email and phone number) through{' '}
                <strong>Account Settings & Preferences</strong>. After that{' '}
                <i>you</i> will need to <strong>Create Contacts</strong> to send
                marketing to, then <strong>Groups</strong> in order to add these{' '}
                <i>contacts</i> in. Lastly, <i>you</i> need to understand how{' '}
                <strong>Tasks</strong> and <strong>Campaigns</strong> work in
                order to complete this tutorial. Click the{' '}
                <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Buttons</strong> below before beginning this tutorial if{' '}
                <i>you</i> have not done so already.
              </p>
              <p>
                <Link to='/Payment'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
                <Link to='/AccountSettings'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Account Settings
                  </button>
                </Link>
                <Link to='/ManagePhoneNumbers'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Manage Phone Numbers
                  </button>
                </Link>
                <Link to='/Contacts'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Create a Contact
                  </button>
                </Link>
                <Link to='/Groups'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Create a Group
                  </button>
                </Link>
                <Link to='/SignUpForms'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Sign-Up Forms
                  </button>
                </Link>
                <Link to='/Tasks'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Tasks
                  </button>
                </Link>
                <Link to='/Campaigns'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Campaigns
                  </button>
                </Link>
              </p>
              <h3 className='before-setup'>Before Setting Up a Campaign</h3>
              <p>
                For the premise of this tutorial let’s say <i>you’re</i> a
                company trying to sell physical products to customers.{' '}
                <i>You</i> have several products but <i>you</i> want to target a
                group of people for a specific product and for the purposes of
                this tutorial we will call this product,{' '}
                <strong>Product X</strong>. First <i>you'll</i> need{' '}
                <strong>Contacts</strong> to create a <strong>Group</strong>{' '}
                that would potentially be interested in{' '}
                <strong>Product X</strong> (ex: group named{' '}
                <strong>Potential Leads for Product X</strong>). Next{' '}
                <i>you'll</i> will need to create a{' '}
                <strong>Sign-up Form</strong> to find out which <i>contacts</i>{' '}
                are actually interested in <strong>Product X</strong> from the{' '}
                <strong>Potential Leads for Product X</strong> group of{' '}
                <i>contacts</i> <i>you</i> made earlier. Then create 2 more
                groups, one <strong>Group</strong> for the leads that are
                actually interested (ex: group named{' '}
                <strong>Leads for Product X</strong>) and another for potential
                buyers (ex: group named{' '}
                <strong>Potential Customers of Product X</strong>). This should
                give <i>you</i> the basics needed to start a campaign.
              </p>
              <h3 className='navigation'>Navigate to Campaigns</h3>
              <p>
                In order to create a <strong>Dynamic Audience</strong>{' '}
                <i>you</i> first need to create a campaign by clicking{' '}
                <strong>Campaigns</strong> in the navigation (Left side menu).
                Additionally, <i>you</i> can navigate to the Campaigns page
                using the following link:{' '}
                <a href='https://console.vastreach.com/campaigns'>
                  https://console.vastreach.com/campaigns
                </a>
                . <i>You</i> will be taken to the Campaigns page.
              </p>
              <p>
                <img
                  src={CampaignImgH}
                  alt='campaign page navigation'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              <p>
                Clicking the <strong>ADD CAMPAIGN</strong> button at the top
                right will take <i>you</i> to the campaign creation page. Once{' '}
                <i>you</i> are on this page the <i>you</i> will be presented
                with the first part of the campaign creation which is the{' '}
                <strong>Campaign</strong> step. This tutorial will assume{' '}
                <i>you</i> have a basic understanding of campaigns already as
                this is one of the prerequisites. If <i>you</i> need to learn
                more about <strong>Campaigns</strong> click{' '}
                <Link to='/Campaigns'>here</Link>.
              </p>
              <h2 className='dynamic-audience-campaign'>
                Creating a Dynamic Audience Campaign
              </h2>
              <p>
                In the campaign <i>you</i> just created, head to the{' '}
                <strong>Funnel</strong> step in the stepper at the top of the
                page. <i>You</i> can begin by creating a{' '}
                <strong>Funnel Stage</strong>, for this tutorial we’ll call it{' '}
                <strong>Potential Leads</strong>. Inside this funnel stage,
                under the <strong>IF THIS HAPPENS</strong> section, let’s add
                the <strong>Submits Signup Form Event Goal</strong>. <i>You</i>{' '}
                can now select the sign-up form <i>you</i> made earlier in this
                tutorial. Then under the <strong>THEN DO THIS…</strong> section{' '}
                <i>you</i> can add the{' '}
                <strong>Add Contact to Group Task</strong> and add the{' '}
                <i>contacts</i> that will sign-up to the{' '}
                <strong>Leads for Product X</strong> group as a result of{' '}
                <i>contacts</i> filling out the sign-up form.
              </p>
              <p>
                <img
                  src={DynamicAudienceImgA}
                  alt='dynamic audience campaign stage 1'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              <p>
                Next create another funnel stage, this time call it{' '}
                <strong>Engagement</strong>. Add the{' '}
                <strong>Added to Group Event Goal</strong> and select the{' '}
                <strong>Leads for Product X</strong> group. Then add a{' '}
                <strong>Send Email Task</strong> in the next section and create
                an email to sell <strong>Product X</strong>, perhaps even a
                coupon email to incentivize the purchase.
              </p>
              <p>
                <img
                  src={DynamicAudienceImgB}
                  alt='dynamic audience campaign stage 2'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              <p>
                Lastly, create another funnel stage and name it{' '}
                <strong>Potential Customers</strong>. Create the{' '}
                <strong>Clicks Link in Email Event Goal</strong> and select the{' '}
                <strong>
                  Email <i>you</i> just created to sell Product X
                </strong>
                . Next add the <strong>Add Contact to Group Task</strong> and
                select the{' '}
                <strong>Potential Customers of Product X Group</strong>{' '}
                <i>you’ve</i> made earlier. Now you know the audience who are
                most likely to purchase products of this nature from <i>you</i>{' '}
                and <i>you</i> could use this group for future marketing
                campaigns or feedback emails and text messages.{' '}
              </p>
              <p>
                <img
                  src={DynamicAudienceImgC}
                  alt='dynamic audience campaign stage 3'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              <p>
                This was a very basic example of using campaigns to dynamically
                move your audience and there are yet many other simple and
                complex ways to take <i>your</i> marketing to the next level
                using <strong>Vast Reach</strong>.
              </p>
              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Remember sending out less emails and texts but rather more
                    engaging and interesting marketing is a better way to keep
                    customers happy while still pulling leads than sending too
                    many emails and messages. Think Quality over Quantity.
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Send too many marketing emails and text messages to{' '}
                    <i>your</i> <i>contacts</i>, its a sure way for them to
                    ignore <i>you</i> or worse unsubscribe.
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <h3>Creating a Dynamic Audience Using Campaign</h3>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
