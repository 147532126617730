import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import './footer.scss';
import Logo from '../img/logo_color.png';
import language from '../utils/vast-languagepack';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const pathObject = {
  '/index': { position: 'relative', zIndex: '999', bottom: '-472px' },
  '/index/': { position: 'relative', zIndex: '999', bottom: '-472px' },
  '/': { position: 'relative', zIndex: '999', bottom: '-472px' },
  '/about': { position: 'relative', zIndex: '999', bottom: '-370px' },
  '/features': { position: 'relative', zIndex: '999', bottom: '-370px' },
  '/faq': { position: 'relative', zIndex: '999', bottom: '-370px' },
  '/CallsAndMessages': {
    position: 'relative',
    zIndex: '999',
    bottom: '-370px',
  },
  '/CampaignInfo': {
    position: 'relative',
    zIndex: '999',
    bottom: '-370px',
  },
  '/Automation': {
    position: 'relative',
    zIndex: '999',
    bottom: '-370px',
  },
  '/ContactSetUp': {
    position: 'relative',
    zIndex: '999',
    bottom: '-370px',
  },
};

export default withRouter(({ location }) => {
  console.log(location);
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <footer
      style={
        location && location.pathname && pathObject[location.pathname]
          ? pathObject[location.pathname]
          : null
      }
      className='container-fluid'
    >
      <div className='row move-top-container'>
        <div className='col-xs-12 move-up-icon text-center' onClick={scrollTop}>
          <div className='move-top-arrow'><ArrowDropUpIcon></ArrowDropUpIcon></div>
          <p className='move-to-top-text'>MOVE TO TOP</p>
        </div>
      </div>
      <div className='fluid-container footer-container'>
        <div className='row'>
          <div className='col-xs-12 col-md-3 footer-nav'>
            <h3>{language.footer.menu}</h3>
            <ul className='no-list-style zero-padding'>
              <li className='footer-links'>
                <Link to='/index'>{language.footer.home}</Link>
              </li>
              <li className='footer-links'>
                <Link to='/about'>{language.footer.about}</Link>
              </li>
              <li className='footer-links'>
                <Link to='/features'>{language.footer.featureList}</Link>
              </li>
              <li className='footer-links'>
                <Link to='/faq'>{language.footer.faq}</Link>
              </li>
            </ul>
          </div>

          <div className='col-xs-12 col-md-6 text-center footer-about-us'>
            <img
              src={Logo}
              alt={language.footer.titleAlt.footerLogoAlt}
              className='footer-logo img-fluid'
              style={{ width: '400px', margin: '0 auto 20px auto' }}
            />
            <p>{language.footer.vastFooterDescription}</p>
          </div>

          <div className='col-xs-12 col-md-3 contacts'>
            <h3>{language.footer.contactUs}</h3>
            <ul className='no-list-style zero-padding'>
              <li className='footer-links'>
                <a href='mailto:info@vastreach.com'>
                  {language.footer.emailAddress}
                </a>
              </li>
              <li className='footer-links'>
                <Link to='/TermsOfService'>Terms of Service</Link>
              </li>
              <li className='footer-links'>
                <Link to='/PrivacyPolicy'>Privacy Policy</Link>
              </li>
              <li className='footer-links'>
                <Link to='/AcceptableUse'>Acceptable Use</Link>
              </li>
              <li className='footer-links'>
                <Link to='/BrandingGuidelines'>Branding Guidelines</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='row copyright-box'>
        <div className='col-xs-12 copyright'>
          <p className='text-center'>&copy; {language.footer.copyright}</p>
        </div>
      </div>
    </footer>
  );
});
