import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../generalCss/style.scss';
import CampaignBuildImg from '../img/phone-example.png';
import SmartTaskImg from '../img/phone-example3.png';
import VastAi from '../img/phone-example4.png';
import rightCaret from '../svg/caret-right.svg';
import language from '../utils/vast-languagepack';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';

export default function About() {
  return (
    <div>
      <div className='containter-fluid text-center page-banners banner-wrap'>
        <div className='container page-header-text'>
          <h1>{language.about.heading}</h1>
        </div>
      </div>

      <div className='page-top-wrap'>
        <div className='container-fluid padding-full'>
          <div className='container wrap'>
            <div className='row'>
              <div className='col-xs-12 col-md-6'>
                <h2 className='text-margin'>
                  <LabelImportantIcon
                    style={{
                      fontSize: '2.5rem',
                      color: '#006fd9',
                      margin: '-6px 5px 0 0',
                    }}
                  />
                  {language.about.productVisionHeading}
                </h2>
                <p>{language.about.visionDescription}</p>
              </div>
              <div className='col-xs-12 col-md-6 text-center'>
                <img
                  src={CampaignBuildImg}
                  alt={language.about.titleAlt.productiveVisionImgAlt}
                  className='img-fluid center-block'
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className='container-fluid bg-2 padding-full'>
          <div className='container wrap'>
            <div className='row'>
              <div className='col-xs-12 col-md-6 order-md-2'>
                <h2 className='text-margin'>
                  <LabelImportantIcon
                    style={{
                      fontSize: '2.5rem',
                      color: '#006fd9',
                      margin: '-6px 5px 0 0',
                    }}
                  />
                  {language.about.missionHeading}
                </h2>
                <p>
                  {language.about.missionDescriptionOne}{' '}
                  <strong>{language.about.missionDescriptionTwo}</strong>{' '}
                  {language.about.missionDescriptionThree}{' '}
                  <strong>{language.about.missionDescriptionFour}</strong>{' '}
                  {language.about.missionDescriptionFive}{' '}
                  <strong>{language.about.missionDescriptionSix}</strong>{' '}
                  {language.about.missionDescriptionSeven}{' '}
                  <strong>{language.about.missionDescriptionEight}</strong>{' '}
                  {language.about.missionDescriptionNine}
                </p>
              </div>
              <div className='col-xs-12 col-md-6 order-md-1 text-center'>
                <img
                  src={SmartTaskImg}
                  alt={language.about.titleAlt.missionImgAlt}
                  className='img-fluid center-block'
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className='container-fluid padding-full'>
          <div className='container wrap'>
            <div className='row'>
              <div className='col-xs-12 col-md-6'>
                <h2 className='text-margin'>
                  <LabelImportantIcon
                    style={{
                      fontSize: '2.5rem',
                      color: '#006fd9',
                      margin: '-6px 5px 0 0',
                    }}
                  />
                  {language.about.aiHeading}
                </h2>
                <p>{language.about.aiInfoOne}</p>
                <ul className='no-list-style zero-padding ai-list'>
                  <li>
                    <img
                      src={rightCaret}
                      className='list-icon'
                      alt={language.general.titleAlt.rightCaretIcon}
                    />
                    <i>
                      {' '}
                      <p style={{ fontSize: '16px', display: 'inline' }}>
                        "{language.about.aiInfoTwo}"
                      </p>
                    </i>
                  </li>
                  <li>
                    <img
                      src={rightCaret}
                      className='list-icon'
                      alt={language.general.titleAlt.rightCaretIcon}
                    />
                    <i>
                      {' '}
                      <p style={{ fontSize: '16px', display: 'inline' }}>
                        "{language.about.aiInfoThree}"
                      </p>
                    </i>
                  </li>
                  <li>
                    <img
                      src={rightCaret}
                      className='list-icon'
                      alt={language.general.titleAlt.rightCaretIcon}
                    />
                    <i>
                      {' '}
                      <p style={{ fontSize: '16px', display: 'inline' }}>
                        "{language.about.aiInfoFour}"
                      </p>
                    </i>
                  </li>
                </ul>
                <p>{language.about.aiInfoFive}</p>
              </div>
              <div className='col-xs-12 col-md-6 text-center'>
                <img
                  src={VastAi}
                  alt={language.about.titleAlt.aiImgAlt}
                  className='img-fluid center-block'
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className='container-fluid padding-top'>
          <div className='container wrap'>
            <div className='col-xs-12'>
              <h2 className='text-center'>
                WANT TO SEE MORE? SCHEDULE A LIVE DEMO TODAY!
              </h2>
            </div>
          </div>
        </div>

        <div className='container-fluid padding-full '>
          <div className='col-xs-12 zero-padding wrap'>
            <iframe
              title='book'
              src='https://koalendar.com/e/meet-with-wil-casillas?embed=true'
              width='100%'
              height='800px'
              frameborder='0'
            ></iframe>
            <div className='col-xs-12 text-center'>
              <h3>
                Don't See a time that works for you? Get in contact with Wil
                Casillas{' '}
              </h3>
              <p>
                <a href='mailto: wcasillas@vasttechnologies.com'>
                  wcasillas@vasttechnologies.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
