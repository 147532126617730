import React from 'react';
import { Link } from 'react-scroll';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import { Accordion, Card } from 'react-bootstrap';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

export default function MultiChannelInboxMobileScrollSpy() {
  return (
    <div className='col-xs-12 col-lg-3 order-lg-2'>
      <Accordion defaultActiveKey='0' className='accordion-wrap'>
        <h2 className='faq-navi-header'>Multi-Channel Inbox Navigation</h2>

        <Card>
          <Card.Header className='accordion-header'>
            <p className='active-link'>
              <Link
                to='inbox-intro'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Introduction To Inbox
              </Link>
            </p>
            <Accordion.Toggle variant='link' eventKey='0' className='carret'>
              <ArrowDropDownIcon />
            </Accordion.Toggle>
          </Card.Header>

          <Accordion.Collapse eventKey='0'>
            <Card.Body>
              <ul className='tutorial-side-nav'>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='prerequisites'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Prerequisites Before Accessing Inbox
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link'>
                    <Link
                      to='navigation'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Navigate to Inbox
                    </Link>
                  </p>
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header className='accordion-header'>
            <p className='active-link'>
              <Link
                to='convo-list'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Learning Your Conversation List
              </Link>
            </p>
            <Accordion.Toggle variant='link' eventKey='1' className='carret'>
              <ArrowDropDownIcon />
            </Accordion.Toggle>
          </Card.Header>

          <Accordion.Collapse eventKey='1'>
            <Card.Body>
              <ul className='tutorial-side-nav'>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='search-bar'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Using The Search Bar To Find Contacts
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link'>
                    <Link
                      to='add-contact'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Reach Out To a New Contact
                    </Link>
                  </p>
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header className='accordion-header'>
            <p className='active-link'>
              <Link
                to='convo-column'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Understanding Conversation Preview
              </Link>
            </p>
            <Accordion.Toggle variant='link' eventKey='2' className='carret'>
              <ArrowDropDownIcon />
            </Accordion.Toggle>
          </Card.Header>

          <Accordion.Collapse eventKey='2'>
            <Card.Body>
              <ul className='tutorial-side-nav'>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='convo-header'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Using The Conversation Header
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link'>
                    <Link
                      to='convo-preview'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Learning The Conversation Types
                    </Link>
                  </p>
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header className='accordion-header'>
            <p className='active-link'>
              <Link
                to='contact-details'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Setting Up Contact Details
              </Link>
            </p>
            <Accordion.Toggle variant='link' eventKey='3' className='carret'>
              <ArrowDropDownIcon />
            </Accordion.Toggle>
          </Card.Header>

          <Accordion.Collapse eventKey='3'>
            <Card.Body>
              <ul className='tutorial-side-nav'>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='contact-history'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Contact History
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='contact-info'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Contact Information
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='tasks'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Tasks
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='groups'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Groups
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link'>
                    <Link
                      to='custom-attributes'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Custom Attributes
                    </Link>
                  </p>
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header className='accordion-header'>
            <p className='active-link'>
              <Link
                to='do-dont'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Do's and Don'ts
              </Link>
            </p>
          </Card.Header>
        </Card>

        <Card>
          <Card.Header className='accordion-header'>
            <p className='active-link'>
              <Link
                to='video-demo'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Video Demonstration
              </Link>
            </p>
          </Card.Header>
        </Card>
      </Accordion>

      <Accordion
        className='position-fixed bottom-nav-wrap'
      >
        <Card>
          <Card.Header className='accordion-header'>
            <div className='header-wrap'>
              <p className='mobile-nav-header'>Jump To...</p>
              <Accordion.Toggle variant='link' eventKey='0' className='carret'>
                <ArrowDropUpIcon />
              </Accordion.Toggle>
            </div>
          </Card.Header>

          <Accordion.Collapse eventKey='0'>
            <Card.Body>
              <ul className='tutorial-side-nav'>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='inbox-intro'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Introduction To Inbox
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='prerequisites'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Prerequisites Before Accessing Inbox
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='navigation'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Navigate to Inbox
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='convo-list'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Learning Your Conversation List
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='search-bar'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Using The Search Bar To Find Contacts
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='add-contact'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Reach Out To a New Contact
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='convo-column'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Understanding Conversation Preview
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='convo-header'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Using The Conversation Header
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='convo-preview'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Learning The Conversation Types
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='contact-details'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Setting Up Contact Details
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='contact-history'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Contact History
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='contact-info'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Contact Information
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='tasks'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Tasks
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='groups'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Groups
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='custom-attributes'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Custom Attributes
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='do-dont'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Do's and Don'ts
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link'>
                    <Link
                      to='video-demo'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Video Demonstration
                    </Link>
                  </p>
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}
