import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import ContactsNaviScrollSpy from './ContactsNaviScrollSpy.js';
import ContactImgA from './img/contact1.png';
import ContactImgB from './img/contact2.jpg';
import ContactImgC from './img/contact3.jpg';
import ContactImgD from './img/contact4.jpg';
import ContactImgE from './img/desktop_nav.jpg';
import ContactImgF from './img/search_filter.jpg';
import ContactImgG from '../ContactsMobile/img/create_contact_button.png';
import video from './video/contacts_tutorial.mov';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function Contacts() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <ContactsNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/Contacts'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/ContactsMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>CONTACTS</h1>
              <p className='contact-intro'>
                <i>You</i> can Import <i>your</i> <i>contacts</i> and their data
                in one place so <i>you</i> can begin automating <i>your</i>{' '}
                work-flow. In this tutorial <i>you</i> will learn how to add an
                individual <i>contact</i> and bulk upload several{' '}
                <i>contacts</i>.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Creating a Contact
              </h3>
              <p>
                Before creating a <strong>Contact</strong>, <i>you</i> must
                first have an active account set up with <i>your</i>{' '}
                <strong>Payment Settings</strong>. This is mandatory and must be
                done before accessing <strong>Contacts</strong> page. Click the{' '}
                <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Button</strong> below to set up{' '}
                <strong>Payments</strong> before beginning this tutorial if{' '}
                <i>you</i> have not done so already.
              </p>
              <p>
                <Link to='/Payment'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
              </p>
              <h3 className='navigation'>Navigate to Contacts Page</h3>
              <p>
                To create a new <i>contact</i> or upload existing{' '}
                <i>contacts</i> <i>you</i> can do so by clicking the{' '}
                <strong>Contacts</strong> link in the navigation (Left side menu
                bar). Additionally, <i>you</i> can navigate to the{' '}
                <strong>Contact List Page</strong> using the following link:
                <a href='https://console.vastreach.com/contacts'>
                  https://console.vastreach.com/contacts
                </a>
                .
              </p>
              <p>
                <img
                  src={ContactImgE}
                  alt='contact page navigation'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              <h4>Understanding Your Contact List</h4>
              <p>
                <i>Note: </i> If you are reading this with a new account scroll
                down to learn how to begin using the{' '}
                <strong>Contact List</strong> page on a new account.
              </p>
              <p>
                Inside the <strong>Contact List</strong> view <i>you</i> will
                see the <i>contacts</i> name on the left, their number in the
                center and an ellipsis icon (3 dot icon) on the right. Clicking
                the ellipsis icon brings up a menu, in this menu <i>you</i> can
                add this <i>contact</i> to available groups here also or delete
                them out right (A confirmation window prompt when deleting).
              </p>
              <p>
                <img
                  src={ContactImgD}
                  alt='contact list'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              <h4>Using The Search & Filter Bar</h4>
              <p>
                Above the list view, this bar will allow you to sort, filter or
                search a specific <i>contact</i> from <i>your</i> list of{' '}
                <i>contacts</i>.
              </p>
              <p style={{ margin: '10px 0 10px 0' }}>
                <img
                  src={ContactImgF}
                  alt='search and filter bar'
                  className='img-fluid'
                  style={{ maxWidth: '150px', width: '100%' }}
                />
              </p>
              <h2 className='new-account'>
                Learning to Use Contact List On a New Account
              </h2>
              <p>
                A new account will see 2 key elements on this page, those are
                the <strong>Bulk Upload</strong> button and the{' '}
                <strong>Add Contact</strong> button.
              </p>
              <p>
                <img
                  src={ContactImgA}
                  alt='upload create contact'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h3 className='bulk-upload'>Bulk Upload Your Contacts</h3>
                  <p>
                    The <strong>Bulk Upload</strong> button at the center will
                    only show if the <i>contact</i> list is empty, otherwise
                    clicking the <strong>Arrow</strong> at the top right inside
                    the <strong>ADD CONTACT</strong> button then selecting{' '}
                    <strong>Bulk Upload</strong> from the list is the only other
                    way. Once clicked <i>you</i> will be taken to a new screen
                    to begin uploading <i>your</i> existing <i>contacts</i>.{' '}
                    <i>You</i> can click <strong>SELECT FILES…</strong> or drag
                    and drop in <i>your</i> existing <i>contact</i> file{' '}
                    <strong>.csv file</strong> here. Once uploaded <i>you</i>{' '}
                    will be taken back to the <strong>Contact List Page</strong>{' '}
                    <i>you</i> were previously on but now populated with{' '}
                    <i>your</i> existing <i>contacts</i> from <i>your</i>{' '}
                    <strong>.csv</strong> file.
                  </p>

                  <p>
                    <i>Note:</i> <i>You</i> can see a record of all your
                    previous <strong>Bulk Uploads</strong> by clicking the{' '}
                    <strong>Arrow</strong> at the top right inside the{' '}
                    <strong>ADD CONTACT</strong> button, then selecting{' '}
                    <strong>Show My Previous Bulk Uploads</strong> from the
                    list. Once clicked a modal (pop-up window) will show{' '}
                    <i>you</i> your previous <strong>.csv</strong> file uploads.
                  </p>
                  <p>
                    <img
                      src={ContactImgB}
                      alt='create contact upload page'
                      className='img-fluid'
                      style={{ maxWidth: '1000px', width: '100%' }}
                    />
                  </p>
                </li>
                <li>
                  <h3 className='creating-contact'>Creating a Contact</h3>
                  <p>
                    Similarly to the <strong>Bulk Upload</strong> button at the
                    center, the <strong>Add Contact</strong> button will only
                    show at the center if the <i>contact</i> list is empty,
                    otherwise clicking the <strong>ADD CONTACT</strong> button
                    at the top right of the screen is the only other way.
                  </p>
                  <p style={{ margin: '5px 0 0 0' }}>
                    <img
                      src={ContactImgG}
                      alt='create contact button'
                      className='img-fluid'
                      style={{ maxWidth: '200px', width: '100%' }}
                    />
                  </p>
                  <p>
                    <i>You</i> will be taken to the <i>contact</i> creation
                    page. There are 6 sections to each <i>contact</i> page and
                    they are listed below.
                  </p>
                  <p>
                    <img
                      src={ContactImgC}
                      alt='create contact layout'
                      className='img-fluid'
                      style={{ maxWidth: '1000px', width: '100%' }}
                    />
                  </p>
                  <ol>
                    <li>
                      <p>
                        The first section at the very top allows <i>you</i> to
                        add the <i>contact's</i> full name (First, Middle and
                        Last) when clicking <strong>Name*</strong>. <i>You</i>{' '}
                        can add the <i>contacts</i> location in the{' '}
                        <strong>Location</strong> label and lastly add notes
                        about this <i>contact</i> under <strong>Notes</strong>.
                      </p>
                      <p>
                        <i>Note:</i> To the left of the text labels there is a{' '}
                        <strong style={{ color: '#15b940' }}>Green</strong> box
                        with a <strong>Contact Icon</strong> in it, if this{' '}
                        <i>contact</i> was a saved <i>contact</i> then there
                        will be a <strong>CONVERSATION</strong> button below
                        this. Clicking this button will take <i>you</i> to the{' '}
                        <strong>Inbox Page</strong> to begin a conversation with
                        this <i>contact</i>.
                      </p>
                    </li>
                    <li>
                      <p>
                        Task section becomes available to use with this{' '}
                        <i>contact</i> once the <i>contact</i> has{' '}
                        <strong>at least one number or email</strong>.{' '}
                        <i>you</i> can then start a task using this{' '}
                        <i>contact</i> by clicking a task from this section.
                      </p>
                    </li>
                    <li>
                      <p>
                        To the right of tasks is <strong>Contact Info</strong>.
                        For a <i>contact</i> to be created and saved, the{' '}
                        <i>contact</i> needs{' '}
                        <strong>at least one Phone Number and Email</strong>,
                        more can be added by clicking the <strong>+ NEW</strong>{' '}
                        button below each <i>contact</i> text area.
                        Additionally, <i>you</i> can manually select a{' '}
                        <i>contact</i> to opt out of being sent any emails or
                        text messages by un-checking the <strong>Opt in</strong>{' '}
                        check box (by default this is checked on).
                      </p>
                    </li>
                    <li>
                      <p>
                        Below the Task section is <strong>Groups</strong>. To
                        add this <i>contact</i> to a group(s) that was
                        previously created, click the{' '}
                        <strong>Select Group</strong> list and choose{' '}
                        <i>your</i> groups. Most used groups will be shown under
                        suggestions below the list. To learn how to create a
                        group click <Link to='/Groups'>here</Link>.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Custom Attributes</strong> are at the bottom of{' '}
                        <strong>Contact Information</strong> section and are
                        text labels such as Occupation, State or ZIP. This can
                        be added manually by clicking the <strong>+ NEW</strong>{' '}
                        button.
                      </p>
                    </li>
                    <li>
                      <p>
                        The last section is the <strong>History</strong>{' '}
                        section. At the top there are 2 text tabs (
                        <strong>ACTIVITY</strong> and <strong>CAMPAIGNS</strong>
                        ).
                        <ul>
                          <li>
                            <p>
                              In the <strong>ACTIVITY</strong> tab <i>you</i>{' '}
                              can keep track of all the activity <i>you</i> have
                              with this <i>contact</i> such as text messages,
                              calls, emails, etc.
                            </p>
                          </li>
                          <li>
                            <p>
                              In the <strong>CAMPAIGNS</strong> tab <i>you</i>{' '}
                              can keep track of all the interactions this{' '}
                              <i>contact</i> has been through in campaigns they
                              were in.
                            </p>
                          </li>
                        </ul>
                      </p>
                    </li>
                  </ol>
                  <p>
                    Once <i>you</i> are finished creating a <i>contact</i> or{' '}
                    bulk uploading <i>your</i> contacts, those <i>contacts</i>{' '}
                    will be automatically saved. <i>You</i> can return to the{' '}
                    <strong>Contact List Page</strong> page to see <i>your</i>{' '}
                    <i>contacts</i>.
                  </p>
                  <p>
                    <i>Note: </i>
                    <i>You</i> can also create <i>contacts</i> inside a Group
                    which will take <i>you</i> to the <i>contact</i> creation
                    page directly from a group page. <i>You</i> can also delete
                    a <i>contact</i> out right from a group page. To learn to
                    create a group Click <Link to='/Groups'>here</Link>.
                  </p>
                </li>
              </ul>

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Remember deleting a <i>contact</i> is permanent and
                    irreversible (we implemented a confirmation feature to
                    prevent accidental contact deletes).
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Forget that a <i>contact</i> will not be saved without an
                    email or phone number.
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
