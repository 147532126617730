import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import CampaignNaviScrollSpy from './EventGoalsScrollSpy.js';
import CampaignImgH from '../Campaigns/img/desktop_nav.jpg';
import addToGroup from './img/added_to_group.png';
import removedFromGroup from './img/removed_from_group.png';
import submitSignupForm from './img/submit_signup_form.png';
import opensEmail from './img/opens_email.png';
import clicksEmailLink from './img/clicks_email_link.png';
import sentEmail from './img/sent_email.png';
import sentSms from './img/sent_sms.png';
import emailBounce from './img/email_bounce.png';
import anyContactCreated from './img/any_contact_created.png';
import anyContactUnsubs from './img/any_contact_unsubs.png';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import video from './video/added_to_group.mov';
import video2 from './video/removed_from_group.mov';
import video3 from './video/submits_signup_form.mov';
import video4 from './video/opens_email.mov';
import video5 from './video/clicks_link_in_email.mov';
import video6 from './video/sent_email.mov';
import video7 from './video/sent_sms.mov';
import video8 from './video/email_bounces.mov';
import video9 from './video/any_contact_created.mov';
import video10 from './video/any_contact_unsubs.mov';
// import video11 from './video/';

export default function EventGoals() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <CampaignNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/EventGoals'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/EventGoalsMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>
                UNDERSTANDING EVENT GOALS
              </h1>
              <p className='event-goals-intro'>
                The purpose of an <strong>Event Goal</strong> is to move a{' '}
                <i>contact</i> from one <strong>Stage</strong> to the next in a{' '}
                <strong>Campaign</strong>. There are many types of{' '}
                <strong>Event Goals</strong> and when a <i>contact</i> triggers
                an <strong>Event Goal</strong> then the <strong>Tasks</strong>{' '}
                inside of the <strong>THEN DO THIS…</strong> section begin to
                fire and the <i>contact</i> will be moved forward to the next{' '}
                <strong>Stage</strong> if applicable. By the end of this
                tutorial <i>you</i> will understand how to set up each of the
                event types inside a <strong>Campaign Stage</strong>.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Creating an Event Goal
              </h3>
              <p>
                Before creating a <strong>Event Goal</strong>, <i>you</i> must
                first have an account set up with an active subscription. It is
                recommend <i>you</i> learn the majority of{' '}
                <strong>Vast Reach’s</strong> feature sets such as adding phone
                numbers, creating <i>contacts</i>, groups, forms and tasks as
                many of them will be incorporated into campaigns which also
                hosts event goals inside the campaign funnel. Click the{' '}
                <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Buttons</strong> below to set up anything <i>you're</i>{' '}
                missing before beginning this tutorial if <i>you</i> have not
                done so already.
              </p>
              <p>
                <Link to='/GettingStarted'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Getting Started
                  </button>
                </Link>
                <Link to='/Payment'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
                <Link to='/ManagePhoneNumbers'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Manage Phone Numbers
                  </button>
                </Link>
                <Link to='/Contacts'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Create a Contact
                  </button>
                </Link>
                <Link to='/Groups'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Create a Group
                  </button>
                </Link>
                <Link to='/ReCaptcha'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    ReCaptcha
                  </button>
                </Link>
                <Link to='/SignUpForms'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Sign-Up Forms
                  </button>
                </Link>
                <Link to='/Tasks'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Tasks
                  </button>
                </Link>
                <Link to='/Campaigns'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Campaigns
                  </button>
                </Link>
              </p>
              <h3 className='navigation'>Navigate to Campaigns</h3>
              <p>
                <i>You</i> can create a campaign by clicking{' '}
                <strong>Campaigns</strong> in the navigation (Left side menu).
                Additionally, <i>you</i> can navigate to the Campaigns page
                using the following link:{' '}
                <a href='https://console.vastreach.com/campaigns'>
                  https://console.vastreach.com/campaigns
                </a>
                . <i>You</i> will be taken to the Campaigns page.
              </p>
              <p>
                <img
                  src={CampaignImgH}
                  alt='campaign page navigation'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              <p>
                Clicking the <strong>ADD CAMPAIGN</strong> button at the top
                right will take <i>you</i> to the campaign creation page. Once{' '}
                <i>you</i> are on this page the <i>you</i> will be presented
                with the first part of the campaign creation which is the{' '}
                <strong>Campaign</strong> step. For the purposes of this
                tutorial we will be skipping ahead to the event goals in the
                campaign funnel. If <i>you</i> would like to learn more about
                Campaigns click <Link to='/Campaigns'>here</Link>.
              </p>
              <h2 className='event-goals'>Understanding Event Goals</h2>
              <p>
                Each event goal does something different for <i>your</i>{' '}
                campaign needs. In this tutorial we will begin to explore each
                event goal with greater detail such as when, why and how{' '}
                <i>you</i> would use a specific event goal.{' '}
              </p>
              <h3 className='add-to-group'>Add To Group</h3>
              <p>
                Whenever a <i>contact</i> is added to a group in this stage this
                event goal will then trigger the <strong>THEN DO THIS…</strong>{' '}
                section of this stage. This will move the <i>contacts</i> from
                this stage onward to the next stage if one is applicable.{' '}
              </p>
              <p>
                An example of how this would be used would be if <i>you</i>{' '}
                scheduled a <strong>Add contact to a group</strong> task to
                trigger the event goal of this stage. This will in turn trigger
                the <strong>THEN DO THIS…</strong> section of tasks you created
                in this stage to move the contacts from this stage to the next
                stage.{' '}
              </p>
              <p>
                <img
                  src={addToGroup}
                  alt='added to group event goal'
                  className='img-fluid'
                  style={{ maxWidth: '800px', width: '100%' }}
                />
              </p>
              <h3 className='removed-from-group'>Removed From Group</h3>
              <p>
                Whenever a <i>contact</i> is removed from this group in this
                stage this event goal will then trigger the{' '}
                <strong>THEN DO THIS…</strong> section of this stage. This will
                move the <i>contacts</i> from this stage onward to the next
                stage if one is applicable.
              </p>
              <p>
                An example of this would be if <i>you</i> wanted to remove some{' '}
                <i>contacts</i> from a marketing group <i>you</i> made but and
                send them a different kind of marketing email <i>you</i> would
                do so by scheduling a task to remove these <i>contacts</i> to
                trigger the <strong>Removed from group</strong> event goal from
                a campaign stage. Then using the <strong>THEN DO THIS…</strong>{' '}
                section to send them a different marketing email.
              </p>
              <img
                src={removedFromGroup}
                alt='removed from group event goal'
                className='img-fluid'
                style={{
                  maxWidth: '800px',
                  width: '100%',
                  marginBottom: '20px',
                }}
              />
              <h3 className='submits-signup-from'>Submits Sign-up Form</h3>
              <p>
                When a <i>contact</i> submits a sign-up form <i>you</i> sent out
                you can use this event goal in a stage to trigger another task.
                For example, <i>you</i> could send out a coupon using an email
                task for the <i>contact</i> using the sign-up form. This may be
                a great way to build customer loyalty.
              </p>
              <img
                src={submitSignupForm}
                alt='submit sign-up form event goal'
                className='img-fluid'
                style={{
                  maxWidth: '800px',
                  width: '100%',
                  marginBottom: '20px',
                }}
              />
              <h3 className='opens-email'>Opens Email</h3>
              <p>
                For this event goal we can continue off the example of the above
                event goal. After sending out a coupon email to a <i>contact</i>{' '}
                <i>you</i> can in the next stage follow up based on if they
                opened the email or not using this event goal. Then as a trigger
                of the event goal <i>you</i> could add them to a group and
                determine later if they are ideal for other marketing campaigns.{' '}
              </p>
              <img
                src={opensEmail}
                alt='opens email event goal'
                className='img-fluid'
                style={{
                  maxWidth: '800px',
                  width: '100%',
                  marginBottom: '20px',
                }}
              />
              <h3 className='click-link-email'>Clicks Link In Email</h3>
              <p>
                Going off the previous event goals examples, after a{' '}
                <i>contact</i> opens an email and satisfies the previous stage{' '}
                <i>your</i> next stage can trigger based on if the{' '}
                <i>contact</i> clicks a link on that previous email using the{' '}
                <strong>Clicks Link in Email</strong> event goal. <i>You</i>{' '}
                could then move this contact to another group for potentially
                interested customers as a result of triggering this event goal.{' '}
              </p>
              <img
                src={clicksEmailLink}
                alt='clicks email link event goal'
                className='img-fluid'
                style={{
                  maxWidth: '800px',
                  width: '100%',
                  marginBottom: '20px',
                }}
              />
              <h3 className='sent-email'>Sent An Email</h3>
              <p>
                When an email is sent this event goal will trigger. One way{' '}
                <i>you</i> could set this stage up would to add <i>contacts</i>{' '}
                to a group as a result of this event goal triggering. This way{' '}
                <i>you</i> could keep track of all the <i>contacts</i> that were
                sent this email.{' '}
              </p>
              <img
                src={sentEmail}
                alt='sent an email event goal'
                className='img-fluid'
                style={{
                  maxWidth: '800px',
                  width: '100%',
                  marginBottom: '20px',
                }}
              />
              <h3 className='sent-sms'>Sent An SMS</h3>
              <p>
                Similarly to the <strong>Sent an Email</strong> event goal, when
                an SMS is sent this event goal will trigger. <i>You</i> could
                set this stage up to add <i>contacts</i> to a group as a result
                of this event goal triggering. This way <i>you</i> could keep
                track of all the <i>contacts</i> that were sent this SMS.{' '}
              </p>
              <img
                src={sentSms}
                alt='sent an sms event goal'
                className='img-fluid'
                style={{
                  maxWidth: '800px',
                  width: '100%',
                  marginBottom: '20px',
                }}
              />
              <h3 className='email-bounces'>Email Bounces</h3>
              <p>
                Email bounces can ruin marketing emails when <i>contacts</i>{' '}
                can’t be reached due to technical or other issues. A good way to
                keep track of <i>contacts</i> that end up returning an email
                bounce is to send them to a group in the{' '}
                <strong>THEN DO THIS…</strong> section when this event goal is
                triggered. This way <i>you</i> can attempt to resend or sort out
                if those <i>contacts</i> can be reached.{' '}
              </p>
              <img
                src={emailBounce}
                alt='email bounce event goal'
                className='img-fluid'
                style={{
                  maxWidth: '800px',
                  width: '100%',
                  marginBottom: '20px',
                }}
              />
              <h3 className='any-contact-created'>Any Contact Created</h3>
              <p>
                When any <i>contact</i> is created <i>you</i> can trigger this
                event goal. This could be a great way send new <i>contacts</i>{' '}
                that are created a welcome email or text message through the{' '}
                <strong>THEN DO THIS…</strong> section.{' '}
              </p>
              <img
                src={anyContactCreated}
                alt='any contact created event goal'
                className='img-fluid'
                style={{
                  maxWidth: '800px',
                  width: '100%',
                  marginBottom: '20px',
                }}
              />
              <h3 className='any-contact-unsubs'>Any Contact Unsubscribes</h3>
              <p>
                Any time a <i>contact</i> unsubscribes from something it’s a
                clear indication they do not want to be part of that marketing
                campaign. This would be a good time to use this event goal to
                trigger a task to remove them from any marketing groups or
                perhaps move them to a marketing campaign they are still
                interested in.{' '}
              </p>
              <img
                src={anyContactUnsubs}
                alt='any contact unsubscribe event goal'
                className='img-fluid'
                style={{
                  maxWidth: '800px',
                  width: '100%',
                  marginBottom: '20px',
                }}
              />
              <h3 className='responds-to-question'>Responds To Question</h3>
              <p>
                This event goal can be useful if <i>you</i> are looking for
                specific <i>contacts</i> that fit a certain criteria. For
                example, if you send a <i>contact</i> a form to fill out and
                they are interested in one of <i>your</i> products but not the
                others <i>you</i> can move them to a specific group and use
                another campaign to only market that kind of product to that{' '}
                <i>contact</i>.
              </p>

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Remember when removing a stage from a campaign cancels all
                    tasks for contacts in that campaign stage. There is no way
                    to undo this.
                  </p>
                </li>
                <li>
                  <p>
                    Remember a contact cannot be moved to previous stage in a
                    campaign funnel. So plan <i>your</i> event goals and tasks
                    properly in each stage.
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Remove stages from a campaign that has already started
                    processing leads through the funnel as this may lead to
                    confusing results.
                  </p>
                </li>
                <li>
                  <p>
                    Send <i>contacts</i> marketing that they unsubscribed from.
                    Honor their decision and remove them from <i>your</i>{' '}
                    marketing.
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <h3>Creating an Add To Group Event Goal</h3>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
              <div className='video-wrapper'>
                <h3>Creating a Removed From Group Event Goal</h3>
                <video
                  src={video2}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
              <div className='video-wrapper'>
                <h3>Creating a Submits Sign-up Form Event Goal</h3>
                <video
                  src={video3}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
              <div className='video-wrapper'>
                <h3>Creating a Opens Email Event Goal</h3>
                <video
                  src={video4}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
              <div className='video-wrapper'>
                <h3>Creating a Clicks Link In Email Event Goal</h3>
                <video
                  src={video5}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
              <div className='video-wrapper'>
                <h3>Creating a Sent An Email Event Goal</h3>
                <video
                  src={video6}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
              <div className='video-wrapper'>
                <h3>Creating a Sent An SMS Event Goal</h3>
                <video
                  src={video7}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
              <div className='video-wrapper'>
                <h3>Creating a Email Bounces Event Goal</h3>
                <video
                  src={video8}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
              <div className='video-wrapper'>
                <h3>Creating an Any Contact Created Event Goal</h3>
                <video
                  src={video9}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
              <div className='video-wrapper'>
                <h3>Creating an Any Contact Unsubscribes Event Goal</h3>
                <video
                  src={video10}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
              {/* <div className='video-wrapper'>
                <h3>Creating a Responds To Question Event Goal</h3>
                <video
                  src={video11}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
