import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import CreateUserNaviScrollSpy from './CreateUserNaviScrollSpy.js';
import CreateUserImgA from '../AccountSettings/img/account_settings.jpg';
import CreateUserImgB from './img/user_list_desktop2.png';
import CreateUserImgD from './img/user_list_desktop3.png';
import CreateUserImgE from './img/user_list_desktop4.png';
import CreateUserImgF from './img/user_list_desktop0.png';
// import CreateUserImgC from '../UserPermission/img/user_permissions1.png';
import AccountSettingsA from '../AccountSettings/img/desktop_nav.jpg';
import video from './video/create_user_tutorial.mov';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function CreateUserMobile() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <CreateUserNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/CreateUser'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/CreateUserMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>INVITE NEW USERS</h1>
              <p className='user-invite'>
                This tutorial will teach <i>you</i> how to invite and manage{' '}
                <i>your</i> <i>users</i> as well as understand <i>user</i>{' '}
                invitation limits based on the subscription plan of <i>your</i>{' '}
                account.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Inviting a User
              </h3>
              <p>
                Before <strong>Inviting a User</strong>, <i>you</i> must first
                have an account set up with an active subscription. Click the{' '}
                <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Button</strong> below to set up anything <i>you're</i>{' '}
                missing before beginning this tutorial if <i>you</i> have not
                done so already.
              </p>
              <p>
                <Link to='/Payment'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
              </p>
              <h3 className='navigation'>
                Navigate to Account Preferences & Settings
              </h3>
              <p>
                In order for <i>you</i> to add a <i>user</i>, <i>you</i> must
                invite a <i>user</i>. To invite a <i>user</i> <i>you</i> can
                begin by visiting{' '}
                <strong>Account Preferences & Settings</strong> page by clicking
                the link in the navigation (Left side menu). Additionally,{' '}
                <i>you</i> can navigate to the{' '}
                <strong>Account Settings & Preferences</strong> using the
                following link:{' '}
                <a href='https://console.vastreach.com/accountsettings'>
                  https://console.vastreach.com/accountsettings
                </a>
                .
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={AccountSettingsA}
                  alt='account settings navigation'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              <p>
                Once on <strong>Account Preferences & Settings</strong> page,{' '}
                <i>you</i> can click <strong>USER INVITES</strong> button at the
                top right of the screen (this button is only visible to the
                account owner).
              </p>
              <p>
                <img
                  src={CreateUserImgA}
                  alt='blank user creation modal'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              <h2 className='create-user'>User Creation</h2>
              <p>
                Now <i>you</i> will see a modal (a pop-up window) called{' '}
                <strong>USER INVITATION</strong> and on this modal <i>you</i>{' '}
                will see all of <i>your</i> <i>users</i>. If <i>you</i> have
                never invited a <i>user</i> the list will be empty, but on the
                top right of the modal is a <strong>+ ADD INVITATION</strong>{' '}
                button to begin inviting a new <i>user</i>.
              </p>
              <p>
                <img
                  src={CreateUserImgB}
                  alt='user invite modal'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              <p>
                Once clicked <i>you</i> will see 3 three input fields (
                <strong>Full name</strong>, <strong>Email</strong> and{' '}
                <strong>Phone number</strong>), along with a{' '}
                <strong>CREATE</strong> button. Once all fields have been filled{' '}
                <i>you</i> can now click the <strong>CREATE</strong> button to
                send the invitation or you can cancel using the{' '}
                <strong>CANCEL</strong> button at the top right.
              </p>
              <p>
                <img
                  src={CreateUserImgD}
                  alt='user invite input'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              <p>
                Once invitation is sent to the <i>user</i> they will receive an
                email with instructions to sign up to{' '}
                <strong>Vast Reach</strong>. Once they create an account{' '}
                <i>your</i> invitation modal will show{' '}
                <strong>Status: Accepted</strong> above that <i>user's</i> full
                name.
              </p>
              <p>
                <i>Note</i>: <i>You</i> can cancel a sent <i>user</i> invitation
                at any time by clicking the <strong>CANCEL INVITATION</strong>{' '}
                button below the grayed out <strong>SENT</strong> button in the
                user invitation modal.
              </p>
              <p>
                <img
                  src={CreateUserImgE}
                  alt='user invite accepted'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              <p>
                <img
                  src={CreateUserImgF}
                  alt='user list'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              {/* <p>
                <i>You</i> can now edit the <i>users</i> profile and permissions
                from the <i>user</i> list page by clicking the <i>users</i> name
                or by clicking the ellipsis icon and selecting{' '}
                <strong>Edit</strong>. For additional information on how to edit{' '}
                <strong>User Permissions</strong> click{' '}
                <Link to='/UserPermission'>here</Link>.
              </p>
              <p>
                Another option would be to click{' '}
                <a class='mailto' href='mailto:info@vastreach.com'>
                  here
                </a>{' '}
                to <i>contact</i> support to transfer <i>your</i> account.
              </p>
              <p>
                <img
                  src={CreateUserImgC}
                  alt='populated user list'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p> */}

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Remember that <i>you</i> can only create a limited amount of{' '}
                    <i>users</i> if <i>your</i> plan is a <strong>Base</strong>{' '}
                    or <strong>Premium</strong> plan.
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Forget to check to see if the <i>user</i> <i>you</i> invited
                    has verified their status.
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
