import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../generalCss/style.scss';

export default function schedule() {
  return (
    <div>
      <div className='container-fluid padding-full'>
        <div className='container wrap'>
          <h1 className='pad-top'>SCHEDULE YOUR FREE ONBOARDING NOW!</h1>
          <div className='col-xs-12'>
            <p>
              Save over $1000 dollars with our limited-time offer for free
              onboarding! This includes integrating with the Dealer Locator,
              preloading campaign automations, preloading master spas email
              templates, preloading text message templates and one custom
              integration of your choice (Shopify or FB Lead Ad). We are also
              offering the same deal as last year: new signups at the dealer
              meeting get 90 day free trials!
            </p>
          </div>
        </div>
      </div>

      <div className='container-fluid'>
        <div className='container wrap'>
          <div className='col-xs-12'>
            <h2 className='text-center'>SCHEDULE YOUR FREE ONBOARDING TODAY!</h2>
          </div>
        </div>
      </div>

      <div className='container-fluid padding-full '>
        <div className='col-xs-12 zero-padding wrap'>
          <iframe
            title='book'
            src='https://koalendar.com/e/meet-with-wil-casillas?embed=true'
            width='100%'
            height='800px'
            frameborder='0'
          ></iframe>
          <div className='col-xs-12 text-center'>
            <h3>
              Don't See a time that works for you? Get in contact with Wil
              Casillas{' '}
            </h3>
            <p>
              <a href='mailto: wcasillas@vasttechnologies.com'>
                wcasillas@vasttechnologies.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
