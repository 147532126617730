import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import DripCampaignsMobileNaviScrollSpy from './DripCampaignsMobileNaviScrollSpy.js';
import CampaignImgH from '../CampaignsMobile/img/mobile_nav.jpg';
import DripCampaignsMobileImg1 from './img/drip_campaign_mobile1.png';
import DripCampaignsMobileImg2 from './img/drip_campaign_mobile2.png';
import DripCampaignsMobileImg3 from './img/drip_campaign_mobile3.png';
import DripCampaignsMobileImg4 from './img/drip_campaign_mobile4.png';
import DripCampaignsMobileImg5 from './img/drip_campaign_mobile5.png';
import DripCampaignsMobileImg6 from './img/drip_campaign_mobile6.png';
import DripCampaignsMobileImg7 from './img/drip_campaign_mobile7.png';
import DripCampaignsMobileImg8 from './img/drip_campaign_mobile8.png';
import DripCampaignsMobileImg9 from './img/drip_campaign_mobile9.png';
import video from '../DripCampaigns/video/drip_campaign_tutoiral.mp4';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function DripCampaignsMobile() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <DripCampaignsMobileNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/DripCampaigns'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/DripCampaignsMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading overview'>
                CREATE SIMPLE DRIP MARKETING CAMPAIGN ON A MOBILE DEVICE
              </h1>
              <p>
                This tutorial is designed to help create a{' '}
                <strong>Drip Campaign</strong>. A <strong>Drip Campaign</strong>{' '}
                or sometimes known as <strong>Drip Marketing</strong> is when a
                set of marketing emails are sent out on a schedule (an email
                sent every 7 days, once a month, etc.) or for example when a{' '}
                <i>contact</i> signs-up using a sign-up form. Of course this is
                not limited to just when a <i>contact</i> signs-up using a form
                as <strong>Vast Reach</strong> has many other triggers such as
                when{' '}
                <strong>
                  A Contact Opens an Email, Sends a Text or Clicks an Email Link
                </strong>{' '}
                and many more.The idea behind all of this is to build an
                interest with <i>your</i> <i>contacts</i> over the long-haul.
                This will bring in more loyal customers since it can be tailored
                to certain groups of <i>contacts</i> verses using an email
                blast, thus avoiding many unsubscribes.
              </p>
              <p>
                By the end of this tutorial <i>you</i> will understand how to
                set up a basic drip campaign and how it all comes together.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Setting Up a Drip Campaign
              </h3>
              <p>
                Before setting up a <strong>Drip Campaign</strong>, <i>you</i>{' '}
                must first have an account set up with an active subscription.
                It is recommend <i>you</i> learn the majority of{' '}
                <strong>Vast Reach’s</strong> feature sets such as adding phone
                numbers, creating <i>contacts</i>, groups, custom forms, sign-up
                forms and tasks as many of them will be incorporated into
                campaigns. Click the{' '}
                <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Buttons</strong> below to set up anything <i>you're</i>{' '}
                missing before beginning this tutorial if <i>you</i> have not
                done so already.
              </p>
              <p>
                <Link to='/GettingStartedMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Getting Started
                  </button>
                </Link>
                <Link to='/PaymentMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
                <Link to='/ManagePhoneNumbersMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Manage Phone Numbers
                  </button>
                </Link>
                <Link to='/ContactsMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Create a Contact
                  </button>
                </Link>
                <Link to='/GroupsMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Create a Group
                  </button>
                </Link>
                <Link to='/ReCaptchaMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    ReCaptcha
                  </button>
                </Link>
                <Link to='/SignUpFormsMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Sign-Up Forms
                  </button>
                </Link>
                <Link to='/TasksMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Tasks
                  </button>
                </Link>
                <Link to='/CampaignsMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Campaigns
                  </button>
                </Link>
              </p>

              <h3 className='before-drip-campaign'>
                Before Creating a Drip Campaign
              </h3>
              <p>
                For this drip campaign tutorial we will be using an example of a
                limited time product sale. This sale will also be based on an
                existing subscriber list. The sale will begin on a Friday and
                end midnight on Sunday. We will be sending an email each day but
                with a new set of deals for each day to attempt to engage the
                subscriber with a sale on one or more of these days. We will
                also list the countdown of the remaining days of sale on these
                emails to promote urgency. For the subscribers that did not
                purchase a product before midnight on Sunday, they will receive
                an email on the following day, Monday, saying the sale has been
                extended for one more day.
              </p>
              <p>
                Before we create this drip campaign we will need to first create
                a minimum of 6 <strong>Email Templates</strong>. To do that
                navigate to the <strong>Templates Page</strong> and click the{' '}
                <strong>+ ADD TEMPLATE</strong> button at the top right to
                begin. The first email will announce to the subscriber that
                there will be a sale during the weekend on Monday of that week.
                The second email will be the day before the sale begins to
                remind the subscriber the sale beings tomorrow along with some
                product sneak peeks. The third email will be the Friday product
                sale email along with a countdown of 3 days remaining. The
                fourth email will be the Saturday product sale email along with
                a countdown of 2 days remaining. The fifth email will be the
                Sunday product sale email along with a countdown of 24 hours or
                till midnight tonight remaining. The sixth and final email will
                be designed only for subscribers who have not engaged in the
                sale and letting them know the sale is extended for one more
                day.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={DripCampaignsMobileImg1}
                  alt='drip campaign email creation'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>
              <p>
                Next we need to create <strong>Groups</strong> to separate out
                subscribers that purchased and those who have not. <i>You</i>{' '}
                should navigate to the <strong>Contacts Groups Page</strong>{' '}
                (Logo at the top left) and click the{' '}
                <strong>+ ADD GROUP</strong> button at the top right to begin.
                The first group will be called <strong>Subscribers</strong> and
                will contain all of your subscribers. The second group we will
                create is an empty group named <strong>Potential Buyers</strong>{' '}
                and this is for those who may have purchased a product during
                this sale.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={DripCampaignsMobileImg2}
                  alt='drip campaign group creation'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>

              <h3 className='navigation'>Navigate to Campaigns</h3>
              <p>
                <i>You</i> can create a campaign by clicking{' '}
                <strong>Campaigns</strong> in the navigation (Logo at the top
                left). <i>You</i> will be taken to the Campaigns page.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={CampaignImgH}
                  alt='campaign page navigation'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>
              <p>
                Clicking the <strong>ADD CAMPAIGN</strong> button at the top
                right will take <i>you</i> to the campaign creation page. Once{' '}
                <i>you</i> are on this page the <i>you</i> will be presented
                with the first part of the campaign creation which is the{' '}
                <strong>Campaign</strong> step in the stepper at the top of the
                page. While it is not required to know all the details about
                campaigns as this tutorial will explain the set-up processes for
                the most part, we do recommend learning the more detailed
                campaign tutorial. To learn{' '}
                <strong>Everything You Need to Know About Campaigns</strong>{' '}
                click <Link to='/CampaignsMobile'>here</Link> as well{' '}
                <strong>Event Goals</strong> by clicking{' '}
                <Link to='/EventGoalsMobile'>here</Link>.
              </p>

              <h2 className='create-campaign'>
                Creating a Simple Drip Campaign
              </h2>
              <p>
                On the <strong>Campaign</strong> step, <i>you</i> will be able
                to give this campaign a name and a short description about it.{' '}
                Lets call this campaign <strong>Simple Drip Campaign </strong>.
                <i>You</i> will also be able to set a begin and end date for
                this campaign by selecting the <strong>Yes</strong> radio button
                from the <strong>Add a date range? </strong> under the
                description input box. Click the{' '}
                <strong>Campaign Calendar </strong> step in the stepper after{' '}
                <i>you</i> have completed filing out this page.{' '}
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={DripCampaignsMobileImg3}
                  alt='campaign step of a drip campaign'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>
              <h3 className='campaign-calender'>
                Setting-up The Campaign Calendar
              </h3>
              <p>
                Once on the <strong>Campaign Calendar</strong> step we will
                begin setting up the <strong>Email Tasks</strong> to send out.
                The first email will be sent out on the Monday of the week of
                the sale, so <i>you</i> can drag and drop an{' '}
                <strong>Email Tasks</strong> to that Monday on the calendar. A
                modal (pop-up window) will present on the screen, <i>you</i> can
                enter the name and description of this email here. Name the
                email <strong>Weekend Sale Notice</strong>. Once the{' '}
                <strong>Task</strong> step has been filled out click the{' '}
                <strong>NEXT</strong> button at the top right or the{' '}
                <strong>Automate</strong> step in the stepper.{' '}
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={DripCampaignsMobileImg4}
                  alt='task step of a task creation in campaign calendar for a drip campaign'
                  className='img-fluid'
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <p>
                On the <strong>Automate</strong> step, make sure the task
                automation is <strong>Add to Campaign Calendar</strong> and
                select the <strong>Simple Drip Campaign</strong> to add it to
                this campaign. Lastly, make sure to schedule this email to send
                out at <strong>12:00 AM Monday</strong> of the sale week you
                choose. Once finished on this step, click <strong>NEXT</strong>{' '}
                or select the <strong>Theme/Template</strong> step from the
                stepper.{' '}
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={DripCampaignsMobileImg5}
                  alt='automate step of a task creation in campaign calendar for a drip campaign'
                  className='img-fluid'
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <p>
                Once on the <strong>Theme/Template</strong> step, <i>you</i>{' '}
                will be presented with 3 choices, select the{' '}
                <strong>Templates</strong> option. Now select the first email
                template <i>you</i> created earlier.{' '}
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={DripCampaignsMobileImg6}
                  alt='theme/template step of a task creation in campaign calendar for a drip campaign'
                  className='img-fluid'
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <p>
                <i>You</i> will be taken to the <strong>Finalize</strong> step
                once <i>you</i> have selected the first email template. From
                here, select the <strong>EMAIL</strong> accordion and open it.
                Inside the <strong>EMAIL</strong> accordion, under{' '}
                <strong>Recipients (Groups and Contacts)</strong>, select the
                group called <strong>Subscribers</strong> that <i>you</i> made
                earlier. Then in the <strong>Subject</strong> text input
                section, if <i>you</i> have not already, let the subscriber know
                about the sale by saying something like{' '}
                <strong>LIMITED TIME SALE THIS WEEKEND!</strong>. Once this step
                is completed, click the{' '}
                <strong style={{ color: 'rgb(21, 185, 64)' }}>Green</strong>{' '}
                <strong>CREATE</strong> button at the top right.{' '}
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={DripCampaignsMobileImg7}
                  alt='finalize step of a task creation in campaign calendar for a drip campaign'
                  className='img-fluid'
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <p>
                Now <i>you</i> will repeat this process for all the emails{' '}
                <strong>except the extended sale email</strong> (we will set up
                the final email later in the tutorial) with the following
                modifications listed below.
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h4>Email 2 – Thursday Reminder Email</h4>
                  <p>
                    Follow the same step as the first email, except:
                    <ol>
                      <li>
                        <p>
                          Set the schedule send date to{' '}
                          <strong>12:00 AM Thursday</strong> of the week of the
                          sale.
                        </p>
                      </li>
                      <li>
                        <p>
                          Select the second email template (Thursday email
                          template) <i>you</i> designed.
                        </p>
                      </li>
                      <li>
                        <p>
                          Make sure in the <i>Finalize</i> step, under the{' '}
                          <strong>EMAIL</strong> accordion, the{' '}
                          <strong>Subject</strong> says something like{' '}
                          <strong>SALE STARTS TOMORROW!</strong>.
                        </p>
                      </li>
                    </ol>
                  </p>
                </li>

                <li>
                  <h4>Email 3 – Friday Sale Email</h4>
                  <p>
                    Follow the same step as the first email, except:
                    <ol>
                      <li>
                        <p>
                          Set the schedule send date to{' '}
                          <strong>12:00 AM Friday</strong> as this will be the
                          first day of the sale.
                        </p>
                      </li>
                      <li>
                        <p>
                          Select the third email template (Friday Sale email
                          template) <i>you</i> designed.
                        </p>
                      </li>
                      <li>
                        <p>
                          Make sure in the <i>Finalize</i> step, under the{' '}
                          <strong>EMAIL</strong> accordion, the{' '}
                          <strong>Subject</strong> says something like{' '}
                          <strong>FRIDAY SALE STARTS NOW!</strong>.
                        </p>
                      </li>
                    </ol>
                  </p>
                </li>

                <li>
                  <h4>Email 4 – Saturday Sale Email</h4>
                  <p>
                    Follow the same step as the first email, except:
                    <ol>
                      <li>
                        <p>
                          Set the schedule send date to{' '}
                          <strong>12:00 AM Saturday</strong> as this will be the
                          second day of the sale.
                        </p>
                      </li>
                      <li>
                        <p>
                          Select the fourth email template (Saturday Sale email
                          template) <i>you</i> designed.
                        </p>
                      </li>
                      <li>
                        <p>
                          Make sure in the <i>Finalize</i> step, under the{' '}
                          <strong>EMAIL</strong> accordion, the{' '}
                          <strong>Subject</strong> says something like{' '}
                          <strong>SATURDAY SALE STARTS NOW!</strong>.
                        </p>
                      </li>
                    </ol>
                  </p>
                </li>

                <li>
                  <h4>Email 5 – Sunday Sale Email</h4>
                  <p>
                    Follow the same step as the first email, except:
                    <ol>
                      <li>
                        <p>
                          Set the schedule send date to{' '}
                          <strong>12:00 AM Sunday</strong> as this will be the
                          final day of the sale.
                        </p>
                      </li>
                      <li>
                        <p>
                          Select the fifth email template (Sunday Sale email
                          template) <i>you</i> designed.
                        </p>
                      </li>
                      <li>
                        <p>
                          Make sure in the <i>Finalize</i> step, under the{' '}
                          <strong>EMAIL</strong> accordion, the{' '}
                          <strong>Subject</strong> says something like{' '}
                          <strong>SALE ENDS MIDNIGHT, DON'T MISS OUT!</strong>.
                        </p>
                      </li>
                    </ol>
                  </p>
                </li>

                <li>
                  <h4>Email 6 – Extended Sale Email</h4>
                  <p>
                    {' '}
                    This final email will be set-up slightly different since it
                    will rely on using the <strong>Campaign Funnel</strong> in
                    the next step of this tutorial. We will be moving the
                    potential buyers of the first three sale days who clicked a
                    link in the email sent into the{' '}
                    <strong>Potential Buyers</strong> group <i>you</i> made
                    earlier. Then using the remaining <i>contacts</i> inside the{' '}
                    <strong>Subscribers</strong> group who have not clicked a
                    link in any email, we will send them the extended sale email
                    (Email 6) in an attempt to make a sale.{' '}
                  </p>
                  <p>
                    For now we will schedule to send the sixth email in advance
                    with the following:
                    <ol>
                      <li>
                        <p>
                          Set the schedule send date to{' '}
                          <strong>06:00 AM Monday</strong>.
                        </p>
                      </li>
                      <li>
                        <p>
                          Select the sixth email template (Monday Extended Sale
                          email template) <i>you</i> designed.
                        </p>
                      </li>
                      <li>
                        <p>
                          Make sure in the <i>Finalize</i> step, under the{' '}
                          <strong>EMAIL</strong> accordion, the{' '}
                          <strong>Subject</strong> says something like{' '}
                          <strong>
                            SALE EXTENDED TO MIDNIGHT, LAST CHANCE TO SAVE!
                          </strong>
                          .
                        </p>
                      </li>
                    </ol>
                  </p>
                </li>
              </ul>
              <p>
                Once this is done, click the <strong>Campaign Funnel</strong>{' '}
                step in the stepper at the top to move to the final part of this
                tutorial.
              </p>

              <h3 className='campaign-funnel'>
                Setting-up The Campaign Funnel
              </h3>

              <p>
                Once on the <strong>Campaign Funnel</strong> step, click the{' '}
                <strong>ADD A FUNNEL STAGE</strong> button. Name this funnel
                stage <strong>Move Potential Buyers</strong> and add three{' '}
                <strong>Clicks Link in Email Event Goals</strong> and select the{' '}
                <strong>Friday Email</strong> for the first,{' '}
                <strong>Saturday Email</strong> for the second and{' '}
                <strong>Sunday Email</strong> for the last event goal. In the{' '}
                <strong>THEN DO THIS…</strong> section of this stage, add a{' '}
                <strong>Move Contacts Between Groups Task</strong>. A modal
                (pop-up window) will present itself, <i>you</i> will see a name
                and description on the <strong>Task</strong> step. Name this
                task <strong>Moving Subscribers to Potential Buyers</strong>. We
                can skip the <strong>Automate</strong> step as it will be
                automatically set to fire right away without delay once a{' '}
                <i>contact</i> clicks a link in the email. Once done, click the{' '}
                <strong>Finalize</strong> step in the stepper. In this step, on
                the left side select the <strong>Subscriber</strong> group to be
                moved to the <strong>Potential Buyers</strong> group. Once
                finished, click the <strong>CREATE</strong> button at the top
                right. What this will do is leave only the <i>contacts</i> in
                the <strong>Subscriber</strong> group who did not click any of
                the first three sale emails while moving all those that did into
                the <strong>Potential Buyers </strong> group.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={DripCampaignsMobileImg8}
                  alt='setting up first stage in campaign funnel for a drip campaign'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>
              <p>
                Lastly, <i>you</i> can create another funnel stage and call it{' '}
                <strong>Potential Extended Day Buyers</strong>. Inside this
                funnel stage, we can add another{' '}
                <strong>Clicks Link in Email Event Goal</strong> and select the{' '}
                <strong>Extended Day Sale Email</strong>. Then add a{' '}
                <strong>Move Contact Between Groups</strong> and move{' '}
                <i>contacts</i> from <strong>Subscribers</strong> to{' '}
                <strong>Potential Buyers</strong> like the previous funnel
                stage. This will move any <i>contact</i> that clicked the link
                inside the <strong>Extended Sale</strong> email to the{' '}
                <strong>Potential Buyers</strong> group.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={DripCampaignsMobileImg9}
                  alt='setting up second stage in campaign funnel for a drip campaign'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Remember that <i>you</i> do not need to make tasks in
                    advance like in this tutorial. <i>You</i> can make them in
                    real time during a campaign creation if <i>you</i> choose
                    to.
                  </p>
                </li>
                <li>
                  <p>
                    Make sure <i>you</i> have a full understanding of all the
                    elements of creating a drip campaign to ensure best
                    marketing results.
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Try to delete or modify <i>your</i> stages, event goals or
                    tasks during an active campaign. Pause it first.
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <h3>Creating a Simple Drip Campaign</h3>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
