import React from 'react';
import { Link } from 'react-scroll';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import { Accordion, Card } from 'react-bootstrap';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

export default function SignUpFormsMobileNaviScrollSpy() {
  return (
    <div className='col-xs-12 col-lg-3 order-lg-2'>
      <Accordion defaultActiveKey='0' className='accordion-wrap'>
        <h2 className='faq-navi-header'>Sign-Up Form Navigation</h2>
        <Card>
          <Card.Header className='accordion-header'>
            <p className='active-link'>
              <Link
                to='sign-up-intro'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Intro to Sign-Up Forms
              </Link>
            </p>
            <Accordion.Toggle variant='link' eventKey='0' className='carret'>
              <ArrowDropDownIcon />
            </Accordion.Toggle>
          </Card.Header>

          <Accordion.Collapse eventKey='0'>
            <Card.Body>
              <ul className='tutorial-side-nav'>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='prerequisites'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Prerequisites Before Creating a Sign-up Form
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link'>
                    <Link
                      to='navigation'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Navigate to Sign-up Forms
                    </Link>
                  </p>
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <p className='active-link'>
              <Link
                to='create-form'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Create a New Form
              </Link>
            </p>
          </Card.Header>
        </Card>

        <Card>
          <Card.Header className='accordion-header'>
            <p className='active-link'>
              <Link
                to='build'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Building a Form
              </Link>
            </p>
            <Accordion.Toggle variant='link' eventKey='1' className='carret'>
              <ArrowDropDownIcon />
            </Accordion.Toggle>
          </Card.Header>

          <Accordion.Collapse eventKey='1'>
            <Card.Body>
              <ul className='tutorial-side-nav'>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='form_section'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Review Your Form Information
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='look_and_feel_section'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Design The Look and Feel of Your Forms
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link'>
                    <Link
                      to='logo_section'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Add Your Logo to Your Forms
                    </Link>
                  </p>
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <p className='active-link'>
              <Link
                to='embed-share'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Share Your Forms
              </Link>
            </p>
          </Card.Header>
        </Card>

        <Card>
          <Card.Header>
            <p className='active-link'>
              <Link
                to='responses'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Analyze Your Form Responses and Statistics
              </Link>
            </p>
          </Card.Header>
        </Card>

        <Card>
          <Card.Header className='accordion-header'>
            <p className='active-link'>
              <Link
                to='do-dont'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Do's and Don'ts
              </Link>
            </p>
          </Card.Header>
        </Card>

        <Card>
          <Card.Header className='accordion-header'>
            <p className='active-link'>
              <Link
                to='video-demo'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Video Demonstration
              </Link>
            </p>
          </Card.Header>
        </Card>
      </Accordion>

      <Accordion
        className='position-fixed bottom-nav-wrap'
      >
        <Card>
          <Card.Header className='accordion-header'>
            <div className='header-wrap'>
              <p className='mobile-nav-header'>Jump To...</p>
              <Accordion.Toggle variant='link' eventKey='0' className='carret'>
                <ArrowDropUpIcon />
              </Accordion.Toggle>
            </div>
          </Card.Header>

          <Accordion.Collapse eventKey='0'>
            <Card.Body>
              <ul className='tutorial-side-nav'>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='sign-up-intro'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Intro to Sign-Up Forms
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='prerequisites'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Prerequisites Before Creating a Sign-up Form
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='navigation'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Navigate to Sign-up Forms
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='create-form'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Create a New Form
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='build'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Building a Form
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='form_section'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Review Your Form Information
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='look_and_feel_section'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Design The Look and Feel of Your Forms
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='logo_section'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Add Your Logo to Your Forms
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='embed-share'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Share Your Forms
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='responses'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Analyze Your Form Responses and Statistics
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='do-dont'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Do's and Don'ts
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link'>
                    <Link
                      to='video-demo'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Video Demonstration
                    </Link>
                  </p>
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}
