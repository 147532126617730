import React, { Component } from 'react';

export default class AcceptableUse extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <div className='container-fluid padding-full'>
          <div className='container wrap'>
            <h1 className='pad-top'> Prohibited Content</h1>
            <p>
              Please don’t use Vast Reach to distribute anything offensive, to
              promote anything illegal, or to harass anyone.{' '}
            </p>

            <p>
              {' '}
              You may not use Vast Reach to create, send, or display messages or
              content pertaining to:
              <li>illegal goods or services</li>
              <li>violating the CAN-SPAM or other anti-spam laws</li>
              <li>Pornography/sexually explicit content</li>
              <li>Terrorism or violent extremism</li>
            </p>

            <p>
              Some sectors have higher-than-average abuse complaints, which can
              jeopardize deliverability. In order to maintain the reliability of
              our platform, we do not allow businesses that offer these types of
              services, products, or content:
              <li>
                Prostitution, escort services, mail-order brides,marriage brokers, and other similar apps, sites and
                services
              </li>
              <li>Hookup, swinger, or sexual encounter services</li>
              <li>
                Work from home, make money online, and lead generation
                opportunities
              </li>
              <li>Gambling and gambling related products</li>
              <li>Multi - level marketing</li>
              <li>Affiliate marketing</li>
              <li>Credit repair and get out of debt opportunities</li>
              <li>List brokers or list rental services</li>
              <li>Selling “Likes” or followers for a social media platform</li>
              <li>
                Cryptocurrencies, virtual currencies, and any digital assets
                related to an Initial Coin Offering
              </li>
              <li>Content Subject to Additional Scrutiny</li>
            </p>

            <p>
              Certain types of content may cause
              high abuse rates. For that reason, we may 
              review, suspend, throttle, or disable accounts that offer the
              following services, products, or content: 
              <li>
                {' '}
                Online trading, day trading tips, or stock market related
                content
              </li>
              <li>Daily horoscope reports</li>
              <li>Mortgages and loans</li>
              <li>Pharmaceuticals</li>
              <li>Nutritional, herbal, and vitamin supplements</li>
              <li>Adult entertainment/novelty items </li>
              <li>
                Online dating services and applications(dating sites or services
                with a sexual emphasis or sexually explicit content – including
                images depicting nudity – are not permitted)
              </li>
              <li>Real estate</li>
              <li>
                Services that support programmatically sending mail on behalf of
                third parties without creating or reviewing the content
              </li>
              <li>Prohibited Actions</li>
              We work hard to maintain the positive reputation of our platform,
              but we count on our Members to pitch in too. You may not:
            </p>
            <h3> Spam.</h3>
            <p>
              {' '}
              Spam can be in the form of mass emails or one to one commercial
              emails. You must be able to point to an opt-in signup form or show other
              evidence of consent for any bulk email you send. Include in your
              Campaigns, Websites, Ads, or account any material that wasn’t
              created by you, provided for you to use, or that would violate
              anyone’s rights. That includes text, photos, graphics, and other
              content. Use any misleading or incorrect names, addresses, email
              addresses, subject lines, or other information in the Service or
              in any emails or messages created or sent using Vast Reach.
              </p>
               <h3>You May not:</h3>     
              <p>
              Share your password. Decipher, decompile, disassemble, or reverse
              engineer any of the Software. Use any of the Software to create a
              competing product. Set up multiple
              accounts for any person or entity in order to send similar
              content, unless you’re part of a franchise or agency. Import or
              incorporate any of this information into any account, audience,
              emails, or otherwise upload to our servers: Social Security
              numbers, passwords, security credentials, or sensitive personal
              information. Send Campaigns that will be delivered to recipients
              as text, SMS, or MMS messages unless using a feature designed for
              that purpose. Upload or send email Campaigns to purchased, rented,
              third-party, co-reg, publicly available data, or partner lists of
              any kind. Send or forward personal mail. Send content created in
              Vast Reach through another service. Host images on our servers for
              any purpose other than for your use of the Service. Host Content
              on our servers for any purpose other than for your use of the
              Service. Except as permitted under our Standard Terms of Use, take
              any action intended to avoid our billing thresholds. 
              Please use Vast Reach campaigns and tasks for your bulk
              sending needs. Send bulk emails, meaning commercial or marketing
              emails directed to a number of individuals with the same content,
              through Vast Reach Inbox. Vast Reach Inbox is designed for
              one-to-one messages. Please use Vast Reach campaigns or tasks for your bulk
              sending needs. If you send a message through your Vast Reach Inbox
              that may be considered a commercial or marketing communication
              under applicable law, you can use the Opt-out Footer to comply
              with applicable law and provide your Contacts with the means to
              unsubscribe from one-to-one messages sent through Inbox.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
