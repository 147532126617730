import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import ContactsMobileNaviScrollSpy from './ContactsMobileNaviScrollSpy.js';
import ContactMobileImgA from './img/create_contact_button.png';
import ContactMobileImgB from './img/contact_mobile1.png';
import ContactMobileImgC from './img/empty_mobile_contact_list.png';
import ContactMobileImgD from './img/mobile_contact_preview_tab.png';
import ContactMobileImgE from './img/mobile_contact_contact_information_tab.png';
import ContactMobileImgF from './img/mobile_contact_tasks_tab.png';
import ContactMobileImgG from './img/mobile_contact_groups_tab.png';
import ContactMobileImgH from './img/mobile_contact_custom attributes_tab.png';
import ContactMobileImgI from './img/mobile_nav.jpg';
import ContactMobileImgJ from '../Contacts/img/search_filter.jpg';
import ContactMobileImgK from './img/contact_list.jpg';
import video from '../Contacts/video/contacts_tutorial.mov';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function ContactsMobile() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <ContactsMobileNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/Contacts'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/ContactsMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>CONTACTS ON MOBILE</h1>
              <p className='contact-intro'>
                <i>You</i> can Import <i>your</i> <i>contacts</i> and their data
                in one place so <i>you</i> can begin automating <i>your</i>{' '}
                work-flow. In this tutorial <i>you</i> will learn how to add an
                individual <i>contact</i> and bulk upload several{' '}
                <i>contacts</i>.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Creating a Contact
              </h3>
              <p>
                Before creating a <strong>Contact</strong>, <i>you</i> must
                first have an active account set up with <i>your</i>{' '}
                <strong>Payment Settings</strong>. This is mandatory and must be
                done before accessing <strong>Contacts</strong> page. Click the{' '}
                <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Button</strong> below to set up{' '}
                <strong>Payments</strong> before beginning this tutorial if{' '}
                <i>you</i> have not done so already.
              </p>
              <p>
                <Link to='/PaymentMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
              </p>
              <h3 className='navigation'>Navigate to Contacts Page</h3>
              <p>
                To create a new <i>contact</i> or upload existing{' '}
                <i>contacts</i> <i>you</i> can do so by clicking the{' '}
                <strong>Contacts</strong> link in the navigation (Clicking the
                Logo on the top left). <i>You</i> will now be taken to the{' '}
                <strong>Contact List</strong> page.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={ContactMobileImgI}
                  alt='contact page navigation'
                  className='img-fluid'
                  style={{ maxWidth: '375px', width: '100%' }}
                />
              </p>
              <h4>Understanding Your Existing Contact List</h4>
              <p>
                <i>Note: </i> If you are reading this with a new account scroll
                down to learn how to begin using the{' '}
                <strong>Contact List</strong> page on a new account.
              </p>
              <p>
                Inside the <strong>Contact List</strong> view <i>you</i> will
                see the <i>contacts</i> name on the left and an ellipsis icon (3
                dot icon) on the right. Taping the ellipsis icon brings up a
                menu, in this menu <i>you</i> can add this <i>contact</i> to
                available groups here also or delete them out right (A
                confirmation window prompt when deleting).
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={ContactMobileImgK}
                  alt='contact list'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>
              <h4>Using The Search & Filter Bar</h4>
              <p>
                Above the list view, this bar will allow you to sort, filter or
                search a specific <i>contact</i> from <i>your</i> list of{' '}
                <i>contacts</i>.
              </p>
              <p style={{ margin: '10px 0 10px 0' }}>
                <img
                  src={ContactMobileImgJ}
                  alt='search and filter bar'
                  className='img-fluid'
                  style={{ maxWidth: '150px', width: '100%' }}
                />
              </p>
              <h2 className='new-account'>
                Learning to Use Contact List On a New Account
              </h2>
              <p>
                A new account will see 2 key elements on this page, those are
                the <strong>Bulk Upload</strong> button and the{' '}
                <strong>Add Contact</strong> button.
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h3 className='bulk-upload'>Bulk Upload Your Contacts</h3>
                  <p>
                    The <strong>Bulk Upload</strong> button at the center will
                    only show if the <i>contact</i> list is empty, otherwise
                    clicking the <strong>Arrow</strong> at the top right inside
                    the <strong>ADD CONTACT</strong> button then selecting{' '}
                    <strong>Bulk Upload</strong> from the list is the only other
                    way. Once clicked <i>you</i> will be taken to a new screen
                    to begin uploading <i>your</i> existing <i>contacts</i>.{' '}
                    <i>You</i> can click <strong>SELECT FILES…</strong> and add
                    in <i>your</i> existing <i>contact</i> file{' '}
                    <strong>.csv file</strong> here. Once uploaded <i>you</i>{' '}
                    will be taken back to the <strong>Contact List Page</strong>{' '}
                    <i>you</i> were previously on but now populated with{' '}
                    <i>your</i> existing <i>contacts</i> from <i>your</i>{' '}
                    <strong>.csv</strong> file.
                  </p>
                  <p>
                    <i>Note:</i> <i>You</i> can see a record of all your
                    previous <strong>Bulk Uploads</strong> by clicking the{' '}
                    <strong>Arrow</strong> at the top right inside the{' '}
                    <strong>ADD CONTACT</strong> button, then selecting{' '}
                    <strong>Show My Previous Bulk Uploads</strong> from the
                    list. Once clicked a modal (pop-up window) will show{' '}
                    <i>you</i> your previous <strong>.csv</strong> file uploads.
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    <img
                      src={ContactMobileImgC}
                      alt='empty contact list on mobile'
                      className='img-fluid'
                      style={{ maxWidth: '400px', width: '100%' }}
                    />
                  </p>
                </li>
                <li>
                  <h3 className='creating-contact'>Creating a Contact</h3>{' '}
                  <p>
                    {' '}
                    Similarly to the <strong>Bulk Upload</strong> button at the
                    center, the <strong>Add Contact</strong> button will only
                    show at the center if the <i>contact</i> list is empty,
                    otherwise clicking the <strong>ADD CONTACT</strong> button
                    at the top right of the screen is the only other way.
                  </p>
                  <p style={{ margin: '5px 0 0 0' }}>
                    <img
                      src={ContactMobileImgA}
                      alt='create contact button'
                      className='img-fluid'
                      style={{ maxWidth: '200px', width: '100%' }}
                    />
                  </p>
                  <p>
                    <i>You</i> will be taken to the <i>contact</i> creation
                    page. There are 5 sections to each <i>contact</i> page and
                    they are listed below.
                  </p>
                  <ul style={{ listStyle: 'none' }}>
                    <li>
                      <h4>Preview Your Contact</h4>
                      <p>
                        The <strong>PREVIEW</strong> section at the very top
                        allows <i>you</i> to add the <i>contact's</i> full name
                        (First, Middle and Last) when clicking{' '}
                        <strong>Name*</strong>. <i>You</i> can add the{' '}
                        <i>contacts</i> location in the{' '}
                        <strong>Location</strong> label and lastly add notes
                        about this <i>contact</i> under <strong>Notes</strong>.
                        Below that is the history section, inside this section{' '}
                        <strong>Vast Reach</strong> will keep track of all the
                        history this <i>contact</i> has gone through such as
                        sending an email, SMS, Campaigns and showing the date
                        and time of said event. Clicking the{' '}
                        <strong>CAMPAIGNS</strong> text button at the top of
                        this section shows <i>you</i> the campaign history.
                      </p>
                      <p>
                        <i>Note:</i> To the left of the text labels there is a{' '}
                        <strong style={{ color: '#15b940' }}>Green</strong> box
                        with a <strong>Contact Icon</strong> in it, if this{' '}
                        <i>contact</i> was a saved <i>contact</i> then there
                        will be a <strong>CONVERSATION</strong> button below
                        this. Clicking this button will take <i>you</i> to the{' '}
                        <strong>Inbox Page</strong> to begin a conversation with
                        this <i>contact</i>.
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        <img
                          src={ContactMobileImgD}
                          alt='empty contact create preview tab'
                          className='img-fluid'
                          style={{
                            maxWidth: '350px',
                            width: '100%',
                            margin: '10px',
                          }}
                        />
                        <img
                          src={ContactMobileImgB}
                          alt='contact page'
                          className='img-fluid'
                          style={{
                            maxWidth: '350px',
                            width: '100%',
                            margin: '10px',
                          }}
                        />
                      </p>
                    </li>
                    <li>
                      <h4>Filling Out The Contact Info Tab</h4>
                      <p>
                        In the <strong>CONTACT INFO</strong> tab <i>you</i> can
                        add numbers and emails to reach this <i>contact</i>. In
                        order for a <i>contact</i> to be created, the{' '}
                        <i>contact</i> needs{' '}
                        <strong>at least one Phone Number and Email</strong>,
                        more can be added by clicking the <strong>+ NEW</strong>{' '}
                        button. Additionally, <i>you</i> can manually select a
                        <i>contact</i> to opt out of being sent any emails or
                        text messages by un-checking the <strong>Opt in</strong>{' '}
                        check box (by default this is checked on).
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        <img
                          src={ContactMobileImgE}
                          alt='contact create contact information tab'
                          className='img-fluid'
                          style={{ maxWidth: '400px', width: '100%' }}
                        />
                      </p>
                    </li>
                    <li>
                      <h4>Start a Task With This Contact</h4>
                      <p>
                        <strong>TASK</strong> tab becomes available to use with
                        this <i>contact</i> once the <i>contact</i> has at least
                        one number and/or email. <i>You</i> can then start a
                        task using this <i>contact</i> by selecting this tab.
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        <img
                          src={ContactMobileImgF}
                          alt='contact create task tab'
                          className='img-fluid'
                          style={{ maxWidth: '400px', width: '100%' }}
                        />
                      </p>
                    </li>
                    <li>
                      <h4>Add This Contact To a Group</h4>
                      <p>
                        The next tab over is the <strong>GROUPS</strong> tab, to
                        add this <i>contact</i> to group(s) that was previously
                        created, click the <strong>Select Group</strong> list
                        and choose group(s). Most used groups will be shown
                        under suggestions below the list. To learn a more about
                        how to create a group click{' '}
                        <Link to='/GroupsMobile'>Here</Link>.
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        <img
                          src={ContactMobileImgG}
                          alt='contact create groups tab'
                          className='img-fluid'
                          style={{ maxWidth: '400px', width: '100%' }}
                        />
                      </p>
                    </li>
                    <li>
                      <h4>Create Custom Attributes For This Contact</h4>
                      <p>
                        <strong>CUSTOM ATTRIBUTES</strong> is the final tab and
                        this tab has text labels such as Occupation, State or
                        ZIP. This can be added manually by clicking the{' '}
                        <strong>+ NEW</strong> button.
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        <img
                          src={ContactMobileImgH}
                          alt='contact create custom attributes tab'
                          className='img-fluid'
                          style={{ maxWidth: '400px', width: '100%' }}
                        />
                      </p>
                    </li>
                  </ul>
                  <p>
                    Once <i>you</i> are finished creating a <i>contact</i> or{' '}
                    bulk uploading <i>your</i> contacts, those <i>contacts</i>{' '}
                    will be automatically saved. <i>You</i> can return to the{' '}
                    <strong>Contact List Page</strong> page to see <i>your</i>{' '}
                    <i>contacts</i>.
                  </p>
                  <p>
                    <i>Note:</i> <i>You</i> can also create <i>contacts</i>{' '}
                    inside a Group which will take <i>you</i> to the{' '}
                    <i>contact</i> creation page directly from a group page.{' '}
                    <i>You</i> can also delete a <i>contact</i> out right from a
                    group page. To learn to create a group Click{' '}
                    <Link to='/GroupsMobile'>here</Link>.
                  </p>
                </li>
              </ul>

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Remember deleting a <i>contact</i> is permanent and
                    irreversible (we implemented a confirmation feature to
                    prevent accidental contact deletes).
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Forget that a <i>contact</i> will not be saved without an
                    email or phone number.
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
