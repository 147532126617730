import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../generalCss/style.scss';
import { Done } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const numberWithCommas = (x) => {
  if (typeof x === 'number') {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '';
};

export default function Pricing() {
  const overages = {
    baseplan_test: {
      plan_id: 'baseplan_test',
      text_limit: 1000,
      voice_limit: 500,
      phone_number_limit: 2,
      storage_limit: 45,
      text_unit: 'text messages',
      voice_unit: 'minutes',
      storage_overage_cost: 0.092,
      storage_unit: 'gigabytes',
      email_limit: 100000,
      user_unit: 'users',
      phone_number_overage_cost: 4,
      email_unit: 'emails',
      voice_overage_cost: 0.016,
      text_overage_cost: 0.03,
      user_limit: 1,
      phone_unit: 'phone numbers',
      user_overage_cost: 40,
      email_overage_cost: 0.0004,
    },
    ultimate_plan_test: {
      plan_id: 'ultimate_plan_test',
      text_limit: 5000,
      voice_limit: 10000,
      phone_number_limit: 100,
      storage_limit: 1000,
      text_unit: 'text messages',
      voice_unit: 'minutes',
      storage_overage_cost: 0.046,
      storage_unit: 'gigabytes',
      email_limit: 1000000,
      user_unit: 'users',
      phone_number_overage_cost: 2,
      email_unit: 'emails',
      voice_overage_cost: 0.008,
      text_overage_cost: 0.015,
      user_limit: 100,
      phone_unit: 'phone numbers',
      user_overage_cost: 20,
      email_overage_cost: 0.0002,
    },
    premium_plan_test: {
      plan_id: 'premium_plan_test',
      text_limit: 2000,
      voice_limit: 1000,
      phone_number_limit: 10,
      storage_limit: 100,
      text_unit: 'text messages',
      voice_unit: 'minutes',
      storage_overage_cost: 0.069,
      storage_unit: 'gigabytes',
      email_limit: 250000,
      user_unit: 'users',
      phone_number_overage_cost: 3,
      email_unit: 'emails',
      voice_overage_cost: 0.012,
      text_overage_cost: 0.0225,
      user_limit: 10,
      phone_unit: 'phone numbers',
      user_overage_cost: 30,
      email_overage_cost: 0.0003,
    },
  };
  const plans = [
    {
      id: 'baseplan_test',
      merchantId: 'ywbk7qtj4yx78m69',
      billingDayOfMonth: null,
      billingFrequency: 1,
      currencyIsoCode: 'USD',
      description: 'Have a small team? Then this is the plan for you!',
      name: 'Vast Reach Base Plan',
      numberOfBillingCycles: null,
      price: '79.00',
      trialDuration: 14,
      trialDurationUnit: 'day',
      trialPeriod: true,
      createdAt: '2021-04-28T17:20:52Z',
      updatedAt: '2021-07-23T16:07:52Z',
      addOns: [],
      discounts: [],
    },
    {
      id: 'premium_plan_test',
      merchantId: 'ywbk7qtj4yx78m69',
      billingDayOfMonth: null,
      billingFrequency: 1,
      currencyIsoCode: 'USD',
      description: 'The premium plan is geared towards mid-size teams!',
      name: 'Premium Plan',
      numberOfBillingCycles: null,
      price: '600.00',
      trialDuration: null,
      trialDurationUnit: null,
      trialPeriod: false,
      createdAt: '2021-04-28T17:22:56Z',
      updatedAt: '2021-07-16T17:31:53Z',
      addOns: [],
      discounts: [],
    },
    {
      id: 'ultimate_plan_test',
      merchantId: 'ywbk7qtj4yx78m69',
      billingDayOfMonth: null,
      billingFrequency: 1,
      currencyIsoCode: 'USD',
      description: 'The Ultimate Plan is for large marketing and sales teams!',
      name: 'Ultimate Plan',
      numberOfBillingCycles: null,
      price: '5000.00',
      trialDuration: null,
      trialDurationUnit: null,
      trialPeriod: false,
      createdAt: '2021-04-28T17:23:47Z',
      updatedAt: '2021-07-16T17:30:36Z',
      addOns: [],
      discounts: [],
    },
  ];
  const PricingSlots =
    Array.isArray(plans) && overages && Object.values(overages).length
      ? plans
          .sort((a, b) => parseInt(a.price) - parseInt(b.price))
          .map((plan) => {
            const bullets = overages[plan.id] ? (
              <>
                <div>
                  <Done
                    style={{
                      color: '#15b940',
                      verticalAlign: 'middle',
                      marginRight: 5,
                    }}
                  />
                  {numberWithCommas(overages[plan.id].user_limit)}{' '}
                  {overages[plan.id].user_unit}
                </div>
                <div>
                  <Done
                    style={{
                      color: '#15b940',
                      verticalAlign: 'middle',
                      marginRight: 5,
                    }}
                  />
                  {numberWithCommas(overages[plan.id].email_limit)}{' '}
                  {overages[plan.id].email_unit}
                </div>
                <div>
                  <Done
                    style={{
                      color: '#15b940',
                      verticalAlign: 'middle',
                      marginRight: 5,
                    }}
                  />
                  {numberWithCommas(overages[plan.id].text_limit)}{' '}
                  {overages[plan.id].text_unit}
                </div>
                <div>
                  <Done
                    style={{
                      color: '#15b940',
                      verticalAlign: 'middle',
                      marginRight: 5,
                    }}
                  />
                  {numberWithCommas(overages[plan.id].voice_limit)}{' '}
                  {overages[plan.id].voice_unit}
                </div>
                <div>
                  <Done
                    style={{
                      color: '#15b940',
                      verticalAlign: 'middle',
                      marginRight: 5,
                    }}
                  />
                  {numberWithCommas(overages[plan.id].phone_number_limit)}{' '}
                  {overages[plan.id].phone_unit}
                </div>
                <div>
                  <Done
                    style={{
                      color: '#15b940',
                      verticalAlign: 'middle',
                      marginRight: 5,
                    }}
                  />
                  {numberWithCommas(overages[plan.id].storage_limit)}{' '}
                  {overages[plan.id].storage_unit}
                </div>
              </>
            ) : null;

            return (
              <Grid key={plan.id} item xs='12' md='12' sm='12' lg='4'>
                <Paper
                  key={plan.id}
                  style={{
                    textAlign: 'center',
                    borderRadius: 22,
                    margin: 8,
                    padding: 20,
                    height: 'calc(100% - 16px)',
                    position: 'relative',
                  }}
                >
                  <h2 style={{ margin: 0 }}>{plan.name}</h2>
                  <h2 style={{ margin: 10 }}>{`$${plan.price}/mo`}</h2>
                  <p style={{ margin: '22', height: '40px' }}>
                    {plan.description}
                  </p>
                  <div>CALL TO ACTION</div>
                  {plan.trialPeriod === true ? (
                    <p>
                      <strong>
                        Try it free for {plan.trialDuration}{' '}
                        {plan.trialDurationUnit}
                        {plan.trialDuration > 1 ? 's' : null}
                      </strong>
                    </p>
                  ) : null}
                  <h4>Plan Includes</h4>
                  <div style={{ textAlign: 'left' }}>{bullets}</div>
                  <Grid
                    key={`overageTile${plan.id}`}
                    item
                    xs='12'
                    md='12'
                    sm='12'
                    lg='4'
                  >
                    <Paper
                      style={{
                        textAlign: 'center',
                        borderRadius: 22,
                        margin: 8,
                        paddingBottom: 6,
                        position: 'relative',
                      }}
                    >
                      <table
                        style={{
                          textAlign: 'left',
                          borderCollapse: 'collapse',
                          width: '100%',
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                fontWeight: 600,
                                paddingLeft: 7,
                                borderBottom: '1px solid gray',
                              }}
                            >
                              Additional costs
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                borderBottom: '1px solid gray',
                                padding: '1px 15px 1px 7px',
                              }}
                            >
                              ${overages[plan.id].user_overage_cost}{' '}
                              per additional{' '}
                              {overages[plan.id].user_unit.slice(
                                0,
                                -1
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                borderBottom: '1px solid gray',
                                padding: '1px 15px 1px 7px',
                              }}
                            >
                              ${overages[plan.id].email_overage_cost}{' '}
                              per additional{' '}
                              {overages[plan.id].email_unit.slice(
                                0,
                                -1
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                borderBottom: '1px solid gray',
                                padding: '1px 15px 1px 7px',
                              }}
                            >
                              ${overages[plan.id].text_overage_cost}{' '}
                              per additional{' '}
                              {overages[plan.id].text_unit.slice(
                                0,
                                -1
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                borderBottom: '1px solid gray',
                                padding: '1px 15px 1px 7px',
                              }}
                            >
                              ${overages[plan.id].voice_overage_cost}{' '}
                              per additional{' '}
                              {overages[plan.id].voice_unit.slice(
                                0,
                                -1
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                borderBottom: '1px solid gray',
                                padding: '1px 15px 1px 7px',
                              }}
                            >
                              $
                              {
                                overages[plan.id]
                                  .phone_number_overage_cost
                              }{' '}
                              per additional{' '}
                              {overages[plan.id].phone_unit.slice(
                                0,
                                -1
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ padding: '1px 15px 1px 7px' }}>
                              $
                              {
                                overages[plan.id]
                                  .storage_overage_cost
                              }{' '}
                              per additional{' '}
                              {overages[plan.id].storage_unit.slice(
                                0,
                                -1
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Paper>
                  </Grid>
                </Paper>
              </Grid>
            );
          })
      : [];

  return (
    <div style={{ minHeight: '900px' }}>
      <div className='containter-fluid text-center page-banners banner-wrap'>
        <div className='container page-header-text'>
          <h1>Pricing</h1>
        </div>
      </div>

      <div className='page-top-wrap'>
        <div className='container-fluid zero-padding features-wrap'>
          <div className='container-fluid pad-sides'>
            <Grid container spacing={16}>
              {PricingSlots}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}
