import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import SignUpCampaignsMobileNaviScrollSpy from './SignUpCampaignsMobileNaviScrollSpy.js';
import CampaignImgJ from '../CampaignsMobile/img/mobile_nav.jpg';
import SignUpCampaignMobileImg0 from './img/sign_up_campaign_mobile0.jpg';
import SignUpCampaignMobileImg1 from './img/sign_up_campaign_mobile1.jpg';
import SignUpCampaignMobileImg2 from './img/sign_up_campaign_mobile2.jpg';
import SignUpCampaignMobileImg3 from './img/sign_up_campaign_mobile3.jpg';
import SignUpCampaignMobileImg4 from './img/sign_up_campaign_mobile4.jpg';
import SignUpCampaignMobileImg5 from './img/sign_up_campaign_mobile5.jpg';
import video from '../SignUpCampaigns/video/signup_campaign_tutorial.mp4';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function SignUpCampaignsMobile() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <SignUpCampaignsMobileNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/SignUpCampaigns'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/SignUpCampaignsMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading overview'>
                CREATE SIMPLE SIGN-UP CAMPAIGN FUNNEL ON A MOBILE DEVICE
              </h1>
              <p>
                This tutorial is designed to help create a campaign that sends a
                simple text message to someone who signs up for something with
                the sign-up form. By the end of this tutorial <i>you</i> will
                have a basic understanding of how to set up a{' '}
                <strong>Campaign</strong> to send out{' '}
                <strong>Sign-up Forms</strong>.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Setting Up a Sign-up Form Campaign{' '}
              </h3>
              <p>
                Before setting up <strong>Sign-up Form Campaign</strong>,{' '}
                <i>you</i> must first have an account set up with an active
                subscription. It is recommend <i>you</i> learn the majority of{' '}
                <strong>Vast Reach’s</strong> feature sets such as adding phone
                numbers, creating <i>contacts</i>, groups, custom forms, sign-up
                forms and tasks as many of them will be incorporated into
                campaigns. Click the{' '}
                <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Buttons</strong> below to set up anything <i>you're</i>{' '}
                missing before beginning this tutorial if <i>you</i> have not
                done so already.
              </p>
              <p>
                <Link to='/GettingStartedMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Getting Started
                  </button>
                </Link>
                <Link to='/PaymentMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
                <Link to='/ManagePhoneNumbersMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Manage Phone Numbers
                  </button>
                </Link>
                <Link to='/ContactsMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Create a Contact
                  </button>
                </Link>
                <Link to='/GroupsMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Create a Group
                  </button>
                </Link>
                <Link to='/ReCaptchaMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    ReCaptcha
                  </button>
                </Link>
                <Link to='/SignUpFormsMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Sign-Up Forms
                  </button>
                </Link>
                <Link to='/TasksMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Tasks
                  </button>
                </Link>
                <Link to='/CampaignsMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Campaigns
                  </button>
                </Link>
              </p>

              <h3 className='create-signup-form'>
                Before Creating a Sign-up Campaign
              </h3>

              <p>
                First <i>you</i> need to create a <strong>Sign-up Form</strong>{' '}
                to use to send out to <i>your</i> contacts using a{' '}
                <strong>Campaign</strong>. To do that navigate to the{' '}
                <strong>Forms List Page</strong> click the{' '}
                <strong>Forms</strong> link on the navigation (logo at the top
                left). Once on the <strong>Forms List Page</strong>, click the{' '}
                <strong>ADD FORM</strong> button at the top right of the form
                list to begin. On the first step of the form page enter a name,
                call it <strong>Simple Sign-up Form</strong> for the purposes of
                this tutorial. Then select <strong>SIGNUP FORM</strong> and
                click <strong>NEXT</strong> at the top right or click the{' '}
                <strong>Build</strong> step. Make sure when creating the form
                that the <strong>Phone</strong> field is selected as it will be
                needed when creating the <strong>Campaign</strong>. After
                building a form click <strong>Create</strong> button at the top
                right.
              </p>
              <p>
                <i>Note:</i> This tutorial will need <i>contacts</i> with phone
                numbers.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={SignUpCampaignMobileImg0}
                  alt='creating a sign-up form'
                  className='img-fluid'
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    border: 'solid 1px #000000',
                  }}
                />
              </p>

              <h3 className='navigation'>Navigate to Campaigns</h3>
              <p>
                <i>You</i> can create a campaign by clicking{' '}
                <strong>Campaigns</strong> in the navigation (Logo at the top
                left). <i>You</i> will be taken to the Campaigns page.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={CampaignImgJ}
                  alt='campaign page navigation'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>
              <p>
                Clicking the <strong>ADD CAMPAIGN</strong> button at the top
                right will take <i>you</i> to the campaign creation page. Once{' '}
                <i>you</i> are on this page the <i>you</i> will be presented
                with the first part of the campaign creation which is the{' '}
                <strong>Campaign</strong> step in the stepper at the top of the
                page. While it is not required to know all the details about
                campaigns as this tutorial will explain the set-up processes for
                the most part, we do recommend learning the more detailed
                campaign tutorial. To learn{' '}
                <strong>Everything You Need to Know About Campaigns</strong>{' '}
                click <Link to='/Campaigns'>here</Link> as well{' '}
                <strong>Event Goals</strong> by clicking{' '}
                <Link to='/EventGoals'>here</Link>.
              </p>

              <h2 className='create-campaign'>
                Creating a Simple Sign-up Form Campaign
              </h2>
              <p>
                On the <strong>Campaign</strong> step, <i>you</i> will be able
                to give this campaign a name and a short description about it.{' '}
                Lets call this campaign{' '}
                <strong>Simple Signup Form Campaign</strong>.<i>You</i> will
                also be able to set a begin and end date for this campaign by
                selecting the <strong>Yes</strong> radio button from the{' '}
                <strong>Add a date range? </strong> under the description input
                box. Click the <strong>Funnel</strong> step in the stepper after{' '}
                <i>you</i> have completed filing out this page.{' '}
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={SignUpCampaignMobileImg1}
                  alt='campaign step'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>
              <p>
                Once on the <strong>Funnel</strong> step click the{' '}
                <strong>ADD A FUNNEL STAGE</strong> button. By default the first
                funnel stage created will be called <strong>Awareness</strong>,
                click the name and rename it <strong>Sign-up Leads</strong> for
                this tutorial. In the <strong>Sign-up Leads</strong> funnel
                stage, click <strong>+ ADD EVENT GOAL</strong> and add a{' '}
                <strong>Submits Signup Form Event Goal</strong> and select the{' '}
                <strong>Simple Sign-up Form</strong> <i>you</i> made earlier.
                This way this event goal will trigger when a <i>contact</i>{' '}
                signs up using that form <i>you</i> made earlier.{' '}
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={SignUpCampaignMobileImg2}
                  alt='campaign funnel step'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>
              <p>
                Next, in the task section below click{' '}
                <strong>+ ADD TASK</strong> and select the{' '}
                <strong>Send SMS</strong> task. The task creation modal (a
                pop-up window) will come up for <i>you</i> to fill out and
                create this task. Name it <strong>Thanks For Signing-up</strong>{' '}
                and write a simple <strong>Thank you for signing-up</strong>{' '}
                text message for this tutorial demonstration. When the{' '}
                <strong>Submits Signup Form Event Goal</strong> <i>you</i> made
                earlier triggers, then that event goal triggers this text
                message task to be sent out to the <i>contact</i>.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={SignUpCampaignMobileImg3}
                  alt='campaign funnel task creation'
                  className='img-fluid'
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    border: 'solid 1px #000000',
                  }}
                />
              </p>
              <p>
                On the top right click the <strong>WORK IN PROGRESS</strong>{' '}
                button and select the <strong>Go Live</strong> option from the
                list.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={SignUpCampaignMobileImg4}
                  alt='campaign about to go live'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>
              <h3 className='testing-signup-form'>Testing Your Sign-up Form</h3>
              <p>
                <i>You</i> can test the form functionality by going back to the
                form list page and selecting the{' '}
                <strong>Simple Sign-up Form</strong> you made. Once clicked, in
                the stepper at the top of the page, click the{' '}
                <strong>Share</strong> step. Once on this step <i>you</i> can
                copy the link and paste in to <i>your</i> browser and then fill
                it out. <i>You</i> should receive the text message <i>you</i>{' '}
                set up in the <strong>Simple Signup Form Campaign</strong>.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={SignUpCampaignMobileImg5}
                  alt='sharing sign-up form'
                  className='img-fluid'
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    border: 'solid 1px #000000',
                  }}
                />
              </p>
              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Test out <i>your</i> sign-up forms to make sure the campaign
                    is working as <i>you</i> intended.
                  </p>
                </li>
                <li>
                  <p>
                    Make sure when sending text messages or emails that the{' '}
                    <i>contacts</i> being used have the respective requirement
                    (email or phone number).
                  </p>
                </li>
                <li>
                  <p>
                    Make sure <i>you</i> have a full understanding of all the
                    elements of creating a sign-up form campaign to ensure best
                    marketing results.
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Try to delete or modify <i>your</i> stages, event goals or
                    tasks during an active campaign. Pause it first.
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <h3>Creating a Sign-up Form and Sign-up Form Campaign</h3>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
