import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import Logo2 from '../../img/logo_black.png';
import language from '../../utils/vast-languagepack';

export default function Fail() {
  return (
    <div className='pad-top'>
      <div className='padding-full'>
        <div className='col-xs-12 text-center wrap'>
          <img
            src={Logo2}
            alt={language.navi.titleAlt.logoAlt}
            className='logo img-fluid center-block'
          />
          <h1 className='text-center margin-top'>
            {language.verification.fail.heading}
          </h1>
          <p className='margin-top'>
            <a class='mailto' href='mailto:info@vastreach.com'>
              {language.verification.fail.failMessage}
            </a>
            {language.verification.fail.failMessage2}
          </p>
        </div>
      </div>
    </div>
  );
}
