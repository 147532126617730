import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import language from '../utils/vast-languagepack';

const handleClick = (vastLink) => () => {
  window.location.href = vastLink;
};

export default function SimpleMenu() {
  return (
    <div className='nav-items'>
      <Link to='/index' className='nav-links'>
        {language.navi.home}
      </Link>
      <Link to='/about' className='nav-links'>
        {language.navi.about}
      </Link>
      <Link to='/features' className='nav-links'>
        {language.navi.features}
      </Link>
      <Link to='/faq' className='nav-links'>
        {language.navi.faq}
      </Link>
      <div
        onClick={handleClick('https://console.vastreach.com/')}
        className='nav-links'
        style={{ cursor: 'pointer' }}
      >
        Log In
      </div>
      <Button color='inherit' className='dl-btn'>
        <Link to='/form' className='button-text'>
          {language.general.startNowButton}
        </Link>
      </Button>
    </div>
  );
}
