import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../generalCss/style.scss';
import './support.scss';
import language from '../utils/vast-languagepack';

export default function Support() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <h1 className='text-center'>{language.support.heading}</h1>

          <div className='row padding-full wrap'>
            <div className='col-xs-12'>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
