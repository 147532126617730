import React, { Component } from 'react';
import '../generalCss/style.scss';
import Button from '@material-ui/core/Button';
import ColorLogo from './img/color_logo.png';
import BlackTextColorLogo from './img/base_logo_black_text.png';
import ColorLogoMark from './img/color_logo_mark.svg';
import BlackLogo from './img/black_logo.png';
import BlackLogoMark from './img/black_logo_mark.svg';
import BlueBackgroundLogoMark from './img/logo_mark_blue_bg.svg';
import WhiteLogo from './img/white_logo.png';
import WhiteLogoMark from './img/white_logo_mark.svg';
import WhiteBackgroundLogoMark from './img/logo_mark_white_bg.svg';
import LogoSize from './img/dont_logo_size.png';
import LogoSize2 from './img/dont_logo_size2.png';
import LogoSpace from './img/logo_space.png';
import LogoSpace2 from './img/do_logo_spacing.png';
import LogoSpace3 from './img/dont_logo_spacing.png';

export default class BrandingGuidelines extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <div className='container-fluid padding-full'>
          <div className='container wrap'>
            <h1 className='pad-top'>Branding Guidelines</h1>
            <p>
              The following guidelines will detail how to interface the Vast
              Reach brand into your websites and applications.{' '}
            </p>
            <div className='padding-top'>
              <h2>Brand Assets</h2>
              <p>
                Below are the 3 variants of the Vast Reach logo. The correct
                logo can be used on your website or application based on the
                contrast of your website/application (example: black logo on
                white background).{' '}
              </p>
              <table
                class='table'
                style={{
                  backgroundColor: '#888888',
                  borderRadius: '35px',
                  border: 'none',
                }}
              >
                <thead style={{ textAlign: 'center' }}>
                  <tr>
                    <th scope='col' style={{ color: '#ffffff', border: '0' }}>
                      Color
                    </th>
                    <th scope='col' style={{ color: '#ffffff', border: '0' }}>
                      Black
                    </th>
                    <th scope='col' style={{ color: '#ffffff', border: '0' }}>
                      White
                    </th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: 'center' }}>
                  <tr>
                    <td>
                      <img
                        src={ColorLogo}
                        alt='Color Logo'
                        className='img-fluid'
                        style={{
                          maxWidth: '250px',
                          width: '100%',
                        }}
                      />
                    </td>
                    <td>
                      {' '}
                      <img
                        src={BlackLogo}
                        alt='Black Logo'
                        className='img-fluid'
                        style={{
                          maxWidth: '250px',
                          width: '100%',
                        }}
                      />
                    </td>
                    <td>
                      {' '}
                      <img
                        src={WhiteLogo}
                        alt='White Logo'
                        className='img-fluid'
                        style={{
                          maxWidth: '250px',
                          width: '100%',
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <ul style={{ listStyle: 'none' }} className='padding-top'>
                <li>
                  <h3>Size Requirements</h3>
                  <p>
                    Make sure the logo is clearly visible. The minimum width of
                    the Vast Reach logos on your website/application cannot be
                    less than 200px wide and must maintain the original aspect
                    ratio. Additionally, the maximum size of the Vast Reach logo
                    cannot exceed the largest logo size available in the assets
                    pack and must maintain the original aspect ratio. If you
                    require a smaller Vast Reach logo for your website see the
                    Logo Mark section below.{' '}
                  </p>
                  <div className='row padding-top'>
                    <div className='col-xs-12 col-md-4'>
                      <p
                        className='text-center'
                        style={{ color: '#15b940', fontWeight: 'bold' }}
                      >
                        DO
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        <img
                          src={BlackLogo}
                          alt='Black Logo'
                          className='img-fluid'
                          style={{
                            maxWidth: '300px',
                            width: '100%',
                          }}
                        />
                      </p>
                    </div>
                    <div className='col-xs-12 col-md-4'>
                      <p
                        className='text-center'
                        style={{ color: '#FB2F2F', fontWeight: 'bold' }}
                      >
                        DON'T
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        <img
                          src={LogoSize}
                          alt='Logo Size Requirements'
                          className='img-fluid'
                          style={{
                            maxWidth: '300px',
                            width: '100%',
                          }}
                        />
                      </p>
                    </div>
                    <div className='col-xs-12 col-md-4'>
                      <p
                        className='text-center'
                        style={{ color: '#FB2F2F', fontWeight: 'bold' }}
                      >
                        DON'T
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        <img
                          src={LogoSize2}
                          alt='Logo Size Requirements example 2'
                          className='img-fluid'
                          style={{
                            maxWidth: '100px',
                            width: '100%',
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </li>
                <li className='padding-top'>
                  <h3>Space Requirements</h3>
                  <p>Always maintain a clear space of 10px around the logo.</p>
                  <p style={{ textAlign: 'center' }}>
                    <img
                      src={LogoSpace}
                      alt='Logo Space Requirements'
                      className='img-fluid'
                      style={{
                        maxWidth: '800px',
                        width: '100%',
                      }}
                    />
                  </p>
                  <div className='row padding-top'>
                    <div className='col-xs-12 col-md-6'>
                      <p
                        className='text-center'
                        style={{ color: '#15b940', fontWeight: 'bold' }}
                      >
                        DO
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        <img
                          src={LogoSpace2}
                          alt='Logo Spacing done right'
                          className='img-fluid'
                          style={{
                            maxWidth: '400px',
                            width: '100%',
                          }}
                        />
                      </p>
                    </div>
                    <div className='col-xs-12 col-md-6'>
                      <p
                        className='text-center'
                        style={{ color: '#FB2F2F', fontWeight: 'bold' }}
                      >
                        DON'T
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        <img
                          src={LogoSpace3}
                          alt='Logo Spacing done wrong'
                          className='img-fluid'
                          style={{
                            maxWidth: '400px',
                            width: '100%',
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className='padding-top'>
              <h2>Logo Mark</h2>
              <p>
                Below are the 3 variants of the Vast Reach logo mark. The
                correct logo mark can be used on your website or application
                based on the contrast of your website/application (example:
                black logo on white background). These are best suited for
                footers and social media icons.
              </p>
              <table
                class='table'
                style={{
                  backgroundColor: '#888888',
                  borderRadius: '35px',
                  border: 'none',
                }}
              >
                <thead style={{ textAlign: 'center' }}>
                  <tr>
                    <th scope='col' style={{ color: '#ffffff', border: '0' }}>
                      Color
                    </th>
                    <th scope='col' style={{ color: '#ffffff', border: '0' }}>
                      Black
                    </th>
                    <th scope='col' style={{ color: '#ffffff', border: '0' }}>
                      White
                    </th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: 'center' }}>
                  <tr>
                    <td>
                      <img
                        src={ColorLogoMark}
                        alt='Color Logo Mark'
                        className='img-fluid'
                        style={{
                          maxWidth: '50px',
                          width: '100%',
                          borderRadius: '0',
                        }}
                      />
                    </td>
                    <td>
                      {' '}
                      <img
                        src={BlackLogoMark}
                        alt='Black Logo Mark'
                        className='img-fluid'
                        style={{
                          maxWidth: '50px',
                          width: '100%',
                          borderRadius: '0',
                        }}
                      />
                    </td>
                    <td>
                      {' '}
                      <img
                        src={WhiteLogoMark}
                        alt='White Logo Mark'
                        className='img-fluid'
                        style={{
                          maxWidth: '50px',
                          width: '100%',
                          borderRadius: '0',
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='padding-top'>
              <h2>Do's and Don'ts</h2>
              <div className='row'>
                <div className='col-xs-12 col-sm-4'>
                  <ul>
                    <li style={{ listStyle: 'none' }}>
                      <h3 style={{ color: '#15b940', fontWeight: 'bold' }}>
                        DO
                      </h3>
                    </li>
                    <li>
                      <p>
                        Logos provided by Vast Reach are to be the only logos
                        used.
                      </p>
                    </li>
                    <li>
                      <p>
                        When using the logos on footers provide a link to the
                        Vast Reach home page.
                      </p>
                    </li>
                    <li>
                      <p>Make sure the logo is properly visible.</p>
                    </li>
                    <li>
                      <p>
                        Make sure the logo size/aspect ratio is correct (to
                        ensure there is no stretching or warping).{' '}
                      </p>
                    </li>
                  </ul>
                </div>
                <div className='col-xs-12 col-sm-4'>
                  <ul>
                    <li style={{ listStyle: 'none' }}>
                      <h3 style={{ color: '#FB2F2F', fontWeight: 'bold' }}>
                        DON'T
                      </h3>
                    </li>
                    <li>
                      <p>
                        Do not alter, change font, color or modify the provided
                        logos.{' '}
                      </p>
                    </li>
                    <li>
                      <p>
                        Do not create, alter, change font, color or modify the
                        provided logos.{' '}
                      </p>
                    </li>
                    <li>
                      <p>
                        Do not represent these logos on websites or applications
                        if the individual/company does not use or have any
                        association to Vast Reach.
                      </p>
                    </li>
                    <li>
                      <p>
                        Do not user similar colors as the logo for a background
                        (black logo on black background).
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className='padding-top'>
              <h2>Download Assets</h2>
              <p>
                Click the respective buttons below to download Vast Reach logo
                asset packs or select the individual logo desired for download.
              </p>
              <h3>Logo Assets</h3>
              <div className='padding-full'>
                <a
                  href='./assets/single logo/color_logo.png'
                  className='button-text'
                  style={{ display: 'inline-block' }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    src={ColorLogo}
                    alt='Color Logo'
                    className='img-fluid single-logo'
                    style={{
                      maxWidth: '300px',
                      width: '100%',
                      margin: '10px',
                      padding: '10px',
                      backgroundColor: '#000000',
                    }}
                  />
                </a>

                <a
                  href='./assets/single logo/base_logo_black_text.png'
                  className='button-text'
                  style={{ display: 'inline-block' }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    src={BlackTextColorLogo}
                    alt='Color Logo'
                    className='img-fluid single-logo'
                    style={{
                      maxWidth: '300px',
                      width: '100%',
                      margin: '10px',
                      padding: '10px',
                      backgroundColor: '#dddddd',
                    }}
                  />
                </a>

                <a
                  href='./assets/single logo/black_logo.png'
                  className='button-text'
                  style={{ display: 'inline-block' }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    src={BlackLogo}
                    alt='Color Logo'
                    className='img-fluid single-logo'
                    style={{
                      maxWidth: '300px',
                      width: '100%',
                      margin: '10px',
                      padding: '10px',
                      backgroundColor: '#dddddd',
                    }}
                  />
                </a>

                <a
                  href='./assets/single logo/white_logo.png'
                  className='button-text'
                  style={{ display: 'inline-block' }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    src={WhiteLogo}
                    alt='Color Logo'
                    className='img-fluid single-logo'
                    style={{
                      maxWidth: '300px',
                      width: '100%',
                      margin: '10px',
                      padding: '10px',
                      backgroundColor: '#000000',
                    }}
                  />
                </a>
              </div>
              <a
                href='./assets/color_logos.zip'
                className='button-text'
                style={{ display: 'inline-block' }}
              >
                <Button
                  color='inherit'
                  className='dl-btn'
                  style={{ margin: '10px' }}
                >
                  Download Color Asset Pack
                </Button>
              </a>
              <a
                href='./assets/black_white_logos.zip'
                className='button-text'
                style={{ display: 'inline-block' }}
              >
                <Button
                  color='inherit'
                  className='dl-btn'
                  style={{ margin: '10px' }}
                >
                  Download Black & White Asset Pack
                </Button>
              </a>
              <h3 style={{ margin: '10px 0 0 0' }}>Logo Mark Assets</h3>
              <div className='padding-full'>
                <a
                  href='./assets/single logo/color_logo_mark.svg'
                  className='button-text'
                  style={{ display: 'inline-block' }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    src={ColorLogoMark}
                    alt='Color Logo'
                    className='img-fluid single-logo'
                    style={{
                      maxWidth: '100px',
                      width: '100%',
                      margin: '10px',
                      padding: '10px',
                      backgroundColor: '#dddddd',
                    }}
                  />
                </a>

                <a
                  href='./assets/single logo/black_logo_mark.svg'
                  className='button-text'
                  style={{ display: 'inline-block' }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    src={BlackLogoMark}
                    alt='Color Logo'
                    className='img-fluid single-logo'
                    style={{
                      maxWidth: '100px',
                      width: '100%',
                      margin: '10px',
                      padding: '10px',
                      backgroundColor: '#dddddd',
                    }}
                  />
                </a>

                <a
                  href='./assets/single logo/white_logo_mark.svg'
                  className='button-text'
                  style={{ display: 'inline-block' }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    src={WhiteLogoMark}
                    alt='Color Logo'
                    className='img-fluid single-logo'
                    style={{
                      maxWidth: '100px',
                      width: '100%',
                      margin: '10px',
                      padding: '10px',
                      backgroundColor: '#000000',
                    }}
                  />
                </a>

                <a
                  href='./assets/single logo/logo_mark_blue_bg.svg'
                  className='button-text'
                  style={{ display: 'inline-block' }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    src={BlueBackgroundLogoMark}
                    alt='Color Logo'
                    className='img-fluid single-logo'
                    style={{
                      maxWidth: '100px',
                      width: '100%',
                      margin: '10px',
                      padding: '10px',
                      backgroundColor: '#dddddd',
                    }}
                  />
                </a>

                <a
                  href='./assets/single logo/logo_mark_white_bg.svg'
                  className='button-text'
                  style={{ display: 'inline-block' }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    src={WhiteBackgroundLogoMark}
                    alt='Color Logo'
                    className='img-fluid single-logo'
                    style={{
                      maxWidth: '100px',
                      width: '100%',
                      margin: '10px',
                      padding: '10px',
                      backgroundColor: '#dddddd',
                    }}
                  />
                </a>
              </div>

              <a
                href='./assets/color_logo_mark.zip'
                className='button-text'
                style={{ display: 'inline-block' }}
              >
                <Button
                  color='inherit'
                  className='dl-btn'
                  style={{ margin: '10px' }}
                >
                  Download Color Mark Asset Pack
                </Button>
              </a>
              <a
                href='./assets/black_white_logo_mark.zip'
                className='button-text'
                style={{ display: 'inline-block' }}
              >
                <Button
                  color='inherit'
                  className='dl-btn'
                  style={{ margin: '10px' }}
                >
                  Download Black & White Mark Asset Pack
                </Button>
              </a>
            </div>

            <div className='padding-top'>
              <h2>Brand Permissions</h2>
              <p>
                Business or partnerships associating with Vast Reach will
                require the individual or company to get approval before using
                the Vast Reach Logo on their website or application.{' '}
                <a href='mailto:info@vastreach.com'>Click here</a> to contact us
                to begin an approval process.{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
