import React from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import TextMessageUseCaseNaviScrollSpy from './TextMessageUseCaseNaviScrollSpy.js';
import MultiChannelInboxImg from '../MultiChannelInbox/img/inbox_email_triple_channel.jpg';
import SelectContact from './img/select_contact_texting.jpg';
import Emoji from './img/emoji.jpg';
import FileUploadTexting from './img/file_upload_texting.jpg';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function TextMessageUseCase() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <TextMessageUseCaseNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/TextMessageUseCase'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/TextMessageUseCaseMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>
                BEST PRACTICES FOR TEXT MESSAGE MARKETING (Desktop Browser)
              </h1>
              <div>
                <p className='intro-text'>
                  Text message marketing is one of the best ways to get{' '}
                  <i>your</i> marketing promotions to customers. With the ever
                  rising use of mobile phones and devices that can receive text
                  messages now it’s even easier to reach <i>your</i> customers.{' '}
                  <i>You</i> no longer have to rely on emails and phone calls
                  alone. This tutorial is designed to show <i> you</i> how to
                  get started with text message marketing and teach <i> you</i>{' '}
                  some best practices to get the ball rolling for <i> your</i>{' '}
                  business.{' '}
                </p>
                <h3 className='prerequisites'>
                  Prerequisites Before Creating and Sending a Text Message
                </h3>
                <p>
                  Before creating and sending a <strong>Text Message</strong>,{' '}
                  <i>you</i> must first have an account set up with an active
                  subscription. <i>You</i> will need a default phone number
                  selected using <strong>Manage Phone Numbers</strong> and at
                  least one <i>contact</i> created with a number. Click the{' '}
                  <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                  <strong>Buttons</strong> below to set up anything{' '}
                  <i>you're</i> missing before beginning this tutorial if{' '}
                  <i>you</i> have not done so already.
                </p>
                <p>
                  <Link to='/GettingStarted'>
                    <button className='dl-btn' style={{ margin: '10px' }}>
                      Getting Started
                    </button>
                  </Link>
                  <Link to='/Payment'>
                    <button className='dl-btn' style={{ margin: '10px' }}>
                      Payment Information
                    </button>
                  </Link>
                  <Link to='/ManagePhoneNumbers'>
                    <button className='dl-btn' style={{ margin: '10px' }}>
                      Manage Phone Numbers
                    </button>
                  </Link>
                  <Link to='/Contacts'>
                    <button className='dl-btn' style={{ margin: '10px' }}>
                      Create a Contact
                    </button>
                  </Link>
                </p>
                <h3 className='navigation'>Navigate to Inbox</h3>
                <p>
                  To access <strong>Calls & Messages</strong> <i>you</i> can
                  click the <strong>Calls & Messages Icon</strong> from the
                  navigation (Left side menu, first icon). Additionally,{' '}
                  <i>you</i> can navigate to the <strong>Inbox</strong> page
                  using the following link:{' '}
                  <a href='https://console.vastreach.com/inbox'>
                    https://console.vastreach.com/inbox
                  </a>
                  . Once clicked <i>you</i> will be taken to the{' '}
                  <strong>Inbox</strong> where <i>you</i> will see 3 columns.
                </p>
                <p style={{ textAlign: 'center' }}>
                  <img
                    src={MultiChannelInboxImg}
                    alt='inbox triple column open'
                    className='img-fluid'
                    style={{
                      maxWidth: '1000px',
                      width: '100%',
                    }}
                  />
                </p>
                <h3 className='understanding-sms'>
                  Understanding The Impact of Text Message Marketing
                </h3>
                <p>
                  Before <i>you</i> begin creating and sending text messages,{' '}
                  <i>you</i> must first know the impact of these text messages
                  and why sending these messages are an important way to market{' '}
                  <i> your</i> business promotions.
                </p>
                <p>
                  Text messaging marketing is so effective because people tend
                  to check their messages far more than their emails or even
                  responding to phone calls. In the past people had to use email
                  to communicate and thus it was an easier way to reach
                  customers as they check regularly checked their emails. Those
                  days have now evolved to text messaging as it is the more
                  convenient way people stay connected and check routinely day
                  to day. Phone calls may be an easy way to build a connection
                  but not everyone is able to converse through the phone
                  conveniently compared to glancing over a text message.{' '}
                </p>

                <p>
                  Additional benefits of text messaging is it can be automated
                  and responded to quicker than anything else. A short message
                  and perhaps an image or thumbnail can get across what{' '}
                  <i>you’re</i> trying to market quick epically if worded to be
                  catchy and engaging directly linking to <i>your</i> promotion.{' '}
                </p>
              </div>

              <h2 className='text-create'>
                Creating and Sending a Text Message
              </h2>
              <p>
                Once <i>you</i> have navigated to the <strong>Inbox</strong>{' '}
                <i>you</i> can begin creating a text message by clicking the{' '}
                <strong>Create New Conversation Icon</strong> (pencil with a
                plus Icon) next to the search on the left side of the page.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={SelectContact}
                  alt='contact select for texting'
                  className='img-fluid'
                  style={{
                    maxWidth: '800px',
                    width: '100%',
                    margin: '5px',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <p>
                After clicking the <strong>Create New Conversation Icon</strong>
                , at the top of the <strong>Conversation Preview</strong> to the
                right of the <strong>Create New Conversation Icon</strong> will
                present a list menu with a cancel button to the right of that.
                Clicking that list menu will allow <i>you</i> to select a{' '}
                <i>contact</i> from the list of <i>contacts</i> <i>you</i> have
                added to <i>your</i> <strong>Vast Reach</strong> account. Select
                a <i>contact</i> <i>you</i> want to send a text message to.
              </p>
              <p>
                Once a <i>contact</i> is selected <i>you</i> will see that{' '}
                <i>contacts</i> name at the top of the{' '}
                <strong>Conversation Preview</strong> where the list menu was
                earlier. At the bottom of the{' '}
                <strong>Conversation Preview</strong> <i>you</i> will see a{' '}
                <strong>To: </strong> and <strong>From: </strong> with a list
                menu to the right of each of them. Select the list menu to the
                right of <strong>To: </strong> and select the <i>contacts</i>{' '}
                number under the <strong>Text</strong> option in the list menu.
                Selecting the list menu to the right of <strong>From:</strong>{' '}
                allows <i>you</i> to select the number <i>you</i> will be
                sending this text message from.
              </p>
              <p>
                <i>Note: </i> Selecting a <i>contact</i> that does not have a
                number will only allow <i>you</i> to email that <i>contact</i>.{' '}
                <i>You</i> must select a <i>contact</i> that has a number or add
                a number to that <i>contact</i> before being able to send a text
                message to them.{' '}
              </p>
              <p>
                <i>Note: </i> If a <i>contact</i> only has a number then by
                default the <strong>Conversation Preview</strong> will likely
                start off in the text message setting.
              </p>
              <p>
                Once <i>you</i> are in text message setting, <i>you</i> can
                begin entering a text message to this <i>contact</i>. Once{' '}
                <i>you’re</i> done typing a message, <i>you</i> can press the{' '}
                <strong>Send Icon</strong> (paper plane icon) to the far right
                of the text input box to send the message.{' '}
              </p>
              <p style={{ margin: '10px 0 5px 0' }}>
                <ReactPlayer
                  style={{ maxWidth: '800px' }}
                  muted
                  playing
                  loop
                  playsinline
                  width='100%'
                  height='100%'
                  url={[
                    `${process.env.PUBLIC_URL}/video/contact_texting.mp4`,
                    `${process.env.PUBLIC_URL}/video/contact_texting.gif`,
                  ]}
                />
              </p>
              <p>
                Additionally, <i>you</i> can add <strong>Emojis</strong> or
                attach a <strong>File/Image</strong> using the icons to the
                right of the text input box. Selecting the{' '}
                <strong>Emoji Icon</strong> will bring up a menu with a list of
                emjois <i>you</i> can use to add to <i>your</i> text messages.
                Similarly, <i>you</i> can add files and images by clicking the{' '}
                <strong>Paper Clip Icon</strong>. This will bring up a modal
                (pop-up window) that will allow <i>you</i> to upload a
                file/image or use an existing one from <i>your</i>{' '}
                <strong>Public File Library</strong>.
              </p>
              <p>
                <i>Note: </i> There is a file/image size limit of XXMb for text
                messages.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={Emoji}
                  alt='texting emoji'
                  className='img-fluid'
                  style={{
                    maxWidth: '500x',
                    width: '100%',
                    margin: '5px',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={FileUploadTexting}
                  alt='adding file to text'
                  className='img-fluid'
                  style={{ maxWidth: '800px', width: '100%', margin: '5px' }}
                />
              </p>
              <p>
                That concludes the basics of how to send a text message using{' '}
                <strong>Vast Reach</strong>. In the next section of this
                tutorial we will discuss on best practices for marking your text
                message promotions.{' '}
              </p>

              <h2 id='best-sms-practices'>
                Best Practices for Text Message Marketing
              </h2>
              <p>
                The follow section will explain tips on how to begin text
                message marketing using some industry best practices.
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h3 className='gen-leads'>Generate Leads</h3>
                  <p>
                    <i>You</i> first need to use <strong>Vast Reach</strong> to
                    generate leads. Using our <strong>Sign-up Forms</strong>{' '}
                    <i>you</i> can add forms to <i>your</i> websites or link
                    them directly to <i>contacts</i> and have them sign-up to{' '}
                    <i>your</i> email marketing list via a{' '}
                    <strong>Contact Group</strong> (Click{' '}
                    <Link to='/Groups'>here</Link> to learn how). Or to learn
                    how to create <strong>Sign-up Forms</strong> using{' '}
                    <strong>Vast Reach</strong> click{' '}
                    <Link to='/SignUpForms'>here</Link>.
                  </p>
                </li>
                <li>
                  <h3 className='permissions'>
                    Get Acknowledgment From Your Customers
                  </h3>
                  <p>
                    When sending SMS text messages <i>you</i> want to make sure{' '}
                    <i>you</i> have permission to send <i>contacts</i> text
                    messages just as <i>you</i> would with email marketing. The
                    laws that protect consumers via email also apply to text
                    messages. So use <strong>Opt-in</strong> and{' '}
                    <strong>Opt-out</strong> subscription sign-up forms to get
                    more leads.
                  </p>
                  <ul style={{ listStyle: 'none' }}>
                    <li>
                      <h4>Respect The Customer’s Choice and Follow The Law</h4>
                      <p>
                        If a <i>contact</i> decides to <strong>Opt-out</strong>{' '}
                        <i>your</i> email marketing then make sure to opt them
                        out to respect their decision and avoid any legal
                        ramifications.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3 className='short-sweet'>Keep It Short and Sweet</h3>
                  <p>
                    <i>You</i> want to create a short but catchy title to entice{' '}
                    <i>your</i> <i>contact</i> but have some connection to the
                    content of the rest of the text message, otherwise it’s just
                    click bait.{' '}
                  </p>
                  <ul style={{ listStyle: 'none' }}>
                    <li>
                      <h4>Let Your Customers Know Who You Are</h4>
                      <p>
                        At the beginning of the text message let them know who
                        <i>you</i> are so they know who this text message is
                        from.
                      </p>
                    </li>
                    <li>
                      <h4>Create a Clear Call to Action</h4>
                      <p>
                        Inside <i>your</i> text message, <i>you</i> want to make
                        sure it is engaging but short and straight to the point.
                        Adding a link and a picture that relates to the text
                        message is also helpful to give a visual appeal for{' '}
                        <i>contacts</i> who may gravitate toward visual response
                        before reading.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Follow text messaging laws and respect <i>contact</i>{' '}
                    <strong>opt-out</strong> choices.
                  </p>
                </li>
                <li>
                  <p>
                    Keep text messages short and sweet and straight to the
                    point.
                  </p>
                </li>
                <li>
                  <p>
                    Use visual aid to help promote <i>your</i> marketing and
                    links to direct <i>contacts</i> to <i>your</i> promotional
                    content.
                  </p>
                </li>
                <li>
                  <p>
                    Always acknowledge who <i>you</i> are as the sender.
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Spam <i>your</i> contacts with too many text messages.{' '}
                    <i>You</i> may be flagged for spamming.
                  </p>
                </li>
                <li>
                  <p>
                    Write long strings of text messages. Nobody wants to read an
                    essay.
                  </p>
                </li>
              </ul>

              {/* <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <h3>Name</h3>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
