import React from 'react';
import { Link } from 'react-scroll';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import { Accordion, Card } from 'react-bootstrap';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

export default function UserPermissionMobileNaviScrollSpy() {
  return (
    <div className='col-xs-12 col-lg-3 order-lg-2'>
      <Accordion defaultActiveKey='0' className='accordion-wrap'>
        <h2 className='faq-navi-header'>User Permission Navigation</h2>

        <Card>
          <Card.Header className='accordion-header'>
            <p className='active-link'>
              <Link
                to='intro'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Intro to User Permissions
              </Link>
            </p>
            <Accordion.Toggle variant='link' eventKey='0' className='carret'>
              <ArrowDropDownIcon />
            </Accordion.Toggle>
          </Card.Header>

          <Accordion.Collapse eventKey='0'>
            <Card.Body>
              <ul className='tutorial-side-nav'>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='prerequisites'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Prerequisites Before Setting-up User Permissions
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link'>
                    <Link
                      to='navigation'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Navigate to Account Preferences & Settings
                    </Link>
                  </p>
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <p className='active-link'>
              <Link
                to='permissions'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Permissions
              </Link>
            </p>
          </Card.Header>
        </Card>

        <Card>
          <Card.Header className='accordion-header'>
            <p className='active-link'>
              <Link
                to='contacts'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Contacts
              </Link>
            </p>
            <Accordion.Toggle variant='link' eventKey='1' className='carret'>
              <ArrowDropDownIcon />
            </Accordion.Toggle>
          </Card.Header>

          <Accordion.Collapse eventKey='1'>
            <Card.Body>
              <ul className='tutorial-side-nav'>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='text-message'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Text message
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='email'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Email
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link'>
                    <Link
                      to='call'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Call
                    </Link>
                  </p>
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <p className='active-link'>
              <Link
                to='data-access'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Data Access
              </Link>
            </p>
          </Card.Header>
        </Card>

        <Card>
          <Card.Header>
            <p className='active-link'>
              <Link
                to='owner-transfer'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Account Ownership Transfer
              </Link>
            </p>
          </Card.Header>
        </Card>

        <Card>
          <Card.Header className='accordion-header'>
            <p className='active-link'>
              <Link
                to='do-dont'
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Do's and Don'ts
              </Link>
            </p>
          </Card.Header>
        </Card>
      </Accordion>

      <Accordion
        className='position-fixed bottom-nav-wrap'
      >
        <Card>
          <Card.Header className='accordion-header'>
            <div className='header-wrap'>
              <p className='mobile-nav-header'>Jump To...</p>
              <Accordion.Toggle variant='link' eventKey='0' className='carret'>
                <ArrowDropUpIcon />
              </Accordion.Toggle>
            </div>
          </Card.Header>

          <Accordion.Collapse eventKey='0'>
            <Card.Body>
              <ul className='tutorial-side-nav'>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='intro'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Intro to User Permissions
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='prerequisites'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Prerequisites Before Setting-up User Permissions
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='navigation'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Navigate to Account Preferences & Settings
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='permissions'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Permissions
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='contacts'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Contacts
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='text-message'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Text message
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='email'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Email
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='call'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Call
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='data-access'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Data Access
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link sub-link-margin'>
                    <Link
                      to='owner-transfer'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Account Ownership Transfer
                    </Link>
                  </p>
                </li>
                <li>
                  <p className='active-sub-link'>
                    <Link
                      to='do-dont'
                      activeClass='active'
                      spy={true}
                      smooth={true}
                      offset={-120}
                      duration={500}
                    >
                      Do's and Don'ts
                    </Link>
                  </p>
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}
