import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../generalCss/style.scss';
import TextImg from '../img/message.png';
import TaskListImg from '../img/task-list.png';
import FormBuildingImg from '../img/forms.png';
import SmartTaskImg from '../img/phone-example3.png';
import ReachAiImg from '../img/ai-type.png';
import CrossPlatformImg from '../img/cross-platform.png';
import MultiChannelInboxImgD from '../img/inbox_home_triple_channel.png';
import PhoneCallsImg from '../img/phone-calls.png';
import language from '../utils/vast-languagepack';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';

export default function Features() {
  return (
    <div>
      <div className='containter-fluid text-center page-banners banner-wrap'>
        <div className='container page-header-text'>
          <h1>{language.features.heading}</h1>
        </div>
      </div>

      <div className='page-top-wrap'>
        <div className='container-fluid zero-padding features-wrap'>
          <div className='container-fluid pad-sides'>
            <div className='row padding-full wrap' id='inbox'>
              <div className='col-xs-12 text-center col-md-6 order-md-2'>
                <img
                  src={MultiChannelInboxImgD}
                  alt={language.features.titleAlt.multiChanAlt}
                  className='img-fluid center-block'
                />
              </div>
              <div className='col-xs-12 col-md-6 order-md-1 image-space'>
                <h2 className='text-margin'>
                  <LabelImportantIcon
                    style={{
                      fontSize: '2.5rem',
                      color: '#006fd9',
                      margin: '-6px 5px 0 0',
                    }}
                  />
                  {language.features.multiChanHeading}
                </h2>
                <p className='text-margin'>
                  {language.features.multiChanDescription}
                </p>
              </div>
            </div>

            <div className='bg-2'>
              <div className='row padding-full wrap' id='task'>
                <div className='col-xs-12 text-center col-md-6'>
                  <img
                    src={TaskListImg}
                    alt={language.features.titleAlt.taskAlt}
                    className='img-fluid center-block'
                  />
                </div>
                <div className='col-xs-12 col-md-6 image-space'>
                  <h2 className='text-margin'>
                    <LabelImportantIcon
                      style={{
                        fontSize: '2.5rem',
                        color: '#006fd9',
                        margin: '-6px 5px 0 0',
                      }}
                    />
                    {language.features.tasksHeading}
                  </h2>
                  <p className='text-margin'>
                    {language.features.tasksDescription}
                  </p>
                </div>
              </div>
            </div>

            <div className='row padding-full wrap' id='campaign'>
              <div className='col-xs-12 text-center col-md-6 order-md-2'>
                <img
                  src={SmartTaskImg}
                  alt={language.features.titleAlt.campaignsAlt}
                  className='img-fluid center-block'
                />
              </div>
              <div className='col-xs-12 col-md-6 order-md-1 image-space'>
                <h2 className='text-margin'>
                  <LabelImportantIcon
                    style={{
                      fontSize: '2.5rem',
                      color: '#006fd9',
                      margin: '-6px 5px 0 0',
                    }}
                  />
                  {language.features.campaignsHeading}
                </h2>
                <p className='text-margin'>
                  {language.features.campaignsDescription}
                </p>
              </div>
            </div>

            <div className='bg-2'>
              <div className='row padding-full wrap' id='form'>
                <div className='col-xs-12 text-center col-md-6'>
                  <img
                    src={FormBuildingImg}
                    alt={language.features.titleAlt.formAlt}
                    className='img-fluid center-block'
                  />
                </div>
                <div className='col-xs-12 col-md-6 image-space'>
                  <h2 className='text-margin'>
                    <LabelImportantIcon
                      style={{
                        fontSize: '2.5rem',
                        color: '#006fd9',
                        margin: '-6px 5px 0 0',
                      }}
                    />
                    {language.features.formsHeading}
                  </h2>
                  <p className='text-margin'>
                    {language.features.formsDescription}{' '}
                  </p>
                </div>
              </div>
            </div>

            <div className='row padding-full wrap' id='cross-platform'>
              <div className='col-xs-12 text-center col-md-6 order-md-2'>
                <img
                  src={CrossPlatformImg}
                  alt={language.features.titleAlt.crossPlatformAlt}
                  className='img-fluid center-block'
                />
              </div>
              <div className='col-xs-12 col-md-6 order-md-1 image-space'>
                <h2 className='text-margin'>
                  <LabelImportantIcon
                    style={{
                      fontSize: '2.5rem',
                      color: '#006fd9',
                      margin: '-6px 5px 0 0',
                    }}
                  />
                  {language.features.crossPlatformHeading}
                </h2>
                <p className='text-margin'>
                  {language.features.crossPlatformDescription}
                </p>
              </div>
            </div>

            <div className='bg-2'>
              <div className='row padding-full wrap' id='message'>
                <div className='col-xs-12 text-center col-md-6'>
                  <img
                    src={TextImg}
                    alt={language.features.titleAlt.msgAlt}
                    className='img-fluid center-block'
                  />
                </div>
                <div className='col-xs-12 col-md-6 image-space'>
                  <h2 className='text-margin'>
                    <LabelImportantIcon
                      style={{
                        fontSize: '2.5rem',
                        color: '#006fd9',
                        margin: '-6px 5px 0 0',
                      }}
                    />
                    {language.features.personalMsgHeading}
                  </h2>
                  <p className='text-margin'>
                    {language.features.personalMsgDescription}{' '}
                  </p>
                </div>
              </div>
            </div>

            <div className='row padding-full wrap' id='ai'>
              <div className='col-xs-12 text-center col-md-6 order-md-2'>
                <img
                  src={ReachAiImg}
                  alt={language.features.titleAlt.aiAlt}
                  className='img-fluid center-block'
                />
              </div>
              <div className='col-xs-12 col-md-6 order-md-1 image-space'>
                <h2 className='text-margin'>
                  <LabelImportantIcon
                    style={{
                      fontSize: '2.5rem',
                      color: '#006fd9',
                      margin: '-6px 5px 0 0',
                    }}
                  />
                  {language.features.aiHeading}
                </h2>
                <p className='text-margin'>{language.features.aiDescription}</p>
              </div>
            </div>

            <div className='bg-2'>
              <div className='row padding-full wrap' id='phone'>
                <div className='col-xs-12 text-center col-md-6'>
                  <img
                    src={PhoneCallsImg}
                    alt={language.features.titleAlt.phoneCallsAlt}
                    className='img-fluid center-block'
                    style={{
                      borderLeft: '5px solid #000000',
                      borderRight: '5px solid #000000',
                      borderTop: '20px solid #000000',
                      borderBottom: '20px solid #000000',
                    }}
                  />
                </div>
                <div className='col-xs-12 col-md-6 image-space'>
                  <h2 className='text-margin'>
                    <LabelImportantIcon
                      style={{
                        fontSize: '2.5rem',
                        color: '#006fd9',
                        margin: '-6px 5px 0 0',
                      }}
                    />
                    {language.features.phoneCallsHeading}
                  </h2>
                  <p className='text-margin' style={{ marginBottom: '0' }}>
                    {language.features.phoneCallsDescription}
                  </p>
                </div>
              </div>
            </div>

            <div className='container-fluid padding-top'>
              <div className='container wrap'>
                <div className='col-xs-12'>
                  <h2 className='text-center'>
                    WANT TO SEE MORE? SCHEDULE A LIVE DEMO TODAY!
                  </h2>
                </div>
              </div>
            </div>

            <div className='container-fluid padding-full '>
              <div className='col-xs-12 zero-padding wrap'>
                <iframe
                  title='book'
                  src='https://koalendar.com/e/meet-with-wil-casillas?embed=true'
                  width='100%'
                  height='800px'
                  frameborder='0'
                ></iframe>
                <div className='col-xs-12 text-center'>
                  <h3>
                    Don't See a time that works for you? Get in contact with Wil
                    Casillas{' '}
                  </h3>
                  <p>
                    <a href='mailto: wcasillas@vasttechnologies.com'>
                      wcasillas@vasttechnologies.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
