import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import CreateUserMobileNaviScrollSpy from './CreateUserMobileNaviScrollSpy.js';
import CreateUserMobileImgA from './img/mobile_user_list1.png';
import CreateUserMobileImgB from './img/mobile_user_list2.png';
import CreateUserMobileImgC from './img/mobile_user_list3.png';
import CreateUserMobileImgD from './img/mobile_user_list4.png';
import CreateUserMobileImgE from './img/mobile_user_list0.png';
import AccountSettingsMobileC from '../AccountSettingsMobile/img/mobile_nav_account.jpg';
import video from '../CreateUser/video/create_user_tutorial.mov';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function CreateUserMobile() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <CreateUserMobileNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/CreateUser'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/CreateUserMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>
                INVITE NEW USERS FROM A MOBILE DEVICE
              </h1>
              <p className='user-invite'>
                This tutorial will teach <i>you</i> how to invite and manage{' '}
                <i>your</i> <i>users</i> on a mobile device as well as
                understand <i>user</i> invitation limits based on the
                subscription plan of <i>your</i> account.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Inviting a User
              </h3>
              <p>
                Before <strong>Inviting a User</strong>, <i>you</i> must first
                have an account set up with an active subscription. Click the{' '}
                <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Button</strong> below to set up anything <i>you're</i>{' '}
                missing before beginning this tutorial if <i>you</i> have not
                done so already.
              </p>
              <p>
                <Link to='/PaymentMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
              </p>
              <h3 className='navigation'>
                Navigate to Account Preferences & Settings
              </h3>
              <p>
                In order for <i>you</i> to add a <i>user</i>, <i>you</i> must
                invite a <i>user</i>. To invite a <i>user</i> <i>you</i> can
                begin by visiting{' '}
                <strong>Account Preferences & Settings</strong> page by clicking
                the link in the navigation (logo at the top left).
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={AccountSettingsMobileC}
                  alt='account settings navigation'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>
              <p>
                Once on <strong>Account Preferences & Settings</strong> page,{' '}
                <i>you</i> can click <strong>USER INVITES</strong> button at the
                top right of the screen (this button is only visible to the
                account owner).
                <p style={{ textAlign: 'center', margin: '20px 0 0 0' }}>
                  <img
                    src={CreateUserMobileImgA}
                    alt='blank mobile user creation page'
                    className='img-fluid'
                    style={{ maxWidth: '400px', width: '100%' }}
                  />
                </p>
              </p>
              <h2 className='create-user'>User Creation</h2>
              <p>
                Now <i>you</i> will see a modal (a pop-up window) called{' '}
                <strong>USER INVITATION</strong> and on this modal <i>you</i>{' '}
                will see all of <i>your</i> <i>users</i>. If <i>you</i> have
                never invited a <i>user</i> the list will be empty, but on the
                top right of the modal is a <strong>+ ADD INVITATION</strong>{' '}
                button to begin inviting a new <i>user</i>.
              </p>
              <p style={{ textAlign: 'center', margin: '20px 0 0 0' }}>
                <img
                  src={CreateUserMobileImgB}
                  alt='blank mobile user creation page'
                  className='img-fluid'
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    margin: '10px',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <p>
                Once clicked <i>you</i> will see 3 three input fields (
                <strong>Full name</strong>, <strong>Email</strong> and{' '}
                <strong>Phone number</strong>), along with a{' '}
                <strong>CREATE</strong> button. Once all fields have been filled{' '}
                <i>you</i> can now click the <strong>CREATE</strong> button to
                send the invitation or you can cancel using the{' '}
                <strong>CANCEL</strong> button at the top right.
              </p>
              <p style={{ textAlign: 'center', margin: '20px 0 0 0' }}>
                <img
                  src={CreateUserMobileImgC}
                  alt='blank mobile user creation page'
                  className='img-fluid'
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    margin: '10px',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <p>
                Once invitation is sent to the <i>user</i> they will receive an
                email with instructions to sign up to{' '}
                <strong>Vast Reach</strong>. Once they create an account{' '}
                <i>your</i> invitation modal will show{' '}
                <strong>Status: Accepted</strong> above that <i>user's</i> full
                name.
              </p>
              <p>
                <i>Note</i>: <i>You</i> can cancel a sent <i>user</i> invitation
                at any time by clicking the <strong>CANCEL INVITATION</strong>{' '}
                button below the grayed out <strong>SENT</strong> button in the
                user invitation modal.
              </p>
              <p style={{ textAlign: 'center', margin: '20px 0 0 0' }}>
                <img
                  src={CreateUserMobileImgD}
                  alt='blank mobile user creation page'
                  className='img-fluid'
                  style={{
                    maxWidth: '350px',
                    width: '100%',
                    margin: '10px',
                    border: '1px solid #000000',
                  }}
                />
                <img
                  src={CreateUserMobileImgE}
                  alt='mobile user list'
                  className='img-fluid'
                  style={{
                    maxWidth: '350px',
                    width: '100%',
                    margin: '10px',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              {/* <p>
                Once a <i>user</i> has accepted the <i>user</i> invitation, it
                will show in <strong style={{ color: '#15b940' }}>Green</strong>{' '}
                as accepted under the status text. Additionally, the <i>user</i>{' '}
                will be now seen on the previous <i>user</i> list screen.{' '}
                <i>You</i> can now edit the <i>users</i> profile and permissions
                from the <i>user</i> list page by clicking the <i>users</i> name
                or by clicking the ellipsis icon and selecting{' '}
                <strong>Edit</strong>. For additional information on how to edit{' '}
                <strong>User Permissions</strong> click{' '}
                <Link to='/UserPermissionMobile'>here</Link>.
              </p>
              <p>
                Another option would be to click{' '}
                <a class='mailto' href='mailto:info@vastreach.com'>
                  here
                </a>{' '}
                to <i>contact</i> support to transfer <i>your</i> account.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={CreateUserMobileImgE}
                  alt='blank mobile user creation page'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p> */}

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Remember that <i>you</i> can only create a limited amount of{' '}
                    <i>users</i> if <i>your</i> plan is a <strong>Base</strong>{' '}
                    or <strong>Premium</strong> plan.
                  </p>
                </li>
                <li>
                  <p>
                    Remember to check to see if the <i>user</i> <i>you</i>{' '}
                    invited has verified their status.
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Invite potential <i>users</i> who may not understand{' '}
                    <i>your</i> business needs.
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
