import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import GettingStartedNaviScrollSpy from './GettingStartedNaviScrollSpy.js';
import GettingStartedImgA from './img/log_in.jpg';
import GettingStartedImgB from './img/create_account.jpg';
import GettingStartedImgC from './img/confirmation_code.jpg';
import GettingStartedImgD from './img/create_account_link.png';
import video from './video/create_account_tutorial.mp4';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function GettingStarted() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <GettingStartedNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/GettingStarted'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/GettingStartedMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>GETTING STARTED</h1>
              <p className='overview'>
                This tutorial was created to guide a new customer on how to
                create a new account on a desktop computer or Laptop using a
                browser. By the end of this tutorial <i>you</i> should be able
                to sign-in and begin setting up <i>your</i> Vast Reach account.
              </p>
              <h2 className='log-in'>Signing into the Vast Reach Console</h2>
              <p>
                To begin <i>you</i> should visit the following link:
                <a
                  href='https://console.vastreach.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {' '}
                  https://console.vastreach.com/
                </a>
                . Once on this page the <i>you</i> will be prompted to sign-in
                to <i>your</i> account. If you already have an account enter{' '}
                <i>your</i> credentials and click <strong>SIGN IN</strong>.  If <i>you</i> don't have an
                account follow the instructions below.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={GettingStartedImgA}
                  alt='vast reach log in screen'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h3 className='account-create'>Create a New Account</h3>
                  <p>
                    <i>You</i> should see on the bottom-left of the sign-in
                    prompt the following text:{' '}
                    <strong>No Account? Create account</strong>. Click the
                    highlighted <strong>Create account</strong> link to
                    continue.
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    <img
                      src={GettingStartedImgD}
                      alt='vast reach create account link'
                      className='img-fluid'
                      style={{ maxWidth: '400px', width: '100%' }}
                    />
                  </p>
                  <p>
                    <i>You</i> will now see a new page with an account creation
                    form. Fill out the form and click the{' '}
                    <strong>CREATE ACCOUNT</strong> button at the bottom right
                    to proceed.
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    <img
                      src={GettingStartedImgB}
                      alt='vast reach account creation'
                      className='img-fluid'
                      style={{ maxWidth: '400px', width: '100%' }}
                    />
                  </p>
                  <p>
                    <i>You</i> will now be required to confirm <i>your</i> phone
                    number. A text message with a confirmation code will be sent
                    to the phone number you used to create <i>your</i> account
                    with. Enter <i>your</i> username and the confirmation code
                    in the fields below and press the <strong>CONFIRM</strong>{' '}
                    button at the bottom right.
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    <img
                      src={GettingStartedImgC}
                      alt='vast reach account confirmation code'
                      className='img-fluid'
                      style={{ maxWidth: '400px', width: '100%' }}
                    />
                  </p>
                </li>
              </ul>

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Use a strong password with over 8 characters using a
                    combination of capital and smaller case letters, symbols and
                    numbers.
                  </p>
                </li>
                <li>
                  <p>
                    Try to use a phone number from a cell provider for{' '}
                    <i>your</i> account over using a temporary or service number
                    for best results.
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Use the same email and password combinations that <i>you</i>{' '}
                    use elsewhere.
                  </p>
                </li>
                <li>
                  <p>
                    Share <i>your</i> log-in credentials with anyone.
                  </p>
                </li>
                <li>
                  <p>Use temporary or generated email services to sign-up.</p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>

              <h2 className='next-steps'>Next Steps</h2>
              <p>
                In order to use Vast Reach <i>you</i> need to enter <i>your</i>{' '}
                account and payment information. See the links below.
              </p>
              <p style={{ textAlign: 'center' }}>
                <Link to='/AccountSettings'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Account Settings
                  </button>
                </Link>
                <Link to='/Payment'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
