import React, { Component } from 'react';
export default class dealermeeting2021 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className='container-fluid padding-full'>
        <div className='container wrap'>
          <h1 className='pad-top'>Dealer Meeting 2021</h1>
          <h3>Become an Early Adopter</h3>
          <p>
            Become a Vast Reach early adopter! As an early adopter you have the
            opportunity to use new technology before it’s available to everyone
            else. We are inviting anyone who signs up this week to become a Vast
            Reach Early Adopter which includes a 90-day free trial and an
            opportunity to begin using Vast Reach to drive sales and
            communication with customers. You will also have an opportunity to
            provide valuable feedback to the entire Vast Reach team.
            <br /> <br />
            Signup for a breakout session for a demo of Vast Reach and to start
            your free 90-day trial!{' '}
          </p>
          <iframe
            title='book'
            src='https://koalendar.com/e/meet-with-wil-casillas?embed=true'
            width='100%'
            height='800px'
            frameborder='0'
          ></iframe>
          <h3>
            Don't See a time that works for you? Get in contact with Wil
            Casillas{' '}
          </h3>
          <a href='mailto: wcasillas@vasttechnologies.com'>
            wcasillas@vasttechnologies.com
          </a>
        </div>
      </div>
    );
  }
}
