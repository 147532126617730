import React from 'react';
import ReactPlayer from 'react-player';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../generalCss/style.scss';
import ContactCreate from './img/contact_create.png';
import ContactConversationStart from './img/conversation_start.jpg';
import ContactInfo from './img/contact_info.jpg';
import ContactActivity from './img/activity.jpg';
// import language from '../utils/vast-languagepack';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';

export default function ContactSetUp() {
  return (
    <div>
      <div className='container-fluid text-center page-banners banner-wrap'>
        <div className='container page-header-text'>
          <h1>IMPORT CONTACTS</h1>
        </div>
      </div>

      <div className='page-top-wrap'>
        <div className='container-fluid padding-top'>
          <div className='container wrap'>
            <div className='col-xs-12 text-center'>
              <img
                src={ContactCreate}
                alt='Contact Create Page'
                className='img-fluid center-block'
                style={{ maxWidth: '800px', width: '100%' }}
              ></img>
            </div>
          </div>
        </div>

        <div className='container-fluid padding-top'>
          <div className='container wrap'>
            <div className='col-xs-12'>
              <h2 className='text-center'>
                IMPORT AND CUSTOMIZE YOUR CONTACT PROFILES
              </h2>
            </div>
          </div>
        </div>

        <div className='container-fluid zero-padding'>
          <div className='row padding-full wrap'>
            <div className='col-xs-12 text-center col-md-6 order-md-2'>
              <img
                src={ContactConversationStart}
                alt='Task Selection Page'
                className='img-fluid center-block'
              />
            </div>
            <div className='col-xs-12 col-md-6 order-md-1 image-space'>
              <h2 className='text-margin'>
                <LabelImportantIcon
                  style={{
                    fontSize: '2.5rem',
                    color: '#006fd9',
                    margin: '-6px 5px 0 0',
                  }}
                />
                Start a Conversation
              </h2>
              <p className='text-margin'>
                You can begin a conversation or begin an email or text message
                task right from this contacts profile.
              </p>
            </div>
          </div>

          <div className='bg-2'>
            <div className='row padding-full wrap'>
              <div className='col-xs-12 text-center col-md-6'>
                <img
                  src={ContactInfo}
                  alt='Task Automation Page'
                  className='img-fluid center-block'
                />
              </div>
              <div className='col-xs-12 col-md-6 image-space'>
                <h2 className='text-margin'>
                  <LabelImportantIcon
                    style={{
                      fontSize: '2.5rem',
                      color: '#006fd9',
                      margin: '-6px 5px 0 0',
                    }}
                  />
                  Input Contact Information
                </h2>
                <p className='text-margin'>
                  You can add several emails or phone numbers to get in touch
                  with your contacts with a click of a button.
                </p>
              </div>
            </div>
          </div>

          <div className='row padding-full wrap'>
            <div className='col-xs-12 text-center col-md-6 order-md-2'>
              <ReactPlayer
                style={{ borderRadius: '10px' }}
                muted
                playing
                loop
                playsinline
                width='100%'
                height='100%'
                url={[
                  `${process.env.PUBLIC_URL}/video/groups.mp4`,
                  `${process.env.PUBLIC_URL}/video/groups.gif`,
                ]}
              />
            </div>
            <div className='col-xs-12 col-md-6 order-md-1 image-space'>
              <h2 className='text-margin'>
                <LabelImportantIcon
                  style={{
                    fontSize: '2.5rem',
                    color: '#006fd9',
                    margin: '-6px 5px 0 0',
                  }}
                />
                Add Contact To a Group
              </h2>
              <p className='text-margin'>
                Add this contact to a group to prepare this contact for your
                marketing goals.
              </p>
            </div>
          </div>

          <div className='bg-2'>
            <div className='row padding-full wrap'>
              <div className='col-xs-12 text-center col-md-6'>
                <ReactPlayer
                  style={{ borderRadius: '10px' }}
                  muted
                  playing
                  loop
                  playsinline
                  width='100%'
                  height='100%'
                  url={[
                    `${process.env.PUBLIC_URL}/video/custom_fields.mp4`,
                    `${process.env.PUBLIC_URL}/video/custom_fields.gif`,
                  ]}
                />
              </div>
              <div className='col-xs-12 col-md-6 image-space'>
                <h2 className='text-margin'>
                  <LabelImportantIcon
                    style={{
                      fontSize: '2.5rem',
                      color: '#006fd9',
                      margin: '-6px 5px 0 0',
                    }}
                  />
                  Select Custom Fields For This Contact
                </h2>
                <p className='text-margin'>
                  Don’t see a field you want to add for this contact? No
                  problem, with Vast Reach you can add your own Custom Fields to
                  this contact.
                </p>
              </div>
            </div>
          </div>

          <div className='row padding-top wrap'>
            <div className='col-xs-12 text-center col-md-6 order-md-2'>
              <img
                src={ContactActivity}
                alt='Email Task Builder'
                className='img-fluid center-block'
              />
            </div>
            <div className='col-xs-12 col-md-6 order-md-1 image-space'>
              <h2 className='text-margin'>
                <LabelImportantIcon
                  style={{
                    fontSize: '2.5rem',
                    color: '#006fd9',
                    margin: '-6px 5px 0 0',
                  }}
                />
                Track This Contacts Activity
              </h2>
              <p className='text-margin'>
                With Vast Reach you’ll never lose track of previous customer
                engagements. You can track this contacts history of engagement
                from previous marketing campaigns they’ve been to simple email
                and phone number updates.
              </p>
            </div>
          </div>

          <div className='container-fluid padding-top'>
            <div className='container wrap'>
              <div className='col-xs-12'>
                <h2 className='text-center'>
                  WANT TO SEE MORE? SCHEDULE A LIVE DEMO TODAY!
                </h2>
              </div>
            </div>
          </div>

          <div className='container-fluid padding-full '>
            <div className='col-xs-12 zero-padding wrap'>
              <iframe
                title='book'
                src='https://koalendar.com/e/meet-with-wil-casillas?embed=true'
                width='100%'
                height='800px'
                frameborder='0'
              ></iframe>
              <div className='col-xs-12 text-center'>
                <h3>
                  Don't See a time that works for you? Get in contact with Wil
                  Casillas{' '}
                </h3>
                <p>
                  <a href='mailto: wcasillas@vasttechnologies.com'>
                    wcasillas@vasttechnologies.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
