import React from 'react';
// import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import CustomFormsNaviScrollSpy from './CustomFormsMobileNaviScrollSpy.js';
import customFormsNavigation from '../SignUpFormsMobile/img/mobile_nav.jpg';
import customFormAddFormButton from '../SignUpForms/img/create_forms_button.png';
import customFormNextButtonMobile from './img/next_arrow.png';
import customFormCancelButtonMobile from './img/close_button.png';
import customFormsFirstStep from './img/form_step_mobile.png';
import customFormsBuildStep from './img/custom_form_build_step_mobile.png';
import customFormsOpenEndedQuestion from './img/custom_form_open_ended_question_mobile.png';
import customFormOpenTextQuestionAdvanced from '../CustomForms/img/open_ended_question_advanced_options.jpg';
import customFormOpenNumberQuestion from '../CustomForms/img/open_ended_question_number.jpg';
import customFormMultiChoiceQuestion from './img/custom_form_yes_no_question_mobile.png';
import customFormMultiChoiceQuestion2 from '../CustomForms/img/multi_choice_options_two.jpg';
import customFormMultiChoiceQuestion3 from '../CustomForms/img/multi_choice_all.jpg';
import customFormMultiChoiceQuestion3Next from '../CustomForms/img/multi_choice_all2.jpg';
import customFormSeparator from '../CustomForms/img/separator.jpg';
import customFormImageLibrary from './img/custom_form_image_library_mobile.png';
import customFormTitle from '../CustomForms/img/title.jpg';
import customFormCustomSection from '../CustomForms/img/custom_section.jpg';
import customFormPageBreak from '../CustomForms/img/page_break.jpg';
import customFormContactFields from '../CustomForms/img/contact_fields.jpg';
import customFormDescription from './img/custom_form_description.png';
import customFormFont from './img/custom_form_font.png';
import customFormBackground from './img/custom_form_background.png';
import customFormButton from './img/custom_form_button.png';
import customFormLogo from './img/custom_form_logo.png';
import customFormLandingPageRedirect from './img/landing_page_redirect.png';
import customFormLandingPageEditor from './img/editing_landing_page.png';
import customFormCreateButton from './img/mobile_form_save_button.png';
import customFormSharing from './img/custom_form_share_warning.png';
import customFormShare from '../SignUpForms/img/share_forms.jpg';
import customFormAnalyze from './img/custom_form_analyze.png';
import customFormViewByQuestionButton from '../CustomForms/img/view_by_question_button.png';
import customFormAnalyzeQuestion from './img/custom_forms_analyze_questions.png';
import customFormAnalyzeQuestion2 from './img/custom_forms_analyze_questions2.png';
import video from '../CustomForms/video/custom_form_tutorial.mp4';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function CustomFormsMobile() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <CustomFormsNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/CustomForms'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/CustomFormsMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>
                CREATE CUSTOM FORMS ON A MOBILE DEVICE
              </h1>
              <p className='custom-form-intro'>
                This tutorial will instruct <i>you</i> on how to create, set up
                and use custom forms on a desktop. Custom forms allow <i>you</i>{' '}
                to add sections such as custom fields, multiple choice
                questions, open ended questions and media content
                (images/files). Additionally, <i>you</i> can analyze the
                responses with extremely detailed statistical data such as
                graphs, sentiment analysis, percentages and numerical data.
                Custom forms can be used to gather more detailed responses from
                external sources such as <i>your</i> <i>contacts</i> or internal
                sources such as <i>your</i> users or employees.
              </p>

              <h3 className='prerequisites'>
                Prerequisites Before Creating a Custom Form
              </h3>
              <p>
                Before creating a <strong>Custom Form</strong>, <i>you</i> must
                first have a account set up with an active subscription. Setting
                up ReCaptcha for your forms to prevent spam is recommended.
                Click the <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Buttons</strong> below to set up anything <i>you're</i>{' '}
                missing before beginning this tutorial if <i>you</i> have not
                done so already.
              </p>
              <p>
                <Link to='/Payment'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
                <Link to='/ReCaptcha'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    ReCaptcha
                  </button>
                </Link>
              </p>
              <p>
                <i>Note:</i> Although not required to create a{' '}
                <strong>Custom From</strong>, <i>you</i> can send these forms
                directly to a <strong>Group</strong>. However, <i>you</i> need
                create <i>contacts</i> to add to a group. Click{' '}
                <Link to='/Contacts'>here</Link> to learn how to create a{' '}
                <i>contact</i> and click <Link to='/Groups'>here</Link> to learn
                how to create a group.
              </p>

              <h3 className='navigation'>Navigate to Custom Forms</h3>
              <p>
                <i>You</i> can create a custom form by clicking the{' '}
                <strong>Forms</strong> link in the navigation (logo at the top
                left).
              </p>
              <p style={{ textAlign: 'center', margin: '15px 0 15px 0' }}>
                <img
                  src={customFormsNavigation}
                  alt='form navigation'
                  className='img-fluid'
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    border: '1px solid #dddddd',
                  }}
                />
              </p>

              <h2 className='create-form'>Create a New Custom Form</h2>
              <p>
                To begin <i>you</i> can click the <strong>ADD FORM</strong>{' '}
                button at the top right of the form list to begin.
                <p style={{ margin: '10px 0 0 0' }}>
                  <img
                    src={customFormAddFormButton}
                    alt='create form button'
                    className='img-fluid'
                    style={{ maxWidth: '150px', width: '100%' }}
                  />
                </p>
                <i>You</i> will be taken to a step by step process for creating
                forms. On step one the <i>you</i> can enter a{' '}
                <strong>Form Name</strong>, select a form type and optionally
                add a <i>contact</i> group automation to the form (
                <Link to='/Groups'> Click here </Link> to learn more about
                groups). In our case, for the purposes of the tutorial we will
                select <strong>CUSTOM FORM</strong> and click the{' '}
                <strong>Arrow</strong> button.
              </p>
              <p style={{ margin: '0 0 10px 0' }}>
                <img
                  src={customFormNextButtonMobile}
                  alt='next button'
                  className='img-fluid'
                  style={{ maxWidth: '40px', width: '100%' }}
                />
              </p>
              <p>
                <i>Note:</i> If <i>you</i> click the <strong>X</strong> button
                at the top left, <i>you</i> will return to the form list.
              </p>
              <p style={{ margin: '0 0 10px 0' }}>
                <img
                  src={customFormCancelButtonMobile}
                  alt='next button'
                  className='img-fluid'
                  style={{ maxWidth: '40px', width: '100%' }}
                />
              </p>

              <p style={{ textAlign: 'center', margin: '10px 0 10px 0' }}>
                <img
                  src={customFormsFirstStep}
                  alt='form step'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>

              <h2 className='build'>Building a Custom Form</h2>
              <p>
                <i>You</i> will now be taken to the <strong>Build</strong> step
                to begin creating <i>your</i> from.
                <p style={{ textAlign: 'center', margin: '10px 0 10px 0' }}>
                  <img
                    src={customFormsBuildStep}
                    alt='form build open ended question'
                    className='img-fluid'
                    style={{ maxWidth: '400px', width: '100%' }}
                  />
                </p>
                There are several tabs below, the first one is the{' '}
                <strong>Preview</strong> tab that shows you a preview of the
                form <i>you’re</i> building. At the bottom of the preview, there
                is a <strong>+ ADD SECTION</strong> text button. Clicking this
                button will present a pop-up that allows <i>you</i> to add and
                customize sections to this form. Below we will go over each
                section.
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h3 className='form_section'>
                    Create an Open-ended Question
                  </h3>
                  <p>
                    You can create an open ended question with a{' '}
                    <strong>Text</strong> response or a <strong>number</strong>{' '}
                    response. With the <strong>Text</strong> response its simple
                    a matter of entering the question <i>you</i> want to ask
                    with an option to add a description to give further details
                    about this question. .
                  </p>
                  <p style={{ textAlign: 'center', margin: '10px 0 10px 0' }}>
                    <img
                      src={customFormsOpenEndedQuestion}
                      alt='form build open ended question'
                      className='img-fluid'
                      style={{ maxWidth: '400px', width: '100%' }}
                    />
                  </p>
                  <p>
                    Advanced options allows <i>you</i> to set a max character
                    limit for the <strong>Text</strong> response.
                  </p>
                  <p style={{ margin: '10px 0 10px 0' }}>
                    <img
                      src={customFormOpenTextQuestionAdvanced}
                      alt='form select'
                      className='img-fluid'
                      style={{
                        maxWidth: '500px',
                        width: '100%',
                        border: '1px solid #000000',
                      }}
                    />
                  </p>
                  <p>
                    With the <strong>number</strong> response there is the
                    question and the description like the <strong>Text</strong>{' '}
                    response, but in addition to that <i>you</i> can fine tune
                    the <strong>Decimal Place</strong>, the{' '}
                    <strong>Max Value</strong>, and <strong>Min Value</strong>{' '}
                    of the <i>contacts</i> response.{' '}
                  </p>
                  <p style={{ margin: '10px 0 10px 0' }}>
                    <img
                      src={customFormOpenNumberQuestion}
                      alt='form select'
                      className='img-fluid'
                      style={{
                        maxWidth: '500px',
                        width: '100%',
                        border: '1px solid #000000',
                      }}
                    />
                  </p>
                </li>
                <li>
                  <h3 className='form_section'>
                    Create a Multi-Choice Question
                  </h3>
                  <p>
                    With multi-choice questions <i>you</i> have 3 options to
                    choose from.{' '}
                  </p>
                  <ol>
                    <li>
                      <p>
                        The first is <strong>Yes/No</strong> option. This option
                        allows a <i>contact</i> to choose yes or no to the
                        question <i>you</i> ask them.
                      </p>
                      <p
                        style={{ textAlign: 'center', margin: '10px 0 10px 0' }}
                      >
                        <img
                          src={customFormMultiChoiceQuestion}
                          alt='form build multi-choice yes/no question'
                          className='img-fluid'
                          style={{ maxWidth: '400px', width: '100%' }}
                        />
                      </p>
                    </li>
                    <li>
                      <p>
                        The second is{' '}
                        <strong>Choose one of a few options</strong>. This one
                        allows a <i>contact</i> to choose from several answers{' '}
                        <i>you</i> input for the <i>contact</i> to choose from.
                      </p>
                      <p style={{ margin: '10px 0 10px 0' }}>
                        <img
                          src={customFormMultiChoiceQuestion2}
                          alt='form select'
                          className='img-fluid'
                          style={{
                            maxWidth: '500px',
                            width: '100%',
                            border: '1px solid #000000',
                          }}
                        />
                      </p>
                    </li>
                    <li>
                      <p>
                        The last is <strong>Choose multiple options</strong>.
                        This option allows a <i>contact</i> to select multiple
                        answers <i>you</i> input for the <i>contact</i> choose
                        from.{' '}
                      </p>
                      <p style={{ margin: '10px 0 10px 0' }}>
                        <img
                          src={customFormMultiChoiceQuestion3Next}
                          alt='form select'
                          className='img-fluid'
                          style={{
                            maxWidth: '400px',
                            width: '100%',
                            border: '1px solid #000000',
                          }}
                        />
                      </p>
                      <p style={{ margin: '10px 0 10px 0' }}>
                        <img
                          src={customFormMultiChoiceQuestion3}
                          alt='form select'
                          className='img-fluid'
                          style={{
                            maxWidth: '500px',
                            width: '100%',
                            border: '1px solid #000000',
                          }}
                        />
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <h3 className='form_section'>Add separators</h3>
                  <p>
                    A separator allows <i>you</i> to sections parts of the form.
                  </p>
                  <p style={{ margin: '10px 0 10px 0' }}>
                    <img
                      src={customFormSeparator}
                      alt='form separator'
                      className='img-fluid'
                      style={{ maxWidth: '500px', width: '100%' }}
                    />
                  </p>
                </li>
                <li>
                  <h3 className='form_section'>Add an Image file</h3>
                  <p>
                    <i>You</i> can add an image file using the{' '}
                    <strong>Vast</strong> image library built in to the custom
                    forms.
                  </p>
                  <p style={{ textAlign: 'center', margin: '10px 0 10px 0' }}>
                    <img
                      src={customFormImageLibrary}
                      alt='form media library'
                      className='img-fluid'
                      style={{ maxWidth: '500px', width: '100%' }}
                    />
                  </p>
                </li>
                <li>
                  <h3 className='form_section'>Create a Title</h3>
                  <p>
                    Similarly to the form title, <i>you</i> can add titles
                    anywhere else on the form.
                  </p>
                  <p style={{ margin: '10px 0 10px 0' }}>
                    <img
                      src={customFormTitle}
                      alt='form title'
                      className='img-fluid'
                      style={{ maxWidth: '500px', width: '100%' }}
                    />
                  </p>
                </li>
                <li>
                  <h3 className='form_section'>Make a Custom Section</h3>
                  <p>
                    Custom Sections allow <i>you</i> to edit and create anything{' '}
                    <i>you</i> could make using our WYSIWYG editor.
                    Additionally, the editor has the capability to input custom
                    HTML if <i>you</i> want to take the customization even
                    further.{' '}
                  </p>
                  <p style={{ margin: '10px 0 10px 0' }}>
                    <img
                      src={customFormCustomSection}
                      alt='form custom selection'
                      className='img-fluid'
                      style={{ maxWidth: '500px', width: '100%' }}
                    />
                  </p>
                </li>
                <li>
                  <h3 className='form_section'>
                    Add a Page Break to Long Forms
                  </h3>
                  <p>
                    Page Breaks allow <i>you</i> to break apart a long form into
                    multiple pages so <i>your</i> contacts don’t have to
                    endlessly scroll through a single page. Page Breaks could
                    also be useful in separating category’s and topics.
                  </p>
                  <p style={{ margin: '10px 0 10px 0' }}>
                    <img
                      src={customFormPageBreak}
                      alt='form page break'
                      className='img-fluid'
                      style={{ maxWidth: '500px', width: '100%' }}
                    />
                  </p>
                </li>
                <li>
                  <h3 className='form_section'>Contact Fields</h3>
                  <p>
                    A <i>contact</i> field asks the <i>contact</i> filling out
                    the form their personal information such as email, phone
                    numbers, name, etc. much like the{' '}
                    <strong>Sign-up Form</strong>.{' '}
                  </p>
                  <p style={{ margin: '10px 0 10px 0' }}>
                    <img
                      src={customFormContactFields}
                      alt='form custom fields'
                      className='img-fluid'
                      style={{ maxWidth: '500px', width: '100%' }}
                    />
                  </p>
                </li>
              </ul>
              <p>
                <i>Note:</i> If a field is either <strong>hidden</strong> or{' '}
                <strong>read</strong> only, a <i>contact</i> will not be able to
                fill out this field manually. Some coding will be needed.
              </p>
              <p>
                <i>Note:</i> The <strong>Required</strong> check box will make
                any section with it checked on mandatory for a <i>contact</i> to
                fill out in order to complete that form.{' '}
              </p>
              <p></p>
              <p>
                The next tab below is called <strong> Look & Feel</strong>. In
                this tab there are several sections (Form, Look and Feel,
                Fields, Font, Background and Button). This tutorial will explain
                the functions of each customization feature.
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h3 className='look_and_feel_section'>
                    Design The Look and Feel of Your Forms
                  </h3>
                  <p>
                    This tab is where all of the style and design elements of
                    this form is selected. At the top of this section there are
                    several text tabs, each of these tabs allow <i>you</i> to
                    style a particular piece of this from. By default <i>you</i>{' '}
                    will begin with the <strong>Description</strong> tab.
                  </p>
                  <ul style={{ listStyle: 'none' }}>
                    <li>
                      <h4>Description </h4>
                      <p>
                        On this tab <i>you</i> can select the type of embedding
                        on this form for <i>your</i> web page, enter the title
                        of the form and select its color and lastly add a
                        description of this form and also select the description
                        color. When this tab is completed click the{' '}
                        <strong>Font</strong> tab to continue.
                      </p>
                      <p
                        style={{ textAlign: 'center', margin: '10px 0 10px 0' }}
                      >
                        <img
                          src={customFormDescription}
                          alt='look and feel container'
                          className='img-fluid'
                          style={{ maxWidth: '400px', width: '100%' }}
                        />
                      </p>
                    </li>
                    <li>
                      <h4>Font </h4>
                      <p>
                        The Font tab has a font selector and a font color
                        selector. The list allows <i>you</i> to select from a
                        verity of web safe fonts. The color selector allows{' '}
                        <i>you</i> to select the color for the font that will be
                        displayed on this entire form (this does not apply to
                        the title, description text or the button text).
                      </p>
                      <p
                        style={{ textAlign: 'center', margin: '10px 0 10px 0' }}
                      >
                        <img
                          src={customFormFont}
                          alt='font container'
                          className='img-fluid'
                          style={{ maxWidth: '400px', width: '100%' }}
                        />
                      </p>
                    </li>
                    <li>
                      <h4>Background </h4>
                      <p>
                        This tab allows the <i>you</i> to change this forms
                        background color.
                      </p>
                      <p
                        style={{ textAlign: 'center', margin: '10px 0 10px 0' }}
                      >
                        <img
                          src={customFormBackground}
                          alt='background container'
                          className='img-fluid'
                          style={{ maxWidth: '400px', width: '100%' }}
                        />
                      </p>
                    </li>
                    <li>
                      <h4>Button </h4>
                      <p>
                        The button tab begins with a text input field, enter the
                        text that will be be shown on the button of this form
                        (example: Send, Subscribe, etc.). The first color
                        selector below the text input field will allow{' '}
                        <i>you</i> to change the text color while the second one
                        allows <i>you</i> to change the button background color.
                      </p>
                      <p
                        style={{ textAlign: 'center', margin: '10px 0 10px 0' }}
                      >
                        <img
                          src={customFormButton}
                          alt='button container'
                          className='img-fluid'
                          style={{ maxWidth: '400px', width: '100%' }}
                        />
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>

              <h3 className='logo_section'>Add Your Logo to Your Forms</h3>
              <p>
                The third tab at the bottom is the <strong>Logo</strong> tab. In
                this tab <i>you</i> can add <i>your</i> logo to <i>your</i>{' '}
                forms by using a direct link to the image or clicking the{' '}
                <strong>BROWSE</strong> button and upload it from <i>your</i>{' '}
                mobile device.
              </p>
              <p style={{ margin: '0 0 10px 0' }}>
                <img
                  src={customFormLogo}
                  alt='logo container'
                  className='img-fluid'
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <h3 className='landing_page'>
                Redirect or Create a Landing Page
              </h3>
              <p>
                The final tab at the bottom is called{' '}
                <strog>Landing Page</strog>. By default this tab lets <i>you</i>{' '}
                redirect a <i>contact</i> to a landing page of <i>your</i>{' '}
                choosing after the <i>contact</i> has finished filling out a
                custom form. <i>You</i> can also switch the radio button at the
                top of this section from{' '}
                <strong>Redirect to another URL</strong> to{' '}
                <strong>Customize Landing Page</strong> and create a landing
                page using our WYSIWYG editor.
              </p>
              <p style={{ margin: '0 0 10px 0' }}>
                <img
                  src={customFormLandingPageRedirect}
                  alt='logo container'
                  className='img-fluid'
                  style={{
                    maxWidth: '600px',
                    width: '100%',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <p style={{ margin: '0 0 10px 0' }}>
                <img
                  src={customFormLandingPageEditor}
                  alt='logo container'
                  className='img-fluid'
                  style={{
                    maxWidth: '600px',
                    width: '100%',
                    border: '1px solid #000000',
                  }}
                />
              </p>

              <p>
                To complete creating a form <i>you</i> can click the{' '}
                <strong style={{ color: '#22b94b' }}>Green</strong>{' '}
                <strong>Floppy Disk</strong> button on the top right of this
                step.
                <i>You</i> will then be taken back to the form list view.
              </p>
              <p style={{ margin: '10px 0 10px 0' }}>
                <img
                  src={customFormCreateButton}
                  alt='group select'
                  className='img-fluid'
                  style={{ maxWidth: '40px', width: '100%' }}
                />
              </p>
              <h2 className='analyze' style={{ margin: '20px 0 10px 0' }}>
                Share Your Forms
              </h2>
              <p style={{ textAlign: 'center', margin: '10px 0 10px 0' }}>
                <img
                  src={customFormSharing}
                  alt='recaptcha missing warning'
                  className='img-fluid'
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    border: '1px solid #dddddd',
                  }}
                />
              </p>
              <p>
                Clicking the <strong>Share</strong> icon allows <i>you</i> to
                embed this form to <i>your</i> website or share a direct link to
                this form. There are 2 fields, the top field is for embedding
                and the bottom is for linking. <i>You</i> can click the{' '}
                <strong>COPY</strong> button of the desired option and it will
                automatically be added to <i>your</i> clipboard to paste.
              </p>
              <p>
                <img
                  src={customFormShare}
                  alt='form sharing'
                  className='img-fluid'
                  style={{
                    maxWidth: '600px',
                    width: '100%',
                    border: '1px solid #dddddd',
                  }}
                />
                <p>
                  <Link to='/ReCaptcha'>Click here</Link> to learn about how to
                  set-up ReCaptcha.
                </p>
              </p>

              <h2 className='responses' style={{ margin: '20px 0 10px 0' }}>
                Analyze Your Form Responses and Statistics
              </h2>
              <p>
                Selecting the <strong>Analyze</strong> icon lets <i>you</i> see
                the responses from the <i>contacts</i> that filed out the form.
                The aggregate tiles at to top shows <i>you</i> the total form
                responses and the number of <i>contacts</i> that visited the
                form.
              </p>
              <p style={{ textAlign: 'center', margin: '10px 0 10px 0' }}>
                <img
                  src={customFormAnalyze}
                  alt='form responses'
                  className='img-fluid'
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    border: '1px solid #dddddd',
                  }}
                />
              </p>

              <p>
                At the top left of the aggregate tiles, there is a{' '}
                <strong>View by Questions</strong> button.
              </p>
              <p>
                <img
                  src={customFormViewByQuestionButton}
                  alt='form responses'
                  className='img-fluid'
                  style={{
                    maxWidth: '200px',
                    width: '100%',
                  }}
                />
              </p>
              <p>
                Clicking this button will switch over to detailed statistical
                data on each question, including graphs, sentiment analysis,
                percentages and numerical statistics.{' '}
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={customFormAnalyzeQuestion}
                  alt='form responses'
                  className='img-fluid'
                  style={{
                    maxWidth: '350px',
                    width: '100%',
                    border: '1px solid #dddddd',
                    margin: '5px',
                  }}
                />
                <img
                  src={customFormAnalyzeQuestion2}
                  alt='form responses'
                  className='img-fluid'
                  style={{
                    maxWidth: '350px',
                    width: '100%',
                    border: '1px solid #dddddd',
                    margin: '5px',
                  }}
                />
              </p>
              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Remember to set up a <strong>Landing Page</strong> or
                    redirect for <i>your</i> forms.
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Forget all <strong>Custom Forms</strong> require a{' '}
                    <strong>Form Name</strong>, <strong>Title</strong>,{' '}
                    <strong>Description</strong> and{' '}
                    <strong>Contact Group</strong> to be created/updated.
                  </p>
                </li>
                <li>
                  <p>
                    Forget to use <strong>Page Breaks</strong> to avoid having a
                    long scrolling form.
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <h3>Creating a Custom Form</h3>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
