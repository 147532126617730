import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import PaymentMobileNaviScrollSpy from './PaymentMobileNaviScrollSpy.js';
import PaymentMobileImgA from './img/payment_plan1.png';
import PaymentMobileImgB from './img/payment_plan2.png';
import PaymentMobileImgC from './img/payment_plan3.png';
import PaymentMobileImgD from './img/payment_plan4.png';
import PaymentMobileImgE from './img/payment_plan5.png';
import PaymentMobileImgF from './img/payment_plan6.png';
import AccountSettingsMobileC from '../AccountSettingsMobile/img/mobile_nav_account.jpg';
import video from '../Payment/video/payment_tutorial.mp4';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function PaymentMobile() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <PaymentMobileNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/Payment'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/PaymentMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>
                PAYMENT SET-UP FROM A MOBILE DEVICE
              </h1>
              <p className='payment-intro'>
                After going through this tutorial <i>you</i> should have full
                understanding on how to setup, maintain and update <i>your</i>{' '}
                payment information as well as select or modify <i>your</i>{' '}
                plan.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Setting Up Payment
              </h3>
              <p>
                Before setting up payment <i>you</i> must have an account
                registered with <strong>Vast Reach</strong> and <i>you</i>
                must be an account owner. To learn more click the{' '}
                <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Button</strong> below.
              </p>
              <p>
                <Link to='/GettingStartedMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Getting Started
                  </button>
                </Link>
              </p>
              <h3 className='navigate'>Navigate to Payment Settings</h3>
              <p>
                <i>You</i> can manage <i>your</i> payment plans by visiting the{' '}
                <strong>Account Preferences & Settings</strong> page by clicking
                in the navigation (Logo Icon at the top left).
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={AccountSettingsMobileC}
                  alt='account settings navigation'
                  className='img-fluid'
                  style={{ maxWidth: '375px', width: '100%' }}
                />
              </p>
              <h3 className='new-users'>
                Setting Up Payment For Your New Account
              </h3>
              <p>
                Once on <strong>Account Preferences & Settings</strong> page,
                click the <strong>$ PAYMENT</strong> button at the top of the
                page. <i>You</i> will be taken to a new page with <i>your</i>{' '}
                payment information. At the top of the page there is a
                step-by-step navigation, by default <i>you</i> will start at{' '}
                <strong>Payment Information</strong> with the <i>your</i>{' '}
                payment card of choice. If <i>your</i> account is a new account
                then this step of the page will be presented with inputs for{' '}
                <i>your</i> credit card payment information.
              </p>
              <h3 className='existing-users'>
                Manage Your Payments For Your Existing Account
              </h3>
              <p>
                Existing paying account owners can add another way of paying by
                clicking <strong>Choose another way to pay</strong> below their
                current card. This will prompt the credit card payment
                information once again to add the new card information. Once an
                owner has competed this step press <strong>NEXT</strong> or
                click <strong>Enter your business address</strong> at the top
                step-by-step navigation.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={PaymentMobileImgA}
                  alt='payment information page'
                  className='img-fluid'
                  style={{
                    maxWidth: '275px',
                    width: '100%',
                    padding: '10px',
                  }}
                />
                <img
                  src={PaymentMobileImgB}
                  alt='payment card selection'
                  className='img-fluid'
                  style={{
                    maxWidth: '275px',
                    width: '100%',
                    padding: '10px',
                  }}
                />
                <img
                  src={PaymentMobileImgC}
                  alt='adding new payment credit card'
                  className='img-fluid'
                  style={{
                    maxWidth: '275px',
                    width: '100%',
                    padding: '10px',
                  }}
                />
              </p>
              <h2 className='business-address'>Add Your Business Address</h2>
              <p>
                On this step <i>you</i> need to add <i>your</i> business address
                then click the{' '}
                <strong style={{ color: 'rgb(21, 185, 64)' }}>Green</strong>{' '}
                <strong>Button</strong> labeled as{' '}
                <strong>SET BUSINESS ADDRESS</strong> below. Once <i>you</i>{' '}
                have competed this step click the <strong>NEXT</strong> button
                or click <strong>Choose A Plan</strong> at the top step-by-step
                navigation.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={PaymentMobileImgD}
                  alt='business address for payment'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>
              <h2 className='plans'>Select Your Plan</h2>
              <p>
                <i>You</i> will now be able select a plan on this step of the
                modal. Currently there are 3 available plans.
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h3 className='basic'>Basic Plan</h3>
                  <p>
                    Plan includes 100,000 emails a month, 45GB of public cloud
                    storage, 2 <i>users</i>, 2 phone numbers, 1000 text messages
                    a month, 500 minutes (VOIP calls) and UNLIMITED{' '}
                    <i>contacts</i>.
                  </p>
                </li>
                <li>
                  <h3 className='premium'>Premium Plan</h3>
                  <p>
                    Plan includes 250,000 emails a month, 100GB of storage, 10{' '}
                    <i>users</i>, 10 phone numbers, 2000 text messages a month,
                    1000 minutes (VOIP calls), UNLIMITED <i>contacts</i>.
                  </p>
                </li>
                <li>
                  <h3 className='ultimate'>Ultimate Plan</h3>
                  <p>
                    Plan includes 1,000,000 emails a month, 1TB of storage,
                    UNLIMITED <i>users</i>, 100 phone numbers, 5000 text
                    messages a month, 10,000 minutes (VOIP calls), UNLIMITED{' '}
                    <i>contacts</i>.
                  </p>
                </li>
              </ul>
              <p>
                <i>You</i> may select the plan that suits <i>your</i> business
                needs, while existing customers may switch their plan to
                accommodate their growing business! Once <i>you</i> have
                competed this step <i>you</i> have effectively completed
                selecting <i>your</i> plan and payment. Clicking the{' '}
                <strong>NEXT</strong> button or clicking{' '}
                <strong>Subscription Details</strong> at the top step-by-step
                navigation will show <i>you</i> more details about <i>your</i>{' '}
                payment plan.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={PaymentMobileImgE}
                  alt='payment plan option'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>
              <h2 className='sub-details'>Review Your Subscription Details</h2>
              <p>
                On the <strong>Subscription Details</strong> step <i>you</i>{' '}
                will be shown <i>your</i> plan, balance, <i>your</i> next bill
                date and the amount due on that date.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={PaymentMobileImgF}
                  alt='payment plan information'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Make sure <i>you</i> select the appropriate subscription
                    plan for <i>your</i> business.
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Forget to check the expiration date on <i>your</i> credit
                    card before using it.
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
