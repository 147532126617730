import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class PrivacyPolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
        <div className='container-fluid padding-full'>
          <div className='container wrap'>
            <h1 className='pad-top'>Privacy Policy for Vast Reach</h1>

            <span id='docs-internal-guid-9609d535-7fff-4aeb-f5f8-867162ba329b'>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '0pt',
                  marginBottom: '2pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  A. About Us
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Vast Reach is an online marketing platform and multi-channel
                  communication inbox operated by Vast Technologies Inc., a
                  company headquartered in the State of Pennsylvania, in the
                  United States.&nbsp; Throughout this document&nbsp; Vast Reach
                  can be referred to as: "we," "us," "our," and "Vast Reach."
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Our Service enables our Customers to, among other things,
                  create and manage campaigns to collect leads, send marketing
                  communication, create signup forms, create
                  surveys/questionnaires, send text messages, manage public
                  cloud storage and send emails.&nbsp; Find out more about our
                  services{' '}
                </span>
                <a
                  href='https://vastreach.com/features'
                  style={{ textDecorationLine: 'none' }}
                >
                  on the features page
                </a>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  .
                </span>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                  <br />
                </span>
              </p>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '2pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  B. Key Terms
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  In this privacy policy, these terms have the following
                  meanings:
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  "Contact" is a person a Customer may contact through our
                  Service. In other words, a Contact is anyone on a Customer's
                  Contact Group or about whom a Customer has given us
                  information.&nbsp; For example, if you are a Customer, a
                  subscriber to your email marketing campaigns would be
                  considered a Contact.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  "Control" means an ownership, voting or similar interest
                  representing fifty percent (50%) or more of the total
                  interests then outstanding of the entity in question. The term
                  "Controlled" shall be construed accordingly.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  "Contact Group" is a list of Contacts a Customer may upload or
                  manage on our platform and all associated information related
                  to those Contacts (for example, email addresses).
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  "Vast Reach Console(s)" has the meaning given to it in our{' '}
                </span>
                <Link to='/TermsOfService'>Terms Of Service</Link>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  .
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  "Customer" means any person or entity that is registered with
                  us to use the Service.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  “Mobile App(s)” means any one or all of the Vast Reach
                  applications available for Customers to use on their mobile
                  devices.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  "Personal Information" means any information that identifies
                  or can be used to identify an individual directly or
                  indirectly. Examples of Personal Information include, but are
                  not limited to, first and last name, date of birth, email
                  address, gender, occupation, or other demographic information.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  “Service” has the meaning given to it in our{' '}
                </span>
                <Link to='/TermsOfService'>Terms Of Service</Link>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  .
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  "Visitor" means, depending on the context, any person who
                  visits any of our Vast Reach Consoles, offices, or otherwise
                  engages with us at our events or in connection with our
                  marketing or recruitment activities.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  "you" and "your" means, depending on the context, either a
                  Customer, a Contact, or a Visitor.
                </span>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                  <br />
                </span>
              </p>
              <h1
                dir='ltr'
                style={{
                  lineHeight: '1.32',
                  marginTop: '0pt',
                  marginBottom: '4pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '20pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  2. Privacy for Customers
                </span>
              </h1>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  This section applies to the Personal Information we collect
                  and process from a Customer or potential Customer through the
                  provision of the Service. If you are not a Customer, the{' '}
                </span>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontWeight: 700,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Visitors{' '}
                </span>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  or{' '}
                </span>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontWeight: 700,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Contacts{' '}
                </span>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  section of this policy may be more applicable to you and your
                  data. In this section, "you" and "your" refer to Customers and
                  potential Customers.
                </span>
              </p>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '2pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  A. Information We Collect
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  The Personal Information that we collect depends on the
                  context of your interactions with Vast Reach, your Vast Reach
                  account settings.&nbsp; The Personal Information we collect
                  broadly falls into the following categories:
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  (i) Information you provide to us: You (or your organization)
                  may provide certain Personal Information to us when you sign
                  up for a Vast Reach account and use the Service, consult with
                  our customer service team, send us an email, integrate the
                  Service with another website or service (for example, when you
                  choose to connect your e-commerce account with Vast Reach), or
                  communicate with us in any other way.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  This information may include:
                </span>
              </p>
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '8pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Business contact information (such as your name, job
                      title, organization, location, phone number, email
                      address, and country);
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Marketing information (such as your contact preferences);
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Account log-in credentials (Your email address or username
                      and password when you sign up for an account with us);
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Troubleshooting and support data (which is data you
                      provide or we otherwise collect in connection with support
                      queries we receive from you. This may include contact or
                      authentication data, the content of your chats and other
                      communications with us, and the product or service you are
                      using related to your help inquiry); and
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Payment information (including your credit card numbers
                      and associated identifiers and billing address) is stored
                      securely on Braintree's servers. See their privacy policy{' '}
                      <a href='https://www.braintreepayments.com/legal/braintree-privacy-policy'>
                        here
                      </a>
                    </span>
                  </p>
                </li>
              </ul>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  (ii) Information we collect automatically: When you use the
                  Service, we may automatically collect or receive certain
                  information about your device and usage of the Service
                  (collectively “Service Usage Data”). In some (but not all)
                  countries, including countries in the European Economic Area
                  (“EEA”), this information is considered Personal Information
                  under applicable data protection laws. We use cookies and
                  other tracking technologies to collect some of this
                  information. If you are using our Mobile App, we may collect
                  this information using our software development kits (“SDKs”)
                  or APIs the first time the SDK or API is initiated on your
                  Mobile App. For further information, please review the section
                  below and our Cookie Statement available{' '}
                </span>
                <Link to='/CookiePolicy'>here</Link>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  .
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Service Usage Data may include:
                </span>
              </p>
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '8pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Device information: We collect information about the
                      device and applications you use to access the Service,
                      such as your IP address, your operating system, your
                      browser ID, and other information about your system and
                      connection. If you are using our Mobile App, we may also
                      collect information about the cellular network associated
                      with your mobile device, your mobile device’s operating
                      system or platform, the type of mobile device you use,
                      your mobile device’s name and unique device ID, and
                      information about the features of our Mobile App that you
                      accessed.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Log data: Our web servers keep log files that record data
                      each time a device accesses those servers and the nature
                      of each access, including originating IP addresses and
                      your activity in the Service (such as the date/time stamps
                      associated with your usage, pages and files viewed,
                      searches and other actions you take (for example, which
                      features you used)), device event information (such as
                      system activity, error reports (sometimes called ‘crash
                      dumps’)), and hardware settings. We may also access
                      metadata and other information associated with files that
                      you upload into our Service.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Usage data: We collect usage data about you whenever you
                      interact with our Service, which may include the dates and
                      times you access the Service and your browsing activities
                      (such as what portions of the Service you used). We also
                      collect information regarding the performance of the
                      Service, including metrics related to the deliverability
                      of emails and other communications you send through the
                      Service. If you are using our Mobile App, we may collect
                      information about how often you use the Mobile App and
                      other performance data. This information allows us to
                      improve the content and operation of the Service, and
                      facilitate research and analysis of the Service.
                    </span>
                  </p>
                </li>
              </ul>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  (iii) Information we collect from other sources: From time to
                  time, we may obtain information about you from third-party
                  sources, such as public databases, social media platforms,
                  third-party data providers, and our joint marketing partners.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Examples of the information we receive from other sources
                  include demographic information (such as age and gender),
                  device information (such as IP addresses), location (such as
                  city and state), and online behavioral data (such as
                  information about your use of social media websites, page view
                  information and search results and links). We use this
                  information, alone or in combination with other Personal
                  Information we collect, to enhance our ability to provide
                  relevant marketing and content to you and to develop and
                  provide you with more relevant products, features, and
                  service.
                </span>
              </p>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '2pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  B. Use of Personal Information
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  We may use the Personal Information we collect or receive
                  through the Service (alone or in combination with other data
                  we source) for the purposes and on the legal bases identified
                  below:
                </span>
              </p>
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '8pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To bill and collect money owed to us by you to perform our
                      contract with you for the use of the Service or where we
                      have not entered into a contract with you, in accordance
                      with our legitimate interests to operate and administer
                      our Service. This includes sending you emails, invoices,
                      receipts, notices of delinquency, and alerting you if we
                      need a different credit card number. We use third parties
                      for secure credit card transaction processing, and those
                      third parties collect billing information to process your
                      orders and credit card payments. To learn more about the
                      steps we take to safeguard that data, see the "Our
                      Security" section of this privacy policy.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To send you system alert messages in reliance on our
                      legitimate interests in administering the Service and
                      providing certain features. For example, we may inform you
                      about temporary or permanent changes to our Service, such
                      as planned outages, or send you account, security or
                      compliance notifications, such as new features, version
                      updates, releases, abuse warnings, and changes to this
                      privacy policy.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To communicate with you about your account and provide
                      customer support to perform our contract with you for the
                      use of the Service or where we have not entered into a
                      contract with you, in reliance on our legitimate interests
                      in administering and supporting our Service. For example,
                      if you use our Mobile Apps, we may ask you if you want to
                      receive push notifications about activity in your account.
                      If you have opted in to these push notifications and no
                      longer want to receive them, you may turn them off through
                      your operating system.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To enforce compliance with our Standard Terms of Use and
                      applicable law, and to protect the rights and safety of
                      our Customers in reliance on our legitimate interest to
                      protect against misuse or abuse of our Service and to
                      pursue remedies available. This may include developing
                      tools and algorithms that help us prevent violations. For
                      example, sometimes we review the content our Customers
                      send or display to ensure it complies with our Standard
                      Terms of Use. To improve that process, we have software
                      that helps us find content that may violate our Standard
                      Terms of Use. We may or our third-party service provider
                      may also review content that our Customers send or
                      display. This benefits all Customers who comply with our
                      Standard Terms of Use because it reduces abuse and helps
                      us maintain a reliable platform. Please do not use Vast
                      Reach to send or display confidential information.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To meet legal requirements, including complying with court
                      orders, valid discovery requests, valid subpoenas, and
                      other appropriate legal mechanisms.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To provide information to representatives and advisors,
                      including attorneys and accountants, to help us comply
                      with legal, accounting, or security requirements in
                      reliance on our legitimate interests.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To prosecute and defend a court, arbitration, or similar
                      legal proceeding.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To respond to lawful requests by public authorities,
                      including to meet national security or law enforcement
                      requirements.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To provide, support and improve the Service to perform our
                      contract with you for the use of the Service or where we
                      have not entered into a contract with you, in reliance on
                      our legitimate interests in administering and improving
                      the Service and providing certain features. For example,
                      this may include sharing your information with third
                      parties in order to provide and support our Service or to
                      make certain features of the Service available to you.
                      When we share your Personal Information with third
                      parties, we take steps to protect your information in a
                      manner that is consistent with our obligations under
                      applicable privacy laws. For further information about how
                      we share your information, refer to Section 5 below.
                    </span>
                  </p>
                </li>

                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To personalize the Service, content and advertisements we
                      serve to you in reliance on our legitimate interests in
                      supporting our marketing activities and providing certain
                      features within the Service. We may use your Personal
                      Information to serve you specifically, such as to deliver
                      marketing information, product recommendations and
                      non-transactional communications (e.g., email,
                      telemarketing calls, SMS, or push notifications) about us,
                      in accordance with your marketing preferences and this
                      privacy policy.
                    </span>
                  </p>
                </li>
              </ul>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '2pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  C. Third-Party Integrations
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  We may use the Personal Information we collect or receive
                  through the Service, as a processor and as otherwise stated in
                  this privacy policy, to enable your use of the integrations
                  and plugins you choose to connect to your Vast Reach account.
                  For instance, if you choose to connect a Google integration to
                  your Vast Reach account, we’ll ask you to grant us permission
                  to view and/or download, as applicable, your Google Sheets,
                  Google Contacts, Google Analytics and Google Drive. This
                  allows us to configure your Google integration(s) in
                  accordance with your preferences. For example, if you wanted
                  to use the Google Contacts integration to share the templates
                  in your Vast Reach account with contacts in your Google
                  address book, we would need to access your Google Contacts to
                  share your templates.
                </span>
              </p>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '2pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  D. Cookies and Tracking Technologies
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  We and our third-party partners may use various technologies
                  to collect and store Service Usage Data when you use our
                  Service (as discussed above), and this may include using
                  cookies and similar tracking technologies, such as pixels, web
                  beacons, and if you use our Mobile Apps, through our SDKs
                  deployed on your mobile device. For example, we use web
                  beacons in the emails we send on your behalf, which enable us
                  to track certain behavior, such as whether the email sent
                  through the Service was delivered and opened and whether links
                  within the email were clicked. Both image trackers and SDKs
                  allow us to collect information such as the recipient’s IP
                  address, browser, email client type and other similar data as
                  further described above details. We use this information to
                  measure the performance of your email campaigns, to provide
                  analytics information, enhance the effectiveness of our
                  Service, and for other purposes described above. Reports are
                  also available to us when we send email to you, so we may
                  collect and review that information.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Our use of cookies and other tracking technologies is
                  discussed in more detail in our Cookie Statement available{' '}
                </span>
                <Link to='/CookiePolicy'>here</Link>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  .
                </span>
              </p>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '2pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  E. Customer Contact Groups
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  In order to send an email campaign or use certain features in
                  your account, you need to upload a Contact Group that provides
                  us information about your Contacts, such as their names and
                  email addresses. We use and process this information to
                  provide the Service in accordance with our contract with you
                  or your organization and this privacy policy.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  If we detect abusive or illegal behavior related to your
                  Contact Group, we may share your Contact Group or portions of
                  it with affected ISPs or anti-spam organizations to the extent
                  permitted or required by applicable law.
                </span>
              </p>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '2pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  F. Your Data Protection Rights
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Depending on the country in which you reside, you may have the
                  following data protection rights:
                </span>
              </p>
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '8pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To access; correct; update; port; delete; restrict; or
                      object to our processing of your Personal Information.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      You can manage your individual account and profile
                      settings within the dashboard provided through the Vast
                      Reach platform, or you may contact us directly by emailing
                      us at personaldatarequests@Vast Reach.com. You can also
                      manage information about your Contacts within the
                      dashboard provided through the Vast Reach platform to
                      assist you with responding to requests to access, correct,
                      update, port or delete information that you receive from
                      your Contacts. Note, if any of your Contacts wish to
                      exercise any of these rights, they should contact you
                      directly, or contact us as described in the “Privacy for
                      Contacts” section below. You can also contact us at any
                      time to update your own marketing preferences (see Section
                      5. General Information, C. Your Choices and Opt-Outs
                      below). Vast Reach takes reasonable steps to ensure that
                      the data we collect is reliable for its intended use,
                      accurate, complete and up to date.
                    </span>
                  </p>
                </li>

                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Similarly, if Personal Information is collected or
                      processed on the basis of consent, the data subject can
                      withdraw their consent at any time. Withdrawing your
                      consent will not affect the lawfulness of any processing
                      we conducted prior to your withdrawal, nor will it affect
                      the processing of your Personal Information conducted in
                      reliance on lawful processing grounds other than consent.
                      If you receive these requests from Contacts, you can
                      segment your lists within the Vast Reach platform to
                      ensure that you only market to Contacts who have not opted
                      out of receiving such marketing.
                    </span>
                  </p>
                </li>
              </ul>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  We respond to all requests we receive from individuals wishing
                  to exercise their data protection rights in accordance with
                  applicable data protection law. We may ask you to verify your
                  identity in order to help us respond efficiently to your
                  request. If we receive a request from one of your Contacts, we
                  will either direct the Contact to reach out to you, or, if
                  appropriate, we may respond directly to their request.
                </span>
              </p>
              <h1
                dir='ltr'
                style={{
                  lineHeight: '1.32',
                  marginTop: '0pt',
                  marginBottom: '4pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '20pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  3. Privacy for Contacts
                </span>
              </h1>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  This section applies to the information we process about our
                  Customers’ Contacts as a data controller. Our Service is
                  intended for use by our Customers. As a result, for much of
                  the Personal Information we collect and process about Contacts
                  through the Service, we act as a processor on behalf of our
                  Customers. Vast Reach is not responsible for the privacy or
                  security practices of our Customers, which may differ from
                  those set forth in this privacy policy. Please check with
                  individual Customers about the policies they have in place.
                  For purposes of this section, "you" and "your" refer to
                  Contacts.
                </span>
              </p>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '2pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  A. Information We Collect
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  The Personal Information that we may collect or receive about
                  you broadly falls into the following categories:
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  (i) Information we receive about Contacts from our Customers:
                  A Customer may provide Personal Information about you to us
                  through the Service. When a Customer uploads their Contact
                  Group or integrates the Service with another website or
                  service (for example, when a Customer chooses to connect their
                  e-commerce account with Vast Reach), or when you sign up for a
                  Customer’s Contact Group on a Vast Reach or other signup form,
                  the Customer may provide us with certain contact information
                  or other Personal Information about you such as your name,
                  email address, address, or telephone number. You may have the
                  opportunity to update some of this information by electing to
                  update or manage your preferences via an email you receive
                  from a Customer.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  (ii) Information we collect automatically: When you interact
                  with an email campaign that you receive from a Customer or
                  browse or purchase from a Customer’s connected store, we may
                  collect information about your device and interaction with an
                  email. We use cookies and other tracking technologies to
                  collect some of this information. Our use of cookies and other
                  tracking technologies is discussed more below and in more
                  detail in our Cookie Statement available{' '}
                </span>
                <Link to='/CookiePolicy'>here</Link>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  .
                </span>
              </p>
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '8pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Device information: We collect information about the
                      device and applications you use to access emails sent
                      through our Service, such as your IP address, your
                      operating system, your browser ID, and other information
                      about your system and connection.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Usage data: It is important for us to ensure the security
                      and reliability of the Service we provide. Therefore, we
                      also collect usage data about your interactions with
                      campaigns (and/or emails) sent through the Service, which
                      may include dates and times you access campaigns (and/or
                      emails) and your browsing activities (such as what pages
                      are viewed and which emails are opened). This information
                      also allows us to ensure compliance with our Standard
                      Terms of Use and Acceptable Use Policy, to monitor and
                      prevent service abuse, and to ensure we attain certain
                      usage standards and metrics in relation to our Service. We
                      also collect information regarding the performance of the
                      Service, including metrics related to the deliverability
                      of emails and other electronic communications that our
                      Customers send through the Service. This information
                      allows us to improve the content and operation of the
                      Service and facilitate research and perform analysis into
                      the use and performance of the Service.
                    </span>
                  </p>
                </li>
              </ul>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  (iii) Information we collect from other sources: From time to
                  time, we may obtain information about you from third-party
                  sources, such as social media platforms, and third-party data
                  providers.
                </span>
              </p>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '2pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  B. Use of Personal Information
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  We may use the Personal Information we collect or receive
                  about you in reliance on our (and where applicable, our
                  Customers’) legitimate interests for the following purposes:
                </span>
              </p>
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '8pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To enforce compliance with our Standard Terms of Use and
                      applicable law. This may include utilizing usage data and
                      developing tools and algorithms that help us prevent
                      violations.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To protect the rights and safety of Customers, third
                      parties, or Vast Reach. For example, sometimes we review
                      the content of our Customers’ email campaigns to make sure
                      they comply with our Standard Terms of Use. To improve
                      that process, we have software that helps us find email
                      campaigns that may violate our Standard Terms of Use. We,
                      or our third-party service provider, may review those
                      particular email campaigns, which may include your contact
                      information. This reduces the amount of spam being sent
                      through our servers and helps us maintain high
                      deliverability.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To meet legal requirements, including complying with court
                      orders, valid discovery requests, valid subpoenas, and
                      other appropriate legal mechanisms.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To provide information to representatives and advisors,
                      including attorneys and accountants, to help us comply
                      with legal, accounting, or security requirements.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To prosecute and defend a court, arbitration, or similar
                      legal proceeding.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To respond to lawful requests by public authorities,
                      including to meet national security or law enforcement
                      requirements.
                    </span>
                  </p>
                </li>

                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To carry out other business purposes. To carry out other
                      legitimate business purposes, as well as other lawful
                      purposes about which we will notify you.
                    </span>
                  </p>
                </li>
              </ul>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '2pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  C. Cookies and Tracking Technologies
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  We and our third-party partners may use various technologies
                  to automatically collect and store certain device and usage
                  information (as discussed above) when you interact with a
                  Customer’s email campaign or connected store, and this may
                  include using cookies and similar tracking technologies, such
                  as pixels and image trackers or if a Customer is using our
                  Mobile App, we may collect this information through our SDKs
                  deployed on our Customers mobile device. For example, we use
                  web beacons in the emails we send on behalf of our Customers.
                  When you receive and engage with a Customer’s campaign, image
                  trackers track certain behavior such as whether the email sent
                  through the Vast Reach platform was delivered and opened and
                  whether links within the email were clicked. Both image
                  trackers and SDKs allow us to collect information such as your
                  IP address, browser, email client type, and other similar data
                  as further described above. We use this information to measure
                  the performance of our Customers’ email campaigns, and to
                  provide analytics information and enhance the effectiveness of
                  our Service, and for the other purposes described above.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Our use of cookies and other tracking technologies is
                  discussed in more detail in our Cookie Statement available{' '}
                </span>
                <Link to='/CookiePolicy'>here</Link>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  .
                </span>
              </p>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '2pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  D. Your Data Protection Rights
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Depending on the country in which you reside, you may have the
                  following data protection rights:
                </span>
              </p>
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '8pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To access; correct; update; port; delete; restrict or
                      object to our processing of your Personal Information.
                    </span>
                  </p>
                </li>
              </ul>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  As described above, for much of the Personal Information we
                  collect and process about Contacts through the Service, we act
                  as a processor on behalf of our{' '}
                </span>
                Customers
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  . In such cases, if you are a Contact and want to exercise any
                  data protection rights that may be available to you under
                  applicable law or have questions or concerns about how your
                  Personal Information is handled by Vast Reach as a processor
                  on behalf of our individual Customers, you should contact the
                  relevant Customer that is using the Vast Reach Service, and
                  refer to their separate privacy policies.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  If you no longer want to be contacted by one of our Customers
                  through our Service, please unsubscribe directly from that
                  Customer’s newsletter or contact the Customer directly to
                  update or delete your data. If you contact us directly, we may
                  either forward your request to the relevant Customer or
                  provide you with the identity of the Customer to enable you to
                  contact them directly.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  We respond to all requests we receive from individuals wishing
                  to exercise their data protection rights in accordance with
                  applicable data protection laws. We may ask you to verify your
                  identity in order to help us respond efficiently to your
                  request.
                </span>
              </p>
              <h1
                dir='ltr'
                style={{
                  lineHeight: '1.32',
                  marginTop: '0pt',
                  marginBottom: '4pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '20pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  4. Privacy for Visitors
                </span>
              </h1>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  This section applies to Personal Information that we collect
                  and process when you visit the Vast Reach Consoles, and in the
                  usual course of our business, such as in connection with our
                  recruitment, events, sales and marketing activities or when
                  you visit our offices. In this section, "you" and "your" refer
                  to Visitors.
                </span>
              </p>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '2pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  A. Information We Collect
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  (i) Information you provide to us on the Vast Reach Consoles
                  or otherwise: Our Vast Reach Consoles offer various ways to
                  contact us, such as through form submissions, email or phone,
                  to inquire about our company and Service. For example, we may
                  ask you to provide certain Personal Information when you
                  express an interest in obtaining information about us or our
                  Service, take part in surveys, subscribe to marketing, apply
                  for a role with Vast Reach, or otherwise contact us. We may
                  also collect Personal Information from you in person when you
                  attend our events or trade shows, if you visit our offices
                  (where you will be required to register as a visitor and
                  provide us with certain information that may also be shared
                  with our service providers) or via a phone call with one of
                  our sales representatives. You may choose to provide
                  additional information when you communicate with us or
                  otherwise interact with us, and we may keep copies of any such
                  communications for our records.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  The Personal Information we collect may include:
                </span>
              </p>
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '8pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Business contact information (such as your name, phone
                      number, email address and country);
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Professional information (such as your job title,
                      institution or company);
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Nature of your communication;
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Marketing information (such as your contact preferences);
                      and
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Any information you choose to provide to us when
                      completing any ‘free text’ boxes in our forms.
                    </span>
                  </p>
                </li>
              </ul>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  (ii) Information we collect automatically through the Vast
                  Reach Consoles: When you visit our Vast Reach Consoles or
                  interact with our emails, we use cookies and similar
                  technologies such as pixels or image trackers, alone or in
                  conjunction with cookies, to collect certain information
                  automatically from your browser or device. In some countries,
                  including countries in the EEA, this information may be
                  considered Personal Information under applicable data
                  protection laws. Our use of cookies and other tracking
                  technologies is discussed more below, and in more detail in
                  our Cookie Statement available{' '}
                </span>
                <Link to='/CookiePolicy'>here</Link>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  .
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  The information we collect automatically includes:
                </span>
              </p>
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '8pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Device information: such as your IP address, your browser,
                      device information, unique device identifiers, mobile
                      network information, request information (speed,
                      frequency, the site from which you linked to us
                      (“referring page”), the name of the website you choose to
                      visit immediately after ours (called “exit page”),
                      information about other websites you have recently visited
                      and the web browser you used (software used to browse the
                      internet) including its type and language)
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Usage data: such as information about how you interact
                      with our emails, Vast Reach Consoles, and other websites
                      (such as the pages and files viewed, searches, operating
                      system and system configuration information and date/time
                      stamps associated with your usage).
                    </span>
                  </p>
                </li>
              </ul>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '2pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  B. Use of Personal Information
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  We may use the information we collect through our Vast Reach
                  Consoles and in connection with our events and marketing
                  activities (alone or in combination with other data we
                  collect) for a range of reasons in reliance on our legitimate
                  interests, including:
                </span>
              </p>
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '8pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To provide, operate, optimize, and maintain the Vast Reach
                      Consoles.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To send you marketing information, product recommendations
                      and non-transactional communications (e.g., email,
                      telemarketing calls, SMS, or push notifications) about us,
                      in accordance with your marketing preferences, including
                      information about our products, services, promotions or
                      events as necessary for our legitimate interest in
                      conducting direct marketing or to the extent you have
                      provided your prior consent.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      For recruitment purposes if you have applied for a role
                      with Vast Reach.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To respond to your online inquiries and requests, and to
                      provide you with information and access to resources or
                      services that you have requested from us.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To manage the Vast Reach Consoles and system
                      administration and security.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To manage event registrations and attendance, including
                      sending related communications to you.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To register visitors to our offices for security reasons
                      and to manage non-disclosure agreements that visitors may
                      be required to sign.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To improve the navigation and content of the Vast Reach
                      Consoles.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To identify any server problems or other IT or network
                      issues.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To process transactions and to set up online accounts.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To compile aggregated statistics about site usage and to
                      better understand the preferences of our Visitors.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To help us provide, improve and personalize our marketing
                      activities.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To facilitate the security and continued proper
                      functioning of the Vast Reach Consoles.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To carry out research and development to improve our Vast
                      Reach Consoles, products and services.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To conduct marketing research, advertise to you, provide
                      personalized information about us on and off our Vast
                      Reach Consoles, and to provide other personalized content
                      based on your activities and interests to the extent
                      necessary for our legitimate interests in supporting our
                      marketing activities or advertising our Service or
                      instances where we seek your consent.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To carry out other legitimate business purposes, as well
                      as other lawful purposes, such as data analysis, fraud
                      monitoring and prevention, identifying usage trends and
                      expanding our business activities in reliance on our
                      legitimate interests.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To cooperate with public and government authorities,
                      courts or regulators in accordance with our legal
                      obligations under applicable laws to the extent this
                      requires the processing or disclosure of Personal
                      Information to protect our rights or is necessary for our
                      legitimate interest in protecting against misuse or abuse
                      of our Vast Reach Consoles and Service, protecting
                      personal property or safety, pursuing remedies available
                      to us and limiting our damages, complying with judicial
                      proceedings, court orders or legal processes, or
                      responding to lawful requests.
                    </span>
                  </p>
                </li>
              </ul>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '2pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  C. Public Information and Third-Party Websites
                </span>
              </h2>
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Social media platforms and widgets. The Vast Reach
                      Consoles include social media features, such as the
                      Facebook Like button. These features may collect
                      information about your IP address and which page you are
                      visiting on our Vast Reach Console, and they may set a
                      cookie to make sure the feature functions properly. Social
                      media features and widgets are either hosted by a third
                      party or hosted directly on our Vast Reach Console. We
                      also maintain presences on social media platforms,
                      including Facebook, Twitter, and Instagram. Any
                      information, communications, or materials you submit to us
                      via a social media platform is done at your own risk
                      without any expectation of privacy. We cannot control the
                      actions of other users of these platforms or the actions
                      of the platforms themselves. Your interactions with those
                      features and platforms are governed by the privacy
                      policies of the companies that provide them.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Links to third-party websites. The Vast Reach Consoles
                      include links to other websites, whose privacy practices
                      may be different from ours. If you submit Personal
                      Information to any of those sites, your information is
                      governed by their privacy policies. We encourage you to
                      carefully read the privacy policy of any website you
                      visit.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Contests and sweepstakes. We may, from time to time, offer
                      surveys, contests, sweepstakes, or other promotions on the
                      Vast Reach Consoles or through social media (collectively,
                      "Promotions"). Participation in our Promotions is
                      completely voluntary. Information requested for entry may
                      include Personal Information such as your name, address,
                      date of birth, phone number, email address, username, and
                      similar details. We use the information you provide to
                      administer our Promotions. We may also, unless prohibited
                      by the Promotion’s rules or law, use the information
                      provided to communicate with you, or other people you
                      select, about our Service. We may share this information
                      with our affiliates and other organizations or service
                      providers in line with this privacy policy and the rules
                      posted for our Promotions.
                    </span>
                  </p>
                </li>
              </ul>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '2pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  D. Cookies and Tracking Technologies
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  We use cookies and similar tracking technologies to collect
                  and use Personal Information about you, including to serve
                  interest-based advertising. For further information about the
                  types of cookies and tracking technologies we use, why, and
                  how you can control them, please see our Cookie Statement
                  available{' '}
                </span>
                <Link to='/CookiePolicy'>here </Link>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  .
                </span>
              </p>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '2pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  E. Other Data Protection Rights
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Depending on the country in which you reside, you may have the
                  following data protection rights:
                </span>
              </p>
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      You may also have the right to complain to a data
                      protection authority about our collection and use of your
                      Personal Information. For more information, please contact
                      your local data protection authority. Contact details for
                      data protection authorities in the EEA are available{' '}
                    </span>
                    <a
                      href='http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm'
                      style={{ textDecorationLine: 'none' }}
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',
                          color: '#007c89',
                          backgroundColor: '#ffffff',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        here
                      </span>
                    </a>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      .
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Similarly, if we have collected and processed your
                      Personal Information with your consent, then you can
                      withdraw your consent at any time. Withdrawing your
                      consent will not affect the lawfulness of any processing
                      we conducted prior to your withdrawal, nor will it affect
                      the processing of your Personal Information conducted in
                      reliance on lawful processing grounds other than consent.
                      You can also contact us at any time to update your
                      marketing preferences (see Section 5. General Information,
                      C. Your Choices and Opt-Outs below).
                    </span>
                  </p>
                </li>
              </ul>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  We respond to all requests we receive from individuals wishing
                  to exercise their data protection rights in accordance with
                  applicable data protection laws. We may ask you to verify your
                  identity in order to help us respond efficiently to your
                  request.
                </span>
              </p>
              <h1
                dir='ltr'
                style={{
                  lineHeight: '1.32',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '20pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  5. General Information
                </span>
              </h1>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  A. How We Share Information
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  We may share and disclose your Personal Information with our
                  Affiliates and to the following types of third parties for the
                  purposes described in this privacy policy (for purposes of
                  this section, "you" and "your" refer to Customers, Contacts,
                  and Visitors unless otherwise indicated). Our Affiliates
                  include Courier Holdings Ltd. located in the United Kingdom.
                  You can learn more about Courier and its privacy practices{' '}
                </span>
                <a
                  href='https://couriermedia.co/privacy/'
                  style={{ textDecorationLine: 'none' }}
                >
                  <span
                    style={{
                      fontSize: '12pt',
                      fontFamily: 'Arial',
                      color: '#007c89',
                      backgroundColor: '#ffffff',
                      fontVariantNumeric: 'normal',
                      fontVariantEastAsian: 'normal',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    here
                  </span>
                </a>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  .
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  (i) Our service providers: Sometimes, we share your
                  information with our third-party service providers working on
                  our behalf for the purposes described in this privacy policy.
                  For example, companies we’ve hired to help us provide and
                  support our Service or assist in protecting and securing our
                  systems and services and other business-related functions.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Other examples include analyzing data, hosting data, engaging
                  technical support for our Service, processing payments, and
                  delivering content.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  We use ReCaptcha's API services in connection with our Service
                  to provide certain features. As such, you acknowledge and
                  agree that by signing up for an account and using the Service,
                  you are also bound by{' '}
                </span>
                <a
                  href='http://www.google.com/policies/privacy'
                  style={{ textDecorationLine: 'none' }}
                >
                  <span
                    style={{
                      fontSize: '12pt',
                      fontFamily: 'Arial',
                      color: '#007c89',
                      backgroundColor: '#ffffff',
                      fontVariantNumeric: 'normal',
                      fontVariantEastAsian: 'normal',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    Google's Privacy Policy
                  </span>
                </a>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  . In addition to the rights set forth in Section 2, you may
                  manage your ReCaptcha API data by visiting Google's security
                  settings page at{' '}
                </span>
                <a
                  href='https://security.google.com/settings/security/permissions'
                  style={{ textDecorationLine: 'none' }}
                >
                  <span
                    style={{
                      fontSize: '12pt',
                      fontFamily: 'Arial',
                      color: '#007c89',
                      backgroundColor: '#ffffff',
                      fontVariantNumeric: 'normal',
                      fontVariantEastAsian: 'normal',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    https://security.google.com/settings/security/permissions
                  </span>
                </a>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  In connection with our Service, we also use a third-party
                  service provider, Twilio, Inc. We use Twilio's API, which
                  allows us to build features into our Vast Reach application to
                  enable us to communicate with our Customers through texting
                  and calling, and their "Authy" product, which we use for
                  two-factor authentication for our application. If you are a
                  Customer, Twilio may need to collect and process certain
                  Personal Information about you as a controller to provide such
                  services. To learn more about Twilio's privacy practices,
                  please visit{' '}
                </span>
                <a
                  href='https://www.twilio.com/legal/privacy'
                  style={{ textDecorationLine: 'none' }}
                >
                  <span
                    style={{
                      fontSize: '12pt',
                      fontFamily: 'Arial',
                      color: '#007c89',
                      backgroundColor: '#ffffff',
                      fontVariantNumeric: 'normal',
                      fontVariantEastAsian: 'normal',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    https://www.twilio.com/legal/privacy
                  </span>
                </a>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  In connection with our Service, we also use a third-party
                  service provider, Braintree (A PayPal Service). We use
                  Braintree's API, which allows us to build features into our
                  Vast Reach application to enable us to bill our customers and
                  securely store financial information. Vast Reach does not
                  store any financial information related to your account, all
                  of it exists on Braintree's servers. If you are a Customer,
                  Braintree may need to collect and process certain Personal
                  Information about you as a controller to provide such
                  services. To learn more about Braintree's privacy practices,
                  please visit{' '}
                </span>
                <a
                  href='https://www.paypal.com/us/webapps/mpp/ua/privacy-full'
                  style={{ textDecorationLine: 'none' }}
                >
                  <span
                    style={{
                      fontSize: '12pt',
                      fontFamily: 'Arial',
                      color: '#007c89',
                      backgroundColor: '#ffffff',
                      fontVariantNumeric: 'normal',
                      fontVariantEastAsian: 'normal',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    https://www.paypal.com/us/webapps/mpp/ua/privacy-full
                  </span>
                </a>
              </p>

              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  (ii) Advertising partners: We may partner with third-party
                  advertising networks, exchanges, and social media platforms
                  (like Facebook) to display advertising on the Vast Reach
                  Consoles or to manage and serve our advertising on other
                  sites, and we may share Personal Information of Customers and
                  Visitors with them for this purpose. We and our third-party
                  partners may use cookies and other similar tracking
                  technologies, such as pixels and image trackers, to gather
                  information about your activities on the Vast Reach Consoles
                  and other sites in order to provide you with targeted
                  advertising based on your browsing activities and interests.
                  For more information, please see our Cookie Statement
                  available{' '}
                </span>
                <Link to='/CookiePolicy'>here</Link>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  .
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  (iii) Any competent law enforcement body, regulatory body,
                  government agency, court or other third party where we believe
                  disclosure is necessary (a) as a matter of applicable law or
                  regulation, (b) to exercise, establish, or defend our legal
                  rights, or (c) to protect your vital interests or those of any
                  other person.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  (iv) A potential buyer (and its agents and advisors) in the
                  case of a sale, merger, consolidation, liquidation,
                  reorganization, or acquisition. In that event, any acquirer
                  will be subject to our obligations under this privacy policy,
                  including your rights to access and choice. We will notify you
                  of the change either by sending you an email or posting a
                  notice on our Vast Reach Console.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  (v) Any other person with your consent.
                </span>
              </p>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  B. Legal Basis for Processing Personal Information (EEA and UK
                  Persons Only)
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  If you are located in the EEA or UK, our legal basis for
                  collecting and using the Personal Information described above
                  will depend on the Personal Information concerned and the
                  specific context in which we collect it.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  However, we will normally collect and use Personal Information
                  from you where the processing is in our legitimate interests
                  and not overridden by your data-protection interests or
                  fundamental rights and freedoms. Our legitimate interests are
                  described in more detail in this privacy policy in the
                  sections above titled “Use of Personal Information”, but they
                  typically include improving, maintaining, providing, and
                  enhancing our technology, products, and services; ensuring the
                  security of the Service and our Vast Reach Consoles; and
                  supporting our marketing activities.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  If you are a Customer, we may need the Personal Information to
                  perform a contract with you. In some limited cases, we may
                  also have a legal obligation to collect Personal Information
                  from you. If we ask you to provide Personal Information to
                  comply with a legal requirement or to perform a contract with
                  you, we will make this clear at the relevant time and advise
                  you whether the provision of your Personal Information is
                  mandatory or not, as well as of the possible consequences if
                  you do not provide your Personal Information.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Where required by law, we will collect Personal Information
                  only where we have your consent to do so.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  If you have questions or need further information concerning
                  the legal basis on which we collect and use your Personal
                  Information, please contact us using the contact details
                  provided in the "Questions and Concerns" section below.
                </span>
              </p>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  C. Your Choices and Opt-Outs
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Customers and Visitors who have opted in to our marketing
                  emails can opt out of receiving marketing emails from us at
                  any time by clicking the "unsubscribe" link at the bottom of
                  our marketing messages.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Also, all opt-out requests can be made by emailing us using
                  the contact details provided in the "Questions and Concerns"
                  section below. Please note that some communications (such as
                  service messages, account notifications, billing information)
                  are considered transactional and necessary for account
                  management, and Customers cannot opt out of these messages
                  unless you cancel your Vast Reach account.
                </span>
              </p>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  D. Our Security
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  We take appropriate and reasonable technical and
                  organizational measures designed to protect Personal
                  Information from loss, misuse, unauthorized access,
                  disclosure, alteration, and destruction, taking into account
                  the risks involved in the processing and the nature of the
                  Personal Information. If you have any questions about the
                  security of your Personal Information, you may contact us at
                  info@VastReach.com.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Vast Reach accounts require a username and password to log in.
                  Customers must keep their username and password secure, and
                  never disclose it to a third party. Because the information in
                  a Customer’s Vast Reach account is private, account passwords
                  are hashed, which means we cannot see a Customer’s password.
                  We cannot resend forgotten passwords either. We will only
                  provide Customers with instructions on how to reset them.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  If you are a Customer, Contact or Visitor who accesses our
                  Service in Australia, this section applies to you. We are
                  subject to the operation of the Privacy Act 1988 ("Australian
                  Privacy Act"). here are the specific points you should be
                  aware of:
                </span>
              </p>
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '8pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      As stated in our Acceptable Use Policy available{' '}
                    </span>
                    <Link to='/AcceptableUse'>here</Link>
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      , sensitive personal information is not permitted on Vast
                      Reach’s platform and Customers are prohibited from
                      importing or incorporating any sensitive personal
                      information into their Vast Reach accounts or uploading
                      any sensitive personal information to Vast Reach’s
                      servers.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Please note that if you do not provide us with your
                      Personal Information or if you withdraw your consent for
                      us to collect, use and disclose your Personal Information,
                      we may be unable to provide the Service to you.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Where we collect Personal Information of our Visitors, the
                      Personal Information we ask you to provide will be
                      information that is reasonably necessary for, or directly
                      related to, one or more of our functions or activities.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Where we say we assume an obligation about Personal
                      Information, we will also require our contractors and
                      subcontractors to undertake a similar obligation.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      We will not use or disclose Personal Information for the
                      purpose of our direct marketing to you unless:
                    </span>
                  </p>
                </li>
                <ul style={{ marginTop: 0, marginBottom: 0 }}>
                  <li
                    dir='ltr'
                    style={{
                      listStyleType: 'circle',
                      fontSize: '12pt',
                      fontFamily: 'Arial',
                      color: '#403b3b',
                      fontVariantNumeric: 'normal',
                      fontVariantEastAsian: 'normal',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre',
                      textAlign: 'left',
                    }}
                  >
                    <p
                      dir='ltr'
                      style={{
                        lineHeight: '1.38',
                        marginTop: '0pt',
                        marginBottom: '0pt',
                      }}
                      role='presentation'
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',
                          backgroundColor: '#ffffff',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        you have consented to receive direct marketing;
                      </span>
                    </p>
                  </li>
                  <li
                    dir='ltr'
                    style={{
                      listStyleType: 'circle',
                      fontSize: '12pt',
                      fontFamily: 'Arial',
                      color: '#403b3b',
                      fontVariantNumeric: 'normal',
                      fontVariantEastAsian: 'normal',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre',
                      textAlign: 'left',
                    }}
                  >
                    <p
                      dir='ltr'
                      style={{
                        lineHeight: '1.38',
                        marginTop: '0pt',
                        marginBottom: '0pt',
                      }}
                      role='presentation'
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',
                          backgroundColor: '#ffffff',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        you would reasonably expect us to use your personal
                        details for marketing; or
                      </span>
                    </p>
                  </li>
                  <li
                    dir='ltr'
                    style={{
                      listStyleType: 'circle',
                      fontSize: '12pt',
                      fontFamily: 'Arial',
                      color: '#403b3b',
                      fontVariantNumeric: 'normal',
                      fontVariantEastAsian: 'normal',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre',
                      textAlign: 'left',
                    }}
                  >
                    <p
                      dir='ltr'
                      style={{
                        lineHeight: '1.38',
                        marginTop: '0pt',
                        marginBottom: '0pt',
                      }}
                      role='presentation'
                    >
                      <span
                        style={{
                          fontSize: '12pt',
                          fontFamily: 'Arial',
                          backgroundColor: '#ffffff',
                          fontVariantNumeric: 'normal',
                          fontVariantEastAsian: 'normal',
                          verticalAlign: 'baseline',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        we believe you may be interested in the material but it
                        is impractical for us to obtain your consent.
                      </span>
                    </p>
                  </li>
                </ul>
              </ul>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  You may opt out of any marketing materials we send to you
                  through an unsubscribe mechanism. If you have requested not to
                  receive further direct marketing messages, we may continue to
                  provide you with messages that are not regarded as "direct
                  marketing" under the Australian Privacy Act, including changes
                  to our terms, system alerts, and other information related to
                  your account as permitted under the Australian Privacy Act and
                  the Spam Act 2003 (Cth).
                </span>
              </p>
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '8pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Our servers are located in the United States. In addition,
                      we or our subcontractors may use cloud technology to store
                      or process Personal Information, which may result in
                      storage of data outside Australia. It is not practicable
                      for us to specify in advance which country will have
                      jurisdiction over this type of offshore activity. All of
                      our subcontractors, however, are required to comply with
                      the Australian Privacy Act in relation to the transfer or
                      storage of Personal Information overseas.
                    </span>
                  </p>
                </li>
              </ul>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  If you think the information we hold about you is inaccurate,
                  out of date, incomplete, irrelevant, or misleading, we will
                  take reasonable steps, consistent with our obligations under
                  the Australian Privacy Act, to correct that information upon
                  your request. If you find that the information we have is not
                  up to date or is inaccurate or incomplete, please contact us
                  in writing at dpo@Vast Reach.com, so we can update our
                  records. We will respond to all requests for correction within
                  a reasonable time.
                </span>
              </p>
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '8pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      If you are unsatisfied with our response to a privacy
                      matter, you may consult either an independent advisor or
                      contact the Office of the Australian Information
                      Commissioner for additional help. We will provide our full
                      cooperation if you pursue this course of action.
                    </span>
                  </p>
                </li>
              </ul>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  F. Retention of Data
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  We retain Personal Information where we have an ongoing
                  legitimate business or legal need to do so. Our retention
                  periods will vary depending on the type of data involved, but,
                  generally, we'll refer to these criteria in order to determine
                  retention period:
                </span>
              </p>
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '8pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Whether we have a legal or contractual need to retain the
                      data.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Whether the data is necessary to provide our Service.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Whether our Customers have the ability to access and
                      delete the data within their Vast Reach accounts.
                    </span>
                  </p>
                </li>
                <li
                  dir='ltr'
                  style={{
                    listStyleType: 'disc',
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}
                >
                  <p
                    dir='ltr'
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                    role='presentation'
                  >
                    <span
                      style={{
                        fontSize: '12pt',
                        fontFamily: 'Arial',
                        backgroundColor: '#ffffff',
                        fontVariantNumeric: 'normal',
                        fontVariantEastAsian: 'normal',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Whether our Customers would reasonably expect that we
                      would retain the data until they remove it or until their
                      Vast Reach accounts are closed or terminated.
                    </span>
                  </p>
                </li>
              </ul>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  When we have no ongoing legitimate business need to process
                  your Personal Information, we will either delete or anonymize
                  it or, if this is not possible (for example, because your
                  Personal Information has been stored in backup archives), then
                  we will securely store your Personal Information and isolate
                  it from any further processing until deletion is possible.
                </span>
              </p>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  G. California Privacy
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  The California Consumer Privacy Act (“CCPA”) provides
                  consumers with specific rights regarding their Personal
                  Information. You have the right to request that businesses
                  subject to the CCPA (which may include our Customers with whom
                  you have a relationship) disclose certain information to you
                  about their collection and use of your Personal Information
                  over the past 12 months. In addition, you have the right to
                  ask such businesses to delete Personal Information collected
                  from you, subject to certain exceptions. If the business sells
                  Personal Information, you have a right to opt-out of that
                  sale. Finally, a business cannot discriminate against you for
                  exercising a CCPA right.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  When offering services to its Customers, Vast Reach acts as a
                  “service provider” under the CCPA and our receipt and
                  collection of any consumer Personal Information is completed
                  on behalf of our Customers in order for us to provide the
                  Service. Please direct any requests for access or deletion of
                  your Personal Information under the CCPA to the Customer with
                  whom you have a direct relationship.
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Consistent with California law, if you choose to exercise your
                  applicable CCPA rights, we won’t charge you different prices
                  or provide you a different quality of services. If we ever
                  offer a financial incentive or product enhancement that is
                  contingent upon you providing your Personal Information, we
                  will not do so unless the benefits to you are reasonably
                  related to the value of the Personal Information that you
                  provide to us.
                </span>
              </p>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  H. Do not Track
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Certain state laws require us to indicate whether we honor “Do
                  Not Track” settings in your browser. Vast Reach adheres to the
                  standards set out in this Privacy Policy and does not monitor
                  or follow any Do Not Track browser requests.
                </span>
              </p>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  I. Changes to this Policy
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  We may change this privacy policy at any time and from time to
                  time. The most recent version of the privacy policy is
                  reflected by the version date located at the top of this
                  privacy policy. All updates and amendments are effective
                  immediately upon notice, which we may give by any means,
                  including, but not limited to, by posting a revised version of
                  this privacy policy or other notice on the Vast Reach
                  Consoles. We encourage you to review this privacy policy often
                  to stay informed of changes that may affect you. Our
                  electronically or otherwise properly stored copies of this
                  privacy policy are each deemed to be the true, complete,
                  valid, authentic, and enforceable copy of the version of this
                  privacy policy that was in effect on each respective date you
                  visited the Vast Reach Console.
                </span>
              </p>
              <h2
                dir='ltr'
                style={{
                  lineHeight: '1.68',
                  marginTop: '10pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '16pt',
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  J. Questions &amp; Concerns
                </span>
              </h2>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  If you have any questions or comments, or if you have a
                  concern about the way in which we have handled any privacy
                  matter, please email us at info@vastreach.com to send us a
                  message. You may also contact us by postal mail at:
                </span>
              </p>
              <p
                dir='ltr'
                style={{
                  lineHeight: '1.8',
                  marginTop: '23pt',
                  marginBottom: '0pt',
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  Attn: Privacy Officer
                </span>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: '12pt',
                    fontFamily: 'Arial',
                    color: '#403b3b',
                    backgroundColor: '#ffffff',
                    fontVariantNumeric: 'normal',
                    fontVariantEastAsian: 'normal',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  P.O. Box 116 Huntingdon Valley, PA 19006
                </span>
              </p>
              <br />
              <br />
            </span>
          </div>
        </div>
      </div>
    );
  }
}
