import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import TaskMobileNaviScrollSpy from './TaskMobileNaviScrollSpy.js';
import TaskImgA from '../Tasks/img/create_task_button.png';
import TaskImgB from '../Tasks/img/scheduled_task_button.jpg';
import TaskImgC from '../Tasks/img/scheduled_task_date.jpg';
import TaskImgD from '../Tasks/img/tiles.png';
import TaskImgE from './img/task_select_mobile.jpg';
import TaskImgF from '../Tasks/img/sort-bar.jpg';
import TaskImgG from '../Tasks/img/task_list_example.jpg';
import TaskImgH from './img/mobile_email_template_select.jpg';
import TaskImgI from './img/mobile_email_theme_select.jpg';
import TaskImgJ from './img/mobile_email_theme_editor.jpg';
import TaskImgK from './img/mobile_email_sms_editor.jpg';
import TaskImgL from './img/mobile_add_contact_to_group.jpg';
import TaskImgM from './img/mobile_remove_contact_from_group.jpg';
import TaskImgO from './img/mobile_move_contact_from_group.jpg';
import TaskImgR from './img/mobile_nav.jpg';
import video from '../Tasks/video/create_email_with_premade_themes.mp4';
import video2 from '../Tasks/video/sms_task_tutorial.mov';
import video3 from '../Tasks/video/add_contact_to_group.mov';
import video4 from '../Tasks/video/remove_contact_from_group.mov';
import video5 from '../Tasks/video/move_contact_between_groups.mov';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function TasksMobile() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <TaskMobileNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/Tasks'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/TasksMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>TASKS ON MOBILE</h1>
              <div>
                <p className='task-intro'>
                  Tasks allow <i>you</i> to automate sending emails and texts to{' '}
                  <i>your</i> customers with the ability to track them if a
                  contact opens, sends and clicks the sent tasks. This tutorial
                  is designed to teach a <i>user</i> on how to create a task. It
                  specifically will go into a deep dive of each of the task
                  settings, task types, how to set them up and lastly show the{' '}
                  <i>user</i> how to monitor task statistics.
                </p>
                <h3 className='prerequisites'>
                  Prerequisites Before Creating a Task
                </h3>
                <p>
                  Before creating a <strong>Task</strong>, <i>you</i> must first
                  have an account set up with an active subscription. Depending
                  on what task <i>you</i> plan to create <i>you</i> will need a
                  verified email address to send email tasks, a phone number
                  selected using <strong>Manage Phone Numbers</strong> or a{' '}
                  <strong>Group</strong> to add, remove or move <i>contacts</i>.
                  Click the <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                  <strong>Buttons</strong> below to set up anything{' '}
                  <i>you're</i> missing before beginning this tutorial if{' '}
                  <i>you</i> have not done so already.
                </p>
                <p>
                  <Link to='/GettingStartedMobile'>
                    <button className='dl-btn' style={{ margin: '10px' }}>
                      Getting Started
                    </button>
                  </Link>
                  <Link to='/PaymentMobile'>
                    <button className='dl-btn' style={{ margin: '10px' }}>
                      Payment Information
                    </button>
                  </Link>
                  <Link to='/ManagePhoneNumbersMobile'>
                    <button className='dl-btn' style={{ margin: '10px' }}>
                      Manage Phone Numbers
                    </button>
                  </Link>
                  <Link to='/ContactsMobile'>
                    <button className='dl-btn' style={{ margin: '10px' }}>
                      Create a Contact
                    </button>
                  </Link>
                  <Link to='/GroupsMobile'>
                    <button className='dl-btn' style={{ margin: '10px' }}>
                      Create a Group
                    </button>
                  </Link>
                </p>
                <h3 className='navigation'>Navigate to Tasks</h3>
                <p>
                  In order to create a task <i>you</i> can start by clicking the{' '}
                  <strong>Tasks</strong> link in the navigation (logo at the top
                  left).
                </p>
                <p style={{ margin: '0 0 20px 0', textAlign: 'center' }}>
                  <img
                    src={TaskImgR}
                    alt='task page navigation'
                    className='img-fluid'
                    style={{ maxWidth: '375px', width: '100%' }}
                  />
                </p>
                <h3 className='task-list'>Understanding The Task List</h3>
                <p>
                  This will take <i>you</i> to the <strong>Tasks List</strong>{' '}
                  page. This page will list all of <i>your</i> tasks including
                  the type of tasks, the individual and overall task statistics,
                  dates created and much more.
                </p>
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    <h4>Learn About Your Task Statistics</h4>
                    <p>
                      On this page there are 5 statistical tiles at the top of
                      the page just under the <strong>ADD TASK</strong> button.
                      We will go over each of the tiles below.
                    </p>
                    <p>
                      <img
                        src={TaskImgD}
                        alt='task aggregate statistics'
                        className='img-fluid'
                        style={{ maxWidth: '800px', width: '100%' }}
                      />
                    </p>
                    <ul>
                      <li>
                        <p>
                          The green tile shows the unique emails opened across
                          all email tasks sent as a percentage and just below it
                          also shows the exact number resulting in a distinct{' '}
                          <i>contact</i> having opened an email sent.
                        </p>
                      </li>
                      <li>
                        <p>
                          The yellow tile shows the unique clicks across all
                          email tasks sent as a percentage and just below it
                          shows the exact number. Resulting in a distinct{' '}
                          <i>contact</i> that clicked a link in the email sent.
                        </p>
                      </li>
                      <li>
                        <p>
                          The purple tile shows the total text messages
                          delivered as a percentage across all text messaging
                          tasks and below it shows the exact number delivered.
                          This statistic allows <i>you</i> to keep track and be
                          certain text messages have been successfully
                          delivered.
                        </p>
                      </li>
                      <li>
                        <p>
                          The dark blue tile allows <i>you</i> to keep track of
                          all the emails sent out to <i>contacts</i>.
                        </p>
                      </li>
                      <li>
                        <p>
                          Lastly, the light blue tile allows <i>you</i> to keep
                          track of all the text messages sent out to{' '}
                          <i>contacts</i>.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h4>Using The Search & Filter Bar</h4>
                    <p>
                      Below the statistical tiles is the{' '}
                      <strong>Search & Filter Bar</strong>. This bar will sort
                      tasks by Date created, when a task was scheduled, opened,
                      sent, clicks and the name. To the left <i>you</i> can sort
                      the tasks in ascending or descending order (A-Z, Z-A,
                      greatest to least, etc.). Search for a specific task as
                      well. <strong>Calendar Icon</strong> will show when tasks
                      are scheduled.
                    </p>
                    <p style={{ textAlign: 'center' }}>
                      <img
                        src={TaskImgF}
                        alt='sort bar'
                        className='img-fluid'
                        style={{ maxWidth: '800px', width: '100%' }}
                      />
                    </p>
                  </li>
                  <li>
                    <h4>Understanding Your Task Details</h4>
                    <p>
                      In the task list view the left of each task has an icon
                      with text underneath it showing <i>you</i> the task type.
                      The right of that is the task name and just below that is
                      the date the task was created and when it will end. Below
                      that will show the specific task stats based on the task
                      type. The ellipsis icon (3 dot icon) to the far right can
                      be clicked to either edit or delete the task. Clicking a
                      task will automatically take <i>you</i> to the edit page.
                    </p>
                    <p style={{ textAlign: 'center' }}>
                      <img
                        src={TaskImgG}
                        alt='task list example'
                        className='img-fluid'
                        style={{ maxWidth: '1000px', width: '100%' }}
                      />
                    </p>
                  </li>
                </ul>
              </div>

              <h2 className='task-create'>Creating a Task</h2>
              <p>
                <i>You</i> can click the button
                <p style={{ margin: '0' }}>
                  <img
                    src={TaskImgA}
                    alt='task create button'
                    className='img-fluid'
                    style={{ maxWidth: '200px', width: '100%' }}
                  />
                </p>
                at the top right and be taken to the first task creation step.
                At the top of the page <i>you</i> can see instructions to name a
                task and describe the task details. After that <i>you</i> will
                be able to select a task type (each task type will be explained
                further along in this tutorial). Once a task type has been
                chosen going to the top right of the screen and clicking{' '}
                <strong>NEXT</strong> will bring <i>you</i> to the next step or
                clicking the <strong>Automate</strong> step in the stepper.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={TaskImgE}
                  alt='task select screen'
                  className='img-fluid'
                  style={{ maxWidth: '355px', width: '100%', margin: '5px' }}
                />
                <img
                  src={TaskImgH}
                  alt='mobile email template select'
                  className='img-fluid'
                  style={{ maxWidth: '350px', width: '100%', margin: '5px' }}
                />
              </p>
              <h3 id='automate-task'>Automate Your Tasks</h3>
              <p>
                <strong>Vast Reach</strong> allows <i>you</i> to automate common
                marketing tasks. <i>You</i> can choose to schedule <i>your</i>{' '}
                tasks, add your task to a campaign calendar or add <i>your</i>{' '}
                task to a campaign funnel stage.
              </p>

              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h4>Schedule Your Task</h4>
                  <p>
                    In some cases <i>you</i> may want to schedule a task to
                    execute without associating it with a campaign. <i>You</i>{' '}
                    can do so by scheduling a task to fire at a desired date and
                    time. By default the <strong>Scheduled Task</strong> button
                    <p style={{ margin: '10px 0 10px 0' }}>
                      <img
                        src={TaskImgB}
                        alt='scheduled task button'
                        className='img-fluid'
                        style={{ maxWidth: '300px', width: '100%' }}
                      />
                    </p>
                    is selected. Below it is a list
                    <p style={{ margin: '10px 0 0 0' }}>
                      <img
                        src={TaskImgC}
                        alt='scheduled task selector'
                        className='img-fluid'
                        style={{ maxWidth: '500px', width: '100%' }}
                      />
                    </p>
                    to select the date (click the calendar icon to the right to
                    select date) and time (click the watch icon to the right of
                    the calendar icon to select the time).
                  </p>
                </li>

                <li>
                  <h4>Add Your Task To a Campaign Calendar</h4>
                  <p>
                    In most cases <i>you</i> should track <i>your</i> tasks with
                    a campaign. Adding a task to a campaign calendar allows{' '}
                    <i>you</i> to schedule a task to execute while tracking it
                    within a campaign. We recommend adding a task to a campaign
                    calendar from the campaign screen (click{' '}
                    <Link to='/CampaignsMobile'>here</Link> to learn how).
                    However, if <i>you'd</i> like <i>you</i> can add a task to a
                    campaign calendar from the tasks screen by following the
                    instructions below.
                  </p>
                  <p>
                    Selecting the Campaign Calendar automation button changes
                    the content below with 2 lists. When selecting this
                    automation <i>you</i> will be able to select the campaign{' '}
                    <i>you</i> want to add this task to (<i>you</i> will need a
                    campaign created before being able to do this automation. To
                    learn more about building campaigns click{' '}
                    <Link to='/CampaignsMobile'>here</Link>). The other list is
                    the same as the <strong>Scheduled Task</strong> date/time
                    selector as this automation is a scheduled task inside a
                    campaign calendar.
                  </p>
                </li>

                <li>
                  <h4>Add Your Task To a Campaign Funnel Stage</h4>
                  <p>
                    In most cases <i>you</i> should track <i>your</i> tasks with
                    a campaign. We recommend adding a task to a campaign funnel
                    stage from the campaign screen (click{' '}
                    <Link to='/CampaignsMobile'>here</Link> to learn how).
                    However, if <i>you'd</i> like <i>you</i> can add a task to a
                    campaign funnel stage from the tasks screen by following the
                    instructions below.
                  </p>
                  <p>
                    This last automation goes inside the campaign funnel stage.
                    When this automation is selected there are 4 selection
                    list's below. First one allows <i>you</i> to select the
                    campaign like the previous automation. The second,{' '}
                    <strong>select a stage</strong> list allows <i>you</i> to
                    select which stage of the campaign this task will be placed
                    in (<i>you</i> need a campaign created before being able to
                    do this automation. To learn more about building campaigns
                    click <Link to='/CampaignsMobile'>here</Link>). The last two
                    selection list's allow <i>you</i> to set the delay time
                    before this task will fire. The first of these list's allow{' '}
                    <i>you</i> to set the number of time (default is 0). The
                    second list allows <i>you</i> to choose minute(s), hour(s),
                    day(s), week(s) or month(s). Based on second list selection,
                    below the first list will show a helper text indicating the
                    maximum of each delay selection (example the maximum hours
                    that can be set is 4,320).
                  </p>
                  <p>
                    <i>Note:</i> <i>You</i> cannot add tasks to a campaign that
                    is live or a campaign that has expired.
                  </p>
                </li>
              </ul>
              <h2 className='task-types'>Task Types</h2>
              <p>
                Once this page has been filled <i>you</i> can now click next at
                the top right to move on to the <strong>Finalize</strong> step.
                Based on the task selected the step will be different or may
                have another step prior to the <strong>Finalize</strong> step.
                For example, selecting the <strong>Email Task</strong> will take{' '}
                <i>you</i> to the <strong>Theme/Template</strong> step before
                the <strong>Finalize</strong> step.
              </p>
              <h3 id='send-email'>Send Email Task</h3>
              <div>
                <p>
                  When selecting this task <i>you</i> will be taken to{' '}
                  <strong>Theme/Template</strong> step specific to this task. On
                  this page <i>you</i> will be presented with 3 choices Blank,
                  Themes or Templates.
                </p>
                <p style={{ textAlign: 'center' }}>
                  <img
                    src={TaskImgI}
                    alt='mobile email template select'
                    className='img-fluid'
                    style={{ maxWidth: '375px', width: '100%' }}
                  />
                </p>
                <ul>
                  <li>
                    <p>
                      The Blank option will allow <i>you</i> to start a blank
                      email template and build a custom email template.
                    </p>
                  </li>
                  <li>
                    <p>
                      The Theme option will display selections of pre-made email
                      templates by <strong>Vast Reach</strong> allowing{' '}
                      <i>you</i> to do minimal editing using our custom themes
                      thus saving valuable time for <i>you</i>.
                    </p>
                  </li>
                  <li>
                    <p>
                      The Templates option will allow <i>you</i> to reuse any
                      modified or custom theme <i>you</i> made before for ease
                      and convenience.
                    </p>
                  </li>
                </ul>
                <p>
                  Once <i>you</i> have selected an option then <i>you</i> will
                  be taken to the email task creation page. For this page{' '}
                  <i>you</i> will have a preview window as your default page and
                  several tabs on at the bottom of the page.
                </p>
                <p style={{ textAlign: 'center' }}>
                  <img
                    src={TaskImgJ}
                    alt='mobile email theme editor'
                    className='img-fluid'
                    style={{ maxWidth: '375px', width: '100%' }}
                  />
                </p>
                <ul>
                  <li>
                    <p>
                      To the right of the <strong>Preview</strong> tab there is
                      the <strong>Task Info</strong> tab. In this tab there are
                      three selection inputs boxes. The first allows <i>you</i>{' '}
                      to choose the receipt (person or group) this email will go
                      to. The second input will allow <i>you</i> to name the
                      template to reuse for a future email. The last input
                      allows the <i>you</i> to set the subject of the email for
                      the recipients.
                    </p>
                  </li>
                  <li>
                    <p>
                      Next tab is the <strong>Snippets</strong>, which allows{' '}
                      <i>you</i> to place in email components in the preview
                      window by taping one to custom build and/or modify{' '}
                      <i>your</i> emails.
                    </p>
                  </li>
                  <li>
                    <p>
                      The final section, although not a tab is the{' '}
                      <strong>Editor</strong> section and only presents itself
                      when a component is selected (double tapped) in the
                      preview window. This section allows <i>you</i> to edit the
                      components finer details such as font size and styling for
                      text, images, links and placement of objects inside this
                      component. Additionally, this editor section has 2
                      additional tabs at the top named{' '}
                      <strong>Merge Fields</strong> and{' '}
                      <strong>Default Values</strong>.{' '}
                      <strong>Merge Fields</strong> allows <i>you</i> to place
                      in <i>contact</i> names and numbers, so when sent out{' '}
                      <strong>Vast Reach</strong> will fill in their names and
                      information for the <i>you</i>. The default values allow{' '}
                      <i>you</i> to edit the vale for each active merge field.
                    </p>
                  </li>
                </ul>
                <p>
                  All these changes made allows <i>you</i> to see them on the
                  preview tab. Each snippet component is highlighted with dotted
                  lines (onces tapped on) and will prompt the editing screen
                  once double tapped. Additionally, each dotted component is
                  draggable to trade the placement of other components. This
                  allows <i>you</i> to make custom emails with ease. If{' '}
                  <i>you</i> have HTML and CSS knowledge <i>you</i> can take it
                  a step further by using our code editor by clicking the{' '}
                  <strong>EDIT CODE</strong> link located at the bottom left of
                  the editing screen to personalize <i>your</i> email designs.
                </p>
              </div>

              <div>
                <h3 id='send-sms'>Send SMS Task</h3>
                <p>
                  When selecting this task <i>you</i> will be directed to the
                  SMS creation page. The default page will have the live preview
                  of the SMS texts. Below that will have the tabs.
                </p>
                <p style={{ textAlign: 'center' }}>
                  <img
                    src={TaskImgK}
                    alt='mobile sms editor'
                    className='img-fluid'
                    style={{ maxWidth: '375px', width: '100%' }}
                  />
                </p>
                <ul>
                  <li>
                    <p>
                      The second tab to the right of the{' '}
                      <strong>Preview</strong> tab will be the{' '}
                      <strong>Task Info</strong> tab. <i>You</i> can select the
                      recipients of this text message and what number number to
                      send from. To manage phone numbers <i>you</i> can click{' '}
                      <strong>Manage Numbers</strong> just above the list. To
                      learn more about managing numbers Click{' '}
                      <Link to='/ManagePhoneNumbersMobile'>Here</Link>.
                    </p>
                  </li>
                  {/* <li>
                    <p>
                      The final section is <strong>Merge Fields</strong>.{' '}
                      <strong>Merge Fields</strong> allows <i>you</i> to place
                      in <i>contact</i> names, numbers, etc.
                      <strong>Vast Reach</strong> will auto-populate names and
                      the recipient’s information for <i>you</i>. The default
                      values allows <i>you</i> to edit the vale for each active{' '}
                      <strong>Merge Field</strong>.
                    </p>
                  </li> */}
                </ul>
                <p>
                  The preview tab, any text inputted in the text input below
                  will appear in the preview window above that text input. It
                  will also show the number of characters that is ideal per text
                  conversational bubble.
                </p>
              </div>

              <div>
                <h3 id='add-contact-group'>Add Contact to Group</h3>
                <p>
                  The first thing <i>you</i> will see is two selection list's.
                  The first list permits <i>you</i> to select <i>contacts</i> to
                  be added, the second permits <i>you</i> to the select group(s)
                  those <i>contacts</i> will be added to.
                </p>
                <p style={{ textAlign: 'center' }}>
                  <img
                    src={TaskImgL}
                    alt='mobile add contact to group editor'
                    className='img-fluid'
                    style={{ maxWidth: '375px', width: '100%' }}
                  />
                </p>
              </div>

              <div>
                <h3 id='remove-contact-from-group'>
                  Remove Contact From Group Task
                </h3>
                <p>
                  <i>You</i> will see two selection list's. The first permits{' '}
                  <i>you</i> to select the <i>contact</i> to be removed, the
                  second permits <i>you</i> to select group(s) the{' '}
                  <i>contact</i> will be removed from.
                </p>
                <p style={{ textAlign: 'center' }}>
                  <img
                    src={TaskImgM}
                    alt='mobile remove from contact group editor'
                    className='img-fluid'
                    style={{ maxWidth: '375px', width: '100%' }}
                  />
                </p>
              </div>

              <div>
                <h3 id='move-contact-between-group'>
                  Move Contact Between Groups Task
                </h3>
                <p>
                  <i>You</i> will see three selection list's. The first permits{' '}
                  <i>you</i> to select the <i>contact</i> to be moved, the
                  second permits <i>you</i> to the select group the{' '}
                  <i>contact</i> will be removed from. The last list permits{' '}
                  <i>you</i> to select the group the <i>contact</i> will be
                  moved to.
                </p>
                <p style={{ textAlign: 'center' }}>
                  <img
                    src={TaskImgO}
                    alt='mobile move contact between group editor'
                    className='img-fluid'
                    style={{ maxWidth: '375px', width: '100%' }}
                  />
                </p>
                <p>
                  Once <i>you</i> have finished creation of a task <i>you</i>{' '}
                  can click the{' '}
                  <strong style={{ color: '#15B940' }}>Green</strong>{' '}
                  <strong>Check Icon</strong> button at the top right. Now{' '}
                  <i>you</i> will be taken back to the task page.
                </p>
              </div>

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Use <strong> Search & Filter Bar</strong> to find specific
                    tasks.
                  </p>
                </li>
                <li>
                  <p>
                    Remember <i>you</i> can use a previously created email
                    template by finding the template name <i>you</i> saved it
                    as.
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Set the <strong>When To Send</strong> too close to{' '}
                    <i>your</i> current time. Instead set the time a few minutes
                    ahead to give <i>yourself</i> adequate time to finish
                    creating <i>your</i> task.
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <h3>Creating Emails Using Our Premade Themes</h3>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
              <div className='video-wrapper'>
                <h3>Creating A Text Message Task</h3>
                <video
                  src={video2}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
              <div className='video-wrapper'>
                <h3>Add Contact To Group</h3>
                <video
                  src={video3}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
              <div className='video-wrapper'>
                <h3>Remove Contact From Group</h3>
                <video
                  src={video4}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
              <div className='video-wrapper'>
                <h3>Move Contact between Groups</h3>
                <video
                  src={video5}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
