import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../generalCss/style.scss';
import TaskListPage from './img/task_list.png';
import TaskAutomatePage from './img/automate_tasks.png';
import TaskBuildingPage from './img/task_builder.png';
import TaskSelectionPage from './img/task_selection.png';
// import language from '../utils/vast-languagepack';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';


export default function Automation() {
  return (
    <div>
      <div className='container-fluid text-center page-banners banner-wrap'>
        <div className='container page-header-text'>
          <h1>AUTOMATION</h1>
        </div>
      </div>

      <div className='page-top-wrap'>
        <div className='container-fluid padding-top'>
          <div className='container wrap'>
            <div className='col-xs-12 text-center'>
              <img
                src={TaskListPage}
                alt='Task List Page'
                className='img-fluid center-block'
                style={{ maxWidth: '800px', width: '100%' }}
              ></img>
            </div>
          </div>
        </div>

        <div className='container-fluid padding-top'>
          <div className='container wrap'>
            <div className='col-xs-12'>
              <h2 className='text-center'>AUTOMATE YOUR WORKFLOW WITH TASKS</h2>
            </div>
          </div>
        </div>

        <div className='container-fluid zero-padding'>
          <div className='row padding-full wrap'>
            <div className='col-xs-12 text-center col-md-6 order-md-2'>
              <img
                src={TaskSelectionPage}
                alt='Task Selection Page'
                className='img-fluid center-block'
              />
            </div>
            <div className='col-xs-12 col-md-6 order-md-1 image-space'>
              <h2 className='text-margin'>
                <LabelImportantIcon
                  style={{
                    fontSize: '2.5rem',
                    color: '#006fd9',
                    margin: '-6px 5px 0 0',
                  }}
                />
                Task Types
              </h2>
              <p className='text-margin'>
                Select from various task types such as creating an email task
                and with Vast Reach creating an email has never been easier.
                Create a custom email or pick form our various selections of
                email templates designed by us and ready to use by you. Send
                text messages to your customers, move, add and remove contacts
                from groups on the fly. With Vast Reach you can automate all
                your tasks and the possibilities are endless.
              </p>
            </div>
          </div>

          <div className='bg-2'>
            <div className='row padding-full wrap'>
              <div className='col-xs-12 text-center col-md-6'>
                <img
                  src={TaskAutomatePage}
                  alt='Task Automation Page'
                  className='img-fluid center-block'
                />
              </div>
              <div className='col-xs-12 col-md-6 image-space'>
                <h2 className='text-margin'>
                  <LabelImportantIcon
                    style={{
                      fontSize: '2.5rem',
                      color: '#006fd9',
                      margin: '-6px 5px 0 0',
                    }}
                  />
                  Automation Types
                </h2>
                <p className='text-margin'>
                  Vast Reach allows you to automate your tasks 3 ways. First you
                  can schedule your task to fire at a date and time of your
                  choosing. Next up you can add a task to fire as part of a
                  campaign calendar and select a date and time for that. Lastly,
                  you can add a task to a campaign funnel stage and set a delay
                  when it should fire.
                </p>
              </div>
            </div>
          </div>

          <div className='row padding-top wrap'>
            <div className='col-xs-12 text-center col-md-6 order-md-2'>
              <img
                src={TaskBuildingPage}
                alt='Email Task Builder'
                className='img-fluid center-block'
              />
            </div>
            <div className='col-xs-12 col-md-6 order-md-1 image-space'>
              <h2 className='text-margin'>
                <LabelImportantIcon
                  style={{
                    fontSize: '2.5rem',
                    color: '#006fd9',
                    margin: '-6px 5px 0 0',
                  }}
                />
                Task Builder
              </h2>
              <p className='text-margin'>
                Build a task in record time. For example our email task builder
                allows you to create a custom email or use one of our premade
                themes, further more you can customize those themes to save time
                and create a unique email template which is made easy using our
                snippets. Our snippets don’t require any knowledge in HTML code
                just simply drag and drop, but we have a built in code editor
                for those who do. With each task type we have a task builder to
                get the job done making it easier for you to get in and get out
                knowing your tasks well be automated saving you valuable time.
              </p>
            </div>
          </div>

          <div className='container-fluid padding-top'>
            <div className='container wrap'>
              <div className='col-xs-12'>
                <h2 className='text-center'>
                  WANT TO SEE MORE? SCHEDULE A LIVE DEMO TODAY!
                </h2>
              </div>
            </div>
          </div>

          <div className='container-fluid padding-full '>
            <div className='col-xs-12 zero-padding wrap'>
              <iframe
                title='book'
                src='https://koalendar.com/e/meet-with-wil-casillas?embed=true'
                width='100%'
                height='800px'
                frameborder='0'
              ></iframe>
              <div className='col-xs-12 text-center'>
                <h3>
                  Don't See a time that works for you? Get in contact with Wil
                  Casillas{' '}
                </h3>
                <p>
                  <a href='mailto: wcasillas@vasttechnologies.com'>
                    wcasillas@vasttechnologies.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}