import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import AccountSettingsMobileNaviScrollSpy from './AccountSettingsMobileNaviScrollSpy.js';
import AccountSettingsMobileC from './img/mobile_nav_account.jpg';
import EmailTypes from './img/email_types.jpg';
import ReCaptchaMobileImgB from '../ReCaptchaMobile/img/recaptcha_mobile1.png';
import CreateUserMobileImgA from '../CreateUserMobile/img/mobile_user_list1.png';
import video from '../AccountSettings/video/account_settings_tutorial.mp4';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function AccountSettingsMobile() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row wrap pad-bottom'>
            <AccountSettingsMobileNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/AccountSettings'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/AccountSettingsMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>
                ACCOUNT SETTINGS ON MOBILE
              </h1>
              <h2 className='overview'>Account Settings Overview</h2>
              <p>
                Account Settings allows <i>you</i> to set up a business name,
                default email address, mailing address and ReCaptcha. Filling
                these out here will greatly assist in auto-filling sections of
                emails, text messages, forms, etc. to save <i>you</i> time. This
                tutorial will explain how to set up <i>your</i> Account Settings
                & Preferences on a mobile device.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Setting Up Account Settings{' '}
              </h3>
              <p>
                Before setting up <strong>Account Settings</strong>, <i>you</i>{' '}
                must first set up <i>your</i> <strong>Payment Settings</strong>.
                This is mandatory and must be done before accessing{' '}
                <strong>Account Settings</strong>. Click the{' '}
                <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Button</strong> below to set up{' '}
                <strong>Payments</strong> before beginning this tutorial if{' '}
                <i>you</i> have not done so already.
              </p>
              <p>
                <Link to='/PaymentMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
              </p>

              <h3 className='navigation'>Navigate to Account Settings</h3>
              <p>
                To begin <i>you</i> must first access the Account Settings
                through the navigation. Tap the logo on the top left to open the
                navigation then tap <strong>Gear</strong> icon with the text{' '}
                <strong>Settings/Preferences</strong> at the bottom of the
                navigation menu to access the page.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={AccountSettingsMobileC}
                  alt='Navigation to Account Settings'
                  className='img-fluid'
                  style={{ maxWidth: '375px', width: '100%' }}
                />
              </p>
              <h2 className='account-page'>Setting Up Account Settings Page</h2>
              <p>
                Once on the Account Settings page <i>you</i> will see several
                input boxes.
              </p>
              <h3 className='business-name'>Business Name</h3>
              <p>
                Start by entering your <strong>Business Name</strong>. This will
                appear at the bottom of all of <i>your</i> emails.
              </p>
              <h3 className='mailing-address'>Mailing Address</h3>
              <p>
                The next step is to fill out the{' '}
                <strong>Mailing Address</strong> of the business under the{' '}
                <strong>Business Name</strong> section. By law this is required
                to be in all of the <i>your</i> emails. To learn more about the{' '}
                <strong>Can Spam Act</strong> compliance law click
                <a href='https://www.ftc.gov/tips-advice/business-center/guidance/can-spam-act-compliance-guide-business'>
                  {' '}
                  here
                </a>
                .
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={CreateUserMobileImgA}
                  alt='Account Settings business name and mailing address'
                  className='img-fluid'
                  style={{ maxWidth: '375px', width: '100%' }}
                />
              </p>
              <h3 className='email-address'>Default Email Address</h3>
              <p>
                Enter <i>your</i> email address that will be used to send out
                emails on this account. Additionally, <i>you</i> must verify
                your email after entering it. Click the{' '}
                <strong>SEND VERIFICATION EMAIL</strong> button after entering
                in <i>your</i> email. <i>You</i> will receive an email with a
                verification link. Once you verify your email, the{' '}
                <strong>Status</strong> field should show a green{' '}
                <strong style={{ color: '#15b940' }}>success</strong> message.
              </p>
              <h3 className='user-email-address'>User Email Address</h3>
              <p>
                <i>You</i> can use these addresses to send emails with Vast
                Reach. Users can only verify their own email addresses. All
                unverified emails can not be used to send emails.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={EmailTypes}
                  alt='default and user email address types'
                  className='img-fluid'
                  style={{
                    maxWidth: '375px',
                    width: '100%',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <h3 className='personal-data'>Delete My Personal Data</h3>
              <p>
                <i>You</i> may delete <i>your</i> user data at any time. Doing
                so may effect the way <i>you</i> use <strong>Vast Reach</strong>
                . This action is also permanent and can not be undone.
              </p>
              <h3 className='recaptcha'>Setting Up ReCaptcha</h3>
              <p>
                The last part is <strong>ReCaptcha</strong>. This will allow{' '}
                <i>you</i> to set up a ReCaptcha to prevent spam on all{' '}
                <i>your</i> forms sent out. To learn more about how to set-up
                ReCaptcha click <Link to='/RecaptchaMobile'>here</Link>. Once
                everything is set up click the{' '}
                <strong style={{ color: '#014c94' }}>Blue</strong>{' '}
                floating-action button with the <strong>Check</strong> icon in
                it to save your settings.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={ReCaptchaMobileImgB}
                  alt='Account Settings email and recaptcha'
                  className='img-fluid'
                  style={{
                    maxWidth: '375px',
                    width: '100%',
                    border: '1px solid #000000',
                  }}
                />
              </p>

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Set-up ReCaptcha so <i>you</i> can share <i>your</i> forms.
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Forget to verify <i>your</i> email.
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>

              <h2 className='additional-features'>Additional Features</h2>
              <p>
                Looking at the buttons at the top of the page will provide
                additional features. Tutorials for these features can be
                navigated to/from the buttons below.
              </p>
              <p style={{ textAlign: 'center' }}>
                <Link to='/PaymentMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Set-Up
                  </button>
                </Link>
                <Link to='/ManagePhoneNumbersMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Mange Phone Numbers
                  </button>
                </Link>
              </p>
              <p style={{ textAlign: 'center' }}>
                <Link to='/CreateUserMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Invite Users
                  </button>
                </Link>
                <Link to='/UserPermissionMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    User Permissions
                  </button>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
