import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import MultiChannelInboxMobileScrollSpy from './MultiChannelInboxMobileScrollSpy.js';
import MultiChannelInboxMobileImgB from './img/mobile_inbox_contact_details.png';
import MultiChannelInboxMobileImgC from './img/convo_search.jpg';
import MultiChannelInboxMobileImgD from './img/mobile_inbox_email.png';
import MultiChannelInboxMobileImgF from './img/mobile_inbox_text_keyboard.png';
import MultiChannelInboxMobileImgG from './img/mobile_inbox_text.png';
import MultiChannelInboxMobileImgH from './img/mobile_nav.jpg';
import MultiChannelInboxMobileImgI from './img/convo_list.jpg';
import MultiChannelInboxMobileImgJ from '../MultiChannelInbox/img/view_contact.png';
import MultiChannelInboxMobileImgK from '../MultiChannelInbox/img/search_this_conversation.png';
import MultiChannelInboxMobileImgL from '../MultiChannelInbox/img/filter_conversation.png';
import MultiChannelInboxMobileImgM from '../MultiChannelInbox/img/email_thread.png';
import MultiChannelInboxMobileImgP from '../MultiChannelInbox/img/contact_info.png';
import MultiChannelInboxMobileImgQ from '../MultiChannelInbox/img/tasks.png';
import MultiChannelInboxMobileImgR from '../MultiChannelInbox/img/groups.png';
import MultiChannelInboxMobileImgS from '../MultiChannelInbox/img/custom_attributes.png';
import MultiChannelInboxMobileImgT from '../MultiChannelInbox/img/custom_attributes_select.png';
import MultiChannelInboxMobileImgU from '../MultiChannelInbox/img/history.png';
import MultiChannelInboxMobileImgV from '../MultiChannelInbox/img/history_campagins.png';
import video from '../MultiChannelInbox/video/email_contact_tutorial.mp4';
import video2 from '../MultiChannelInbox/video/send_sms_to_contact_tutorial.mp4';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function MultiChannelInboxMobile() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <MultiChannelInboxMobileScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/MultiChannelInbox'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/MultiChannelInboxMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>
                ENGAGE YOUR CONTACTS WITH THE INBOX ON MOBILE
              </h1>
              <p className='inbox-intro'>
                By the end of this tutorial <i>you</i> will understand how to
                navigate the <strong>Inbox</strong>, how to start conversations
                with <i>contacts</i>, how to use the{' '}
                <strong>Contact Details</strong> tab and lastly how to switch
                between <strong>Phone Calls</strong>,{' '}
                <strong>Text Message</strong> and <strong>Emails</strong> on a
                mobile device.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Accessing Inbox
              </h3>
              <p>
                Before accessing <strong>Inbox</strong>, <i>you</i> must first
                have an account with an active subscription plan. Additionally,{' '}
                <i>you</i> will need a verified email to send emails or a phone
                number using <strong>Manage Phone Numbers</strong> on the
                account to send text messages and make calls. Lastly, <i>you</i>{' '}
                will need to create a <i>contact</i> to send emails, text
                messages or phone calls to. Click the{' '}
                <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Buttons</strong> below to set up anything <i>you're</i>{' '}
                missing before beginning this tutorial if <i>you</i> have not
                done so already.
              </p>
              <p>
                <Link to='/GettingStartedMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Getting Started
                  </button>
                </Link>
                <Link to='/PaymentMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
                <Link to='/ManagePhoneNumbersMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Manage Phone Numbers
                  </button>
                </Link>
                <Link to='/ContactsMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Create a Contact
                  </button>
                </Link>
              </p>
              <h3 className='navigation'>Navigate to Inbox</h3>
              <p>
                To access the <strong>Inbox</strong> <i>you</i> can click the{' '}
                <strong>Inbox Icon</strong> from the navigation (Logo at the top
                left).
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={MultiChannelInboxMobileImgH}
                  alt='inbox page navigation'
                  className='img-fluid'
                  style={{ maxWidth: '375px', width: '100%' }}
                />
              </p>
              <h2 className='convo-list'>Learning Your Conversation List</h2>
              <p>
                The first thing a <i>you</i> will see entering the{' '}
                <strong>Inbox</strong> page is a list of all of the active
                messages <i>you</i> have with <i>your</i> <i>contacts</i>, but a
                new account will show an empty list (see image below to see a
                populated list example). This empty list will fill up over time
                as <i>you</i> communicate with more <i>contacts</i>.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={MultiChannelInboxMobileImgI}
                  alt='inbox page navigation'
                  className='img-fluid'
                  style={{
                    maxWidth: '375px',
                    width: '100%',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <h3 className='search-bar'>
                Using The Search Bar To Find Contacts
              </h3>
              <p>
                At the top of the <strong>Conversation List</strong> <i>you</i>{' '}
                will see a <strong>Search Bar</strong>. <i>You</i> can search a
                specific <i>contact</i> here to find that conversation if the
                list is heavily populated.
              </p>

              <h3 className='add-contact'>Reach Out To a New Contact</h3>
              <p>
                To the right of the <strong>Search Bar</strong> is an{' '}
                <strong>Add Icon</strong> (pencil with a plus). Clicking this
                icon shows <i>you</i> the <strong>Conversation Preview</strong>{' '}
                and at the top of it brings up a text input bar. <i>You</i> can
                enter a <i>contact</i> name to begin a conversation with them or
                click the <strong>Arrow Icon</strong> toward the right of the
                input box to select a <i>contact</i> from the list of{' '}
                <i>contacts</i>. Lastly, <i>you</i> can click the{' '}
                <strong>Cancel</strong> text toward the right of the input box
                to cancel adding a <i>contact</i> if <i>you</i> change{' '}
                <i>your</i> mind.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={MultiChannelInboxMobileImgC}
                  alt='mobile inbox add contact'
                  className='img-fluid'
                  style={{
                    maxWidth: '375px',
                    width: '100%',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <h2 className='convo-column'>
                Understanding Conversation Preview
              </h2>
              <p>
                Once <i>you</i> have selected a <i>contact</i> to begin a
                conversation with from the <strong>Conversation List</strong>,{' '}
                <i>you</i> will be taken to the{' '}
                <strong>Conversation Preview</strong> page.
              </p>
              <h3 className='convo-header'>Using The Conversation Header</h3>
              <p>
                At the top of the <strong>Conversation Preview</strong> page,
                inside the <strong>Conversation Header</strong> shows the{' '}
                <i>contact's</i> name on the left side.
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h4>View Contact Details</h4>
                  <p>
                    Right below the name is an icon (eye icon), with the text{' '}
                    <strong>View this contact</strong>. Clicking this will take{' '}
                    <i>you</i> to the <strong>Contact Details</strong> page (We
                    will discuss this page further into this tutorial).
                  </p>
                  <p style={{ textAlign: 'left' }}>
                    <img
                      src={MultiChannelInboxMobileImgJ}
                      alt='view contact details'
                      className='img-fluid'
                      style={{
                        maxWidth: '500px',
                        width: '100%',
                        border: '1px solid #000000',
                      }}
                    />
                  </p>
                </li>
                <li>
                  <h4>Search This Conversation</h4>
                  <p>
                    To the right side of the header, the first icon (magnify
                    glass icon), clicking this icon changes the left of the
                    header where the name was to a <strong>Search Bar</strong>.
                    Inside this <strong>Search Bar</strong> <i>you</i> can
                    search specific text content from this conversation.
                  </p>
                  <p style={{ textAlign: 'left' }}>
                    <img
                      src={MultiChannelInboxMobileImgK}
                      alt='search conversation'
                      className='img-fluid'
                      style={{
                        maxWidth: '500px',
                        width: '100%',
                        border: '1px solid #000000',
                      }}
                    />
                  </p>
                </li>
                <li>
                  <h4>Filter This Conversation</h4>
                  <p>
                    The last icon on the right side of the header is the{' '}
                    <strong>Filter Icon</strong>, clicking this icon brings a
                    filter. At the top of the filter <i>you</i> can filter
                    content you want to see from specific{' '}
                    <strong>Date Ranges</strong> from this conversation. Below
                    that <i>you</i> can select what{' '}
                    <strong>Type of Communication</strong> <i>you</i> want to{' '}
                    <strong>ONLY</strong> see by selecting the{' '}
                    <strong>Checkboxes</strong> on or off. <i>You</i> can
                    combine the <strong>Date Ranges</strong> with the{' '}
                    <strong>Type of Communication</strong> or use them
                    separately. <i>You</i> can clear the filters by clicking the{' '}
                    <strong>CLEAR FILTERS</strong> button below.
                  </p>
                  <p style={{ textAlign: 'left' }}>
                    <img
                      src={MultiChannelInboxMobileImgL}
                      alt='filter conversation'
                      className='img-fluid'
                      style={{
                        maxWidth: '250px',
                        width: '100%',
                        border: '1px solid #000000',
                      }}
                    />
                  </p>
                </li>
              </ul>
              <h3 className='convo-preview'>Learning The Conversation Types</h3>
              <p>
                At the bottom of the <strong>Conversation Preview</strong> is
                the text area to send a message. Above the text area there are 2
                lists, the first one is <strong>TO</strong> and the second{' '}
                <strong>FROM</strong>. <i>You</i> can click the{' '}
                <strong>TO</strong> list to switch between text, call or email
                with this contact. Doing so will change the text area below to
                adjust for that use case.
              </p>
              <p>
                The <strong>Conversation Preview</strong> above the text area is
                where all communication between <i>you</i> and a <i>contact</i>{' '}
                are shown. Based on what <strong>Conversation Type</strong> is
                engaged <i>you</i> will have different visual information. We
                will go in depth to describe the visual differences of each{' '}
                <strong>Conversation Type</strong> starting with{' '}
                <strong>Text Messages</strong> below.
              </p>
              <p>
                Lastly, <strong>Vast Reach AI</strong> has a feature called{' '}
                <strong>Sentiment Analysis</strong> built in to the
                conversations. This shows <i>you</i> the sentiment behind a
                message sent by a <i>contact</i> using an emoji (
                <strong>happy, neutral or sad</strong>), giving <i>you</i> an
                idea of how the <i>contacts</i> attitude is in their message.
                This emoji is shown on the right side of the contacts name.
              </p>
              <p>
                <i>Note:</i> A universal visual indication to identify{' '}
                <i>contacts</i> and <i>users</i> are that all <i>contacts</i>{' '}
                are shown to have a{' '}
                <strong style={{ color: '#15b940' }}>Green</strong> background
                on their icons, while all <i>users</i> are shown to have{' '}
                <strong style={{ color: '#014c94' }}>Blue</strong> ones.
              </p>
              <p>
                <i>Note:</i> An icon to the left of each message will show what
                type of message it is (<strong>Smart Phone Icon</strong> for
                text, <strong>Mail Icon</strong> for email and{' '}
                <strong>Phone icon</strong> for calls).
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h4>Send Text Messages</h4>
                  <p>
                    Text messages on inbox is similar to any text messaging app
                    on <i>your</i> phone. At the bottom <i>you</i> enter{' '}
                    <i>your</i> message, to the left of that <i>you</i> can
                    click the <strong>Smiley Icon</strong> to add emojis and
                    lastly to the far right <i>you</i> can click the{' '}
                    <strong>Send Icon</strong> button (or hit send from your
                    keyboard) to send <i>your</i> message.
                  </p>
                  <p>
                    <i>Note:</i> Inside the{' '}
                    <strong>Conversation Preview</strong>, a text message bubble
                    will indicate at the bottom right of it if it has been
                    delivered or not.
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    <img
                      src={MultiChannelInboxMobileImgG}
                      alt='mobile inbox text message'
                      className='img-fluid'
                      style={{
                        maxWidth: '350px',
                        width: '100%',
                        margin: '5px',
                        border: 'solid 1px #000000',
                      }}
                    />
                    <img
                      src={MultiChannelInboxMobileImgF}
                      alt='mobile inbox text message with keyboard open'
                      className='img-fluid'
                      style={{
                        maxWidth: '350px',
                        width: '100%',
                        margin: '5px',
                        border: 'solid 1px #000000',
                      }}
                    />
                  </p>
                </li>
                <li>
                  <h4>Send Emails</h4>
                  <p>
                    Switching to <strong>Email</strong> from the{' '}
                    <strong>TO</strong> list will change the text area similar
                    to a word editor program. At the top of the text area{' '}
                    <i>you</i> will see a <strong>Subject</strong> text input
                    box, here <i>you</i> can enter the subject of this email.
                    Just below the <strong>Subject</strong> text input,{' '}
                    <i>you</i> will see an emoji icon and several text editor
                    icons to help edit and format <i>your</i> emails.
                  </p>
                  <p>
                    Once <i>you</i> finish editing <i>your</i> email, <i>you</i>{' '}
                    can click the <strong>Send Icon</strong> button on the
                    bottom right (or hit send from your keyboard) to send{' '}
                    <i>your</i> email.
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    <img
                      src={MultiChannelInboxMobileImgD}
                      alt='mobile inbox email editor'
                      className='img-fluid'
                      style={{
                        maxWidth: '400px',
                        width: '100%',
                        border: 'solid 1px #000000',
                      }}
                    />
                  </p>
                  <p>
                    Emails with several replies will be shown in{' '}
                    <strong>Threads</strong>. At the top of a{' '}
                    <strong>Thread</strong> is the email subject. <i>You</i> can
                    click on any email sent in a <strong>Thread</strong> to see
                    the contents of it. The latest email will always be the last
                    one in the <strong>Thread</strong> (at the bottom of the
                    thread).
                  </p>
                  <p style={{ textAlign: 'left' }}>
                    <img
                      src={MultiChannelInboxMobileImgM}
                      alt='email thread'
                      className='img-fluid'
                      style={{
                        maxWidth: '500px',
                        width: '100%',
                      }}
                    />
                  </p>
                </li>
                <li>
                  <h4>Make Phone Calls</h4>
                  <p>
                    Switching to <strong>Phone</strong> from the{' '}
                    <strong>TO</strong> list will change the text area to phone
                    call icons. To learn more about making a phone call click{' '}
                    <Link to='/PhoneCallsMobile'>here</Link>.
                  </p>
                </li>
              </ul>
              <h2 className='contact-details'>Setting Up Contact Details</h2>
              <p>
                <strong>Contact Details</strong> is accessed when <i>you</i>{' '}
                click <strong>View this contact</strong> from the{' '}
                <strong>Conversation Header</strong> of the{' '}
                <strong>Conversation Preview</strong> page. At the top of the
                page there is a{' '}
                <strong style={{ color: '#15b940' }}>Green</strong>{' '}
                <strong>Contact Icon</strong>, <strong>Contact Name</strong>,{' '}
                <strong>Contact Location</strong> and a small section for{' '}
                <strong>Notes</strong> relating to this contact. All of them are
                editable by clicking the text inputs and the information will
                auto save once changed. Next up is{' '}
                <strong>Contact History</strong> and the rest of the{' '}
                <strong>Contact Details</strong> which are discussed below.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={MultiChannelInboxMobileImgB}
                  alt='contact information'
                  className='img-fluid'
                  style={{
                    maxWidth: '375px',
                    width: '100%',
                    margin: '10px',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <h3 className='contact-history'>Contact History</h3>
              <p>
                Right below <strong>Notes</strong> <i>you</i> will see a{' '}
                <strong>History</strong> list showing all this interactions that
                involves this <i>contact</i>. At the top of the{' '}
                <strong>History</strong> list there are 2 tabs (
                <strong>ACTIVITY</strong> and <strong>CAMPAIGNS</strong>). While
                in the <strong>ACTIVITY</strong> tab <i>you</i> will see all the
                history of emails, texts and phone calls that this{' '}
                <i>contact</i> has been involved in and it will show the date
                and time of these interactions. Similarly, while in the{' '}
                <strong>CAMPAIGNS</strong> tab <i>you</i> will see all the
                campaign history this <i>contact</i> has been involved with.
              </p>
              <p style={{ textAlign: 'left' }}>
                <img
                  src={MultiChannelInboxMobileImgU}
                  alt='contact activity'
                  className='img-fluid'
                  style={{
                    maxWidth: '300px',
                    width: '100%',
                    margin: '10px',
                    border: '1px solid #000000',
                  }}
                />
                <img
                  src={MultiChannelInboxMobileImgV}
                  alt='contact campaign history'
                  className='img-fluid'
                  style={{
                    maxWidth: '300px',
                    width: '100%',
                    margin: '10px',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <h3 className='contact-info'>Contact Information</h3>
              <p>
                The first tab at the bottom of the{' '}
                <strong>Contact Details</strong> page is the{' '}
                <strong>Contact Info</strong> tab. In this tab <i>you</i> can
                add emails (top row) and phone numbers (bottom row) to reach
                this contact. To add new emails or phone numbers <i>you</i> can
                click the <strong>+ NEW</strong> button, a text input will
                appear with the icon of contact type (<strong>Mail</strong> or{' '}
                <strong>Phone</strong> icon). Once added, <i>you</i> can select
                to <strong>Opt In</strong> this <i>contact</i> by checking the
                box on the right or leave it blank to opt out this{' '}
                <i>contact</i> from receiving emails and/or text messages.
              </p>
              <p>
                <img
                  src={MultiChannelInboxMobileImgP}
                  alt='contact information'
                  className='img-fluid'
                  style={{
                    maxWidth: '300px',
                    width: '100%',
                    margin: '10px',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <h3 className='tasks'>Tasks</h3>
              <p>
                Clicking the <strong>Tasks</strong> tab will present <i>you</i>{' '}
                with <strong>Task</strong> options to begin with this{' '}
                <i>contact</i>. Clicking a <strong>Task</strong> will take{' '}
                <i>you</i> to the <strong>Tasks</strong> creation screen. To
                learn more about <strong>Tasks</strong> click{' '}
                <Link to='/TasksMobile'>here</Link>.
              </p>
              <p>
                <img
                  src={MultiChannelInboxMobileImgQ}
                  alt='contact details tasks'
                  className='img-fluid'
                  style={{
                    maxWidth: '300px',
                    width: '100%',
                    margin: '10px',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <h3 className='groups'>Groups</h3>
              <p>
                The <strong>Groups</strong> tab will allow <i>you</i> to add
                this <i>contact</i> to a <strong>Group</strong>. Select a{' '}
                <strong>Group</strong> from the list to add this <i>contact</i>{' '}
                to. <i>You</i> can also use the <strong>Suggestions</strong>{' '}
                below the list to quick add this <i>contact</i> to <i>your</i>{' '}
                most used <strong>Groups</strong>. To learn more about{' '}
                <strong>Groups</strong> click{' '}
                <Link to='/GroupsMobile'>here</Link>.
              </p>
              <p>
                <img
                  src={MultiChannelInboxMobileImgR}
                  alt='contact details groups'
                  className='img-fluid'
                  style={{
                    maxWidth: '300px',
                    width: '100%',
                    margin: '10px',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <h3 className='custom-attributes'>Custom Attributes</h3>
              <p>
                The final tab <strong>Custom Attributes</strong> allows{' '}
                <i>you</i> to add attributes to this <i>contact</i> (example:
                Occupation, Title, etc.). Clicking the <strong>+ NEW</strong>{' '}
                button will bring up a modal (pop-up window) called{' '}
                <strong>Add details</strong>, under that <i>you</i> can enter in
                an attribute title in the text input and toggle it on or off
                using the <strong>Check Box</strong> on the right of each text
                input. Once <i>you</i> are done, press the <strong>OK</strong>{' '}
                link at the bottom. <i>You</i> will see <i>your</i> new
                attribute(s) above the <strong>+ NEW</strong> button. <i>You</i>{' '}
                can enter in <i>your</i> string of text that suits the attribute
                and it will auto save for <i>you</i>.
              </p>
              <p style={{ textAlign: 'left' }}>
                <img
                  src={MultiChannelInboxMobileImgS}
                  alt='contact activity'
                  className='img-fluid'
                  style={{
                    maxWidth: '300px',
                    width: '100%',
                    margin: '10px',
                    border: '1px solid #000000',
                  }}
                />
                <img
                  src={MultiChannelInboxMobileImgT}
                  alt='contact campaign history'
                  className='img-fluid'
                  style={{
                    maxWidth: '300px',
                    width: '100%',
                    margin: '10px',
                    border: '1px solid #000000',
                  }}
                />
              </p>

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Upgrade <i>your</i> plan if <i>you</i> start to reach{' '}
                    <i>your</i> current plans limit to avoid overages.
                  </p>
                </li>
                <li>
                  <p>
                    Make sure <i>your</i> <i>contacts</i> have a phone number
                    before attempting to text or make calls (these options will
                    not show up under the <strong>TO</strong> list menu without
                    a phone number on that <i>contact</i>).
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Forget that creating more numbers than <i>your</i> plan
                    allows through <strong>Manage Phones</strong> will result in
                    overage charges.
                  </p>
                </li>
                <li>
                  <p>
                    Forget that exceeding <i>your</i> plans monthly email, text
                    and minutes will also result in overages.
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <h3>Sending Emails To Your Contacts</h3>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
              <div className='video-wrapper'>
                <h3>Sending Text Messages To Your Contacts</h3>
                <video
                  src={video2}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
