import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import UserPermissionMobileNaviScrollSpy from './UserPermissionMobileNaviScrollSpy.js';
import UserPermissionMobileImgA from './img/user_list1.png';
import UserPermissionMobileImgB from './img/user_permission1.png';
import UserPermissionMobileImgC from './img/user_permission2.png';
import UserPermissionMobileImgD from './img/user_permission3.png';
import UserPermissionMobileImgE from './img/user_permission4.png';
import UserPermissionMobileImgF from './img/user_permission5.png';
import AccountSettingsMobileC from '../AccountSettingsMobile/img/mobile_nav_account.jpg';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function UserPermissionMobile() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <UserPermissionMobileNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/UserPermission'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/UserPermissionMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>
                USER PERMISSIONS ON MOBILE
              </h1>
              <p className='intro'>
                This tutorial will teach <i>you</i> how to set up <i>your</i>{' '}
                <i>user</i> permissions from a <i>contact</i> level to page
                access as well as how to transfer the account ownership to a{' '}
                <i>user</i> on a mobile device.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Setting-up User Permissions
              </h3>
              <p>
                Before <strong>Setting-up User Permissions</strong>, <i>you</i>{' '}
                must first have an account set up with an active subscription.{' '}
                <i>You</i> must also <strong>Invite Users</strong> to set their
                permissions. Click the{' '}
                <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Buttons</strong> below to set up anything <i>you're</i>{' '}
                missing before beginning this tutorial if <i>you</i> have not
                done so already.
              </p>
              <p>
                <Link to='/PaymentMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
                <Link to='/CreateUserMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Create a User
                  </button>
                </Link>
              </p>
              <h3 className='navigation'>
                Navigate to Account Preferences & Settings
              </h3>
              <p>
                In order for <i>you</i> to set <i>user</i> permissions,{' '}
                <i>you</i> must visit the{' '}
                <strong>Account Preferences & Settings</strong> page by clicking
                in the navigation (logo at the top left).
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={AccountSettingsMobileC}
                  alt='account settings navigation'
                  className='img-fluid'
                  style={{ maxWidth: '375px', width: '100%' }}
                />
              </p>
              <p>
                Then on the <strong>Account Preferences & Settings</strong> page
                at the top right of that screen, click{' '}
                <strong>USER LIST</strong>. <i>You</i> will now be taken to a
                list of all the <i>users</i> available. A <i>user’s</i> profile
                page can be accessed by selecting that user from the list. Once
                clicked <i>you</i> will be taken to that <i>user’s</i> profile
                where <i>you</i> have access to that <i>user’s</i> information
                and permissions. <i>You</i> will see permissions box under the{' '}
                <i>users</i> information at the top, and below that are 2 tabs,
                by default the screen is the <strong>PERMISSIONS</strong> tab
                and next to it is an <strong>ACCOUNT OWNERSHIP TRANSFER</strong>{' '}
                tab.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={UserPermissionMobileImgA}
                  alt='mobile user list'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%' }}
                />
              </p>
              <h2 className='permissions'>Permissions</h2>
              <p>
                At the top of this page there is a{' '}
                <strong>Give Full Access</strong> check box. Checking this box
                will effetely give this <i>user</i> full access to all
                permissions only second to <i>you</i>.
              </p>
              <p>
                To the right of that there are 2 button tabs, the first of which
                is named <strong>CONTACTS</strong> and by default this will be
                selected. In the <strong>CONTACTS</strong> tabs <i>you</i> can
                give this <i>user</i> accesses to view or manipulate{' '}
                <i>contacts</i> and <i>contact</i> information. Turning on any
                switches gives the <i>user</i> by default view only access to
                the specific category. Turning off the switch restricts the{' '}
                <i>user</i> from that category entirely.
              </p>
              <h2 className='contacts'>Contacts</h2>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h3 className='text-message'>Text message</h3>
                  <p>
                    Turning this switch on by default allows a <i>user</i> to
                    view/read <i>contact</i> text messages only. <i>You</i> may
                    give permission to view and send SMS text messages by
                    selecting the list adjacent to the switch.
                  </p>
                </li>

                <li>
                  <h3 className='email'>Email</h3>
                  <p>
                    Turning this switch on by default allows a <i>user</i> to
                    view/read contact emails only. <i>You</i> may give
                    permission to view and send emails by selecting the list
                    menu adjacent to the switch.
                  </p>
                </li>

                <li>
                  <h3 className='call'>Call</h3>
                  <p>
                    Turning this switch on by default allows a <i>user</i> to
                    view/read call logs only. <i>You</i> may give permission to
                    view and call <i>contacts</i> by selecting the list adjacent
                    to the switch.
                  </p>
                </li>
              </ul>
              <p>
                Below the switches are regional permission. <i>You</i> can
                restrict the above <i>user</i> permissions to certain countries,
                states and cities by clicking <strong>+ ADD REGION</strong> text
                and selecting from the list.
              </p>
              <h2 className='data-access'>Data Access</h2>
              <p>
                This can be accessed by clicking the{' '}
                <strong>DATA ACCESS</strong> button next to the{' '}
                <strong>CONTACTS</strong> button at the top. Each switch in this
                section allows <i>you</i> to give this <i>user</i> access to
                select pages. Once a switch is on by default this <i>user</i>{' '}
                only has access to view that page. <i>You</i> can allow the{' '}
                <i>user</i> to view and edit in that page by selecting the list
                to do so.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={UserPermissionMobileImgB}
                  alt='mobile user page contacts permission tab'
                  className='img-fluid'
                  style={{
                    maxWidth: '375px',
                    width: '100%',
                    margin: '10px',
                  }}
                />
                <img
                  src={UserPermissionMobileImgC}
                  alt='mobile user page data access permission tab'
                  className='img-fluid'
                  style={{
                    maxWidth: '375px',
                    width: '100%',
                    margin: '10px',
                  }}
                />
              </p>
              <h2 className='owner-transfer'>Account Ownership Transfer</h2>
              <p>
                <i>You</i> can transfer account ownership to any <i>user</i>{' '}
                <i>you</i> choose to pass on to. A modal (pop-up window) will
                pop up giving <i>you</i> (the current owner) a warning and
                confirmation of what will happen if <i>you</i> do. To confirm{' '}
                <i>you</i> must type in the selected <i>users</i> full name,
                then press{' '}
                <strong>CONFIRM ACCOUNT OWNERSHIP TO "USER'S NAME"</strong> to
                account transfer. This is not reversible unless the <i>user</i>{' '}
                who is given ownership decides to transfer it back.
              </p>
              <p>
                Another option would be to click{' '}
                <a class='mailto' href='mailto:info@vastreach.com'>
                  here
                </a>{' '}
                to <i>contact</i> support to transfer <i>your</i> account.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={UserPermissionMobileImgD}
                  alt='mobile account ownership transfer tab'
                  className='img-fluid'
                  style={{
                    maxWidth: '250px',
                    width: '100%',
                    margin: '10px',
                  }}
                />
                <img
                  src={UserPermissionMobileImgE}
                  alt='mobile account ownership transfer prompt'
                  className='img-fluid'
                  style={{
                    maxWidth: '250px',
                    width: '100%',
                    margin: '10px',
                  }}
                />
                <img
                  src={UserPermissionMobileImgF}
                  alt='mobile account ownership transfer prompt with keyboard'
                  className='img-fluid'
                  style={{
                    maxWidth: '250px',
                    width: '100%',
                    margin: '10px',
                  }}
                />
              </p>

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Remember <strong>Account Ownership Transfer</strong> is
                    permanent and irreversible unless the <i>user</i> <i>you</i>{' '}
                    transferred to returns the ownership.
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Transfer <i>your</i> <strong>Account Ownership</strong> to
                    anyone claiming to be a <strong>Vast Reach</strong>{' '}
                    representative. We will never ask <i>you</i> for <i>your</i>{' '}
                    credentials or account ownership.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
