import React from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import SignUpFormsNaviScrollSpy from './SignUpFormsNaviScrollSpy.js';
import SignUpFormsImgA from './img/create_forms_button.png';
import SignUpFormsImgB from './img/forms1.jpg';
// import SignUpFormsImgC from './img/forms2.gif';
import SignUpFormsImgE from './img/responses.jpg';
import SignUpFormsImgF from './img/form_list_navigation.jpg';
import SignUpFormsImgJ from './img/next.jpg';
// import SignUpFormsImgK from './img/preview_editor.gif';
import SignUpFormsSubImg1 from './img/form.jpg';
import SignUpFormsSubImg2 from './img/description.jpg';
import SignUpFormsSubImg3 from './img/fields.jpg';
import SignUpFormsSubImg4 from './img/fonts.jpg';
import SignUpFormsSubImg5 from './img/background.jpg';
import SignUpFormsSubImg6 from './img/button.jpg';
import SignUpFormsSubImg7 from './img/logo.jpg';
import SignUpFormsImgL from './img/create_button.jpg';
import SignUpFormsImgQ from './img/recaptcha_warning.png';
import SignUpFormsImgR from './img/share_forms.jpg';
import video from './video/create_sign_up_form.mov';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function SignUpForms() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <SignUpFormsNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/SignUpForms'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/SignUpFormsMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>SIGN-UP FORMS</h1>
              <p className='sign-up-intro'>
                This tutorial will guide <i>you</i> on how to create sign-up
                forms on a desktop. The purpose of signup forms is to build an
                audience or to collect new contacts. By the end of this tutorial{' '}
                <i>you</i> will learn how to create a sign-up form, design it,
                customize it, learn how to embed/share the form and finally
                understand how to monitor the form analytics after it's live.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Creating a Sign-up Form
              </h3>
              <p>
                Before creating a <strong>Sign-up Form</strong>, <i>you</i> must
                first have a account set up with an active subscription. Setting
                up ReCaptcha for your forms to prevent spam is recommended.
                Click the <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Buttons</strong> below to set up anything <i>you're</i>{' '}
                missing before beginning this tutorial if <i>you</i> have not
                done so already.
              </p>
              <p>
                <Link to='/Payment'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
                <Link to='/ReCaptcha'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    ReCaptcha
                  </button>
                </Link>
              </p>
              <p>
                <i>Note:</i> Although not required to create a{' '}
                <strong>Sign-up From</strong>, <i>you</i> can send these forms
                directly to a <strong>Group</strong>. However, <i>you</i> need
                create <i>contacts</i> to add to a group. Click{' '}
                <Link to='/Contacts'>here</Link> to learn how to create a{' '}
                <i>contact</i> and click <Link to='/Groups'>here</Link> to learn
                how to create a group.
              </p>
              <h3 className='navigation'>Navigate to Sign-up Forms</h3>
              <p>
                <i>You</i> can build a sign-up form by clicking the{' '}
                <strong>Forms</strong> link in the navigation (Left side menu
                bar). Additionally, <i>you</i> can navigate to the form list
                using the following link:{' '}
                <a href='https://console.vastreach.com/forms'>
                  https://console.vastreach.com/forms
                </a>
                .
              </p>
              <p style={{ textAlign: 'center', margin: '15px 0 15px 0' }}>
                <img
                  src={SignUpFormsImgF}
                  alt='form navigation'
                  className='img-fluid'
                  style={{
                    maxWidth: '1000px',
                    width: '100%',
                    border: '1px solid #dddddd',
                  }}
                />
              </p>

              <h2 className='create-form'>Create a New Form</h2>
              <p>
                To begin <i>you</i> can click the <strong>ADD FORM</strong>{' '}
                button at the top right of the form list to begin.
                <p style={{ margin: '10px 0 0 0' }}>
                  <img
                    src={SignUpFormsImgA}
                    alt='create form button'
                    className='img-fluid'
                    style={{ maxWidth: '150px', width: '100%' }}
                  />
                </p>
                <i>You</i> will be taken to a step by step process for creating
                forms. On step one the <i>you</i> can enter a{' '}
                <strong>Form Name</strong>, select a form type and optionally
                add a <i>contact</i> group automation to the form (
                <Link to='/Groups'> Click here </Link> to learn more about
                groups). In our case, for the purposes of the tutorial we will
                select <strong>SIGNUP FORM</strong> and click{' '}
                <strong>NEXT</strong>.
              </p>
              <p style={{ margin: '0 0 10px 0' }}>
                <img
                  src={SignUpFormsImgJ}
                  alt='next button'
                  className='img-fluid'
                  style={{ maxWidth: '100px', width: '100%' }}
                />
              </p>
              <p>
                <i>Note:</i> If <i>you</i> click the <strong>CANCEL</strong>{' '}
                button at the top left, <i>you</i> will return to the form list.
              </p>
              <p>
                <i>Note:</i> <i>You</i> can edit the form name and manage groups
                on the next step as well.
              </p>

              <p style={{ margin: '10px 0 10px 0' }}>
                <img
                  src={SignUpFormsImgB}
                  alt='form select'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>

              <h2 className='build'>Building a Form</h2>
              <p>
                <i>You</i> will now be taken to the <strong>Build</strong> step
                to begin creating <i>your</i> from.
                <p style={{ margin: '10px 0 10px 0' }}>
                  <ReactPlayer
                    muted
                    playing
                    loop
                    playsinline
                    width='100%'
                    height='100%'
                    url={[
                      `${process.env.PUBLIC_URL}/video/preview_editor.mp4`,
                      `${process.env.PUBLIC_URL}/video/preview_editor.gif`,
                    ]}
                  />
                </p>
                On the left side is a preview window. Here <i>you</i> will see{' '}
                <i>your</i> form edits in real time as they happen. <i>You</i>{' '}
                can also drag and drop input fields in any order.
                <p style={{ margin: '10px 0 5px 0' }}>
                  <ReactPlayer
                    style={{ maxWidth: '400px' }}
                    muted
                    playing
                    loop
                    playsinline
                    width='100%'
                    height='100%'
                    url={[
                      `${process.env.PUBLIC_URL}/video/forms2.mp4`,
                      `${process.env.PUBLIC_URL}/video/forms2.gif`,
                    ]}
                  />
                </p>
              </p>
              <p>
                On the right pane of this step there are several sections (Form,
                Look and Feel, Fields, Font, Background and Button). Each
                section can be expanded and collapsed. This tutorial will
                explain the functions of each section starting with Form.
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h3 className='form_section'>Review Your Form Information</h3>
                  <p>
                    In the Form section <i>you</i> can edit the{' '}
                    <strong>Form Name</strong> like in the previous step as well
                    as add <strong>Contact Groups</strong>.
                  </p>
                  <p style={{ margin: '10px 0 10px 0' }}>
                    <img
                      src={SignUpFormsSubImg1}
                      alt='form container'
                      className='img-fluid'
                      style={{ maxWidth: '400px', width: '100%' }}
                    />
                  </p>
                </li>
                <li>
                  <h3 className='look_and_feel_section'>
                    Design The Look and Feel of Your Forms
                  </h3>
                  <p>
                    This section is where all of the style and design elements
                    of this form is selected. At the top of this section there
                    are several text tabs, each of these tabs allow <i>you</i>{' '}
                    to style a particular piece of this from. By default{' '}
                    <i>you</i> will begin with the <strong>Description</strong>{' '}
                    tab.
                  </p>
                  <ul style={{ listStyle: 'none' }}>
                    <li>
                      <h4>Description </h4>
                      <p>
                        On this tab <i>you</i> can select the type of embedding
                        on this form for <i>your</i> web page, enter the title
                        of the form and select its color and lastly add a
                        description of this form and also select the description
                        color. When this tab is completed click the{' '}
                        <strong>Fields</strong> tab to continue.
                      </p>
                      <p style={{ margin: '0 0 10px 0' }}>
                        <img
                          src={SignUpFormsSubImg2}
                          alt='look and feel container'
                          className='img-fluid'
                          style={{ maxWidth: '400px', width: '100%' }}
                        />
                      </p>
                    </li>
                    <li>
                      <h4>Fields </h4>
                      <p>
                        Select a desired field in this tab by turning that
                        fields switch on. This will add that option as a from
                        input to <i>your</i> forms seen in the preview (by
                        default at least a phone number or email is required).
                        On the right side of each switch there will be a{' '}
                        <strong>Required </strong>
                        check box. Checking this box will make sure the{' '}
                        <i>contact</i> is required to fill out this field.
                        Additionally, there will be a <strong>*</strong> by each
                        required field letting the <i>contact</i> know this is a
                        required field.
                      </p>
                      <p style={{ margin: '0 0 10px 0' }}>
                        <img
                          src={SignUpFormsSubImg3}
                          alt='fields container'
                          className='img-fluid'
                          style={{ maxWidth: '400px', width: '100%' }}
                        />
                      </p>
                    </li>
                    <li>
                      <h4>Font </h4>
                      <p>
                        The Font tab has a font selector and a font color
                        selector. The list allows <i>you</i> to select from a
                        verity of web safe fonts. The color selector allows{' '}
                        <i>you</i> to select the color for the font that will be
                        displayed on this entire form (this does not apply to
                        the title, description text or the button text).
                      </p>
                      <p style={{ margin: '0 0 10px 0' }}>
                        <img
                          src={SignUpFormsSubImg4}
                          alt='font container'
                          className='img-fluid'
                          style={{ maxWidth: '400px', width: '100%' }}
                        />
                      </p>
                    </li>
                    <li>
                      <h4>Background </h4>
                      <p>
                        This tab allows the <i>you</i> to change this forms
                        background color.
                      </p>
                      <p style={{ margin: '0 0 10px 0' }}>
                        <img
                          src={SignUpFormsSubImg5}
                          alt='background container'
                          className='img-fluid'
                          style={{ maxWidth: '400px', width: '100%' }}
                        />
                      </p>
                    </li>
                    <li>
                      <h4>Button </h4>
                      <p>
                        The button tab begins with a text input field, enter the
                        text that will be be shown on the button of this form
                        (example: Send, Subscribe, etc.). The first color
                        selector below the text input field will allow{' '}
                        <i>you</i> to change the text color while the second one
                        allows <i>you</i> to change the button background color.
                      </p>
                      <p style={{ margin: '0 0 10px 0' }}>
                        <img
                          src={SignUpFormsSubImg6}
                          alt='button container'
                          className='img-fluid'
                          style={{ maxWidth: '400px', width: '100%' }}
                        />
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3 className='logo_section'>Add Your Logo to Your Forms</h3>
                  <p>
                    In this section <i>you</i> can add <i>your</i> logo to{' '}
                    <i>your</i> forms by using a direct link to the image or
                    clicking the <strong>BROWSE</strong> button and upload it
                    from <i>your</i> computer.
                  </p>
                  <p style={{ margin: '0 0 10px 0' }}>
                    <img
                      src={SignUpFormsSubImg7}
                      alt='logo container'
                      className='img-fluid'
                      style={{ maxWidth: '400px', width: '100%' }}
                    />
                  </p>
                </li>
              </ul>
              <p>
                To complete creating a form <i>you</i> can click the{' '}
                <strong>CREATE</strong> button on the top right of this step.
                <i>You</i> will then be taken back to the form list view.
              </p>
              <p style={{ margin: '10px 0 10px 0' }}>
                <img
                  src={SignUpFormsImgL}
                  alt='group select'
                  className='img-fluid'
                  style={{ maxWidth: '100px', width: '100%' }}
                />
              </p>
              <h2 className='embed-share' style={{ margin: '20px 0 10px 0' }}>
                Share Your Forms
              </h2>
              <p>
                <img
                  src={SignUpFormsImgQ}
                  alt='recaptcha missing warning'
                  className='img-fluid'
                  style={{
                    maxWidth: '1000px',
                    width: '100%',
                    border: '1px solid #dddddd',
                  }}
                />
              </p>
              <p>
                Clicking the <strong>Share</strong> icon allows <i>you</i> to
                embed this form to <i>your</i> website or share a direct link to
                this form. There are 2 fields, the top field is for embedding
                and the bottom is for linking. <i>You</i> can click the{' '}
                <strong>COPY</strong> button of the desired option and it will
                automatically be added to <i>your</i> clipboard to paste.
              </p>
              <p>
                <img
                  src={SignUpFormsImgR}
                  alt='form sharing'
                  className='img-fluid'
                  style={{
                    maxWidth: '600px',
                    width: '100%',
                    border: '1px solid #dddddd',
                  }}
                />
              </p>
              <p>
                <Link to='/ReCaptcha'>Click here</Link> to learn about how to
                set-up ReCaptcha.
              </p>
              <h2 className='responses' style={{ margin: '20px 0 10px 0' }}>
                Analyze Your Form Responses and Statistics
              </h2>
              <p>
                Selecting the <strong>Analyze</strong> icon lets <i>you</i> see
                the responses from the <i>contacts</i> that filed out the form.
                The aggregate tiles at to top shows <i>you</i> the total form
                responses and the number of <i>contacts</i> that visited the
                form.
              </p>
              <p>
                <img
                  src={SignUpFormsImgE}
                  alt='form responses'
                  className='img-fluid'
                  style={{
                    maxWidth: '800px',
                    width: '100%',
                    border: '1px solid #dddddd',
                  }}
                />
              </p>

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Remember that at least a phone number <strong>OR</strong>{' '}
                    email is required for every <strong>Sign-up Form</strong> in
                    order to get in touch with the <i>contact</i>.
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Forget all <strong>Sign-up Forms</strong> require a{' '}
                    <strong>Form Name</strong>, <strong>Title</strong>,{' '}
                    <strong>Description</strong>, <strong>Contact Group</strong>{' '}
                    and a <strong>Phone Number</strong> or{' '}
                    <strong>Email</strong> to be created/updated.
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <h3>Creating a Sign-up Form</h3>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
