import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import GroupsNaviScrollSpy from './GroupsNaviScrollSpy.js';
import GroupsImgA from './img/groups1.jpg';
import GroupsImgB from './img/groups2.png';
import GroupsImgD from './img/desktop_nav.jpg';
import GroupsImgE from './img/sort_bar.jpg';
import GroupsImgF from './img/groups4.jpg';
import GroupsImgG from './img/add_group_button.png';
import video from './video/groups_tutorial.mov';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function Groups() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <GroupsNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/Groups'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/GroupsMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>
                CREATE A CONTACTS GROUP
              </h1>
              <p className='groups-intro'>
                Contact Groups help <i>you</i> organize <i>your</i>{' '}
                <i>contacts</i> for different marketing strategies and
                campaigns. This tutorial will teach <i>you</i> how to create a
                group and add <i>contacts</i> to it. It will also teach{' '}
                <i>you</i> how to navigate the list view of <i>contacts</i> in
                the group and its settings.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Creating a Contact Group
              </h3>
              <p>
                Before creating a <strong>Contact Group</strong>, <i>you</i>{' '}
                must first have an account set up with an active subscription.{' '}
                <i>You</i> will also need to have created <i>contacts</i> in
                order to add them to a group <i>you</i> create. Click the{' '}
                <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Buttons</strong> below to set up anything <i>you're</i>{' '}
                missing before beginning this tutorial if <i>you</i> have not
                done so already.
              </p>
              <p>
                <Link to='/GettingStarted'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Getting Started
                  </button>
                </Link>
                <Link to='/Payment'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
                <Link to='/Contacts'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Create a Contact
                  </button>
                </Link>
              </p>
              <h3 className='navigation'>Navigate to Contact Groups</h3>
              <p>
                <i>You</i> can begin creating a group by clicking the{' '}
                <strong>Contact Groups</strong> link in the navigation (Left
                side menu bar). Additionally, <i>you</i> can navigate to the{' '}
                <strong>Contact Groups List</strong> page using the following
                link:{' '}
                <a href='https://console.vastreach.com/contactgroups'>
                  https://console.vastreach.com/contactgroups
                </a>
                .
              </p>
              <p>
                <img
                  src={GroupsImgD}
                  alt='contact group page navigation'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              <h3 className='new-groups-list'>
                Understanding Your Contact Groups List With a New Account
              </h3>
              <p>
                Once on the <strong>Contact Groups List</strong> page <i>you</i>{' '}
                will see an empty <i>contact</i> list. <i>You</i> must first
                create a group. Clicking the <strong>+ Add Group</strong> button
                at the center of the empty list or clicking the{' '}
                <strong>+ ADD GROUP</strong> button at the top right of the{' '}
                <strong>Contact Groups List</strong> page will take <i>you</i>{' '}
                to the group creation page.
              </p>
              <p>
                <img
                  src={GroupsImgA}
                  alt='create contact upload page'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              <h3 className='groups-list'>
                Learning To Manage a Populated Contact Groups List
              </h3>
              <p>
                A populated <strong>Contact Groups List</strong> page will have
                list of groups. Each group in the list has a name to the left, a{' '}
                <strong>contact icon</strong> to the right with the number of{' '}
                <i>contacts</i> that are in each group and lastly an ellipsis
                icon (3 dot icon) at the far right. Clicking the ellipsis icon
                brings up a menu with an option to <strong>Edit</strong> or{' '}
                <strong>Delete</strong> this group (selecting delete will prompt
                a confirmation window).
              </p>
              <p>
                <img
                  src={GroupsImgF}
                  alt='populated contact group page'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h4>Using The Search & Filter Bar</h4>
                  <p>
                    This bar will allow <i>you</i> to sort, filter or search a
                    specific <i>contact</i> from <i>your</i> list of{' '}
                    <i>Contacts.</i>
                  </p>
                  <p>
                    <img
                      src={GroupsImgE}
                      alt='contact list search & filter bar'
                      className='img-fluid'
                      style={{ maxWidth: '200px', width: '100%' }}
                    />
                  </p>
                </li>
              </ul>
              <p>
                Clicking the <strong>+ ADD GROUP</strong> button at the top
                right of the <strong>Contact Groups List</strong> page will take{' '}
                <i>you</i> to the group creation page. Unlike an empty contact
                list, this button is the only way to create a group from this
                page once populated with <i>contacts</i>.
              </p>
              <img
                src={GroupsImgG}
                alt='add group button'
                className='img-fluid'
                style={{ maxWidth: '200px', width: '100%' }}
              />
              <h2 className='create-group-page'>Creating a Group</h2>
              <p>
                On the create group page <i>you</i> will see an{' '}
                <strong>input text box</strong>, below that a{' '}
                <strong>list selection</strong> and lastly a{' '}
                <strong>grayed out submit button</strong> at the top right of
                the page. In the <strong>input text box</strong> <i>you</i> can
                enter the name of this group. Below that <i>you</i> can select
                which of <i>your</i> <i>contacts</i> to add to this group. If{' '}
                <i>you</i> have no <i>contacts</i> then <i>you</i> can create
                one from the list of <i>contacts.</i> To learn more about{' '}
                <strong>Contacts</strong> click <Link to='/Contacts'>here</Link>
                .
              </p>
              <p>
                Once <i>you</i> are done adding the desired <i>contacts</i> to
                this group <i>you</i> will notice the previously{' '}
                <strong>grayed out submit button</strong> at the top right
                became <strong style={{ color: '#014c94' }}>Blue</strong>. Click
                the <strong>SUBMIT</strong> button to create this group.
              </p>
              <p>
                <i>Note:</i> <i>You</i> must have at least one <i>contact</i> or
                the <strong>SUBMIT</strong> button will not become{' '}
                <strong style={{ color: '#014c94' }}>Blue</strong> and
                clickable. However, if <i>you</i> enter a group name and add no{' '}
                <i>contacts</i>, the group will then be auto-saved without any{' '}
                <i>contacts</i> inside it. <i>You</i> will see <i>your</i> empty
                group in the <strong>Group List</strong> page.
              </p>
              <p>
                <img
                  src={GroupsImgB}
                  alt='create contact page'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Remember a group will not be created without a{' '}
                    <strong>Group Name</strong>, but it can be made without any{' '}
                    <i>contacts</i>.
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Use <i>contacts</i> from a group with mixed contact
                    information (some <i>contacts</i> only have emails or only
                    have phone numbers) as this will result in mixed results.
                    For example, when sending a scheduled email, only{' '}
                    <i>contacts</i> with emails will get the email and those in
                    the group without one will not. If this is not intended{' '}
                    <i>you</i> will reach less <i>contacts</i>.
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
