import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../generalCss/style.scss';
import CampaignFunnelStepPreview from './img/campaign_funnel_view.png';
import CampaignCalendarStep from './img/campaign_calendar.jpg';
import CampaignFunnelStep from './img/campaign_funnel.png';
import CampaignAnalyzeStep from './img/campaign_analyze.jpg';
// import language from '../utils/vast-languagepack';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';

export default function CampaignInfo() {
  return (
    <div>
      <div className='container-fluid text-center page-banners banner-wrap'>
        <div className='container page-header-text'>
          <h1>CAMPAIGNS</h1>
        </div>
      </div>

      <div className='page-top-wrap'>
        <div className='container-fluid padding-top'>
          <div className='container wrap'>
            <div className='col-xs-12 text-center'>
              <img
                src={CampaignFunnelStepPreview}
                alt='Campaign Funnel Preview'
                className='img-fluid center-block'
                style={{ maxWidth: '800px', width: '100%' }}
              ></img>
            </div>
          </div>
        </div>

        <div className='container-fluid padding-top'>
          <div className='container wrap'>
            <div className='col-xs-12'>
              <h2 className='text-center'>LET'S DIVE INTO CAMPAIGNS</h2>
            </div>
          </div>
        </div>

        <div className='container-fluid zero-padding'>
          <div className='row padding-full wrap'>
            <div className='col-xs-12 text-center col-md-6 order-md-2'>
              <img
                src={CampaignFunnelStep}
                alt='Campaign Funnels Step'
                className='img-fluid center-block'
              />
            </div>
            <div className='col-xs-12 col-md-6 order-md-1 image-space'>
              <h2 className='text-margin'>
                <LabelImportantIcon
                  style={{
                    fontSize: '2.5rem',
                    color: '#006fd9',
                    margin: '-6px 5px 0 0',
                  }}
                />
                Campaign Funnel
              </h2>
              <p className='text-margin'>
                Campaign Funnels allow you to create complex marketing campaigns
                in a simple and easy to use interface. No more complicated work
                flows and tedious learning curves to understand the basics.
                Simply create a task and our event goals will execute when a
                goal is reached.
              </p>
            </div>
          </div>

          <div className='bg-2'>
            <div className='row padding-full wrap'>
              <div className='col-xs-12 text-center col-md-6'>
                <img
                  src={CampaignCalendarStep}
                  alt='Campaign Calendar Step'
                  className='img-fluid center-block'
                />
              </div>
              <div className='col-xs-12 col-md-6 image-space'>
                <h2 className='text-margin'>
                  <LabelImportantIcon
                    style={{
                      fontSize: '2.5rem',
                      color: '#006fd9',
                      margin: '-6px 5px 0 0',
                    }}
                  />
                  Campaign Calendar
                </h2>
                <p className='text-margin'>
                  The Campaign Calendar lets you set up tasks to fire on a date
                  and time of your choosing.
                </p>
              </div>
            </div>
          </div>

          <div className='row padding-top wrap'>
            <div className='col-xs-12 text-center col-md-6 order-md-2'>
              <img
                src={CampaignAnalyzeStep}
                alt='Campaign Analyze Step'
                className='img-fluid center-block'
              />
            </div>
            <div className='col-xs-12 col-md-6 order-md-1 image-space'>
              <h2 className='text-margin'>
                <LabelImportantIcon
                  style={{
                    fontSize: '2.5rem',
                    color: '#006fd9',
                    margin: '-6px 5px 0 0',
                  }}
                />
                Campaign Statistics
              </h2>
              <p className='text-margin'>
                You can analyze the progression of your campaigns and keep track
                of your marketing goals.
              </p>
            </div>
          </div>

          <div className='container-fluid padding-top'>
            <div className='container wrap'>
              <div className='col-xs-12'>
                <h2 className='text-center'>
                  WANT TO SEE MORE? SCHEDULE A LIVE DEMO TODAY!
                </h2>
              </div>
            </div>
          </div>

          <div className='container-fluid padding-full '>
            <div className='col-xs-12 zero-padding wrap'>
              <iframe
                title='book'
                src='https://koalendar.com/e/meet-with-wil-casillas?embed=true'
                width='100%'
                height='800px'
                frameborder='0'
              ></iframe>
              <div className='col-xs-12 text-center'>
                <h3>
                  Don't See a time that works for you? Get in contact with Wil
                  Casillas{' '}
                </h3>
                <p>
                  <a href='mailto: wcasillas@vasttechnologies.com'>
                    wcasillas@vasttechnologies.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
