import React, { Component } from "react";
import { Link } from "react-router-dom";


export default class TermsOfService extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
        <div className="container-fluid padding-full">
          <div className="container wrap">
            <h1 className='pad-top'>Terms Of Service</h1>
            <p>Thanks for using Vast Reach</p>
            <p>Please read these Terms thoroughly. By using Vast Reach or signing up for an account, you’re agreeing to these Terms, which will result in a legal agreement between you and Vast Reach (“Agreement”). </p>
            <p>Here are a few definitions to help better understand these Terms.
              Vast Reach (“Vast Reach,” “we,” or “us”) is an online marketing platform (the “App or Console”) that allows Our Service enables our Customers to, among other things, create and manage campaigns to collect leads, send marketing communication, create signup forms, create surveys/questionnaires, send text messages, manage public cloud storage and send emails.
              Find out more about our services on <Link to="/">the homepage</Link></p>
            <p>
              The App is offered through our websites, including https://www.VastReach.com,  https://console.vastreach.com and any other website or mobile application owned, operated or controlled by us (we’ll collectively refer to these as the “Vast Reach Console, app or site” and together with the App, the “Service”). Vast Reach is a Pennsylvania company whose legal name is Vast Technologies Inc. d/b/a Vast Reach. Vast Reach has employees, independent contractors, and representatives (“our Team”). As a customer of the Service or a representative of an entity that’s a customer of the Service, you’re a “Customer” according to this Agreement (or “you”).
            </p>
            <p>
              These Standard Terms of Use (“Terms,” including our <Link to="/privacypolicy">Privacy Policy</Link> and  <Link to="/acceptableuse">Acceptable Use Policy</Link>) define the terms and conditions under which you’re allowed to use the Service in accordance with the Agreement, which in addition to our Privacy Policy and Cookie Statement, describe how we’ll treat your account and the data we collect and process about you, your end users, and your contacts while you’re a Customer. If you don’t agree to these Terms, you must immediately discontinue your use of the Service.
            </p>
            <p>
              Some features of the Service are offered as add-ons to your Vast Reach account (“Add-ons”). Unless expressly stated otherwise, these Terms apply to the use of Add-ons.
               Also note that a Customer’s account may have access to unique features of the Vast Reach based on their historic usage or status.

            </p>
            <p>
              If you download the App through other app store stores (“App Marketplace), you acknowledge and agree that:
              (a) these Terms are between vast reach and you the user or customer, and not with the App Provider, and that we are responsible for the Service, not the App Provider;
               (b) the App Marketplace has no obligation to maintain and provide support services or handle any claims;
               (c) the App Provider is not responsible for addressing any claims you or any third party have relating to the App; and
               (d) the App Marketplace is a third party beneficiary of these Terms as related to your use of the App, and the App Marketplace will have the right to use these Terms as related to your use of the App against you.
            </p>

            <h2>Your Account</h2>
            <h3>Am I eligible to use Vast Reach?</h3>
            <p>
              In order to use the Service, you must:
be at least eighteen (18) years old and able to enter into legally binding contracts;
complete the account registration process;
agree to these Terms;
provide true, complete, and up-to-date contact and billing information;
not be based in Cuba, Iran, North Korea, Syria, or any other territory that is subject to a U.S. government embargo, or that has been designated by the U.S. government as a “terrorist-supporting” country; and
not be listed on any U.S. government list of prohibited or restricted persons.

            </p>
            <p>
              By using the Vast Reach, you represent and warrant that you meet all the requirements listed above, and that you won’t use the Service in a way that violates any laws or regulations. Note that by representing and warranting, you are making a legally enforceable promise.

            </p>
            <p>
              Vast Reach may refuse service, close accounts of any Customers, and change eligibility requirements at any time.

            </p>
            <h3>How Long are these terms applicable?</h3>
            <p>
              When you sign up for an account and agree to these Terms, the Agreement between you and Vast Reach is formed, the term of the Agreement (the “Term”) will start. The Term will go on for as long as you have a Vast Reach account or until you or Vast Reach terminates the Agreement in accordance with these Terms, whichever occurs first. If you sign up for an account on behalf of a business or other entity, you agree that you have the authority to accept these Terms and enter into the Agreement on its behalf.
            </p>
            <h3>Can I Close my Account?</h3>
            <p>
              You or Vast Reach may terminate the Agreement at any time and for any reason by terminating your Vast Reach account or giving notice.
              Vast Reach may terminate the Service to you at any time, with or without cause. If we terminate your account without cause, and your account is a paid account, we’ll refund a prorated portion of your monthly prepayment.
              We won’t refund or reimburse you, including if your account is suspended or terminated for a breach or violation of any Terms.
              If your account is inactive for 2 years or more, we may terminate your account and you won’t be entitled to a refund for a prepaid month.
              Once your account is terminated, you acknowledge and agree that we may permanently delete your account and all the data associated with it, including your Campaigns.
              Usernames are unique and can only be used once. If your account has been terminated, the username will no longer be available for use on any future accounts and cannot be reclaimed.
            </p>
            <h3>Do these terms Change?</h3>
            <p>
              Vast Reach may change any of the Terms by posting revised Terms on our Vast Reach Console and Site.
              If you do not terminate your account, the new Terms will be effective immediately upon posting and apply to any further use of the Service.
              We may change the Service, Add-ons, or any features of the Service at any time, and we may discontinue the Service, Add-ons, or any features of the Service at any time.
            </p>
            <h3>Is my account password and information secure and up to date?</h3>
            <p>
              You’re responsible for keeping your account username and password a secret.
              You’re responsible for any account that you have access to and any activity occurring in the account (this excludes tasks that Vast Reach is directly responsible for that isn’t performed in accordance with your instructions), whether or not you authorized that activity.
              You’ll immediately notify Vast Reach of any unauthorized access, use or breaches of your accounts and or passwords.  Vast Reach is not responsible for any losses due to stolen or hacked passwords.
              We don’t have a copy of your current password, for security purposes, we will only provide you with information on how to reset your password.
              We have the right to update any of your contact information for billing and record keeping purposes.
              In addition, you agree that all information you provide to us when you create and access an account is and will remain complete and up to date.
              We may reach out to you, based on the information provided in your account.
            </p>
            <h2>Payments</h2>
            <p>
              When you sign up for a paid monthly plan, you are required to select your pricing tier (e.g., Base Plan, Premium, Ultimate) from those posted on our Vast Reach Console based on your anticipated use of the Service.
              Each Monthly Plan offers different pricing and feature options. Once you select your Monthly Plan, Vast Reach will never automatically upgrade or downgrade your Monthly Plan.
              If you exceed your Monthly Plan usage limits you will incur additional charges for the prior billing cycle which will be detailed on your bill and in your account.
              You will always have the option to upgrade to a higher tier at any time. If you upgrade during a billing cycle charges will be prorated.
              When you sign up for a Monthly Plan, you agree to monthly recurring billing, starting on the date you sign up. Billing occurs on the same day each month, based on the date that you started your Monthly Plan.
              Payments are due for any month on the same or closest date to the day you made your first monthly payment (the “Pay Date”).
              If any part of a month is included in the Term, then payment is required for the full month. If you exceed your self-selected usage limits for your Monthly Plan, then you’ll have to pay additional charges (as described above) for the prior billing cycle on or before the next Pay Date,
              even if the Term ends or you downgrade to a Free Plan before that payment is otherwise due. Billing for Monthly Plans may be paused, and you may choose to close your account permanently at any time.
            </p>
            <h3>
              Will Prices Ever Change?
            </h3>
            <p>
              We may change our fees, including our charges for Monthly Plans, at any time by posting a new pricing structure to our Vast Reach Console or in your account and/or sending you a notification by email or sms.
            </p>
            <h2>
              All Rights reserved
            </h2>
            <p>
              We own all proprietary rights in the Service, including, but not limited to, patents, trademarks, service marks, trade secrets, copyrights, and other intellectual property rights.  You own all rights to your content.
            </p>
            <p>
              You shall retain all rights, title, and interest in and to the material, content, data, and information (including your personal information and the personal information of others) you submit to Vast Reach in the course of using the Service or which Vast Reach otherwise retrieves or accesses at your direction or with your permission (collectively, your “Content”). Subject to these Terms, you grant us permission to use or disclose your Content (including any personal information therein) only as necessary to provide the Service to you and/or as otherwise permitted by these Terms. You represent and warrant that: (i) you own or have otherwise obtained all necessary rights, releases, and permissions to submit all your Content to the Service and to grant the rights granted to us in these Terms and (ii) your Content and its submission and use as you authorize in these Terms will not violate (1) any applicable law, (2) any third-party intellectual property, privacy, publicity, or other rights, or (3) any of your or third-party policies or terms governing your Content.

            </p>
            <h2>Privacy</h2>
            <p>
              Your privacy is important read our Privacy Policy and Cookie Statement for information regarding how we collect, use, and disclose your personal information and the privacy rights available to you when you use and interact with the Vast Reach.
            </p>
            <h3>Does Vast Reach review Account content?</h3>
            <p>
              We may view, copy, and internally distribute Content from your Campaigns and account to create algorithms and programs (“Tools”) that help us spot problem accounts and improve the Service. We use these Tools to find Customers who violate these Terms or laws and to study data internally to make the Service smarter and create better experiences for Customers and their contacts.
            </p>
            <p>
              Hate speech and abuse is absolutely NOT tolerated.  Any accounts promoting hate and abuse will be terminated immediately at our discretion.
            </p>
            <h2>
              Compliance
            </h2>
            <p>
              You agree that your use of the Service will comply with all applicable laws and regulations.
              You’re responsible for determining whether the Service is suitable for you to use in light of your obligations under any regulations like HIPAA, GLB, Data Protection Laws,
              United States export control laws and regulations and economic sanctions laws and regulations (“U.S. Export Control Laws and Regulations”), or other applicable laws.
              If you’re subject to regulations (like HIPAA) and you use the Service, then we won’t be liable if the Service doesn’t meet those requirements.
              You may not use the Service for any unlawful or discriminatory activities, including acts prohibited by the Federal Trade Commission Act, Fair Credit Reporting Act, Equal Credit Opportunity Act, Children's Online Privacy Protection Act, or any other applicable laws.
            </p>
            <h2>
              Exports
            </h2>
            <p>
              The software that supports the App (the “Software”) is subject to U.S. Export Control Laws and Regulations. Export laws are set up by the U.S. government to keep certain goods and services from reaching other countries, usually because of security concerns or trade agreements. None of the Software may be downloaded or otherwise exported or re-exported in violation of U.S. Export Control Laws and Regulations and any other applicable export laws and regulations (collectively, “Export Control Laws”). Therefore, you agree that you won’t, directly or indirectly through a third party, allow the Software or your Campaigns to be accessed or generated from within, or distributed or sent to, any prohibited or embargoed country as mentioned in any Export Control Laws. In addition, you certify that neither you nor any principals, officers, directors, or any person or entity you know to be directly involved with the use of the Service is designated on any U.S. government list of prohibited or restricted persons.
              It’s important to note that this Section 21 isn’t meant to provide a comprehensive summary of the Export Control Laws that govern Vast Reach, the Service, or the Software. You’re downloading and using the Software at your own risk, and it’s your responsibility to consult with a legal advisor to make sure your use of the Service and the Software complies with applicable laws.

            </p>
            <h2>Limitation of Liability</h2>
            <p>
              To the maximum extent permitted by law, you agree that you assume full responsibility for any loss that results from your use of Vast Reach, including any downloads from the Vast Reach Console;
              we and our Team won’t be liable for any indirect, punitive, special, or consequential damages under any circumstances, even if they’re based on negligence or we’ve been advised of the possibility of those damages;
              in any calendar month, our total liability to you arising under or in connection with the Agreement—whether in contract, tort (including negligence), breach of statutory duty, or otherwise—will be no more than what you paid us for the Service the preceding month.
              For the avoidance of doubt, in no instance will we or our Team be liable for any losses or damages you suffer if you use the Vast Reach in violation of these Terms, regardless of whether we terminate or suspend your account due to such violation.

            </p>
            <h2>No Warranty, No guarantee</h2>
            <p>
              Vast Reach provides the service as-is and we don't provide and warranties in relation to the service.  Customers have a variety of needs, we cannot guarantee that the service will meet your specific needs.
            </p>
            <h2>Entire Terms Agreement</h2>
            <p>
              These Terms and any Additional Terms you’ve agreed to by enabling any Add-ons make up the entire agreement between us in relation to its subject matter and supersede all prior agreements, representations, and understandings.
            </p>
            <p>
              <em>Updated as of November 10, 2020.</em>
            </p>                
          </div>
        </div>
      </div>
    );
  }
}
