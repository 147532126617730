import React from 'react';
import { withRouter } from 'react-router-dom';
class ScrollToTop extends React.Component {
    componentDidMount(){
        console.log("component did mount")
        window.scrollTo(0, 0);
    }
  componentDidUpdate(prevProps) {
      console.log("Hits component Did Update")
    if (this.props.location.pathname !== prevProps.location.pathname) {
        console.log("Hits if")
        console.log(this.props.location)
      window.scrollTo(0, 0);
    }
  }
  render() {
    return null;
  }
}
export default withRouter(ScrollToTop);
