import React from 'react';
import ReactPlayer from 'react-player';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../generalCss/style.scss';
import CallsAndMessagesPage from '../Faq/MultiChannelInbox/img/inbox_text.png';
import CallsAndMessagesContactList from './img/contact_list.png';
import CallsAndMessagesContactDetails from './img/contact_details.png';
// import language from '../utils/vast-languagepack';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';

export default function CallsAndMessages() {
  return (
    <div>
      <div className='container-fluid text-center page-banners banner-wrap'>
        <div className='container page-header-text'>
          <h1>CALLS & MESSAGES</h1>
        </div>
      </div>

      <div className='page-top-wrap'>
        <div className='container-fluid padding-top'>
          <div className='container wrap'>
            <div className='row'>
              <div className='col-xs-12'>
                <img
                  style={{ width: '100%' }}
                  src={CallsAndMessagesPage}
                  alt='Calls & Messages Page'
                  className='img-fluid center-block'
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className='container-fluid padding-top'>
          <div className='container wrap'>
            <div className='col-xs-12'>
              <h2 className='text-center'>LET'S DIVE INTO CALLS & MESSAGES</h2>
            </div>
          </div>
        </div>

        <div className='container-fluid zero-padding'>
          <div className='row padding-full wrap'>
            <div className='col-xs-12 text-center col-md-6 order-md-2'>
              <img
                src={CallsAndMessagesContactList}
                alt='Calls & Messages Contact List'
                className='img-fluid center-block'
              />
            </div>
            <div className='col-xs-12 col-md-6 order-md-1 image-space'>
              <h2 className='text-margin'>
                <LabelImportantIcon
                  style={{
                    fontSize: '2.5rem',
                    color: '#006fd9',
                    margin: '-6px 5px 0 0',
                  }}
                />
                Contact Conversation List
              </h2>
              <p className='text-margin'>
                The Contact Conversation List will show you all of your active
                conversations making it easier for you to keep track of all of
                your customers.
              </p>
            </div>
          </div>
          <div className='bg-2'>
            <div className='row padding-full wrap'>
              <div className='col-xs-12 text-center col-md-6'>
                <ReactPlayer
                  style={{ borderRadius: '10px' }}
                  muted
                  playing
                  loop
                  playsinline
                  width='100%'
                  height='100%'
                  url={[
                    `${process.env.PUBLIC_URL}/video/conversation_preview.mp4`,
                    `${process.env.PUBLIC_URL}/video/conversation_preview.webm`,
                    `${process.env.PUBLIC_URL}/video/conversation_preview.gif`,
                  ]}
                />
              </div>
              <div className='col-xs-12 col-md-6 image-space'>
                <h2 className='text-margin'>
                  <LabelImportantIcon
                    style={{
                      fontSize: '2.5rem',
                      color: '#006fd9',
                      margin: '-6px 5px 0 0',
                    }}
                  />
                  Conversation Preview
                </h2>
                <p className='text-margin'>
                  The Conversation Preview allows you to see the entire history
                  of conversation you have with the selected contact from the
                  Contact Conversation List. Additionally, you can email, text
                  and call your contacts directly from this screen. No more
                  navigating around endlessly to switch between conversation
                  types, with Vast Reach all your conversations can be accessed
                  on one page.
                </p>
              </div>
            </div>
          </div>

          <div className='container-fluid zero-padding'>
            <div className='row padding-top wrap'>
              <div className='col-xs-12 text-center col-md-6 order-md-2'>
                <img
                  src={CallsAndMessagesContactDetails}
                  alt='Contact Details'
                  className='img-fluid center-block'
                />
              </div>
              <div className='col-xs-12 col-md-6 order-md-1 image-space'>
                <h2 className='text-margin'>
                  <LabelImportantIcon
                    style={{
                      fontSize: '2.5rem',
                      color: '#006fd9',
                      margin: '-6px 5px 0 0',
                    }}
                  />
                  Contact Details
                </h2>
                <p className='text-margin'>
                  With the Contact Details you can add a new email or phone
                  number for the selected contact, begin a conversation with
                  them, add them to a group, give them a custom attribute and
                  monitor all of the activity you’ve had with this contact.
                </p>
              </div>
            </div>
            <div className='row padding-top wrap'>
              <div className='col-xs-12 text-center'>
                <ReactPlayer
                  style={{ borderRadius: '10px' }}
                  muted
                  playing
                  loop
                  playsinline
                  width='100%'
                  height='100%'
                  url={[
                    `${process.env.PUBLIC_URL}/video/contact_details.mp4`,
                    `${process.env.PUBLIC_URL}/video/contact_details.gif`,
                  ]}
                />
              </div>
            </div>
          </div>

          <div className='container-fluid padding-top'>
            <div className='container wrap'>
              <div className='col-xs-12'>
                <h2 className='text-center'>
                  WANT TO SEE MORE? SCHEDULE A LIVE DEMO TODAY!
                </h2>
              </div>
            </div>
          </div>

          <div className='container-fluid padding-full '>
            <div className='col-xs-12 zero-padding wrap'>
              <iframe
                title='book'
                src='https://koalendar.com/e/meet-with-wil-casillas?embed=true'
                width='100%'
                height='800px'
                frameborder='0'
              ></iframe>
              <div className='col-xs-12 text-center'>
                <h3>
                  Don't See a time that works for you? Get in contact with Wil
                  Casillas{' '}
                </h3>
                <p>
                  <a href='mailto: wcasillas@vasttechnologies.com'>
                    wcasillas@vasttechnologies.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
