import React from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import CampaignNaviScrollSpy from './CampaignNaviScrollSpy.js';
import CampaignImgA from './img/funnel_stage_header.jpg';
import CampaignImgB from './img/funnel_stage_contacts.png';
import CampaignImgC from './img/camp_stepper.jpg';
import CampaignImgD from './img/campaign_full_page.png';
import CampaignImgE from './img/campaign_status.png';
import CampaignImgF from './img/campaign_full_page2.png';
import CampaignImgG from './img/campaign_calendar.png';
import CampaignImgH from './img/desktop_nav.jpg';
// import CampaignImgI from './img/campaign_calendar_drag_n_drop.gif';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import video from './video/campaign_tutorial_1.mp4';

export default function Campaigns() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <CampaignNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/Campaigns'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/CampaignsMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>
                EVERYTHING YOU NEED TO KNOW ABOUT CAMPAIGNS
              </h1>
              <p className='campaign-begin'>
                Campaign’s automatically manage <i>your</i> tasks, or delegate
                them to <i>your</i> sales team, based on pre-determined goals.
                The Campaign Calendar also allows <i>you</i> to schedule{' '}
                <i>your</i> tasks to execute at a specific date and time. By the
                end of this tutorial <i>you</i> should have an understanding of
                how to set up a Campaign and learn how to use the{' '}
                <strong>Campaign Calendar</strong> and{' '}
                <strong>Campaign Funnel</strong>.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Creating a Campaign
              </h3>
              <p>
                Before creating a <strong>Campaign</strong>, <i>you</i> must
                first have an account set up with an active subscription. It is
                recommend <i>you</i> learn the majority of{' '}
                <strong>Vast Reach’s</strong> feature sets such as adding phone
                numbers, creating <i>contacts</i>, groups, custom forms and
                tasks as many of them will be incorporated into campaigns. Click
                the <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Buttons</strong> below to set up anything <i>you're</i>{' '}
                missing before beginning this tutorial if <i>you</i> have not
                done so already.
              </p>
              <p>
                <Link to='/GettingStarted'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Getting Started
                  </button>
                </Link>
                <Link to='/Payment'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
                <Link to='/ManagePhoneNumbers'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Manage Phone Numbers
                  </button>
                </Link>
                <Link to='/Contacts'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Create a Contact
                  </button>
                </Link>
                <Link to='/Groups'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Create a Group
                  </button>
                </Link>
                <Link to='/ReCaptcha'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    ReCaptcha
                  </button>
                </Link>
                <Link to='/SignUpForms'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Sign-Up Forms
                  </button>
                </Link>
                <Link to='/Tasks'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Tasks
                  </button>
                </Link>
              </p>
              <h3 className='navigation'>Navigate to Campaigns</h3>
              <p>
                <i>You</i> can create a campaign by clicking{' '}
                <strong>Campaigns</strong> in the navigation (Left side menu).
                Additionally, <i>you</i> can navigate to the Campaigns page
                using the following link:{' '}
                <a href='https://console.vastreach.com/campaigns'>
                  https://console.vastreach.com/campaigns
                </a>
                . <i>You</i> will be taken to the Campaigns page.
              </p>
              <p>
                <img
                  src={CampaignImgH}
                  alt='campaign page navigation'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              <p>
                Clicking the <strong>ADD CAMPAIGN</strong> button at the top
                right will take <i>you</i> to the campaign creation page. Once{' '}
                <i>you</i> are on this page the <i>you</i> will be presented
                with the first part of the campaign creation which is the{' '}
                <strong>Campaign</strong> step.
              </p>

              <h2 className='campaigns'>Create a Campaign</h2>

              <p>
                On this step <i>you</i> can give this campaign a name and below
                that is a campaign description text to write more details about
                this campaign. The last part of this step is the{' '}
                <strong>Date Range</strong>, there is a <strong>Yes</strong> or{' '}
                <strong>No</strong> selection which allows <i>you</i> to add a
                start and end date for this campaign if <strong>Yes</strong> is
                selected. If <i>you</i> need to make changes to this again it
                will be available in all the steps going forward. Once this is
                completed click <strong>Campaign Calendar</strong> in the
                stepper above (see image below) to be taken to the{' '}
                <strong>Campaign Calendar</strong> step of this campaign.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={CampaignImgC}
                  alt='campaign stepper navigation'
                  className='img-fluid'
                  style={{ maxWidth: '300px', width: '100%' }}
                />
              </p>

              <h3 className='campaign-calendar'>
                Setting Up a Campaign Calendar
              </h3>

              <p>
                The first part of the step to understand begins with the
                sections on the right side.
              </p>

              <p style={{ textAlign: 'center' }}>
                <img
                  src={CampaignImgG}
                  alt='campaign calendar page'
                  className='img-fluid'
                  style={{ maxWidth: '800px', width: '100%' }}
                />
              </p>

              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h4>Review Your Campaign Information</h4>
                  <p>
                    This first section presents <i>you</i> with everything the{' '}
                    <strong>Campaign</strong> step had.
                  </p>
                </li>
                <li>
                  <h4>Automate Your Tasks</h4>
                  <p>
                    Inside the second section <i>you</i> can drag and drop a
                    task into the calendar. <i>You</i> can also click the
                    desired task or click the date on the calendar where{' '}
                    <i>you</i> want to begin creating a task. Once a task
                    creation is initiated <i>you</i> will be presented with task
                    creation modal (a pop-up window). To learn more about
                    creating a task click <Link to='/Tasks'>here</Link>.
                  </p>
                </li>
              </ul>

              <p style={{ textAlign: 'center' }}>
                <ReactPlayer
                  muted
                  playing
                  loop
                  playsinline
                  width='100%'
                  height='100%'
                  url={[
                    `${process.env.PUBLIC_URL}/video/drag_drop.mp4`,
                    `${process.env.PUBLIC_URL}/video/drag_drop.webm`,
                    `${process.env.PUBLIC_URL}/video/drag_drop.gif`,
                  ]}
                />
              </p>

              <h4>Learn About Calendar Statistics</h4>

              <p>
                <strong>Calendar Statistics</strong> at the top left of the page
                shows <i>you</i> 5 tiles of statistics. This allows a{' '}
                <i>user</i> to keep track how well a campaign is doing once
                live.
              </p>
              <ul>
                <li>
                  <p>
                    The green tile shows the unique emails opened across all
                    email tasks sent as a percentage and just below it also
                    shows the exact number. Vast Reach tracks if a distinct{' '}
                    <i>contact</i> has opened an email sent.
                  </p>
                </li>
                <li>
                  <p>
                    The yellow tile shows the unique clicks across all email
                    tasks sent as a percentage in this campaign and just below
                    it shows the exact number. Vast Reach tracks if a distinct{' '}
                    <i>contact</i> clicked a link in the email sent.
                  </p>
                </li>
                <li>
                  <p>
                    The purple tile shows the total text messages delivered as a
                    percentage across all text message tasks in this campaign
                    and below it shows the exact number delivered. This stat
                    allows a <i>user</i> to keep track and know if their text
                    messages have been successfully delivered.
                  </p>
                </li>
                <li>
                  <p>
                    The dark blue tile allows <i>you</i> to keep track of all
                    the emails sent out to <i>contacts</i> in this entire
                    campaign.
                  </p>
                </li>
                <li>
                  <p>
                    Lastly, the light blue tile allows <i>you</i> to keep track
                    of all the text messages sent out to <i>contacts</i> in this
                    entire campaign.
                  </p>
                </li>
              </ul>
              <p>
                Below the <strong>Calendar Statistics</strong> tiles will be a
                calendar (or instructional text and below that a large button to
                create a task if there are none). If <i>you</i> have created a
                scheduled task the calendar will show the task(s) on the date it
                is scheduled to be sent out. The month is shown at the top of
                the calendar and above that <i>you</i> can navigate to a
                specific day, week or month to track <i>your</i> tasks within
                the blue bar.
              </p>
              <p>
                <img
                  src={CampaignImgD}
                  alt='empty campaign calendar page'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              <p>
                Once completed <i>you</i> can click{' '}
                <strong>Campaign Funnel</strong> in the stepper above to move on
                to the <strong>Campaign Funnel</strong> step.
              </p>

              <h3 className='campaign-funnel'>
                Understanding Your Campaign Funnels
              </h3>

              <p>
                By default a new <strong>Campaign Funnel</strong> will be empty
                and will only show <strong>NEW FUNNEL STAGE</strong> button and
                the <strong>Campaign Information</strong> section. To begin
                click the <strong>NEW FUNNEL STAGE</strong> Button.
              </p>

              <p>Sections on the right side of the page.</p>

              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h4>Campaign Information</h4>
                  <p>
                    This first section presents the <i>you</i> with everything
                    the <strong>Campaign</strong> step had.
                  </p>
                </li>
                <li>
                  <h4>Event Goals</h4>
                  <p>
                    Inside this section <i>you</i> can drag and drop an{' '}
                    <strong>Event Goal</strong> into the top portion of a{' '}
                    <strong>Funnel Stage</strong> or simply click the{' '}
                    <strong>+ ADD EVENT</strong> text at the top right of the{' '}
                    <strong>Funnel Stage</strong>. This will add an{' '}
                    <strong>Event Goal</strong> inside this stage. An{' '}
                    <strong>Event Goal</strong> <strong>Happens</strong> when a
                    specific <strong>Event Goal</strong> is reached. Inside an{' '}
                    <strong>Event Goal</strong> there is a list labeled{' '}
                    <strong>Select an event goal</strong>. For example if{' '}
                    <i>you</i> select from the list{' '}
                    <strong>Clicks Link in Email</strong> another list will pop
                    up inside event goal labeled <strong>Select a task</strong>.
                    If <i>you</i> click <strong>Select a task</strong>{' '}
                    <i>you</i> will be presented with a list of email tasks that
                    were created. If no tasks were made, Click{' '}
                    <Link to='/Tasks'>here</Link> to learn how. Once <i>you</i>{' '}
                    select an email the event goal will now say{' '}
                    <strong>
                      Contact clicks link in 'your email task name'
                    </strong>
                    . What this means is if the campaign is live and a{' '}
                    <i>contact</i> clicks a link in that selected email when it
                    is sent out then this funnel will do the following task
                    below in the <strong>Then do this</strong> portion of this
                    funnel after that <i>contact</i> clicks a link from that
                    email. A completion of an <strong>Event Goal</strong> is
                    what moves a <i>contact</i> forward into the next stage if
                    there is another <strong>Funnel Stage</strong> to this
                    campaign. A quick break down of this would be if <i>you</i>{' '}
                    sent an email task out to a <i>contact</i> about a product
                    and the <i>contact</i> clicks a link in the email, then this
                    funnel in this campaign will send them out another email or
                    text message task to sign-up using a form for the product.
                    The next stage after can have an <strong>Event Goal</strong>{' '}
                    set to send a thank you text message if the <i>contact</i>{' '}
                    submitted a form from the email or text message task{' '}
                    <i>you</i> sent from the previous stage. There will have a
                    video at the end of this tutorial with a real campaign
                    scenario explaining this with far more detail.
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    <ReactPlayer
                      muted
                      playing
                      loop
                      playsinline
                      width='100%'
                      height='100%'
                      url={[
                        `${process.env.PUBLIC_URL}/video/campaign_funnel_event_goals_drag_n_drop.mp4`,
                        `${process.env.PUBLIC_URL}/video/campaign_funnel_event_goals_drag_n_drop.gif`,
                      ]}
                    />
                  </p>
                </li>
                <li>
                  <h4>Automate Your Tasks</h4>
                  <p>
                    This section acts a little different from the Campaign
                    Calendar step. The difference is in a{' '}
                    <strong>Funnel Stage</strong> a task is initiated once an
                    event goal is completed as mentioned in the Event Goals
                    section. <i>You</i> can drag and drop tasks into a funnel or
                    click <strong>+ ADD TASK</strong> text on the right side,
                    under the event goals portion of a stage. This will bring up
                    a task creation modal for <i>you</i>. To learn more about
                    creating a task click <Link to='/Tasks'>here</Link>.
                  </p>
                </li>
              </ul>

              <p>The Funnels are on the left side of the page.</p>

              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h4>Funnel Stage Header</h4>
                  <p style={{ textAlign: 'center' }}>
                    <img
                      src={CampaignImgA}
                      alt='funnel stage header'
                      className='img-fluid'
                      style={{
                        maxWidth: '600px',
                        width: '100%',
                        margin: '15px 0 15px 0',
                      }}
                    />
                  </p>

                  <ul>
                    <li>
                      <p>
                        The left of <strong>Stage A</strong> is a drag icon,
                        this allows <i>you</i> to drag the order of this stage
                        with other stages in a funnel.
                      </p>
                    </li>
                    <li>
                      <p>
                        Clicking the text <strong>Stage A</strong> of the funnel
                        made earlier will allow <i>you</i> to change the name of
                        the funnel.
                      </p>
                    </li>
                    <li>
                      <p>
                        To the right of the stage name is an ellipsis (3 dot
                        icon), clicking it will allow <i>you</i> to bring up a
                        delete modal for this stage (a delete confirmation will
                        prompt if <strong>Delete stage</strong> is clicked).
                      </p>
                    </li>
                    <li>
                      <p>
                        To the right of the ellipsis is a{' '}
                        <strong>Contacts Icon</strong> with a 0 next to it. When
                        populating a stage with Tasks that have <i>contacts</i>{' '}
                        the <strong>0</strong> text will show the number of{' '}
                        <i>contacts</i> added to this stage from those tasks.
                        Clicking <strong>Contacts Icon</strong>, when there are{' '}
                        <i>contacts</i> in a stage will prompt a modal showing
                        all the <i>contacts</i> that are in this stage. At the
                        top of the window there is an option to select between
                        all the <i>contacts</i> in the stage currently or all
                        the <i>contacts</i> that have ever been in this stage
                        before moving on to another stage. There is also a
                        filter icon to filter the <i>contacts</i> (A-Z, Z-A).
                        The window will show the <i>contact's</i> name, number
                        and email.
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        <img
                          src={CampaignImgB}
                          alt='funnel stage contacts'
                          className='img-fluid'
                          style={{ maxWidth: '600px', width: '100%' }}
                        />
                      </p>
                    </li>
                    <li>
                      <p>
                        To the right of the <i>contacts</i> icon is a list icon,
                        clicking it opens up the stage.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h4>Inner Funnel Stage</h4>
                  <ul>
                    <li>
                      <p>
                        The top part of the <strong>Funnel Stage</strong> is
                        where the <strong>Event Goals</strong> are placed as
                        described earlier in this tutorial.
                      </p>
                    </li>
                    <li>
                      <p>
                        Each <strong>Event Goal</strong> has an ellipsis icon on
                        the right side of it which allows <i>you</i> to delete
                        that specific <strong>Event Goal</strong> (this also
                        applies to deleting a task).
                      </p>
                    </li>
                    <li>
                      <p>
                        Below the <strong>Funnel Stage</strong> is where tasks
                        are placed.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    Additionally, <i>you</i> can create new stages by clicking
                    the dotted <strong>+</strong> icon or the dotted{' '}
                    <strong>ADD A FUNNEL STAGE</strong> button below the first
                    stage.
                  </p>
                </li>
              </ul>

              <p>
                <i>Note:</i> Removing a stage from a campaign cancels all tasks
                for <i>contacts</i> in that campaign stage.{' '}
                <strong>There is no way to undo this.</strong>
              </p>
              <p>
                <i>Note:</i> Tasks in a <strong>Funnel Stage</strong> execute in
                order. A <i>contact</i> can leave a stage before all tasks have
                completed. In that case, the <i>contact</i> will move onto a
                later stage and the tasks in the previous stage will no longer
                execute for that particular <i>contact</i>.
              </p>
              <p>
                <i>Note:</i> <i>Contact</i> cannot be moved to previous stage in
                a campaign funnel. If you feel the urge to send a <i>contact</i>{' '}
                to a previous stage consider adding a subsequent funnel with
                similar tasks as the previous stage.
              </p>

              <p>
                <i>Note:</i> Adding or removing stages from a campaign that has
                already started processing leads through the funnel and may lead
                to confusing results.
              </p>

              <p>
                <strong>
                  Example: Why have some of my <i>contacts</i> in the 4th stage
                  of my campaign not received the coupon email from stage 3?
                </strong>
                <br />
                If <i>you</i> added a new stage 3 to send the coupon email after
                the campaign started, some of the <i>contacts</i> were already
                moved into a later stage so it never made it to the coupon email
                stage.
              </p>

              <p>
                <i>Note:</i> The easiest way to move a <i>contact</i> in between
                stages is to utilize the <strong>Added to contact group</strong>{' '}
                <strong>Event Goal</strong> and the{' '}
                <strong>Add to contact group</strong> task. See below for an
                example of a <i>contact</i> being added to later stages after 1
                day. <i>You</i> can also utilize this technique to send{' '}
                <i>contacts</i> to other campaign <strong>Funnel Stage</strong>.
              </p>

              <h3 className='campaign-live'>Taking Your Campaign Live</h3>
              <p>
                Once <i>you</i> are done creating a campaign funnel <i>you</i>{' '}
                can select the list button at the top right in the stepper above
                <p>
                  <img
                    src={CampaignImgE}
                    alt='campaign status toggle button'
                    className='img-fluid'
                    style={{
                      maxWidth: '200px',
                      width: '100%',
                      padding: '20px 0 0 0',
                    }}
                  />
                </p>
                to select if they want to make the campaign go live.
              </p>
              <p>
                <i>Note:</i> A campaign can <strong>not</strong> be deleted if
                it is live, <i>you</i> must first pause the campaign then select
                delete from the list button (A confirmation prompt will appear
                before deleting).
              </p>
              <p>
                <i>Note:</i> If <i>you</i> pause a campaign, make minor changes,
                and set it live, <i>your</i> pending delayed tasks will still be
                executed normally.
              </p>
              <p>
                <i>Note:</i> Delayed tasks will not be executed while the
                campaign is paused. Additionally, if a campaign is paused for
                more than 5 minutes, delayed tasks set to run while the campaign
                was paused <strong>may never</strong> be executed.
              </p>
              <p>
                <img
                  src={CampaignImgF}
                  alt='campaign funnel page'
                  className='img-fluid'
                  style={{ maxWidth: '1000px', width: '100%' }}
                />
              </p>
              {/* <p className='campaign-video'>
                To see a real campaign demonstration watch the video below for a
                full walk-through of how to build one.
              </p>
              <p style={{ textAlign: 'center' }}>
                <strong>INSERT VIDEO HERE</strong>
              </p> */}

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Remember when removing a stage from a campaign cancels all
                    tasks for <i>contacts</i> in that campaign stage. There is
                    no way to undo this.
                  </p>
                </li>
                <li>
                  <p>
                    Remember a <i>contact</i> cannot be moved to previous stage
                    in a campaign funnel.
                  </p>
                </li>
                <li>
                  <p>
                    Remember a campaign cannot be deleted if it is live,{' '}
                    <i>you</i> must first pause the campaign then select delete
                    from the list button (A confirmation prompt will appear
                    before deleting).
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Remove stages from a campaign that has already started
                    processing leads through the funnel as this may lead to
                    confusing results.
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <h3>Creating a Basic Email Engagement Campaign</h3>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
