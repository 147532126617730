import React from 'react';
// import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import FormListNaviScrollSpy from './FormListNaviScrollSpy.js';
import SignUpFormsImgD from './img/forms3.png';
import SignUpFormsImgF from './img/form_list_navigation.jpg';
import SignUpFormsImgM from './img/form_aggregates.jpg';
import SignUpFormsImgN from './img/search_filter.jpg';
import SignUpFormsImgO from './img/form_list_stats.jpg';
import SignUpFormsImgP from './img/form_list_options.jpg';
// import video from './video/create_sign_up_form.mov';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function FormList() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <FormListNaviScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/FormList'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/FormListMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>
                LEARNING TO USE THE FORM LIST
              </h1>
              <p className='sign-up-intro'>
                The form list is the hub for all forms on a Vast Reach account
                and you will be able to monitor how well they are doing. By the
                end of this tutorial <i>you</i> will know how to read the
                statistical information of all forms combined at the top of the
                page, how to sort <i>your</i> forms, read individual form
                details and its individual statistics, how to quick edit and
                delete a form.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Creating a Sign-up Form
              </h3>
              <p>
                Before learning to use the <strong>Form List</strong>,{' '}
                <i>you</i> must first have an account set up with an active
                subscription. While there aren’t any other prerequisites to
                learn how to use the majority of the form list, it would be good
                to know how to make a form or have a form already made. Click
                the <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Buttons</strong> below to set up anything <i>you're</i>{' '}
                missing or want to learn before beginning this tutorial if{' '}
                <i>you</i> have not done so already.
              </p>
              <p>
                <Link to='/Payment'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
                <Link to='/SignUpForms'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Sign-up Forms
                  </button>
                </Link>
                <Link to='/CustomForms'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Custom Forms
                  </button>
                </Link>
              </p>
              <h3 className='navigation'>Navigate to The Form List</h3>
              <p>
                <i>You</i> can learn about the form list by clicking the{' '}
                <strong>Forms</strong> link in the navigation (Left side menu
                bar). Additionally, <i>you</i> can navigate to the form list
                using the following link:{' '}
                <a href='https://console.vastreach.com/forms'>
                  https://console.vastreach.com/forms
                </a>
                .
              </p>
              <p style={{ textAlign: 'center', margin: '15px 0 15px 0' }}>
                <img
                  src={SignUpFormsImgF}
                  alt='form navigation'
                  className='img-fluid'
                  style={{
                    maxWidth: '1000px',
                    width: '100%',
                    border: '1px solid #dddddd',
                  }}
                />
              </p>
              <h3 className='form-list'>Learning The Form List</h3>
              <p>
                The form list shows statistical information about all the forms
                on this account. This tutorial will go through each part of the
                form list beginning with the statistical aggregates.
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h4>Learn About Aggregates</h4>
                  <p>
                    This will show the Total statistics of all forms in the form
                    list.
                    <ul>
                      <li>
                        The green aggregate shows a the total response
                        percentage as a calculation of total from responses over
                        form visits.
                      </li>
                      <li>
                        The yellow aggregate shows the total unique visits
                        across all forms.
                      </li>
                      <li>
                        The purple aggregate shows the total number of forms on
                        this account.
                      </li>
                    </ul>
                  </p>
                </li>
                <p style={{ margin: '10px 0 10px 0' }}>
                  <img
                    src={SignUpFormsImgM}
                    alt='aggregate tiles'
                    className='img-fluid'
                    style={{ maxWidth: '800px', width: '100%' }}
                  />
                </p>
                <li>
                  <h4>Using The Search & Filter Bar </h4>
                  <p>
                    This bar will allow <i>you</i> to sort, filter or search a
                    specific form from <i>your</i> list of forms.
                  </p>
                </li>
                <p style={{ margin: '10px 0 10px 0' }}>
                  <img
                    src={SignUpFormsImgN}
                    alt='search and filter bar'
                    className='img-fluid'
                    style={{ maxWidth: '250px', width: '100%' }}
                  />
                </p>
                <li>
                  <h4>Understanding Your Form Details</h4>
                  <p>
                    <img
                      src={SignUpFormsImgD}
                      alt='form statistics'
                      className='img-fluid'
                      style={{ maxWidth: '1000px', width: '100%' }}
                    />
                  </p>
                  <p>
                    Forms that <i>you</i> build will be shown here and each form
                    will show the statistics of <i>contacts</i> that responded
                    or visited that form.
                  </p>
                  <p style={{ margin: '10px 0 10px 0' }}>
                    <img
                      src={SignUpFormsImgO}
                      alt='form list statistics'
                      className='img-fluid'
                      style={{ maxWidth: '200px', width: '100%' }}
                    />
                  </p>
                  <p>
                    <i>You</i> can see form responses by clicking the form name
                    of that form. If <i>you</i> click the ellipsis icon (3 dot
                    icon) on the right of the statistics of each form will show
                    a menu.
                    <ol>
                      <li>
                        <i>You</i> can click <strong>Edit</strong> to return to
                        the build step of that form and make changes.
                      </li>
                      <li>
                        <i>You</i> can click <strong>Share</strong> and this
                        will take <i>you</i> to the share step of this form.
                      </li>
                      <li>
                        <i>You</i> can click <strong>Delete</strong> and it will
                        delete this form (this is the only way to delete a
                        form).
                      </li>
                    </ol>
                  </p>
                  <p>
                    <i>Note: </i> When deleting a form, a confirmation window
                    will present on screen to confirm the delete to prevent any
                    accidental deletes.
                  </p>
                  <p style={{ margin: '10px 0 10px 0' }}>
                    <img
                      src={SignUpFormsImgP}
                      alt='from list ellipsis'
                      className='img-fluid'
                      style={{ maxWidth: '150px', width: '100%' }}
                    />
                  </p>
                </li>
              </ul>

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Remember that at least a phone number <strong>OR</strong>{' '}
                    email is required for every <strong>Sign-up Form</strong> in
                    order to get in touch with the <i>contact</i>.
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Forget all <strong>Sign-up Forms</strong> require a{' '}
                    <strong>Form Name</strong>, <strong>Title</strong>,{' '}
                    <strong>Description</strong>, <strong>Contact Group</strong>{' '}
                    and a <strong>Phone Number</strong> or{' '}
                    <strong>Email</strong> to be created/updated.
                  </p>
                </li>
              </ul>

              {/* <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <h3>Creating a Sign-up Form</h3>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div> */}

              <h2 className='next-steps'>Next Steps</h2>
              <p>
                Now that <i>you</i> understand how to use the form list, if{' '}
                <i>you</i> have not done so already, <i>you</i> can begin
                creating a <strong>Sign-up Form</strong> or{' '}
                <strong>Custom Form</strong>. Click the buttons below to begin
                to learn how.
              </p>
              <p style={{ textAlign: 'center' }}>
                <Link to='/SignUpForms'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Sign-up Forms
                  </button>
                </Link>
                <Link to='/CustomForms'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Custom Forms
                  </button>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
