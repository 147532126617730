import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default (props) => (
  <SvgIcon {...props} viewBox='0 0 30 28'>
    <g id='Group_2699' transform='translate(21826 14366.978)'>
      <path
        id='chat-processing'
        d='M-21806.6-14367c5.8,0,10.5,3.8,10.5,8.4c0,4.7-4.7,8.4-10.5,8.4c-1.3,0-2.5-0.2-3.7-0.5
		c-1.9,1.6-4.3,2.5-6.8,2.6c2.5-2.5,2.8-4.1,2.9-4.7c-1.8-1.4-2.8-3.5-2.9-5.8C-21817.1-14363.2-21812.4-14367-21806.6-14367
		 M-21801.3-14357.5v-2.1h-2.1v2.1H-21801.3 M-21805.5-14357.5v-2.1h-2.1v2.1H-21805.5 M-21809.8-14357.5v-2.1h-2.1v2.1H-21809.8z'
      />
      <path
        id='phone'
        d='M-21822.4-14349.2c1.4,2.8,3.8,5.1,6.6,6.6l2.2-2.2c0.3-0.3,0.7-0.4,1-0.2c1.2,0.4,2.4,0.6,3.6,0.6
		c0.6,0,1,0.4,1,1v3.5c0,0.6-0.4,1-1,1c-9.4,0-17-7.6-17-17c0-0.6,0.4-1,1-1h3.5c0.6,0,1,0.4,1,1c0,1.2,0.2,2.4,0.6,3.6
		c0.1,0.4,0,0.8-0.2,1L-21822.4-14349.2z'
      />
    </g>
  </SvgIcon>
);
