import React from "react";
import "./navi.scss";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Hidden, Grid } from "@material-ui/core";
import Logo from '../img/logo_color.png';
import DesktopMenu from "./desktopMenu";
import MobileMenu from "./mobileMenu";
import language from "../utils/vast-languagepack";

const LogoLink = props => 
  <Link to="/index">
    <span className="navbar-brand">
      <img
        src={Logo}
        alt={language.navi.titleAlt.logoAlt}
        className="logo justify-content-start img-fluid"
      />
    </span>
  </Link>
export default function Navi() {
  return (
    <AppBar className="appbar" position="fixed">
      <Toolbar className="toolbar">
        <Grid container spacing={1}>
          <Hidden smDown>
            <Grid item xs={3} className="desktop-logo">
              <LogoLink />
            </Grid>
            <Grid item xs={9} className="desktop-nav">
              <DesktopMenu />
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item xs={10}>
              <LogoLink />
            </Grid>
            <Grid item xs={2}>
              <MobileMenu  />
            </Grid>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
