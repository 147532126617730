import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default (props) => (
  <SvgIcon
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='14.5'
    font-size='2em'
  >
    <path
      d='M12 0a3.5 3.5 0 11-3.5 3.5A3.5 3.5 0 0112 0M5 2.5a2.952 2.952 0 011.53.42 5.536 5.536 0 001.13 3.96A3 3 0 115 2.5m14 0a3 3 0 11-2.66 4.38 5.536 5.536 0 001.13-3.96A2.952 2.952 0 0119 2.5M5.5 12.75C5.5 10.68 8.41 9 12 9s6.5 1.68 6.5 3.75v1.75h-13v-1.75M0 14.5V13c0-1.39 1.89-2.56 4.45-2.9a4.044 4.044 0 00-.95 2.65v1.75H0m24 0h-3.5v-1.75a4.044 4.044 0 00-.95-2.65c2.56.34 4.45 1.51 4.45 2.9z'
    />
  </SvgIcon>
);
