import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default props => (
  <SvgIcon {...props} viewBox='0 0 500 500'>
    <g>
      <path
        d='M253.13,401.33H86.56V198.14h284.46v27.93h40.64V116.87c0-22.44-18.19-40.64-40.64-40.64H350.7V35.59h-40.64v40.64H147.52
		V35.59h-40.64v40.64H86.56c-22.55,0-40.64,18.29-40.64,40.64v284.46c0,22.44,18.19,40.64,40.64,40.64h166.56V401.33z M86.56,116.87
		h284.46v40.64H86.56V116.87z'
      />
      <g>
        <g>
          <path
            className='st0'
            d='M428.96,291.31H219.52c-13.87,0-25.12-11.24-25.12-25.12v0c0-13.87,11.24-25.12,25.12-25.12h209.44
				c13.87,0,25.12,11.24,25.12,25.12v0C454.07,280.06,442.83,291.31,428.96,291.31z'
          />
          <path
            className='st0'
            d='M395.61,349.83H251.75c-13.82,0-25.03-11.21-25.03-25.03v0c0-13.82,11.21-25.03,25.03-25.03h143.86
				c13.82,0,25.03,11.21,25.03,25.03v0C420.64,338.62,409.43,349.83,395.61,349.83z'
          />
        </g>
        <path
          className='st0'
          d='M363.18,408.45H285.3c-13.89,0-25.14-11.26-25.14-25.14v0c0-13.89,11.26-25.14,25.14-25.14h77.88
			c13.89,0,25.14,11.26,25.14,25.14v0C388.32,397.19,377.06,408.45,363.18,408.45z'
        />
        <path
          className='st0'
          d='M330.72,464.41h-14.08c-13.35,0-24.17-10.82-24.17-24.17v0c0-13.35,10.82-24.17,24.17-24.17h14.08
			c13.35,0,24.17,10.82,24.17,24.17v0C354.89,453.59,344.07,464.41,330.72,464.41z'
        />
      </g>
    </g>
  </SvgIcon>
);
