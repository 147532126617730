import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import { Link } from 'react-router-dom';
import ManagePhoneNumbersMobileScrollSpy from './ManagePhoneNumbersMobileScrollSpy.js';
import AccountSettingsMobileC from '../AccountSettingsMobile/img/mobile_nav_account.jpg';
import ManagePhoneMobileImgD from '../ManagePhoneNumbers/img/add_number_button.png';
import ManagePhoneMobileImgE from './img/mobile_manage_numbers_page.png';
import ManagePhoneMobileImgF from './img/mobile_manage_numbers_page_add_number.png';
import video from '../ManagePhoneNumbers/video/add_phone_number.mp4';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function ManagePhoneNumbersMobile() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <ManagePhoneNumbersMobileScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/ManagePhoneNumbers'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/ManagePhoneNumbersMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>
                MANAGE PHONE NUMBERS ON MOBILE
              </h1>
              <p className='manage-phones-intro'>
                The purpose of managing phone numbers using{' '}
                <strong>Vast Reach</strong> is to better communicate with{' '}
                <i>your</i> <i>contacts</i> and inform <i>your</i>{' '}
                <i>contacts</i> about promotional and marketing campaigns.{' '}
                <i>You</i> can also set up call forwarding to manage all{' '}
                <i>your</i> calls through <strong>Vast Reach</strong>.
                Additionally, <i>you</i> can assign <i>users</i> to phone
                numbers to communicate with <i>your</i> <i>contacts</i>. This
                tutorial will instruct <i>you</i> on how to set up and manage{' '}
                <i>your</i> phone numbers. Additionally, <i>you</i> will also
                learn how assign a <i>user</i> to a specific number and how to
                set up a call forwarding number.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Managing Phone Numbers{' '}
              </h3>
              <p>
                Before <strong>Managing Phone Numbers</strong>, <i>you</i> must
                first have an active account set up with <i>your</i>{' '}
                <strong>Payment Settings</strong>. This is mandatory and must be
                done before accessing <strong>Account Settings</strong> where{' '}
                <strong>Managing Phone Numbers</strong> is located. Click the{' '}
                <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Button</strong> below to set up{' '}
                <strong>Payments</strong> before beginning this tutorial if{' '}
                <i>you</i> have not done so already.
              </p>
              <p>
                <Link to='/PaymentMobile'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
              </p>

              <h3 className='manage-phones-option1'>
                Manage Your Phone Numbers Through Account Settings
              </h3>
              <p>
                Below will explain how <i>you</i> can manage <i>your</i> phone
                numbers from <strong>Account Settings</strong>.
              </p>
              <p>
                <i>Note:</i> <i>You</i> can also manage your phone numbers
                through <strong>Inbox</strong>. To learn more about{' '}
                <strong>Multi-Channel Inbox</strong> click{' '}
                <Link to='/MultiChannelInboxMobile'>here</Link>.
              </p>
              <p>
                <i>You</i> can reach <strong>Account Settings</strong> through
                the navigation menu (Click the logo at the top left,{' '}
                <strong>Settings/Preferences</strong> with the{' '}
                <strong>Gear</strong> icon).
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={AccountSettingsMobileC}
                  alt='account settings navigation'
                  className='img-fluid'
                  style={{ maxWidth: '375px', width: '100%' }}
                />
              </p>
              <p>
                Once on the <strong>Account Settings & Preferences</strong>{' '}
                page. On the top of the page there will be a button called{' '}
                <strong>PHONE NUMBERS</strong>. Clicking this will prompt the{' '}
                <strong>Phone Numbers</strong> modal.
              </p>
              <h2 className='adding-new-number'>Add a New Phone Number</h2>
              <p>
                <i>You</i> can add a new number by clicking the{' '}
                <strong>Add Number</strong> button on the top right of the page.
              </p>
              <p style={{ textAlign: 'left' }}>
                <img
                  src={ManagePhoneMobileImgD}
                  alt='Add New Phone Number'
                  className='img-fluid'
                  style={{ maxWidth: '150px', width: '100%' }}
                />
              </p>
              <p>
                Enter desired area code in the <strong>Area Code</strong> field
                (be aware all phone numbers are greyed out until selecting an
                area code), which will supply a list of available phone numbers.
                Choose desired phone number and then click the{' '}
                <strong style={{ color: '#15b940' }}>green check</strong> button
                button to add the phone number.
              </p>
              <p>
                <i>Note: </i> Adding more numbers may result in a payment
                charge. Please refer to the account plan for more information.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={ManagePhoneMobileImgF}
                  alt='manage phones from mobile inbox'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%', border: '1px solid #000000' }}
                />
              </p>
              <h2 className='manage-phones-numbers'>
                Managing Your Phone Numbers
              </h2>
              <p>
                <i>You</i> will see all of your phone numbers here.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={ManagePhoneMobileImgE}
                  alt='mobile inbox'
                  className='img-fluid'
                  style={{ maxWidth: '400px', width: '100%', border: '1px solid #000000' }}
                />
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h3 className='user-assign'>Assign a User</h3>
                  <p>
                    Below the number, in the{' '}
                    <strong>Assign this number to a user</strong> list{' '}
                    <i>you</i> can select which <i>user</i> will be assigned to
                    this phone number. Assigning a <i>user</i> to a phone number
                    will allow that <i>user</i> to make phone calls and send
                    texts to <i>contacts</i> through the <strong>Inbox</strong>.
                  </p>
                </li>
                <li>
                  <h3 className='call-forwarding'>
                    Receive Phone Calls With Call Forwarding
                  </h3>
                  <p>
                    Under this <i>you</i> can input a number all calls will be
                    forwarded to (personal cellphone, work phone, etc.).
                  </p>
                </li>
              </ul>

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Remember that some area codes may not be available so{' '}
                    <i>you</i> may need try a different one.
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Forget adding more numbers than <i>your</i> subscription
                    plan allows will result in overage charges (<i>you</i> will
                    receive a warning notice near the phone number limit).
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <h3>Add A Phone Number</h3>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
