import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../generalCss/style.scss';
import './faq.scss';
import Fuse from 'fuse.js';
import $ from 'jquery';
import language from '../utils/vast-languagepack';
import TextField from '@material-ui/core/TextField';
import { debounce } from 'lodash';
import { DoneAll } from '@material-ui/icons'
import { Payment } from '@material-ui/icons';
import { Contacts } from '@material-ui/icons';
import { Group } from '@material-ui/icons';
import { Refresh } from '@material-ui/icons';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { Description } from '@material-ui/icons';
import { LockOpen } from '@material-ui/icons';
import { GroupAdd } from '@material-ui/icons';
import CampaignIcon from '../img/icon/campaignIcon.js';
import DynamicAudienceIcon from '../img/icon/dynamicAudience.js';
import EventGoalIcon from '../img/icon/eventGoal.js';
import { Dialpad } from '@material-ui/icons'; 
import ConversationIcon from '../img/icon/conversationIcon.js'; 
import { Phone } from '@material-ui/icons'; 
import { SpeakerNotes } from '@material-ui/icons'; 
import { FiberNew } from '@material-ui/icons'; 
import { SettingsApplications } from '@material-ui/icons';  
import { Assignment } from '@material-ui/icons';  
//TODO: [VR-1389] Navigation images for tutorials
const topics = [
  {
    to: '/GettingStarted',
    topic: 'Getting Started',
    meta: 'how do I get started with Vast Reach?',
    description:
      'Learn how to get started with Vast Reach Application and create your account.',
    icon: <FiberNew />,
  },
  {
    to: '/AccountSettings',
    topic: 'Account Settings',
    meta: 'How do begin setting up my account?',
    description:
      'Learn to set up your vast reach account Settings & Preferences.',
    icon: <SettingsApplications />,
  },
  {
    to: '/Payment',
    topic: 'Payment Set-Up',
    meta: 'how do I set up payment?',
    description: 'Manage your payment settings using our easy to use UI.',
    icon: <Payment />,
  },
  {
    to: '/ManagePhoneNumbers',
    topic: 'Manage Your Phone Numbers',
    meta: 'how do I manage my phone numbers?',
    description:
      'Manage multiple phone numbers on your account, including call forwarding numbers.',
    icon: <Dialpad />,
  },
  {
    to: '/ReCaptcha',
    topic: 'Setting Up ReCaptcha',
    meta: 'how do I add ReCaptcha to my forms?',
    description:
      'Add Google ReCaptcha to your forms to prevent spam from any device with Vast Reach!',
    icon: <Refresh />,
  },
  {
    to: '/Contacts',
    topic: 'Add Contacts',
    meta: 'how do I add contacts?',
    description:
      'Import your contacts and their data in once place knowing with a few taps you will be on your way to fully automating your work-flow.',
    icon: <Contacts />,
  },
  {
    to: '/MultiChannelInbox',
    topic: 'Engage Your Contacts With The Inbox',
    meta: 'how do I use the inbox?',
    description:
      'Engage with your customers using our Vast Reach inbox. Make and answer calls, send emails, text messages and much more!',
    icon: <ConversationIcon />,
  },
  {
    to: '/PhoneCalls',
    topic: 'Phone Calls',
    meta: 'how do I make phone calls?',
    description: 'Make phone calls and set up call forwarding with Vast Reach!',
    icon: <Phone />,
  },
  {
    to: '/TextMessageUseCase',
    topic: 'Best Practices for Text Message Marketing',
    meta: 'How to Text Message Market',
    description:
      'Vast Reach will teach you how to best use text messages for your marketing needs.',
    icon: <SpeakerNotes />,
  },
  {
    to: '/FormList',
    topic: 'Learning To Use The Form List',
    meta: 'how do I use the Form List?',
    description:
      'Understanding the Form List will allow you to oversee how your forms are doing.',
    icon: <ListAltIcon />,
  },
  {
    to: '/SignUpForms',
    topic: 'Sign-Up Form',
    meta: 'how do I create sign-up forms?',
    description:
      'Create sign-up forms using Vast Reach to reach new customers!',
    icon: <Description />,
  },
  {
    to: '/CustomForms',
    topic: 'Create Custom Forms',
    meta: 'how do I create custom forms?',
    description:
      'Create custom forms using Vast Reach to embed on your websites or share directly to your customers.',
    icon: <Assignment />,
  },
  {
    to: '/Tasks',
    topic: 'Tasks',
    meta: 'how do I create a task?',
    description: 'Automate your tasks with Vast Reach.',
    icon: <DoneAll />,
  },
  {
    to: '/Campaigns',
    topic: 'Everything You Need to Know About Campaigns',
    meta: 'how do I create a campaign?',
    description:
      'Automation simplified! With Vast Reach Funnel Builder, you can easily build complex campaigns with our user-friendly interface.',
    icon: <CampaignIcon />,
  },
  {
    to: '/EventGoals',
    topic: 'Understanding Event Goals',
    meta: 'What are Event Goals?',
    description:
      'Event Goals are the backbone to making campaign funnels work. When an event goal happens, it fires the tasks in a camping funnel to move down the funnel stages.',
    icon: <EventGoalIcon />,
  },
  {
    to: '/DripCampaigns',
    topic: 'Create Simple Drip Marketing Campaign (Schedule Send)',
    meta: 'how do I create a drip campaign?',
    description:
      'Automation simplified! With Vast Reach Funnel Builder, you can easily build complex campaigns with our user-friendly interface.',
    icon: <CampaignIcon />,
  },
  {
    to: '/SignUpCampaigns',
    topic: 'Create Simple Sign-Up Campaign Funnel',
    meta: 'how do I create a sign-Up form campaign?',
    description:
      'Automation simplified! With Vast Reach Funnel Builder, you can easily build complex campaigns with our user-friendly interface.',
    icon: <CampaignIcon />,
  },
  {
    to: '/Groups',
    topic: 'Create a Contact Group',
    meta: 'how do I create a group?',
    description:
      'Create a Contact Group with Vast Reach and customize your marketing for groups of customers you want to target uniquely.',
    icon: <Group />,
  },
  {
    to: '/CreateUser',
    topic: 'Invite New Users',
    meta: 'how do I create a user?',
    description: 'Create users by inviting them using our invite system. ',
    icon: <GroupAdd />,
  },
  {
    to: '/UserPermission',
    topic: 'Set-Up User Permission',
    meta: 'how do I set permissions for my users?',
    description:
      'Give your users the control they need to make progress with their tasks. ',
    icon: <LockOpen />,
  },
  {
    to: '/DynamicAudience',
    topic: 'Create a Dynamic Audience',
    description:
      'Create a dynamic audience with Vast Reach and customize your marketing for groups of customers you want to target uniquely.',
    icon: <DynamicAudienceIcon />,
  },
];

export default function Faq() {
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState(topics);
  const options = {
    includeScore: true,
    threshold: 0.2,
    keys: ['topic', 'to', 'meta', 'description'],
  };
  const fuse = new Fuse(topics, options);
  const sendQuery = query => query ? setSearchResults(fuse.search(query)) : setSearchResults(topics);
  const delayedQuery = useCallback(
    debounce((q) => sendQuery(q), 500),
    []
  );
  useEffect(() => {
    $.getScript('https://leads.vastreach.com/scripts/form_finder.js');
  })
 const onChange = (event) => {
   const input = event.target.value;
   setSearchInput(input);
   delayedQuery(input);
 }; 
  const topicUi = searchResults.map((result) => {
    let topic = result.item || result;
    return (
      <div>
        <span className='topic-header'>
          <p className='topic-icon'>{topic.icon}</p>
          <h3>{topic.topic}</h3>
        </span>
        <p>
          <em>{topic.description}</em>
        </p>
        <p className='topic-links'>
          <hr />
          <Link to={topic.to}>Learn More</Link>
          <br />
        </p>
      </div>
    );
  });
  return (
    <div>
      <div className='containter-fluid text-center page-banners banner-wrap'>
        <div className='container page-header-text'>
          <h1>{language.faq.heading}</h1>
        </div>
      </div>

      <div className='page-top-wrap'>
        <div className='container-fluid zero-padding features-wrap'>
          <div
            className='container-fluid pad-sides'
            style={{ paddingTop: '50px' }}
          >
            <div className='wrap'>
              <div className='search-form-wrap'>
                <TextField
                  id='outlined-basic'
                  label='Search Vast Reach Knowledge Center'
                  variant='outlined'
                  className='topic-search'
                  onChange={onChange}
                  value={searchInput}
                />
              </div>
            </div>
            <div className='row padding-full wrap'>
              <div className='container-fluid topic-container'>{topicUi}</div>
            </div>
          </div>
        </div>
        <div
          class='vast_reach_custom_form wrap'
          style={{padding: '0 0 40px 0'}}
          data-vast-form-id='55bdeed8-1391-4cac-84c5-569843478967'
        ></div>
      </div>
    </div>
  );
}

