import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../generalCss/style.scss';
import '../faq.scss';
import { Link } from 'react-router-dom';
import PhoneCallsScrollSpy from './PhoneCallsScrollSpy.js';
import MultiChannelInboxImgA from './img/inbox_active_phone_call.png';
import MultiChannelInboxImgE from './img/inbox_phone_call.png';
import MultiChannelInboxImgL from './img/call_indication.png';
import MultiChannelInboxImgM from './img/call_buttons.png';
import MultiChannelInboxImgB from '../MultiChannelInbox/img/inbox_email_triple_channel.jpg';
import video from './video/calling_contact.mp4';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

export default function PhoneCalls() {
  return (
    <div>
      <div className='container-fluid zero-padding features-wrap'>
        <div className='container-fluid pad-top pad-sides'>
          <div className='row pad-bottom wrap'>
            <PhoneCallsScrollSpy />

            <div className='col-xs-12 col-lg-9'>
              <div className='col-xs-12' style={{ textAlign: 'center' }}>
                <div className='button-wrap'>
                  <Link to='/PhoneCalls'>
                    <div className='deviceType-button'>Desktop</div>
                  </Link>
                </div>

                <div className='button-wrap'>
                  <Link to='/PhoneCallsMobile'>
                    <div className='deviceType-button'>Mobile</div>
                  </Link>
                </div>
              </div>
              <h1 className='text-left guide-heading'>MAKE PHONE CALLS</h1>
              <p>
                Phone calls allow <i>you</i> to have more personal one on one
                conversation with <i>your</i> contacts. This tutorial will guide{' '}
                <i>you</i> through making a phone call with <i>your</i>{' '}
                <i>contacts</i> and understanding call indications such as
                missed calls. <i>You</i> will learn how to make a phone call
                through the <strong>Inbox </strong> from <i>your</i> desktop
                browser by the end of this tutorial.
              </p>
              <h3 className='prerequisites'>
                Prerequisites Before Making a Phone Call
              </h3>
              <p>
                Before making a <strong>Phone Call</strong>, <i>you</i> must
                first have a account set up with an active subscription.{' '}
                <i>You</i> must also have a phone number added to the account
                using <strong>Manage Phone Numbers</strong>. Lastly, <i>you</i>{' '}
                must have a <i>contact</i> created to make a call to. Click the{' '}
                <strong style={{ color: '#d89300' }}>Yellow</strong>{' '}
                <strong>Buttons</strong> below to set up anything <i>you're</i>{' '}
                missing before beginning this tutorial if <i>you</i> have not
                done so already.
              </p>
              <p>
                <Link to='/Payment'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Payment Information
                  </button>
                </Link>
                <Link to='/ManagePhoneNumbers'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Manage Phone Numbers
                  </button>
                </Link>
                <Link to='/Contacts'>
                  <button className='dl-btn' style={{ margin: '10px' }}>
                    Create a Contact
                  </button>
                </Link>
              </p>
              <h3 className='navigation'>Navigate to Inbox</h3>
              <p>
                To navigate to the <strong>Inbox </strong> <i>you</i> can click
                the <strong>Inbox Icon</strong> from the navigation (Left side
                menu, first icon). Additionally, <i>you</i> can navigate to the{' '}
                <strong>Inbox</strong> page using the following link:{' '}
                <a href='https://console.vastreach.com/inbox'>
                  https://console.vastreach.com/inbox
                </a>
                . Once clicked <i>you</i> will be taken to the{' '}
                <strong>Inbox</strong> where <i>you</i> will see 3 columns.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={MultiChannelInboxImgB}
                  alt='inbox triple column open'
                  className='img-fluid'
                  style={{
                    maxWidth: '1000px',
                    width: '100%',
                  }}
                />
              </p>
              <h2 className='make-calls'>Make Phone Calls</h2>
              <p>
                To begin making a call <i>you</i> first have to select a phone
                number from the <strong>Call</strong> section of the{' '}
                <strong>TO</strong> list.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={MultiChannelInboxImgE}
                  alt='inbox phone ui'
                  className='img-fluid'
                  style={{
                    maxWidth: '1000px',
                    width: '100%',
                    margin: '10px',
                  }}
                />
              </p>
              <p>
                The text area below will be replaced by 2 icons (
                <strong>Mute Icon</strong> and{' '}
                <strong style={{ color: '#15b940' }}>Green</strong>{' '}
                <strong>Call Icon</strong>) and <strong>Manage Phones</strong>{' '}
                link at the bottom right. After selecting <i>your</i> phone
                number from the <strong>FROM</strong> list, <i>you</i> can click
                the <strong>Call Icon</strong> to begin a call with this{' '}
                <i>contact</i>.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={MultiChannelInboxImgM}
                  alt='inbox active phone call'
                  className='img-fluid'
                  style={{
                    maxWidth: '1000px',
                    width: '100%',
                    margin: '10px',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <p>
                Once <i>you</i> begin a call, the{' '}
                <strong style={{ color: '#15b940' }}>Green</strong>{' '}
                <strong>Call Icon</strong> now becomes a{' '}
                <strong style={{ color: '#ff0416' }}>Red</strong>{' '}
                <strong>End Call Icon</strong>. <i>You</i> can use the{' '}
                <strong>Mute Icon</strong> to mute or{' '}
                <strong>End Call Icon</strong> to end the call any time. The
                ongoing phone <strong>Call Duration</strong> will be shown above
                the icons.
              </p>
              <p style={{ textAlign: 'center' }}>
                <img
                  src={MultiChannelInboxImgA}
                  alt='inbox active phone call'
                  className='img-fluid'
                  style={{
                    maxWidth: '1000px',
                    width: '100%',
                    margin: '10px',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <p>
                <i>You</i> will see all call interactions as a message inside
                the <strong>Conversation Preview</strong>. For example, if{' '}
                <i>you</i> missed a call it will show a message bubble
                indicating who called, what time and how long the call was. At
                the center of the bubble will indicate the type of call with an{' '}
                <strong>icon and text</strong> (
                <strong>
                  contact called but missed, called and answered, called but
                  canceled
                </strong>
                ).
              </p>
              <p style={{ textAlign: 'left' }}>
                <img
                  src={MultiChannelInboxImgL}
                  alt='call indication message bubble'
                  className='img-fluid'
                  style={{
                    maxWidth: '500px',
                    width: '100%',
                    border: '1px solid #000000',
                  }}
                />
              </p>
              <p>
                <i>Note:</i> <i>You</i> can change <i>your</i> phone numbers by
                clicking the <strong>Manage Phones</strong> link on the bottom
                right. To learn more about how to mange <i>your</i> phone
                numbers click <Link to='/ManagePhoneNumbers'>here</Link>.
              </p>
              <p>
                <i>Note: </i> When clicking the{' '}
                <strong style={{ color: '#15b940' }}>Green</strong>{' '}
                <strong>Call Icon</strong> on <i>your</i> browser, <i>your</i>{' '}
                browser will prompt <i>you</i> to select <i>your</i>{' '}
                <strong>microphone</strong> and <strong>speakers</strong>.
                Please make sure <i>your</i> <strong>microphone</strong> and{' '}
                <strong>speakers</strong> are on before making calls.
              </p>

              <h2 className='do-dont'>Do's and Don'ts</h2>
              <h3 style={{ color: 'rgb(21, 185, 64)' }}>
                <ThumbUpIcon /> Do
              </h3>
              <ul>
                <li>
                  <p>
                    Upgrade <i>your</i> plan if <i>you</i> start to reach{' '}
                    <i>your</i> current plans minutes limit to avoid overages.
                  </p>
                </li>
                <li>
                  <p>
                    Make sure <i>your</i> <i>contacts</i> have a phone number
                    before attempting to make calls (it will not show up under
                    the <strong>TO</strong> list menu without a phone number on
                    that <i>contact</i>).
                  </p>
                </li>
              </ul>

              <h3 style={{ color: 'rgb(251, 47, 47, 1)' }}>
                <ThumbDownIcon /> Don't
              </h3>
              <ul>
                <li>
                  <p>
                    Forget to set up <i>your</i> <strong>microphone</strong> and{' '}
                    <strong>speakers</strong> on <i>your</i> desktop browser
                    before making calls.
                  </p>
                </li>
                <li>
                  <p>
                    Forget that creating more numbers than <i>your</i> plan
                    allows through <strong>Manage Phones</strong> will result in
                    overage charges.
                  </p>
                </li>
                <li>
                  <p>
                    Forget that exceeding <i>your</i> plans monthly minutes will
                    also result in overages.
                  </p>
                </li>
              </ul>

              <div className='video-wrapper'>
                <h2 className='video-demo'>Video Demonstration</h2>
                <video
                  src={video}
                  style={{ borderRadius: '10px' }}
                  width='100%'
                  controls
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
