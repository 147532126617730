export default {
  general: {
    startNowButton: 'Start Now',
    titleAlt: {
      rightCaretIcon: 'right caret icon',
    },
    errors: {
      Four04:
        'Unfortunately the page you are requesting does not exist Error Code 404',
    },
  },
  navi: {
    home: 'Home',
    about: 'About',
    features: 'Features',
    faq: 'Knowledge Center',
    titleAlt: {
      logoAlt: 'Main Logo',
    },
  },
  home: {
    homeHeading: 'VAST REACH',
    homeIntroOne: 'Marketing automation',
    homeIntroTwo: 'and',
    homeIntroThree: 'customer engagement',
    homeIntroFour: 'made easy',
    vastHeading: 'What is Vast Reach?',
    vastHeadingCallsMessages:
      'Make phone calls, send emails & send text messages',
    vastHeadingCampaign: 'Automated marketing campaigns',
    vastHeadingContacts: 'Import your contacts from anywhere',
    vastHeadingAutomation: 'Automate your work-flow',
    vastHeadingAnalyze: 'Analyze responses to forms and surveys',
    vastHeadingCrossPlatform:
      'Access Vast Reach from any device with cross-platform support',
    vastHeadingAi: 'Save time with our AI',
    vastMobileDownloadHeading: 'Download now on mobile',
    additionalUsageHeading: 'Track your usage',
    subscriptionPlansHeading: 'Subscription plans',
    vastDescription: 'Vast Reach is a ',
    vastDescriptionTwo: ' CRM and marketing platform ',
    vastDescriptionThree:
      'that puts intelligence into your hands! No more complicated work-flows. Creating ',
    vastDescriptionFour: 'Campaigns',
    vastDescriptionFive: ', ',
    vastDescriptionSix: 'Forms ',
    vastDescriptionSeven: 'and ',
    vastDescriptionEight: 'Personalized Messages ',
    vastDescriptionNine: 'has never been ',
    vastDescriptionTen: 'easier',
    vastDescriptionEleven: '.',
    vastDescriptionCallsMessages:
      'Calls & Messages is a multi-channel inbox that coordinates all of your conversations in one ',
    vastDescriptionCallsMessagesTwo: 'easy ',
    vastDescriptionCallsMessagesThree:
      'to follow dashboard. You can view and edit all of your contacts’ emails, text messages and phone call logs all in one place. Acquire phone numbers from any area code, send text messages, send emails, you can even make and receive phone calls!',
    vastDescriptionCampaigns: 'Automation simplified! ',
    vastDescriptionCampaignsTwo: 'With the Vast Reach ',
    vastDescriptionCampaignsThree: 'Funnel Builder',
    vastDescriptionCampaignsFour: ', you can ',
    vastDescriptionCampaignsFive: 'easily ',
    vastDescriptionCampaignsSix:
      'build complex campaigns with our user-friendly interface. Using our drag and drop system, you can build a campaign to automatically track your tasks and contacts based on predetermined goals. Easily tell what stage in a funnel your customers are in! The ',
    vastDescriptionCampaignsSeven: 'Campaign Calendar ',
    vastDescriptionCampaignsEight:
      'also allows you to schedule your tasks to execute at a specific date and time.',
    vastDescriptionContacts:
      "Import your contacts and their data in once place knowing with a few taps you'll be on your way to fully automating your work-flow.",
    vastDescriptionAutomation:
      'Leave all those routine tasks to Vast Reach so you can get more done with your day. Let us handle your day to day tasks and ',
    vastDescriptionAutomationTwo: 'To-do lists ',
    vastDescriptionAutomationThree: 'with our ',
    vastDescriptionAutomationFour: 'task manger',
    vastDescriptionAutomationFive: '.',
    vastDescriptionAnalyze:
      'With Vast Reach you can design surveys and web forms. You will have access to precise form response analytics. Analyze responses in great detail to give you insights on what your audiences want and need.',
    vastDescriptionCrossPlatform:
      'With Vast Reach, you have true cross-platform features. Whether on your ',
    vastDescriptionCrossPlatformTwo: 'mobile device',
    vastDescriptionCrossPlatformThree: ', ',
    vastDescriptionCrossPlatformFour: 'tablet',
    vastDescriptionCrossPlatformFive: ',  ',
    vastDescriptionCrossPlatformSix: 'desktop or laptop',
    vastDescriptionCrossPlatformSeven:
      ', you have access to all of the features and functionality.',
    vastDescriptionAi:
      'Our AI will help you save time setting up your tasks and campaigns. Reduce your mindless tasks with our AI and focus on engaging your customers!',
    vastMobileDownloadDescription: 'Vast Reach ',
    vastMobileDownloadDescription1: 'is available on ',
    vastMobileDownloadDescription2: 'iOS ',
    vastMobileDownloadDescription3: 'and ',
    vastMobileDownloadDescription4: 'Android',
    vastMobileDownloadDescription5: '. Click below to download. ',
    vastDescriptionAdditionalUsage:
      'Track all of your usage and data in one place. Monitor your month to month usage and make adjustments to your Vast Reach plan according to your business workflow needs.',
    vastDescriptionSubscriptionPlans:
      'You can subscribe to one of our monthly Vast Reach plans depending on how many users, emails and text message sends, call minutes, phone numbers and storage your business needs. Change your Vast Reach subscription plan at any time. All new Vast Reach customers receive their first 3 months free once you subscribe to any of our plans.',
    tabDescription: 'Be',
    tabDescriptionTwo: 'productive on day one',
    tabDescriptionThree:
      'and hit the ground running. After time our platform will learn your day-to-day process to',
    tabDescriptionFour: 'remove busy work',
    tabDescriptionFive:
      ', repetitive tasks and take your productivity to the next level!',
    homeButtons: {
      watchVideoButton: 'Watch Video',
      findOutMoreButton: 'Learn More',
      contactUsButton: 'Contact Us',
    },
    altTitles: {
      homeBanner:
        'Get more from your customers and relationships with Vast Reach',
      vastReach: 'Vast Reach',
      contacts: 'contacts',
      tasks: 'tasks',
      vastAi: 'Vast Reach AI',
      analyzeFormResponses: 'Form Responses',
      vastMobileDownload: 'Download Vast Reach on Mobile',
      iOSDownloadButton: 'download on iOS',
      androidDownloadButton: 'download on Android',
      appTabletView: 'Vast Reach app tablet view',
      additionalUsage: 'additional usage information',
    },
  },
  about: {
    heading: 'ABOUT US',
    productVisionHeading: 'Product Vision',
    visionDescription:
      'Vast Reach is a CRM and Marketing Platform that puts intelligence into your hands! No more complicated work-flows. Creating Campaigns, Forms and personalized messages has never been easier.',
    missionHeading: 'Mission',
    missionDescriptionOne: "Vast Reach's state of the art",
    missionDescriptionTwo: 'AI',
    missionDescriptionThree:
      'models and services and does the busy work for you which will allow you to put the focus on establishing and maintaining relationships with your customers.',
    missionDescriptionFour: 'Import your contacts from anywhere',
    missionDescriptionFive: 'and start',
    missionDescriptionSix: 'automating your repetitive tasks',
    missionDescriptionSeven: 'like sending personalized',
    missionDescriptionEight: 'email and SMS',
    missionDescriptionNine: 'messages.',
    aiHeading: 'Content Generation & AI',
    aiInfoOne:
      'Using our Natural Language Models can generate campaigns and automatons using simple commands.',
    aiInfoTwo: 'Send a coupon email to the VIP Group next Thursday',
    aiInfoThree: 'Create a Campaign with a with a 4 stage marketing funnel',
    aiInfoFour: 'Create a Drip Campaign with 4 emails and two SMS messages',
    aiInfoFive: 'Over time the AI will even learn new commands!',
    titleAlt: {
      productiveVisionImgAlt: 'Vast Reach app campaign builder',
      missionImgAlt: 'Vast Reach app smart tasks',
      aiImgAlt: 'Vast Reach AI',
    },
  },
  features: {
    heading: 'FEATURES',
    personalMsgHeading: 'Personalized Messaging',
    personalMsgDescription:
      'With the Vast Reach Template Manager, you can access several free themes and template merge fields to create personalized text messages and emails.',
    tasksHeading: 'Tasks',
    tasksDescription:
      'Tasks are the core of the Vast Reach automation platform! They allow you to automate previously manual tasks like sending custom emails, text messages and contact group management. You will also be able to  automate the assignment of daily to-dos to your team members.',
    formsHeading: 'Forms',
    formsDescription:
      'With Vast Reach Forms, you can easily create forms, surveys and questionnaires that can be shared using a link or embedded on your website with the ability to track opens and responses.',
    campaignsHeading: 'Campaigns',
    campaignsDescription:
      'Automation simplified! With the Vast Reach Funnel Builder, you can easily build complex campaigns with our user-friendly interface. Using our drag and drop system, you can build a campaign to automatically track your tasks and contacts based on predetermined goals. Easily tell what stage in a funnel your customers are in! The Campaign Calendar also allows you to schedule your tasks to execute at a specific date and time.',
    aiHeading: 'AI',
    aiDescription:
      'With Vast Reach, our state-of-the-art AI makes generating forms, campaigns, emails and task so simple by offering suggestions and options as you type.',
    crossPlatformHeading: 'Cross-Platform',
    crossPlatformDescription:
      'With Vast Reach, you have true cross-platform features. Whether on your mobile device, tablet, desktop or laptop, you have access to all of the features and functionality.',
    multiChanHeading: 'Calls & Messages',
    multiChanDescription:
      'Calls & Messages is a multi-channel inbox that coordinates all of your conversations in one easy to follow dashboard. You can view and edit all of your contacts’ emails, text messages and phone call logs all in one place. Acquire phone numbers from any area code, send text messages, send emails, you can even make and receive phone calls!',
    phoneCallsHeading: 'Phone Calls',
    phoneCallsDescription:
      'With Vast Reach, you can manage multiple phone numbers, set call forwarding and utilize other features within the app which makes multi-tasking easy.',
    titleAlt: {
      msgAlt: 'text message image',
      taskAlt: 'task list image',
      formAlt: 'form building image',
      campaignsAlt: 'Campaigns image',
      aiAlt: 'Vast Reach AI image',
      crossPlatformAlt: 'Cross Platform image',
      multiChanAlt: 'Multi-Channel Inbox image',
      phoneCallsAlt: 'Phone Call image',
    },
  },
  vastVideo: {
    vastVideoHeading: 'WHAT IS Vast Reach?',
    titleAlt: {
      whatIsVastTitle: 'What is Vast Reach?',
    },
  },
  footer: {
    vastFooterDescription:
      'Vast Reach is a CRM and Marketing Platform that puts intelligence into your hands! No more complicated work-flows. Creating Campaigns, Forms and personalized messages has never been easier.',
    contactUs: 'Contact Us & Legal',
    email: 'Email:',
    emailAddress: 'info@vastreach.com',
    menu: 'Menu',
    home: 'Home',
    about: 'About',
    faq: 'Knowledge Center',
    featureList: 'Feature List',
    copyright: `Vast Reach 2018 - ${new Date().getFullYear()}. All Rights Reserved.`,
    titleAlt: {
      footerLogoAlt: 'footer logo',
    },
  },
  faq: {
    heading: 'VAST REACH KNOWLEDGE CENTER',
  },
  verification: {
    fail: {
      heading: 'VERIFICATION FAILED!',
      failMessage: 'CLICK HERE',
      failMessage2: ' to contact support for assistance.',
    },
    success: {
      heading: 'Thank you!',
      heading2: 'Your email address was successfully verified.',
      successMessage: 'Return to ',
      successMessage2: 'Vast Reach Console',
    },
  },
  support: {
    heading: 'SUPPORT',
  },
};
