import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../generalCss/style.scss';
import './vastVideo.scss';
import video from './video/VastReach_vid.mp4';

export default function demo() {
  return (
    <div>
      <div className='padding-top'></div>
      <div className='container-fluid video-padding'>
        <h1 className='text-center'>VAST REACH DEMO</h1>
        <div className='container wrap'>
          <div className='videoWrapper'>
            <div className='video-wrapper'>
              <video
                src={video}
                style={{ borderRadius: '10px' }}
                width='100%'
                controls
              ></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
