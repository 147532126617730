import React from 'react';
import { Link } from 'react-router-dom';
import { SwipeableDrawer, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuIcon from '@material-ui/icons/Menu';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import Home from '@material-ui/icons/Home';
import Info from '@material-ui/icons/Info';
import HelpIcon from '@material-ui/icons/Help';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import language from '../utils/vast-languagepack';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:focus > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color='inherit' className={classes.labelIcon} />
          <Typography variant='body2' className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant='caption' color='inherit'>
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
});

export default function SimpleMenu() {
  const [state, setState] = React.useState({
    show: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, show: open });
  };

  const classes = useStyles();

  const handleClick = (vastLink) => () => {
    window.location.href = vastLink;
  };

  return (
    <div className='mobilenav'>
      <Button onClick={toggleDrawer(!state.show)}>
        {state.show ? <CloseIcon /> : <MenuIcon />}
      </Button>
      <SwipeableDrawer
        className='mobilesidenav'
        anchor='right'
        open={state.show}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <TreeView
          className={classes.root}
          defaultExpanded={['3']}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
        >
          <Link to='/index'>
            <StyledTreeItem
              nodeId='1'
              labelText={language.navi.home}
              labelIcon={Home}
              style={{ padding: '10px 0 10px 0' }}
            />
          </Link>
          <Link to='/about'>
            <StyledTreeItem
              nodeId='2'
              labelText={language.navi.about}
              labelIcon={Info}
              style={{ padding: '10px 0 10px 0' }}
            />
          </Link>
          <Link to='/features'>
            <StyledTreeItem
              nodeId='3'
              labelText={language.navi.features}
              labelIcon={FormatListNumbered}
              style={{ padding: '10px 0 10px 0' }}
            />
          </Link>
          <Link to='/faq'>
            <StyledTreeItem
              nodeId='4'
              labelText={language.navi.faq}
              labelIcon={HelpIcon}
              style={{ padding: '10px 0 10px 0' }}
            />
          </Link>
          <div onClick={handleClick('https://console.vastreach.com/')}>
            <StyledTreeItem
              nodeId='5'
              labelText='Log In'
              labelIcon='none'
              style={{ padding: '10px 0 10px 0' }}
            />
          </div>
          <Button color='inherit' className='dl-btn mobile-start-button'>
            <Link to='/form'>{language.general.startNowButton}</Link>
          </Button>
        </TreeView>
      </SwipeableDrawer>
    </div>
  );
}
